import React from 'react';

import { FunctionsProvider } from './Functions';
import { RefsProvider } from './Refs';
// Custom imports:
import { FirebaseProvider } from './Firebase';
import { DialogsProvider } from './Dialogs';
import { DatabasesProvider } from './Databases';


export const ContextProviders = ({ children }) => {
  // Custom code:
  return (
    
		<FirebaseProvider>
			<DatabasesProvider>
				<RefsProvider>
					<DialogsProvider>
						<FunctionsProvider>
							<>{children}</>
						</FunctionsProvider>
					</DialogsProvider>
				</RefsProvider>
			</DatabasesProvider>
		</FirebaseProvider>

  );
};


export * from './Functions';
export * from './Refs';
// Custom exports:
export * from './Firebase';
export * from './Dialogs';
export * from './Databases';
