import React from 'react';

// Custom imports:
import Account_deletionDialog from './Account_deletionDialog';
import Admin_edit_applicationDialog from './Admin_edit_applicationDialog';
import Admin_newcostDialog from './Admin_newcostDialog';
import Admin_newhousingDialog from './Admin_newhousingDialog';
import Admin_newrankingDialog from './Admin_newrankingDialog';
import Admin_newschoolDialog from './Admin_newschoolDialog';
import Application_messagesDialog from './Application_messagesDialog';
import Edit_dialogDialog from './Edit_dialogDialog';
import ErrorDialog from './ErrorDialog';
import Invalid_loginDialog from './Invalid_loginDialog';
import Main_dialogDialog from './Main_dialogDialog';
import Msg_i18nDialog from './Msg_i18nDialog';
import Recover_errorDialog from './Recover_errorDialog';
import Recover_passwordDialog from './Recover_passwordDialog';
import Recover_sentDialog from './Recover_sentDialog';
import Remove_dialogDialog from './Remove_dialogDialog';
import School_send_applicationDialog from './School_send_applicationDialog';
import Select_degreeDialog from './Select_degreeDialog';
import Select_degree_1Dialog from './Select_degree_1Dialog';
import Select_sportDialog from './Select_sportDialog';
import Signup_errorDialog from './Signup_errorDialog';
import Signup_wrongDialog from './Signup_wrongDialog';
import Stu_must_premiumDialog from './Stu_must_premiumDialog';
import Stu_send_applicationDialog from './Stu_send_applicationDialog';
import TermsDialog from './TermsDialog';
import Terms_acceptDialog from './Terms_acceptDialog';
import TranslateDialog from './TranslateDialog';
import Uni_savedDialog from './Uni_savedDialog';
import ToastContainer from 'react-bootstrap/ToastContainer';



export const Dialogs = () => {
  return (
    <>
      
			<Account_deletionDialog />
			<Admin_edit_applicationDialog />
			<Admin_newcostDialog />
			<Admin_newhousingDialog />
			<Admin_newrankingDialog />
			<Admin_newschoolDialog />
			<Application_messagesDialog />
			<Edit_dialogDialog />
			<ErrorDialog />
			<Invalid_loginDialog />
			<Main_dialogDialog />
			<Msg_i18nDialog />
			<Recover_errorDialog />
			<Recover_passwordDialog />
			<Recover_sentDialog />
			<Remove_dialogDialog />
			<School_send_applicationDialog />
			<Select_degreeDialog />
			<Select_degree_1Dialog />
			<Select_sportDialog />
			<Signup_errorDialog />
			<Signup_wrongDialog />
			<Stu_must_premiumDialog />
			<Stu_send_applicationDialog />
			<TermsDialog />
			<Terms_acceptDialog />
			<TranslateDialog />
			<ToastContainer className="p-3 position-fixed" position="bottom-end">
				<Uni_savedDialog />
			</ToastContainer>
      
    </>
  );
};

export default Dialogs;
