import React from 'react';
import { useFunctions } from '../hooks';

// Custom imports:
import { Offcanvas, List, Group, Text } from '../components';
import { useTranslation } from 'react-i18next';

export const Application_messagesDialog = () => {
    const { run } = useFunctions();
  // Custom exports:
	const { t } = useTranslation();

  // Custom functions:

  return (
    
    
			<Offcanvas id="application_messages" placement="end">
					<Offcanvas.Body>
						<List
							id="appMessageList"
							src="@firebase.firestore.students.(@cookie.userID).applications.(@cookie.applicationID).messages"
							order={{"type":"desc","field":"created_at"}}
							className="w-100 h-auto position-relative" >
							<Group
								id="messageRow"
								direction="vertical"
								className="w-100 h-auto position-relative p-16px" >
								<Text
									id="messageDate"
									format={{"type":"date","day":"numeric","month":"long","year":"numeric"}}
									tag="small"
									src="created_at"
									className="w-100 h-auto position-relative text-primary ff-bold" >
									{t('application_messages.messageDate')}
								</Text>
								<Text
									id="messageText"
									src="note"
									className="w-100 h-auto position-relative text-primary-text ff-regular" >
									{t('application_messages.messageText')}
								</Text>
							</Group>
						</List>
					</Offcanvas.Body>
			</Offcanvas>
    
  );
};

export default Application_messagesDialog;
