import React from 'react';
import { useDialogs } from '../hooks';
import { useFunctions } from '../hooks';

// Custom imports:
import { Modal } from '../components';
import { useTranslation } from 'react-i18next';

const Main_dialogDialog = () => {
    const { run } = useFunctions();
    // Custom exports:
	const { t } = useTranslation();

    // Custom functions:

  return (
    
    
			<Modal id="main_dialog">
					<Modal.Header>
						<Modal.Title>{t('main_dialog.title')}</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						{t('main_dialog.text')}
					</Modal.Body>
			</Modal>
    
  );
};

export default Main_dialogDialog;
