import React from 'react';
import { Container } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import { useFunctions, useRefs } from '../hooks';
import { FullscreenWrapper } from '../wrappers';

// Custom imports:
import { Group, Icon, Text, Image, Nav, Dropdown, Input } from '../components';
import { useEffect } from 'react';
import logo_72_white from '../assets/icon/logo_72_white.png';
import logo_72 from '../assets/icon/logo_72.png';
import { useTranslation } from 'react-i18next';

export const Profile_cView = () => {
  const { run } = useFunctions();
  // Custom exports:
	const { t } = useTranslation();

  // Custom functions:
	const profile_cOnLoaded = (options) => {
		run([{"function":"set","what":"@cookie.stuSportPreference","value":"@firebase.firestore.students.(@cookie.userID).sport_preference"},{"function":"set","what":"@cookie.stuSportLevel","value":"@firebase.firestore.students.(@cookie.userID).sport_level"},{"function":"set","what":"@cookie.stuSchoolInvestment","value":"@firebase.firestore.students.(@cookie.userID).school_investment"},{"function":"set","what":"@property.showLevel","value":false,"if":{"what":"@cookie.stuSportPreference","is":null,"or":{"what":"@cookie.stuSportPreference.length","is":0},"else":[{"function":"set","what":"@property.showLevel","value":true}]}}], options)
	}
	useEffect(profile_cOnLoaded, [])
	const homeFooterWeb1658767076276OnClick = (options) => {
		run([{"function":"browser","url":"https:\/\/www.maeducation.net","target":"_self"}], options)
	}
	const homeFooterEmail1658767076276OnClick = (options) => {
		run([{"function":"browser","url":"mailto:info@maeducation.net"}], options)
	}
	const homeFooterTel1658767076276OnClick = (options) => {
		run([{"function":"browser","url":"tel:+34646516514"}], options)
	}
	const homeFooterCookies1663113865909OnClick = (options) => {
		run([{"function":"set","what":"@property.policy","value":"cookies_policy"},{"function":"popup","dialog":"terms"}], options)
	}
	const homeFooterPrivacy1663113865909OnClick = (options) => {
		run([{"function":"set","what":"@property.policy","value":"privacy_policy"},{"function":"popup","dialog":"terms"}], options)
	}
	const formInvestment1658767076276OnLostFocus = (options) => {
		run([{"function":"set","what":"@firebase.firestore.students.(@cookie.userID).school_investment","value":"@cookie.stuSchoolInvestment"},{"function":"set","what":"@property.step3","value":true,"if":{"what":"@cookie.stuSchoolInvestment","is_not":null,"else":[{"function":"set","what":"@property.step3","value":false}]}},{"function":"set","what":"@firebase.firestore.students.(@cookie.userID).step3_sc","value":"@property.step3","if":{"what":"@property.step3","is_not":"@cookie.step3"}},{"function":"set","what":"@cookie.step3","value":"@property.step3","if":{"what":"@property.step3","is_not":"@cookie.step3"}}], options)
	}
	const formProfileSportLevelOnChanged = (options) => {
		run([{"function":"set","what":"@firebase.firestore.students.(@cookie.userID).sport_level","value":"@cookie.stuSportLevel"}], options)
	}
	const formOption1658767076276OnLostFocus = (options) => {
		run([{"function":"set","what":"@firebase.firestore.students.(@cookie.userID).sport_preference","value":"@cookie.stuSportPreference"},{"function":"set","what":"@property.showLevel","value":false,"if":{"what":"@cookie.stuSportPreference","is":null,"or":{"what":"@cookie.stuSportPreference.length","is":0},"else":[{"function":"set","what":"@property.showLevel","value":true}]}}], options)
	}
	const step3_but2Group1658767076276OnClick = (options) => {
		run([{"function":"goto","view":"profile_b"}], options)
	}
	const step3_but1Group1658767076276OnClick = (options) => {
		run([{"function":"goto","view":"profile_a"}], options)
	}
	const Session_menuItemsSessionLogoutOnClick = (options) => {
		run([{"function":"goto","view":"logout"}], options)
	}
	const Session_menuItemsSessionSettingsOnClick = (options) => {
		run([{"function":"goto","view":"account"}], options)
	}
	const Student_schools_menuItemsStudentProfile1658518549491OnClick = (options) => {
		run([{"function":"goto","view":"profile_a"}], options)
	}
	const Student_schools_menuItemsStudentFaq1658518549491OnClick = (options) => {
		run([{"function":"goto","view":"faq"}], options)
	}
	const Student_schools_menuItemsSchoolsApplicationsOnClick = (options) => {
		run([{"function":"goto","view":"applications"}], options)
	}
	const Student_schools_menuItemsStudentSchoolsOnClick = (options) => {
		run([{"function":"goto","view":"schools"}], options)
	}
	const homeBlueYoutubeIcon1658767076276OnClick = (options) => {
		run([{"function":"browser","url":"https:\/\/www.youtube.com\/channel\/UCmfIE4aab0Qjz5xuqD3Pbfg","target":"_maeyoutube"}], options)
	}
	const homeBlueTwitterIcon1658767076276OnClick = (options) => {
		run([{"function":"browser","url":"https:\/\/twitter.com\/mae_sports","target":"_maetwitter"}], options)
	}
	const homeBlueFacebookIcon1658767076276OnClick = (options) => {
		run([{"function":"browser","url":"https:\/\/www.facebook.com\/mae.sports\/","target":"_maefacebook"}], options)
	}
	const homeBlueInstagramIcon1658767076276OnClick = (options) => {
		run([{"function":"browser","url":"https:\/\/www.instagram.com\/mae.sports\/","target":"_maeinstagram"}], options)
	}
	const homeBlueEmail1658767076276OnClick = (options) => {
		run([{"function":"browser","url":"mailto:info@maeducationusa.com"}], options)
	}
	const homeBluePhone1658767076276OnClick = (options) => {
		run([{"function":"browser","url":"tel:+34646516514"}], options)
	}

  return (
    <>
      <Helmet>
				<title>{t('profile_c.title')}</title>
      </Helmet>
      <FullscreenWrapper>
          
			<Group
				id="stuProfileCBody"
				direction="vertical"
				className="w-100 min-h-100 fg-100 position-relative scrollable" >
				<Group
					id="stuMainHeader1658767076276"
					direction="vertical"
					className="w-100 h-auto position-relative" >
					<Group
						id="homeBlueBar1658767076276"
						direction="vertical"
						className="w-100 h-auto position-relative bg-primary" >
						<Group
							id="homeBlueContent1658767076276"
							direction="auto"
							className="w-100 h-auto align-center position-relative" >
							<Group
								id="homeBluePhone1658767076276"
								onClick={homeBluePhone1658767076276OnClick}
								direction="horizontal"
								className="w-auto h-auto align-center position-relative p-12px" >
								<Icon
									id="homeBluePhoneIcon1658767076276"
									src="phone"
									color="var(--text-on-primary)"
									className="w-auto h-auto position-relative"
								/>
								<Text
									id="homeBluePhoneNumber1658767076276"
									className="w-auto h-auto valign-center position-relative text-text-on-primary f-16px" >
									{t('profile_c.homeBluePhoneNumber1658767076276')}
								</Text>
							</Group>
							<Group
								id="homeBlueEmail1658767076276"
								onClick={homeBlueEmail1658767076276OnClick}
								direction="horizontal"
								className="w-auto h-auto align-center position-relative p-12px" >
								<Icon
									id="homeBlueEmailIcon1658767076276"
									src="email"
									color="var(--text-on-primary)"
									className="w-auto h-auto position-relative"
								/>
								<Text
									id="homeBlueEmailAddress1658767076276"
									className="w-auto h-auto valign-center position-relative text-text-on-primary f-16px" >
									{t('profile_c.homeBlueEmailAddress1658767076276')}
								</Text>
							</Group>
							<Group
								id="homeBlueSpacer1658767076276"
								direction="vertical"
								className="w-fill h-auto position-relative" >
							</Group>
							<Group
								id="homeBlueRightSide1658767076276"
								direction="vertical"
								className="w-auto h-auto align-center position-relative p-12px" >
								<Group
									id="homeBlueSSNN1658767076276"
									direction="horizontal"
									className="w-auto h-auto align-end position-relative" >
									<Icon
										id="homeBlueInstagramIcon1658767076276"
										onClick={homeBlueInstagramIcon1658767076276OnClick}
										src="instagram"
										color="var(--text-on-primary)"
										className="w-auto h-auto position-relative me-12px"
									/>
									<Icon
										id="homeBlueFacebookIcon1658767076276"
										onClick={homeBlueFacebookIcon1658767076276OnClick}
										src="facebook"
										color="var(--text-on-primary)"
										className="w-auto h-auto position-relative me-12px"
									/>
									<Icon
										id="homeBlueTwitterIcon1658767076276"
										onClick={homeBlueTwitterIcon1658767076276OnClick}
										src="twitter"
										color="var(--text-on-primary)"
										className="w-auto h-auto position-relative me-12px"
									/>
									<Icon
										id="homeBlueYoutubeIcon1658767076276"
										onClick={homeBlueYoutubeIcon1658767076276OnClick}
										src="youtube"
										color="var(--text-on-primary)"
										className="w-auto h-auto position-relative"
									/>
								</Group>
							</Group>
						</Group>
					</Group>
					<Group
						id="stuMainWhiteHeader1658767076276"
						direction="vertical"
						className="w-100 h-auto position-relative p-24px" >
						<Group
							id="homeHeaderContent1658767076276"
							direction="auto"
							className="w-100 h-auto align-center valign-center position-relative" >
							<Group
								id="homeNavBar1658767076276"
								direction="horizontal"
								className="min-w-320px w-fill h-auto align-start valign-center position-relative" >
								<Image
									id="homeLogo1658767076276"
									src={logo_72}
									className="w-auto min-h-64px h-64px valign-center position-relative" />
							</Group>
							<Group
								id="homeNavBarContent1658767076276"
								direction="auto"
								className="w-auto h-auto align-end valign-center position-relative" >
								<Nav
									id="homeMenu1658767076276"
									className="w-auto h-auto position-relative"
									direction="horizontal" >
									<Nav.Item title={t('student_schools_menu.items.studentSchools.title')} onClick={Student_schools_menuItemsStudentSchoolsOnClick} href="schools" />
									<Nav.Item title={t('student_schools_menu.items.schoolsApplications.title')} onClick={Student_schools_menuItemsSchoolsApplicationsOnClick} href="applications" />
									<Nav.Item title={t('student_schools_menu.items.studentFaq1658518549491.title')} onClick={Student_schools_menuItemsStudentFaq1658518549491OnClick} href="faq" />
									<Nav.Item title={t('student_schools_menu.items.studentProfile1658518549491.title')} onClick={Student_schools_menuItemsStudentProfile1658518549491OnClick} href="profile_a" />
								</Nav>
								<Dropdown
									id="applicationsAccountMenu1659654438257"
									className="w-auto h-auto border-radius-32px valign-center position-relative"
									variant="primary"
									title={<>

									<Text
										id="applicationsAccountMenu1659654438257Title"
										className="w-auto h-auto position-relative ff-regular" >
										{t('profile_c.applicationsAccountMenu1659654438257Title')}
									</Text>
								</>} >
									<Dropdown.Item title={t('session_menu.items.sessionSettings.title')} onClick={Session_menuItemsSessionSettingsOnClick} href="account" />
									<Dropdown.Item title={t('session_menu.items.sessionLogout.title')} onClick={Session_menuItemsSessionLogoutOnClick} href="logout" />
								</Dropdown>
							</Group>
						</Group>
					</Group>
				</Group>
				<Group
					id="tabsStepProfileC"
					direction="horizontal"
					className="w-100 h-auto position-relative" >
					<Group
						id="tabsLeftStep1658767076276"
						direction="vertical"
						className="w-24px min-h-100 h-100 border-gray_b border-bottom position-relative" >
					</Group>
					<Group
						id="step3_but1Group1658767076276"
						onClick={step3_but1Group1658767076276OnClick}
						direction="horizontal"
						className="max-w-30 w-auto min-h-64px h-64px border-top border-bottom border-start border-end border-radius-tl10px-tr10px align-center position-relative bg-light p-8px" >
						<Icon
							id="step1_but1_ko1658767076276"
							if={{"what":"@firebase.firestore.students.(@cookie.userID).step1_sc","is_not":true}}
							src="alert"
							size={1}
							color="var(--accent)"
							className="w-auto h-auto align-end valign-start position-absolute p-4px ms-8px"
						/>
						<Text
							id="step1_but1_ok1658767076276"
							tag="b"
							className="max-w-100 w-auto h-auto border-radius-50px valign-center position-relative py-6px text-primary ff-bold text-center" >
							{t('profile_c.step1_but1_ok1658767076276')}
						</Text>
					</Group>
					<Group
						id="tabsMid1Step1658767076276"
						direction="vertical"
						className="w-16px min-h-100 h-100 border-gray_b border-bottom position-relative" >
					</Group>
					<Group
						id="step3_but2Group1658767076276"
						onClick={step3_but2Group1658767076276OnClick}
						direction="horizontal"
						className="max-w-30 w-auto min-h-64px h-64px border-top border-bottom border-start border-end border-radius-tl10px-tr10px align-center position-relative bg-light p-8px" >
						<Icon
							id="step1_but2_ko1658767076276"
							if={{"what":"@firebase.firestore.students.(@cookie.userID).step2_sc","is_not":true}}
							src="alert"
							size={1}
							color="var(--accent)"
							className="w-auto h-auto align-end valign-start position-absolute p-4px ms-8px"
						/>
						<Text
							id="step1_but2_ok1658767076276"
							tag="b"
							className="max-w-100 w-auto h-auto valign-center position-relative px-16px py-6px text-dark ff-bold text-center" >
							{t('profile_c.step1_but2_ok1658767076276')}
						</Text>
					</Group>
					<Group
						id="tabsMid2Step1658767076276"
						direction="vertical"
						className="w-16px min-h-100 h-100 border-gray_b border-bottom position-relative" >
					</Group>
					<Group
						id="step3_but3Group1658767076276"
						direction="horizontal"
						className="max-w-30 w-auto min-h-64px h-64px border-top border-start border-end border-radius-tl10px-tr10px align-center position-relative p-8px" >
						<Icon
							id="step1_but3_ko1658767076276"
							if={{"what":"@firebase.firestore.students.(@cookie.userID).step3_sc","is_not":true}}
							src="alert"
							size={1}
							color="var(--accent)"
							className="w-auto h-auto align-end valign-start position-absolute p-4px"
						/>
						<Text
							id="step1_but3_ok1658767076276"
							tag="b"
							className="max-w-100 w-auto h-auto valign-center position-relative px-16px py-6px text-dark ff-bold text-center" >
							{t('profile_c.step1_but3_ok1658767076276')}
						</Text>
					</Group>
					<Group
						id="tabsRightStep1658767076276"
						direction="vertical"
						className="w-fill min-h-100 h-100 border-gray_b border-bottom position-relative" >
					</Group>
				</Group>
				<Group
					id="stuStep3Content1658767076276"
					direction="vertical"
					className="w-100 h-auto align-center position-relative p-24px" >
					<Text
						id="step3Title1658767076276"
						tag="h1"
						className="w-100 h-auto position-relative pt-40px text-primary f-32px ff-bold" >
						{t('profile_c.step3Title1658767076276')}
					</Text>
					<Group
						id="profileCform"
						direction="auto"
						className="w-100 h-auto position-relative" >
						<Group
							id="formWishSportMargin"
							direction="vertical"
							className="w-100 h-auto position-relative pe-32px mt-32px" >
							<Group
								id="formOption1Group1658767076276"
								direction="vertical"
								className="w-100 h-auto position-relative me-32px" >
								<Input.Text
									id="formOption1658767076276"
									label={t('profile_c.formOption1658767076276.label')}
									name="text"
									src="@cookie.stuSportPreference"
								onBlur={formOption1658767076276OnLostFocus}
									className="max-w-500px w-fill h-auto position-relative mt-24px" />
							</Group>
							<Group
								id="formProfileCLevelGroup"
								if={{"what":"@property.showLevel","is":true}}
								direction="vertical"
								className="w-300px h-auto position-relative me-32px" >
								<Input.Select
									id="formProfileSportLevel"
									label={t('profile_c.formProfileSportLevel.label')}
									name="select"
									src="@cookie.stuSportLevel"
									onChange={formProfileSportLevelOnChanged}
									className="w-auto h-auto position-relative mt-24px" >
									<option value={"aficionado"}>{t('profile_c.formProfileSportLevel.opt0')}</option>
									<option value={"amateur"}>{t('profile_c.formProfileSportLevel.opt1')}</option>
									<option value={"professional"}>{t('profile_c.formProfileSportLevel.opt2')}</option>
								</Input.Select>
							</Group>
						</Group>
						<Group
							id="formInvestmentMargin1658767076276"
							direction="vertical"
							className="max-w-500px w-100 h-auto position-relative pe-32px mt-64px" >
							<Input.Number
								id="formInvestment1658767076276"
								label={t('profile_c.formInvestment1658767076276.label')}
								name="text"
								src="@cookie.stuSchoolInvestment"
							onBlur={formInvestment1658767076276OnLostFocus}
								className="w-100 h-auto position-relative" />
							<Icon
								id="iconMandatory_1658767076276"
								if={{"what":"@cookie.stuSchoolInvestment","is":null,"or":{"what":"@cookie.stuSchoolInvestment.length","is":0}}}
								src="asterisk"
								color="var(--danger)"
								className="w-auto h-auto align-end valign-end position-absolute-bottom me-6px mb-6px"
							/>
						</Group>
					</Group>
				</Group>
				<Group
					id="uniInfoFooter1658767076276"
					direction="vertical"
					className="w-100 h-auto position-relative mt-48px" >
					<Group
						id="footerSpacer1658767076276"
						direction="vertical"
						className="w-100 min-h-300px h-300px position-relative" >
					</Group>
					<Group
						id="homeFooterBar1658767076276"
						direction="vertical"
						className="w-100 h-auto valign-end position-relative bg-footer_bg py-48px" >
						<Group
							id="homeFooterBarContent1658767076276"
							direction="auto"
							className="max-w-1200px w-100 h-auto align-center position-relative p-32px" >
							<Group
								id="homeFooterInfo1663113865909"
								direction="vertical"
								className="w-fill h-auto position-relative" >
								<Text
									id="homeFooterAbout1663113865909"
									tag="p"
									className="max-w-500px min-w-300px w-fill h-auto position-relative text-text-on-primary ff-medium" >
									{t('profile_c.homeFooterAbout1663113865909')}
								</Text>
								<Group
									id="homeFooterPolicy1663113865909"
									direction="auto"
									className="w-fill h-auto position-relative" >
									<Text
										id="homeFooterPrivacy1663113865909"
										onClick={homeFooterPrivacy1663113865909OnClick}
										tag="small"
										className="max-w-100 w-auto h-auto position-relative pe-8px text-gray_b ff-medium" >
										{t('profile_c.homeFooterPrivacy1663113865909')}
									</Text>
									<Text
										id="homeFooterPrivacySeparator1663113865909"
										tag="small"
										className="w-auto h-auto position-relative pe-8px text-gray_b ff-medium" >
										{t('profile_c.homeFooterPrivacySeparator1663113865909')}
									</Text>
									<Text
										id="homeFooterCookies1663113865909"
										onClick={homeFooterCookies1663113865909OnClick}
										tag="small"
										className="max-w-100 w-auto h-auto position-relative pe-8px text-gray_b ff-medium" >
										{t('profile_c.homeFooterCookies1663113865909')}
									</Text>
								</Group>
							</Group>
							<Group
								id="homeFooterSpacer1658767076276"
								direction="vertical"
								className="w-fill h-auto position-relative" >
							</Group>
							<Group
								id="homeFooterContact1658767076276"
								direction="vertical"
								className="max-w-400px min-w-300px w-fill h-auto position-relative" >
								<Image
									id="homeFooterLogo1658767076276"
									src={logo_72_white}
									className="w-auto min-h-72px h-72px align-end position-relative" />
								<Text
									id="homeFooterCities1658767076276"
									tag="p"
									className="w-auto h-auto align-end position-relative text-text-on-primary ff-medium text-end" >
									{t('profile_c.homeFooterCities1658767076276')}
								</Text>
								<Text
									id="homeFooterTel1658767076276"
									onClick={homeFooterTel1658767076276OnClick}
									tag="p"
									className="w-auto h-auto align-end position-relative text-text-on-primary ff-medium text-end" >
									{t('profile_c.homeFooterTel1658767076276')}
								</Text>
								<Text
									id="homeFooterEmail1658767076276"
									onClick={homeFooterEmail1658767076276OnClick}
									tag="p"
									className="w-auto h-auto align-end position-relative text-text-on-primary ff-medium text-end" >
									{t('profile_c.homeFooterEmail1658767076276')}
								</Text>
								<Text
									id="homeFooterWeb1658767076276"
									onClick={homeFooterWeb1658767076276OnClick}
									tag="p"
									className="w-auto h-auto align-end position-relative text-text-on-primary ff-medium text-end" >
									{t('profile_c.homeFooterWeb1658767076276')}
								</Text>
								<Group
									id="homeBlueSSNN1658767076276"
									direction="horizontal"
									className="w-auto h-auto align-end position-relative" >
									<Icon
										id="homeBlueInstagramIcon1658767076276"
										onClick={homeBlueInstagramIcon1658767076276OnClick}
										src="instagram"
										color="var(--text-on-primary)"
										className="w-auto h-auto position-relative me-12px"
									/>
									<Icon
										id="homeBlueFacebookIcon1658767076276"
										onClick={homeBlueFacebookIcon1658767076276OnClick}
										src="facebook"
										color="var(--text-on-primary)"
										className="w-auto h-auto position-relative me-12px"
									/>
									<Icon
										id="homeBlueTwitterIcon1658767076276"
										onClick={homeBlueTwitterIcon1658767076276OnClick}
										src="twitter"
										color="var(--text-on-primary)"
										className="w-auto h-auto position-relative me-12px"
									/>
									<Icon
										id="homeBlueYoutubeIcon1658767076276"
										onClick={homeBlueYoutubeIcon1658767076276OnClick}
										src="youtube"
										color="var(--text-on-primary)"
										className="w-auto h-auto position-relative"
									/>
								</Group>
							</Group>
						</Group>
					</Group>
				</Group>
			</Group>
      </FullscreenWrapper>
    </>
  );
};
