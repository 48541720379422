import React from 'react';
import { useDialogs } from '../hooks';
import { useFunctions } from '../hooks';

// Custom imports:
import { Modal, Input, Button } from '../components';
import { useTranslation } from 'react-i18next';

const Admin_newcostDialog = () => {
    const { run } = useFunctions();
    // Custom exports:
	const { t } = useTranslation();

    // Custom functions:
	const admin_newcost_buttons_positiveOnClick = (options) => {
		run([{"function":"add","what":{"title_es":"@property.costTitle_es","subtitle_es":"@property.costSubtitle_es","title_en":"@property.costTitle_en","subtitle_en":"@property.costSubtitle_en"},"to":"@firebase.firestore.schools.(@cookie.universityID).costs","if":{"what":"@property.costKey","is":false,"else":[{"function":"add","what":{"subtitle":"@property.costSubtitle_es","subtitle_en":"@property.costSubtitle_en","title_es":"@property.costTitle_es","title_en":"@property.costTitle_en"},"to":"@firebase.firestore.schools.(@cookie.universityID).costs","key":"@property.costKey"}]}},{"function":"hide","dialog":"admin_newcost"}], options)
	}
	const admin_newcost_buttons_negativeOnClick = (options) => {
		run([{"function":"hide","dialog":"admin_newcost"}], options)
	}

  return (
    
    
			<Modal id="admin_newcost">
					<Modal.Body>
						<Input.Text
							id="newCostTitle"
							label={t('admin_newcost.newCostTitle.label')}
							name="text"
							src="@property.costTitle_es"
							className="w-auto h-auto position-relative mt-24px" />
						<Input.Text
							id="newCostTitleEN"
							label={t('admin_newcost.newCostTitleEN.label')}
							name="text"
							src="@property.costTitle_en"
							className="w-auto h-auto position-relative" />
						<Input.Text
							id="newCostSubtitle"
							label={t('admin_newcost.newCostSubtitle.label')}
							name="text"
							src="@property.costSubtitle_es"
							className="w-auto h-auto position-relative mt-64px" />
						<Input.Text
							id="newCostSubtitle1665394295775"
							label={t('admin_newcost.newCostSubtitle1665394295775.label')}
							name="text"
							src="@property.costSubtitle_en"
							className="w-auto h-auto position-relative" />
					</Modal.Body>
					<Modal.Footer>
						<Button
							id="admin_newcost_buttons_negative"
							onClick={admin_newcost_buttons_negativeOnClick}
							variant="transparent"
							className="w-auto h-auto position-relative" >
							{t('admin_newcost.admin_newcost_buttons_negative')}
						</Button>
						<Button
							id="admin_newcost_buttons_positive"
							onClick={admin_newcost_buttons_positiveOnClick}
							variant="primary"
							className="w-auto h-auto position-relative" >
							{t('admin_newcost.admin_newcost_buttons_positive')}
						</Button>
					</Modal.Footer>
			</Modal>
    
  );
};

export default Admin_newcostDialog;
