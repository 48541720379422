import React from 'react';
import { Container } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import { useFunctions, useRefs } from '../hooks';
import { FullscreenWrapper } from '../wrappers';

// Custom imports:
import { Group, Image, Nav, Button, Dropdown, Text, Input, List, Icon } from '../components';
import logo_72_white from '../assets/icon/logo_72_white.png';
import user_placeholder from '../assets/image/user_placeholder.svg';
import home_promo from '../assets/image/home_promo.png';
import home_logos from '../assets/image/home_logos.png';
import medal from '../assets/image/medal.svg';
import home_girl from '../assets/image/home_girl.png';
import home_bg from '../assets/image/home_bg.png';
import flag_es from '../assets/assets/flag_es.png';
import logo_72 from '../assets/icon/logo_72.png';
import { useTranslation } from 'react-i18next';

export const HomenewView = () => {
  const { run } = useFunctions();
  // Custom exports:
	const { t } = useTranslation();

  // Custom functions:
	const homeBlueYoutubeIcon1665275533606OnClick = (options) => {
		run([{"function":"browser","url":"https:\/\/www.youtube.com\/channel\/UCmfIE4aab0Qjz5xuqD3Pbfg","target":"_maeyoutube"}], options)
	}
	const homeBlueTwitterIcon1665275533606OnClick = (options) => {
		run([{"function":"browser","url":"https:\/\/twitter.com\/mae_sports","target":"_maetwitter"}], options)
	}
	const homeBlueFacebookIcon1665275533606OnClick = (options) => {
		run([{"function":"browser","url":"https:\/\/www.facebook.com\/mae.sports\/","target":"_maefacebook"}], options)
	}
	const homeBlueInstagramIcon1665275533606OnClick = (options) => {
		run([{"function":"browser","url":"https:\/\/www.instagram.com\/mae.sports\/","target":"_maeinstagram"}], options)
	}
	const homeFooterWeb1665275533606OnClick = (options) => {
		run([{"function":"browser","url":"https:\/\/www.maeducation.net","target":"_self"}], options)
	}
	const homeFooterEmail1665275533606OnClick = (options) => {
		run([{"function":"browser","url":"mailto:info@maeducation.net"}], options)
	}
	const homeFooterTel1665275533606OnClick = (options) => {
		run([{"function":"browser","url":"tel:+34646516514"}], options)
	}
	const homeFooterCookies1665275533606OnClick = (options) => {
		run([{"function":"set","what":"@property.policy","value":"cookies_policy"},{"function":"popup","dialog":"terms"}], options)
	}
	const homeFooterPrivacy1665275533606OnClick = (options) => {
		run([{"function":"set","what":"@property.policy","value":"privacy_policy"},{"function":"popup","dialog":"terms"}], options)
	}
	const homeFormButtonUni1665262468011OnClick = (options) => {
		run([{"function":"popup","dialog":"signup_error","if":{"what":"@cookie.name","is":null,"or":{"what":"@cookie.lastname","is":null,"or":{"what":"@cookie.birthdate","is":null,"or":{"what":"@cookie.city","is":null,"or":{"what":"@cookie.email","is":null,"or":{"what":"@cookie.phone","is":null,"or":{"what":"@cookie.school","is":null,"or":{"what":"@cookie.schoolyear","is":null}}}}}}},"else":[{"function":"set","what":"@cookie.studentType","value":"student"},{"function":"goto","view":"stu_signup"}]}}], options)
	}
	const homeFormButtonSchool1665262468011OnClick = (options) => {
		run([{"function":"popup","dialog":"signup_error","if":{"what":"@cookie.name","is":null,"or":{"what":"@cookie.lastname","is":null,"or":{"what":"@cookie.birthdate","is":null,"or":{"what":"@cookie.city","is":null,"or":{"what":"@cookie.email","is":null,"or":{"what":"@cookie.phone","is":null,"or":{"what":"@cookie.school","is":null,"or":{"what":"@cookie.schoolyear","is":null}}}}}}},"else":[{"function":"set","what":"@cookie.studentType","value":"student_school"},{"function":"goto","view":"stu_signup"}]}}], options)
	}
	const homeMoreButtonOnClick = (options) => {
		run([{"function":"scroll","to":"@element.homeFormGroup"}], options)
	}
	const Lang_menuItemsOptENOnClick = (options) => {
		run([{"function":"set","what":"@app.language","value":"en"},{"function":"set","what":"@cookie.flag","value":"flag_en.png"},{"function":"set","what":"@cookie.langName","value":"English"}], options)
	}
	const Lang_menuItemsOptESOnClick = (options) => {
		run([{"function":"set","what":"@app.language","value":"es"},{"function":"set","what":"@cookie.flag","value":"flag_es.png"},{"function":"set","what":"@cookie.langName","value":"Espa\u00f1ol"}], options)
	}
	const headerFreeButton1665236195837OnClick = (options) => {
		run([{"function":"scroll","to":"@element.homeFormGroup"}], options)
	}
	const Main_menuItemsOption1OnClick = (options) => {
		run([{"function":"goto","view":"stu_login"}], options)
	}

  return (
    <>
      <Helmet>
				<title>{t('homenew.title')}</title>
      </Helmet>
      <FullscreenWrapper>
          
			<Group
				id="mainBody"
				direction="vertical"
				className="w-100 h-auto position-relative" >
				<Group
					id="homeHeader"
					direction="vertical"
					className="w-100 h-auto position-relative px-32px py-24px" >
					<Group
						id="homeHeaderContent"
						direction="auto"
						className="w-100 h-auto align-center valign-center position-relative" >
						<Group
							id="homeLogoWrapper1665236195837"
							direction="vertical"
							className="min-w-330px w-fill h-auto position-relative" >
							<Image
								id="homeLogo1665236195837"
								src={logo_72}
								className="w-auto min-h-64px h-64px valign-center position-relative" />
						</Group>
						<Group
							id="homeNavBarContent1665236195837"
							direction="auto"
							className="w-auto h-auto valign-center position-relative" >
							<Nav
								id="homeMenu1665236195837"
								className="w-auto h-auto valign-center position-relative my-4px"
								direction="horizontal" >
								<Nav.Item title={t('main_menu.items.option1.title')} onClick={Main_menuItemsOption1OnClick} href="stu_login" />
							</Nav>
							<Button
								id="headerFreeButton1665236195837"
								onClick={headerFreeButton1665236195837OnClick}
								variant="accent"
								className="min-w-150px w-auto h-auto valign-center position-relative my-4px" >
								{t('homenew.headerFreeButton1665236195837')}
							</Button>
							<Dropdown
								id="langDropdown1665236195837"
								className="w-auto h-auto border-radius-40px valign-center position-relative ms-24px my-4px"
								variant="primary"
								drop="down"
								align="end"
								title={<>

								<Image
									id="langDropdown1665236195837Icon"
									fallback={flag_es}
									src="@cookie.flag"
									className="w-24px h-auto position-relative" />
								<Text
									id="langDropdown1665236195837Title"
									src="@cookie.langName"
									className="w-auto h-auto position-relative ff-regular" >
									{t('homenew.langDropdown1665236195837Title')}
								</Text>
							</>} >
								<Dropdown.Item title={t('lang_menu.items.optES.title')} onClick={Lang_menuItemsOptESOnClick} />
								<Dropdown.Item title={t('lang_menu.items.optEN.title')} onClick={Lang_menuItemsOptENOnClick} />
							</Dropdown>
						</Group>
					</Group>
				</Group>
				<Group
					id="mainBanner"
					direction="vertical"
					className="w-100 h-auto position-relative" >
					<Group
						id="mainBannerBackground"
						direction="vertical"
						className="w-100 h-auto position-relative bg-gray_b" >
						<Group
							id="mainBannerWrapper"
							direction="horizontal"
							className="w-100 h-auto position-relative" >
							<Image
								id="image1665172560250"
								src={home_bg}
								className="w-100 min-h-100 h-100 position-absolute" />
							<Group
								id="homeBannerContent"
								direction="vertical"
								className="min-w-300px w-fill h-auto valign-start position-relative pt-96px mt-64px" >
								<Group
									id="homeBannerContentWrapper"
									direction="vertical"
									className="w-80 h-auto align-center valign-center position-relative pb-128px" >
									<Text
										id="homeBannerTitle"
										tag="h1"
										className="w-100 h-auto position-relative text-white ff-semibolditalic" >
										{t('homenew.homeBannerTitle')}
									</Text>
									<Text
										id="homeBannerDescription"
										tag="h3"
										className="w-100 h-auto position-relative mt-24px text-primary-dark ff-semibolditalic" >
										{t('homenew.homeBannerDescription')}
									</Text>
									<Text
										id="homeMoreButton"
										onClick={homeMoreButtonOnClick}
										className="w-auto h-auto border-radius-10px position-relative bg-primary px-16px py-8px mt-32px text-text-on-primary ff-semibolditalic" >
										{t('homenew.homeMoreButton')}
									</Text>
								</Group>
							</Group>
							<Image
								id="homeBannerGirl"
								src={home_girl}
								className="max-w-600px w-fill h-auto align-end valign-end position-relative" />
						</Group>
						<Group
							id="homeWhatGroup"
							direction="vertical"
							className="max-w-900px w-92 h-auto border-top border-bottom border-start border-end border-radius-32px align-center position-relative bg-white p-24px mt--128px mb-128px" >
							<Text
								id="homeWhatTitle"
								className="w-100 h-auto position-relative text-gray f-24px ff-semibolditalic" >
								{t('homenew.homeWhatTitle')}
							</Text>
							<Text
								id="homeWhatDescription"
								className="w-100 h-auto position-relative pb-16px mt-24px mb-64px text-primary ff-semibolditalic" >
								{t('homenew.homeWhatDescription')}
							</Text>
							<Image
								id="bannerMedal"
								src={medal}
								className="w-196px h-auto align-end valign-end position-absolute-bottom me-16px mb--96px" />
						</Group>
					</Group>
				</Group>
				<Group
					id="homeLogosGroup"
					direction="vertical"
					className="w-100 h-auto position-relative p-64px" >
					<Image
						id="image1665248567427"
						src={home_logos}
						className="w-80 h-auto align-center position-relative" />
				</Group>
				<Group
					id="homePromoGroup"
					direction="auto"
					className="w-100 h-auto position-relative p-48px mt-64px" >
					<Group
						id="homePromoImageArea"
						direction="vertical"
						className="min-w-300px w-fill h-auto position-relative pe-64px" >
						<Text
							id="text1665260892070"
							tag="h1"
							className="w-100 h-auto position-relative text-primary ff-bold" >
							{t('homenew.text1665260892070')}
						</Text>
						<Image
							id="image1665261049891"
							src={home_promo}
							className="w-100 h-auto position-relative" />
					</Group>
					<Group
						id="homePromoStepsArea"
						direction="vertical"
						className="min-w-300px w-fill h-auto position-relative" >
						<Group
							id="homeStep1"
							direction="vertical"
							className="w-100 h-auto position-relative mt-64px" >
							<Text
								id="step1Title"
								tag="h3"
								className="w-fill h-auto position-relative text-primary ff-bold" >
								{t('homenew.step1Title')}
							</Text>
							<Text
								id="stepDescription1"
								className="w-fill h-auto position-relative mt-24px text-primary-text ff-regular" >
								{t('homenew.stepDescription1')}
							</Text>
						</Group>
						<Group
							id="homeStep1665261536348"
							direction="vertical"
							className="w-100 h-auto position-relative mt-64px" >
							<Text
								id="stepTitle2"
								tag="h3"
								className="w-fill h-auto position-relative text-primary ff-bold" >
								{t('homenew.stepTitle2')}
							</Text>
							<Text
								id="text1665261536348"
								className="w-fill h-auto position-relative mt-24px text-primary-text ff-regular" >
								{t('homenew.text1665261536348')}
							</Text>
						</Group>
						<Group
							id="homeStep1665261590980"
							direction="vertical"
							className="w-100 h-auto position-relative mt-64px" >
							<Text
								id="stepTitle3"
								tag="h3"
								className="w-fill h-auto position-relative text-primary ff-bold" >
								{t('homenew.stepTitle3')}
							</Text>
							<Text
								id="stepDescription"
								className="w-fill h-auto position-relative mt-24px text-primary-text ff-regular" >
								{t('homenew.stepDescription')}
							</Text>
						</Group>
					</Group>
				</Group>
				<Group
					id="homeFormGroup"
					direction="vertical"
					className="w-100 h-auto position-relative p-48px" >
					<Group
						id="homeForm1665262468011"
						direction="vertical"
						className="max-w-1200px w-100 h-auto align-center position-relative bg-form_background p-32px mt-32px" >
						<Text
							id="homeFormTitle1665262468011"
							className="w-100 h-auto position-relative p-24px text-primary f-32px ff-bold text-center" >
							{t('homenew.homeFormTitle1665262468011')}
						</Text>
						<Text
							id="homeFormSubtitle1665262468011"
							className="w-100 h-auto position-relative p-24px text-primary f-26px ff-bold text-center" >
							{t('homenew.homeFormSubtitle1665262468011')}
						</Text>
						<Group
							id="homeFormInputs1665262468011"
							direction="vertical"
							className="w-100 h-auto position-relative p-24px mt-24px" >
							<Input.Text
								id="homeFormName1665262468011"
								name="text"
								src="@cookie.name"
							placeholder={t('homenew.homeFormName1665262468011.hint')}
								className="w-100 h-auto border-form_inputs position-relative bg-form_inputs p-16px" />
							<Input.Text
								id="homeFormLastName1665262468011"
								name="text"
								src="@cookie.lastname"
							placeholder={t('homenew.homeFormLastName1665262468011.hint')}
								className="w-100 h-auto border-form_inputs position-relative bg-form_inputs p-16px mt-24px" />
							<Input.Date
								id="homeFormBirthday1665262468011"
								label={t('homenew.homeFormBirthday1665262468011.label')}
								name="text"
								src="@cookie.birthdate"
								className="w-100 h-auto border-form_inputs position-relative bg-form_inputs p-16px mt-24px" />
							<Input.Text
								id="homeFormCity1665262468011"
								name="text"
								src="@cookie.city"
							placeholder={t('homenew.homeFormCity1665262468011.hint')}
								className="w-100 h-auto border-form_inputs position-relative bg-form_inputs p-16px mt-24px" />
							<Input.Text
								id="homeFormEmail1665262468011"
								name="text"
								src="@cookie.email"
							placeholder={t('homenew.homeFormEmail1665262468011.hint')}
								className="w-100 h-auto border-form_inputs position-relative bg-form_inputs p-16px mt-24px" />
							<Input.Text
								id="homeFormPhone1665262468011"
								name="text"
								src="@cookie.phone"
							placeholder={t('homenew.homeFormPhone1665262468011.hint')}
								className="w-100 h-auto border-form_inputs position-relative bg-form_inputs p-16px mt-24px" />
							<Input.Text
								id="homeFormSchool1665262468011"
								name="text"
								src="@cookie.school"
							placeholder={t('homenew.homeFormSchool1665262468011.hint')}
								className="w-100 h-auto border-form_inputs position-relative bg-form_inputs p-16px mt-48px" />
							<Input.Text
								id="homeFormYear1665262468011"
								name="text"
								src="@cookie.schoolyear"
							placeholder={t('homenew.homeFormYear1665262468011.hint')}
								className="w-100 h-auto border-form_inputs position-relative bg-form_inputs p-16px mt-24px" />
							<Input.Select
								id="homeFormInterested1665262468011"
								label={t('homenew.homeFormInterested1665262468011.label')}
								name="text"
								src="@cookie.studentType"
								className="w-100 h-auto border-form_inputs position-relative bg-form_inputs p-16px mt-24px" >
								<option value={"student_school"}>{t('homenew.homeFormInterested1665262468011.opt0')}</option>
								<option value={"student"}>{t('homenew.homeFormInterested1665262468011.opt1')}</option>
							</Input.Select>
							<Group
								id="homeFormButtons1665262468011"
								direction="auto"
								className="max-w-90 w-auto h-auto align-center position-relative" >
								<Button
									id="homeFormButtonSchool1665262468011"
									onClick={homeFormButtonSchool1665262468011OnClick}
									variant="primary"
									className="w-auto h-auto align-end position-relative p-16px me-8px mt-32px mb-16px" >
									{t('homenew.homeFormButtonSchool1665262468011')}
								</Button>
								<Button
									id="homeFormButtonUni1665262468011"
									onClick={homeFormButtonUni1665262468011OnClick}
									variant="primary"
									className="w-auto h-auto align-end position-relative p-16px mt-32px mb-16px" >
									{t('homenew.homeFormButtonUni1665262468011')}
								</Button>
							</Group>
						</Group>
					</Group>
				</Group>
				<Group
					id="homeReviewsGroup"
					direction="vertical"
					className="w-fill h-auto position-relative mt-64px" >
					<Text
						id="homeReviewsTitle"
						tag="h1"
						className="w-100 h-auto position-relative text-primary ff-bold text-center" >
						{t('homenew.homeReviewsTitle')}
					</Text>
					<Text
						id="homeReviewsDescription"
						className="w-100 h-auto position-relative mt-24px text-primary-text ff-regular text-center" >
						{t('homenew.homeReviewsDescription')}
					</Text>
					<Group
						id="reviewListWrapper"
						direction="vertical"
						className="w-100 h-auto position-relative mt-32px" >
						<List
							id="reviewList"
							direction="auto"
							src="@firebase.firestore.data.reviews.list"
							columns={"auto"}
							className="w-auto h-auto align-center position-relative" >
							<Group
								id="reviewListCellWrapper"
								direction="vertical"
								className="max-w-550px min-w-300px w-100 h-auto position-relative p-24px" >
								<Group
									id="reviewListCard"
									direction="vertical"
									className="w-fill min-h-280px h-auto border-top border-bottom border-start border-end border-radius-15px align-center position-relative p-16px" >
									<Group
										id="reviewCardHeader"
										direction="horizontal"
										className="w-100 h-auto position-relative" >
										<Image
											id="homeReviewsFace"
											fallback={user_placeholder}
											src="image"
											className="w-72px min-h-72px h-72px border-radius-200px position-relative cover" />
										<Group
											id="reviewPersonGroup"
											direction="vertical"
											className="w-100 h-auto valign-center position-relative ms-24px" >
											<Text
												id="homeReviewsDescription1665279229985"
												src="name"
												className="w-100 h-auto position-relative text-primary ff-bold text-start" >
												{t('homenew.homeReviewsDescription1665279229985')}
											</Text>
											<Text
												id="homeReviewsPosition"
												tag="small"
												src="position"
												className="w-100 h-auto position-relative mt-4px text-primary-text ff-regular text-start" >
												{t('homenew.homeReviewsPosition')}
											</Text>
										</Group>
									</Group>
									<Text
										id="homeReviewsPosition1665279370748"
										tag="small"
										src="comment"
										className="w-100 h-auto position-relative mt-24px text-primary-text ff-regular text-start" >
										{t('homenew.homeReviewsPosition1665279370748')}
									</Text>
								</Group>
							</Group>
						</List>
					</Group>
				</Group>
				<Group
					id="homeFooter1665275533606"
					direction="vertical"
					className="w-100 h-auto position-relative mt-48px" >
					<Group
						id="homeFooterBar1665275533606"
						direction="vertical"
						className="w-100 h-auto valign-end position-relative bg-footer_bg py-48px" >
						<Group
							id="homeFooterBarContent1665275533606"
							direction="auto"
							className="max-w-1200px w-100 h-auto align-center position-relative p-32px" >
							<Group
								id="homeFooterInfo1665275533606"
								direction="vertical"
								className="min-w-300px w-fill h-auto position-relative" >
								<Text
									id="homeFooterAbout1665275533606"
									tag="p"
									className="max-w-500px min-w-300px w-fill h-auto position-relative text-text-on-primary ff-medium" >
									{t('homenew.homeFooterAbout1665275533606')}
								</Text>
								<Group
									id="homeFooterPolicy1665275533606"
									direction="auto"
									className="w-fill h-auto position-relative mb-24px" >
									<Text
										id="homeFooterPrivacy1665275533606"
										onClick={homeFooterPrivacy1665275533606OnClick}
										tag="small"
										className="max-w-100 w-auto h-auto position-relative pe-8px text-gray_b ff-medium" >
										{t('homenew.homeFooterPrivacy1665275533606')}
									</Text>
									<Text
										id="homeFooterPrivacySeparator1665275533606"
										tag="small"
										className="w-auto h-auto position-relative pe-8px text-gray_b ff-medium" >
										{t('homenew.homeFooterPrivacySeparator1665275533606')}
									</Text>
									<Text
										id="homeFooterCookies1665275533606"
										onClick={homeFooterCookies1665275533606OnClick}
										tag="small"
										className="max-w-100 w-auto h-auto position-relative pe-8px text-gray_b ff-medium" >
										{t('homenew.homeFooterCookies1665275533606')}
									</Text>
								</Group>
							</Group>
							<Group
								id="homeFooterSpacer1665275533606"
								direction="vertical"
								className="w-fill h-auto position-relative" >
							</Group>
							<Group
								id="homeFooterContact1665275533606"
								direction="vertical"
								className="max-w-400px min-w-300px w-fill h-auto position-relative" >
								<Image
									id="homeFooterLogo1665275533606"
									src={logo_72_white}
									className="w-auto min-h-72px h-72px align-end position-relative" />
								<Text
									id="homeFooterCities1665275533606"
									tag="p"
									className="w-auto h-auto align-end position-relative text-text-on-primary ff-medium text-end" >
									{t('homenew.homeFooterCities1665275533606')}
								</Text>
								<Text
									id="homeFooterTel1665275533606"
									onClick={homeFooterTel1665275533606OnClick}
									tag="p"
									className="w-auto h-auto align-end position-relative text-text-on-primary ff-medium text-end" >
									{t('homenew.homeFooterTel1665275533606')}
								</Text>
								<Text
									id="homeFooterEmail1665275533606"
									onClick={homeFooterEmail1665275533606OnClick}
									tag="p"
									className="w-auto h-auto align-end position-relative text-text-on-primary ff-medium text-end" >
									{t('homenew.homeFooterEmail1665275533606')}
								</Text>
								<Text
									id="homeFooterWeb1665275533606"
									onClick={homeFooterWeb1665275533606OnClick}
									tag="p"
									className="w-auto h-auto align-end position-relative text-text-on-primary ff-medium text-end" >
									{t('homenew.homeFooterWeb1665275533606')}
								</Text>
								<Group
									id="homeBlueSSNN1665275533606"
									direction="horizontal"
									className="w-auto h-auto align-end position-relative" >
									<Icon
										id="homeBlueInstagramIcon1665275533606"
										onClick={homeBlueInstagramIcon1665275533606OnClick}
										src="instagram"
										color="var(--text-on-primary)"
										className="w-auto h-auto position-relative me-12px"
									/>
									<Icon
										id="homeBlueFacebookIcon1665275533606"
										onClick={homeBlueFacebookIcon1665275533606OnClick}
										src="facebook"
										color="var(--text-on-primary)"
										className="w-auto h-auto position-relative me-12px"
									/>
									<Icon
										id="homeBlueTwitterIcon1665275533606"
										onClick={homeBlueTwitterIcon1665275533606OnClick}
										src="twitter"
										color="var(--text-on-primary)"
										className="w-auto h-auto position-relative me-12px"
									/>
									<Icon
										id="homeBlueYoutubeIcon1665275533606"
										onClick={homeBlueYoutubeIcon1665275533606OnClick}
										src="youtube"
										color="var(--text-on-primary)"
										className="w-auto h-auto position-relative"
									/>
								</Group>
							</Group>
						</Group>
					</Group>
				</Group>
			</Group>
      </FullscreenWrapper>
    </>
  );
};
