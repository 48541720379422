import React from 'react';
import { Route, Redirect, useLocation } from 'react-router-dom';
import Cookies from 'js-cookie';

export const PrivateRoute = ({ component: Component, ...rest }) => {
  const location = useLocation();
  let pathname = 'login';
  const user = JSON.parse(Cookies.get('__fbu') || '{}');
  const isPermitted = !!user.id;

  return (
    <Route
      {...rest}
      render={(props) =>
        isPermitted ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              ...location,
              pathname,
              state: { from: props.location },
            }}
          />
        )
      }
    />
  );
};

export default PrivateRoute;
