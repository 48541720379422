import React from 'react';
import { useTranslation } from 'react-i18next';
import { useFunctions } from '../hooks';
import DoyoBadge from '../components/DoyoBadge';

// Custom imports:
import { Group, Nav, Image } from '../components';
import logo_72 from '../assets/icon/logo_72.png';
import Navbar from 'react-bootstrap/Navbar';
import Offcanvas from 'react-bootstrap/Offcanvas';
import Container from 'react-bootstrap/Container';
import { Link } from 'react-router-dom'

export const Private_adminWrapper = ({ children }) => {
  const { t } = useTranslation();
  const { run } = useFunctions();
  
  // Custom consts:

  // Custom functions:
	const Admin_menuItemsAdmin_logoutOnClick = (options) => {
		run([{"function":"goto","view":"logout"}], options)
	}
	const Admin_menuItemsMenuAdminFaqOnClick = (options) => {
		run([{"function":"goto","view":"admin_faq"}], options)
	}
	const Admin_menuItemsMenuAdminApplicationsOnClick = (options) => {
		run([{"function":"goto","view":"admin_students"}], options)
	}
	const Admin_menuItemsMenuAdminStudentsOnClick = (options) => {
		run([{"function":"goto","view":"admin_students"}], options)
	}
	const Admin_menuItemsMenuAdminSchoolsOnClick = (options) => {
		run([{"function":"goto","view":"admin_schools"}], options)
	}
	const Admin_menuItemsMenuAdminUnisOnClick = (options) => {
		run([{"function":"goto","view":"admin_main"}], options)
	}

  return (
    <main id="Private_adminWrapper" className="view h-fill">
        
				<Navbar bg="white" expand="lg" sticky="top" className="z-index-10 px-3 border-bottom">
					<Navbar.Toggle aria-controls="basic-navbar-nav" className="border-0 rounded-circle me-3 p-1" />
					<Navbar.Offcanvas id="basic-navbar-nav" className="w-auto d-lg-none">
					<Offcanvas.Body className="p-0">
						<Nav
							id="private_admin_actionbar_menu"
							className="w-auto h-auto align-end valign-center position-relative" >
						</Nav>
						<Group
							id="private_admin_actionbar_content"
							className="w-auto h-auto position-relative" >
							<Nav
								id="component1649630151488"
								className="w-auto h-auto position-relative" >
								<Nav.Item title={t('admin_menu.items.menuAdminUnis.title')} onClick={Admin_menuItemsMenuAdminUnisOnClick} href="admin_main" />
								<Nav.Item title={t('admin_menu.items.menuAdminSchools.title')} onClick={Admin_menuItemsMenuAdminSchoolsOnClick} href="admin_schools" />
								<Nav.Item title={t('admin_menu.items.menuAdminStudents.title')} onClick={Admin_menuItemsMenuAdminStudentsOnClick} href="admin_students" />
								<Nav.Item title={t('admin_menu.items.menuAdminApplications.title')} onClick={Admin_menuItemsMenuAdminApplicationsOnClick} href="admin_students" />
								<Nav.Item title={t('admin_menu.items.menuAdminFaq.title')} onClick={Admin_menuItemsMenuAdminFaqOnClick} href="admin_faq" />
								<Nav.Item title={t('admin_menu.items.admin_logout.title')} onClick={Admin_menuItemsAdmin_logoutOnClick} href="logout" />
							</Nav>
						</Group>
					</Offcanvas.Body>
					</Navbar.Offcanvas>
						<Navbar.Brand>
							<Link to="/">
								<Image
									id="private_admin_actionbar_logo"
									src={logo_72}
									className="w-auto min-h-40px h-40px position-relative" />
							</Link>
						</Navbar.Brand>
				<Group className="w-auto h-auto d-none d-lg-flex">
						<Nav
							id="private_admin_actionbar_menu"
							className="w-auto h-auto align-end valign-center position-relative" >
						</Nav>
				</Group>
				<Group className="w-fill h-auto" />
				<Group className="w-auto h-auto d-none d-lg-flex">
						<Group
							id="private_admin_actionbar_content"
							className="w-auto h-auto position-relative" >
							<Nav
								id="component1649630151488"
								className="w-auto h-auto position-relative" >
								<Nav.Item title={t('admin_menu.items.menuAdminUnis.title')} onClick={Admin_menuItemsMenuAdminUnisOnClick} href="admin_main" />
								<Nav.Item title={t('admin_menu.items.menuAdminSchools.title')} onClick={Admin_menuItemsMenuAdminSchoolsOnClick} href="admin_schools" />
								<Nav.Item title={t('admin_menu.items.menuAdminStudents.title')} onClick={Admin_menuItemsMenuAdminStudentsOnClick} href="admin_students" />
								<Nav.Item title={t('admin_menu.items.menuAdminApplications.title')} onClick={Admin_menuItemsMenuAdminApplicationsOnClick} href="admin_students" />
								<Nav.Item title={t('admin_menu.items.menuAdminFaq.title')} onClick={Admin_menuItemsMenuAdminFaqOnClick} href="admin_faq" />
								<Nav.Item title={t('admin_menu.items.admin_logout.title')} onClick={Admin_menuItemsAdmin_logoutOnClick} href="logout" />
							</Nav>
						</Group>
				</Group>
				</Navbar>
		
				<Group className="flex-grow-1">{children}</Group>
        <DoyoBadge />
    </main>
  );
};
