import React from 'react';
import { Container } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import { useFunctions, useRefs } from '../hooks';
import { FullscreenWrapper } from '../wrappers';

// Custom imports:
import { Group, Icon, Image, Button, Text, List, Video } from '../components';
import { useEffect } from 'react';
import placeholder_banner from '../assets/image/placeholder_banner.jpg';
import logo_placeholder from '../assets/image/logo_placeholder.png';
import placeholder_uni from '../assets/image/placeholder_uni.jpg';
import heart_on from '../assets/assets/heart_on.svg';
import heart_off from '../assets/assets/heart_off.svg';
import logo_72_white from '../assets/icon/logo_72_white.png';
import { useTranslation } from 'react-i18next';

export const SchoolView = () => {
  const { run } = useFunctions();
  // Custom exports:
	const { t } = useTranslation();

  // Custom functions:
	const schoolOnLoaded = (options) => {
		run([{"function":"set","what":"@cookie.lang","value":"es","if":{"what":"@app.language","starts_with":"es","else":[{"function":"set","what":"@cookie.lang","value":"en"}]}}], options)
	}
	useEffect(schoolOnLoaded, [])
	const homeBlueYoutubeIcon1658518569672OnClick = (options) => {
		run([{"function":"browser","url":"https:\/\/www.youtube.com\/channel\/UCmfIE4aab0Qjz5xuqD3Pbfg","target":"_maeyoutube"}], options)
	}
	const homeBlueTwitterIcon1658518569672OnClick = (options) => {
		run([{"function":"browser","url":"https:\/\/twitter.com\/mae_sports","target":"_maetwitter"}], options)
	}
	const homeBlueFacebookIcon1658518569672OnClick = (options) => {
		run([{"function":"browser","url":"https:\/\/www.facebook.com\/mae.sports\/","target":"_maefacebook"}], options)
	}
	const homeBlueInstagramIcon1658518569672OnClick = (options) => {
		run([{"function":"browser","url":"https:\/\/www.instagram.com\/mae.sports\/","target":"_maeinstagram"}], options)
	}
	const homeFooterWeb1658518569672OnClick = (options) => {
		run([{"function":"browser","url":"https:\/\/www.maeducation.net","target":"_self"}], options)
	}
	const homeFooterEmail1658518569672OnClick = (options) => {
		run([{"function":"browser","url":"mailto:info@maeducation.net"}], options)
	}
	const homeFooterTel1658518569672OnClick = (options) => {
		run([{"function":"browser","url":"tel:+34646516514"}], options)
	}
	const homeFooterCookies1663113479180OnClick = (options) => {
		run([{"function":"set","what":"@property.policy","value":"cookies_policy"},{"function":"popup","dialog":"terms"}], options)
	}
	const homeFooterPrivacy1663113479180OnClick = (options) => {
		run([{"function":"set","what":"@property.policy","value":"privacy_policy"},{"function":"popup","dialog":"terms"}], options)
	}
	const buttonFooterReviewApplication1658518569672OnClick = (options) => {
		run([{"function":"goto","view":"applications"}], options)
	}
	const buttonFooterApply1658518569672OnClick = (options) => {
		run([{"function":"popup","dialog":"school_send_application"}], options)
	}
	const buttonFooterRemoveFav1658518569672OnClick = (options) => {
		run([{"function":"delete","what":"@firebase.firestore.students.(@firebase.user.id).favs.(@cookie.entityID)"},{"function":"set","what":"@database.universities.(@cookie.entityID).fav","value":false},{"function":"set","what":"@property.isFavorite","value":false}], options)
	}
	const buttonFooterAddFav1658518569672OnClick = (options) => {
		run([{"function":"set","what":"@firebase.firestore.students.(@firebase.user.id).favs.(@cookie.entityID).isFav","value":true},{"function":"set","what":"@property.isFavorite","value":true},{"function":"set","what":"@database.schools.(@cookie.entityID).fav","value":true}], options)
	}
	const rowDescriptionFeat7_value1658518569672OnClick = (options) => {
		run([{"function":"browser","url":"@database.university.website","target":"_outmae"}], options)
	}
	const buttonApplySchoolOnClick = (options) => {
		run([{"function":"popup","dialog":"school_send_application"}], options)
	}
	const schoolActionBarHeartOnOnClick = (options) => {
		run([{"function":"delete","what":"@firebase.firestore.students.(@firebase.user.id).favs.(@cookie.universityID)"},{"function":"set","what":"@database.universities.(@cookie.universityID).fav","value":false},{"function":"set","what":"@property.isFavorite","value":false}], options)
	}
	const schoolActionBarHeartOffOnClick = (options) => {
		run([{"function":"set","what":"@firebase.firestore.students.(@firebase.user.id).favs.(@cookie.entityID).isFav","value":true},{"function":"set","what":"@property.isFavorite","value":true},{"function":"set","what":"@database.schools.(@cookie.entityID).fav","value":true}], options)
	}
	const schoolWrapperLogoOnClick = (options) => {
		run([{"function":"back"}], options)
	}
	const schoolWrapperBackIconOnClick = (options) => {
		run([{"function":"back"}], options)
	}

  return (
    <>
      <Helmet>
				<title>{t('school.title')}</title>
      </Helmet>
      <FullscreenWrapper>
          
			<Group
				id="schoolWrapperBody"
				direction="horizontal"
				className="w-100 h-auto position-relative bg-primary p-20px" >
				<Icon
					id="schoolWrapperBackIcon"
					onClick={schoolWrapperBackIconOnClick}
					src="arrow-left"
					color="var(--text-on-primary)"
					className="w-auto h-auto valign-center position-relative"
				/>
				<Image
					id="schoolWrapperLogo"
					onClick={schoolWrapperLogoOnClick}
					src={logo_72_white}
					className="w-72px h-auto position-relative" />
				<Group
					id="schoolWrapperSpacer"
					direction="vertical"
					className="w-fill h-auto position-relative" >
				</Group>
				<Image
					id="schoolActionBarHeartOff"
					if={{"what":"@property.isFavorite","is_not":true}}
					onClick={schoolActionBarHeartOffOnClick}
					src={heart_off}
					className="w-32px h-auto valign-center position-relative" />
				<Image
					id="schoolActionBarHeartOn"
					if={{"what":"@property.isFavorite","is":true}}
					onClick={schoolActionBarHeartOnOnClick}
					src={heart_on}
					className="w-32px h-auto position-relative" />
				<Button
					id="buttonApplySchool"
					if={{"what":"@firebase.firestore.students.(@cookie.userID).applications.(@cookie.entityID).active","is_not":true}}
					onClick={buttonApplySchoolOnClick}
					variant="primary"
					className="w-auto h-auto border-radius-50px valign-center position-relative bg-accent ms-16px" >
					{t('school.buttonApplySchool')}
				</Button>
			</Group>
			<Group
				id="bodySchoolInfo"
				direction="vertical"
				className="w-100 h-fill position-relative scrollable" >
				<Group
					id="schoolInfoBanner"
					direction="vertical"
					className="w-100 min-h-800px h-800px position-relative" >
					<Group
						id="schoolBannerGroup"
						direction="vertical"
						className="w-100 min-h-500px h-500px position-relative" >
						<Image
							id="bannerImageSchool"
							fallback={placeholder_uni}
							src="@firebase.firestore.schools.(@cookie.entityID).photo_school"
							className="w-100 min-h-500px h-500px position-relative cover" />
					</Group>
					<Group
						id="logoPositioningSchool"
						direction="horizontal"
						className="max-w-90 w-100 h-auto align-center position-relative" >
						<Group
							id="schoolInfoHeadLogo"
							direction="vertical"
							className="min-w-300px w-fill min-h-300px h-300px position-relative bg-white p-25px" >
							<Image
								id="schoolInfoHeadLogoImg"
								fallback={logo_placeholder}
								src="@firebase.storage.schools/(@cookie.entityID)/logo.png"
								className="w-auto min-h-250px h-250px align-center position-relative" />
						</Group>
						<Group
							id="schoolInfoHead"
							direction="vertical"
							className="max-w-1200px w-fill min-h-300px h-300px align-center position-relative p-32px" >
							<Group
								id="schoolInfoHeadHighlightsContainer"
								direction="vertical"
								className="w-100 min-h-100 fg-100 align-center position-relative" >
								<Group
									id="schoolInfoHeadHighlightsText"
									direction="vertical"
									className="w-auto h-auto align-center valign-center position-absolute" >
									<Text
										id="schoolInfoHeadhlightCity"
										tag="h3"
										src="@firebase.firestore.schools.(@cookie.entityID).city"
										className="w-auto h-auto position-relative text-primary ff-black" >
										{t('school.schoolInfoHeadhlightCity')}
									</Text>
									<Group
										id="schoolInfoHeadlightStudentsGroup"
										if={{"what":"@database.entity.students","more_than":0}}
										direction="horizontal"
										className="w-auto h-auto position-relative mt-16px" >
										<Text
											id="schoolInfoHeadlightStudentsAmount"
											format={{"type":"amount","thousands":true}}
											tag="b"
											src="@database.entity.students"
											className="w-auto h-auto position-relative ff-regular" >
											{t('school.schoolInfoHeadlightStudentsAmount')}
										</Text>
										<Text
											id="schoolInfoHeadlightStudentsDesc"
											tag="b"
											className="w-auto h-auto position-relative ps-8px ff-regular" >
											{t('school.schoolInfoHeadlightStudentsDesc')}
										</Text>
									</Group>
									<Group
										id="schoolInfoHeadlightTuitionGroup"
										direction="horizontal"
										className="w-auto h-auto position-relative mt-16px" >
										<Text
											id="schoolInfoHeadlightTuitionFrom"
											tag="b"
											className="w-auto h-auto position-relative ff-regular" >
											{t('school.schoolInfoHeadlightTuitionFrom')}
										</Text>
										<Text
											id="schoolInfoHeadlightTuitionAmount"
											tag="b"
											src="@firebase.firestore.schools.(@cookie.entityID).tuition"
											className="w-auto h-auto position-relative ps-8px ff-regular" >
											{t('school.schoolInfoHeadlightTuitionAmount')}
										</Text>
										<Text
											id="schoolInfoHeadlightTuitionDesc"
											tag="b"
											className="w-auto h-auto position-relative ps-8px ff-regular" >
											{t('school.schoolInfoHeadlightTuitionDesc')}
										</Text>
									</Group>
								</Group>
							</Group>
						</Group>
					</Group>
				</Group>
				<Group
					id="schoolInfoPromo"
					direction="vertical"
					className="w-100 h-auto position-relative bg-light" >
					<Text
						id="schoolInfoPromoText"
						tag="h3"
						src="@firebase.firestore.schools.(@cookie.entityID).slogan_(@cookie.lang)"
						className="w-100 h-auto position-relative p-64px text-primary ff-slogan text-center" >
						{t('school.schoolInfoPromoText')}
					</Text>
				</Group>
				<Group
					id="schoolInfoDescription"
					direction="horizontal"
					className="w-100 h-auto position-relative py-64px" >
					<Group
						id="schoolInfoDescriptionFeatures"
						direction="vertical"
						className="max-w-800px min-w-340px w-fill h-auto position-relative p-48px" >
						<Group
							id="schoolInfoBestOfTitleGroup"
							direction="vertical"
							className="w-auto h-auto align-center position-relative" >
							<Text
								id="schoolInfoDescriptionFeaturesTitle"
								tag="h3"
								className="w-auto h-auto position-relative text-primary ff-bold" >
								{t('school.schoolInfoDescriptionFeaturesTitle')}
							</Text>
							<Group
								id="schoolDescriptionFeatures"
								if={{"what":"@database.entity.religion","is_not":null}}
								direction="horizontal"
								className="w-100 h-auto position-relative mt-24px" >
								<Text
									id="rowDescriptionFeat2_label1658518569672"
									className="w-auto h-auto position-relative text-primary-text ff-regular" >
									{t('school.rowDescriptionFeat2_label1658518569672')}
								</Text>
								<Text
									id="rowDescriptionFeat2_value1658518569672"
									src="@database.entity.religion"
									className="w-auto h-auto position-relative ms-12px text-primary-text ff-bold" >
									{t('school.rowDescriptionFeat2_value1658518569672')}
								</Text>
							</Group>
							<Group
								id="schoolRowDescriptionFeatures2"
								if={{"what":"@database.entity.airports","is_not":null}}
								direction="horizontal"
								className="w-100 h-auto position-relative mt-24px" >
								<Text
									id="rowDescriptionFeat3_label1658518569672"
									className="w-auto h-auto position-relative text-primary-text ff-regular" >
									{t('school.rowDescriptionFeat3_label1658518569672')}
								</Text>
								<Text
									id="rowDescriptionFeat3_value1658518569672"
									src="@database.entity.airports"
									className="w-auto h-auto position-relative ms-12px text-primary-text ff-bold" >
									{t('school.rowDescriptionFeat3_value1658518569672')}
								</Text>
							</Group>
							<Group
								id="schoolRowDescriptionFeatures3"
								if={{"what":"@database.entity.city","is_not":null}}
								direction="horizontal"
								className="w-100 h-auto position-relative mt-24px" >
								<Text
									id="rowDescriptionFeat4_label1658518569672"
									className="w-auto h-auto position-relative text-primary-text ff-regular" >
									{t('school.rowDescriptionFeat4_label1658518569672')}
								</Text>
								<Text
									id="rowDescriptionFeat4_value1658518569672"
									src="@database.entity.city"
									className="w-auto h-auto position-relative ms-12px text-primary-text ff-bold" >
									{t('school.rowDescriptionFeat4_value1658518569672')}
								</Text>
							</Group>
							<Group
								id="schoolRowDescriptionFeatures3b"
								if={{"what":"@database.entity.cities","is_not":null}}
								direction="horizontal"
								className="w-100 h-auto position-relative mt-24px" >
								<Text
									id="rowDescriptionFeat4_label1660003952430"
									className="w-auto h-auto position-relative text-primary-text ff-regular" >
									{t('school.rowDescriptionFeat4_label1660003952430')}
								</Text>
								<Text
									id="rowDescriptionFeat4_value1660003952431"
									src="@database.entity.cities"
									className="w-auto h-auto position-relative ms-12px text-primary-text ff-bold" >
									{t('school.rowDescriptionFeat4_value1660003952431')}
								</Text>
							</Group>
							<Group
								id="schoolRowDescriptionFeatures4"
								if={{"what":"@database.entity.facultyRatio","is_not":null}}
								direction="horizontal"
								className="w-100 h-auto position-relative mt-24px" >
								<Text
									id="rowDescriptionFeat5_label1658518569672"
									className="w-auto h-auto position-relative text-primary-text ff-regular" >
									{t('school.rowDescriptionFeat5_label1658518569672')}
								</Text>
								<Text
									id="rowDescriptionFeat5_value1658518569672"
									src="@database.entity.facultyRatio"
									className="w-auto h-auto position-relative ms-12px text-primary-text ff-bold" >
									{t('school.rowDescriptionFeat5_value1658518569672')}
								</Text>
							</Group>
							<Group
								id="schoolRowDescriptionFeatures5"
								if={{"what":"@database.entity.students","is_not":null,"and":{"what":"@database.entity.students","more_than":0}}}
								direction="horizontal"
								className="w-100 h-auto position-relative mt-24px" >
								<Text
									id="rowDescriptionFeat6_label1658518569672"
									className="w-auto h-auto position-relative text-primary-text ff-regular" >
									{t('school.rowDescriptionFeat6_label1658518569672')}
								</Text>
								<Text
									id="rowDescriptionFeat6_value1658518569672"
									src="@database.entity.students"
									className="w-auto h-auto position-relative ms-12px text-primary-text ff-bold" >
									{t('school.rowDescriptionFeat6_value1658518569672')}
								</Text>
							</Group>
							<Group
								id="schoolRowDescriptionFeatures5b"
								if={{"what":"@database.entity.internationalStudents","is_not":null}}
								direction="horizontal"
								className="w-100 h-auto position-relative mt-24px" >
								<Text
									id="rowDescriptionFeat6_label1660004839368"
									className="w-auto h-auto position-relative text-primary-text ff-regular" >
									{t('school.rowDescriptionFeat6_label1660004839368')}
								</Text>
								<Text
									id="rowDescriptionFeat6_value1660004839368"
									src="@database.entity.internationalStudents"
									className="w-auto h-auto position-relative ms-12px text-primary-text ff-bold" >
									{t('school.rowDescriptionFeat6_value1660004839368')}
								</Text>
							</Group>
							<Group
								id="schoolRowDescriptionFeatures6"
								if={{"what":"@database.entity.website","is_not":null}}
								direction="horizontal"
								className="w-100 h-auto position-relative mt-24px" >
								<Text
									id="rowDescriptionFeat7_label1658518569672"
									className="w-auto h-auto position-relative text-primary-text ff-regular" >
									{t('school.rowDescriptionFeat7_label1658518569672')}
								</Text>
								<Text
									id="rowDescriptionFeat7_value1658518569672"
									onClick={rowDescriptionFeat7_value1658518569672OnClick}
									src="@database.entity.website"
									className="w-fill h-auto position-relative ms-12px text-primary-text ff-bold" >
									{t('school.rowDescriptionFeat7_value1658518569672')}
								</Text>
							</Group>
						</Group>
					</Group>
					<Text
						id="schoolInfoDescriptionText"
						tag="p"
						src="@firebase.firestore.schools.(@cookie.entityID).overview_(@cookie.lang)"
						className="min-w-300px w-fill h-auto valign-center position-relative p-64px text-primary ff-medium text-center" >
						{t('school.schoolInfoDescriptionText')}
					</Text>
				</Group>
				<Group
					id="schoolAdmissions"
					direction="horizontal"
					className="w-100 h-auto align-center position-relative" >
					<Image
						id="schoolInfoBestOfImage"
						fallback={placeholder_banner}
						src="@firebase.firestore.schools.(@cookie.entityID).photo_campus"
						className="w-100 h-fill position-absolute cover" />
					<Group
						id="component1658518569672"
						direction="vertical"
						className="w-fill h-auto position-relative" >
					</Group>
					<Group
						id="schoolAdmissionsContent"
						direction="vertical"
						className="max-w-800px w-auto h-auto align-end position-relative bg-darken p-48px" >
						<Group
							id="schoolAdmissionsTitleGroup"
							direction="vertical"
							className="w-auto h-auto position-relative" >
							<Text
								id="schoolInfoBestOfName"
								tag="h3"
								className="w-auto h-auto position-relative text-text-on-primary ff-bold" >
								{t('school.schoolInfoBestOfName')}
							</Text>
							<Group
								id="schoolRowAdmission_1"
								direction="horizontal"
								className="w-100 h-auto position-relative mt-24px" >
								<Text
									id="rowAdmissionLabel_deadline"
									className="w-auto h-auto position-relative text-text-on-primary ff-regular" >
									{t('school.rowAdmissionLabel_deadline')}
								</Text>
								<Text
									id="rowAdmissionValue_1658518569672"
									format={{"type":"date","year":"numeric","month":"long","day":"numeric"}}
									src="@database.entity.deadline"
									className="w-auto h-auto position-relative ms-12px text-text-on-primary ff-bold" >
									{t('')}
								</Text>
							</Group>
							<Group
								id="schoolRowAdmission_3_english"
								if={{"what":"@database.entity.minEnglish","is_not":null}}
								direction="horizontal"
								className="w-100 h-auto position-relative mt-24px" >
								<Text
									id="rowAdmissionLabel_1658558113662"
									className="w-auto h-auto position-relative text-text-on-primary ff-regular" >
									{t('school.rowAdmissionLabel_1658558113662')}
								</Text>
								<Text
									id="rowAdmissionValue_1658558113662"
									src="@database.entity.minEnglish"
									className="w-fill h-auto position-relative ms-12px text-text-on-primary ff-bold" >
									{t('school.rowAdmissionValue_1658558113662')}
								</Text>
							</Group>
							<Group
								id="schoolRowAdmission_4_letters"
								if={{"what":"@database.entity.letters","is_not":null}}
								direction="auto"
								className="w-100 h-auto position-relative mt-24px" >
								<Text
									id="rowAdmissionLabel_4"
									className="w-auto h-auto position-relative text-text-on-primary ff-regular" >
									{t('school.rowAdmissionLabel_4')}
								</Text>
								<Text
									id="rowAdmissionValue_1660005656622"
									src="@database.entity.letters"
									className="max-w-300px w-auto h-auto position-relative ms-12px text-text-on-primary ff-bold" >
									{t('school.rowAdmissionValue_1660005656622')}
								</Text>
							</Group>
							<Group
								id="schoolRowAdmission_5_interview"
								direction="horizontal"
								className="w-100 h-auto position-relative mt-24px" >
								<Text
									id="rowAdmissionLabel_interview"
									className="w-auto h-auto position-relative text-text-on-primary ff-regular" >
									{t('school.rowAdmissionLabel_interview')}
								</Text>
								<Text
									id="rowAdmissionValue_1660005708936"
									if={{"what":"@database.entity.interview","is":true}}
									className="w-fill h-auto position-relative ms-12px text-text-on-primary ff-bold" >
									{t('school.rowAdmissionValue_1660005708936')}
								</Text>
								<Text
									id="rowAdmissionValue_1660006034579"
									if={{"what":"@database.entity.interview","is_not":true}}
									className="w-fill h-auto position-relative ms-12px text-text-on-primary ff-bold" >
									{t('school.rowAdmissionValue_1660006034579')}
								</Text>
							</Group>
							<Group
								id="schoolRowAdmission_6_requirements"
								if={{"what":"@database.entity.requirements","is_not":null}}
								direction="auto"
								className="w-100 h-auto position-relative mt-24px" >
								<Text
									id="rowAdmissionLabel_requirements"
									className="w-auto h-auto position-relative text-text-on-primary ff-regular" >
									{t('school.rowAdmissionLabel_requirements')}
								</Text>
								<Text
									id="rowAdmissionValue_1658518569672"
									src="@database.entity.requirements"
									className="max-w-300px w-auto h-auto position-relative ms-12px text-text-on-primary ff-bold" >
									{t('school.rowAdmissionValue_1658518569672')}
								</Text>
							</Group>
						</Group>
					</Group>
				</Group>
				<Group
					id="schoolInfoSports"
					direction="horizontal"
					className="w-100 h-auto align-center position-relative" >
					<Image
						id="schoolInfoSportsImage"
						fallback={placeholder_banner}
						src="@firebase.firestore.schools.(@cookie.entityID).photo_sports"
						className="w-100 h-fill position-absolute cover" />
					<Group
						id="schoolInfoSportsHeaderContentMargin"
						direction="horizontal"
						className="max-w-1000px w-auto h-auto align-start position-relative bg-lighten p-48px" >
						<Group
							id="rowSportsList1660007754900"
							direction="vertical"
							className="max-w-100 w-auto h-auto position-relative" >
							<Text
								id="schoolSportsTitle1660007754900"
								tag="h3"
								className="w-auto h-auto position-relative text-primary ff-bold" >
								{t('school.schoolSportsTitle1660007754900')}
							</Text>
							<Group
								id="schoolRowSports_1"
								direction="vertical"
								className="max-w-100 min-w-380px w-auto h-auto position-relative mt-24px" >
								<Text
									id="rowSchoolSportsLabel_1"
									className="max-w-300px min-w-250px w-auto h-auto position-relative ff-bold" >
									{t('school.rowSchoolSportsLabel_1')}
								</Text>
								<List
									id="schoolSportsValue_1"
									direction="auto"
									src="@firebase.firestore.schools.(@cookie.entityID).sports_var"
									order={{"type":"asc","field":"name"}}
									columns={"auto"}
									className="max-w-100 min-w-300px w-auto h-auto position-relative mt-16px" >
									<Text
										id="rowSchoolSportsList_1"
										src="name"
										className="w-auto h-auto border-radius-50px position-relative bg-light px-8px py-4px me-6px ff-regular" >
										{t('school.rowSchoolSportsList_1')}
									</Text>
								</List>
							</Group>
							<Group
								id="schoolRowSports_2"
								direction="vertical"
								className="max-w-100 min-w-380px w-auto h-auto position-relative mt-24px" >
								<Text
									id="rowSchoolSportsLabel_2"
									className="max-w-300px min-w-250px w-fill h-auto position-relative ff-bold" >
									{t('school.rowSchoolSportsLabel_2')}
								</Text>
								<List
									id="rowSchoolSportsValue_2"
									direction="auto"
									src="@firebase.firestore.schools.(@cookie.entityID).sports_var_female"
									order={{"type":"asc","field":"name"}}
									columns={"auto"}
									className="max-w-100 min-w-300px w-auto h-auto position-relative mt-16px" >
									<Text
										id="rowSchoolSportsList_2"
										src="name"
										className="w-auto h-auto border-radius-50px position-relative bg-light px-8px py-4px me-6px ff-regular" >
										{t('school.rowSchoolSportsList_2')}
									</Text>
								</List>
							</Group>
							<Group
								id="schoolRowSports_3"
								direction="vertical"
								className="max-w-100 min-w-380px w-auto h-auto position-relative mt-24px" >
								<Text
									id="rowSchoolSportsLabel_3"
									className="max-w-300px min-w-250px w-fill h-auto position-relative ff-bold" >
									{t('school.rowSchoolSportsLabel_3')}
								</Text>
								<List
									id="rowSchoolSportsValue_3"
									direction="auto"
									src="@firebase.firestore.schools.(@cookie.entityID).sports"
									order={{"type":"asc","field":"name"}}
									columns={"auto"}
									className="max-w-100 min-w-300px w-auto h-auto position-relative mt-16px" >
									<Text
										id="rowSchoolSportsList_3"
										src="name"
										className="w-auto h-auto border-radius-50px position-relative bg-light px-8px py-4px me-6px ff-regular" >
										{t('school.rowSchoolSportsList_3')}
									</Text>
								</List>
							</Group>
						</Group>
					</Group>
					<Group
						id="schoolInfoSportsHeader"
						direction="vertical"
						className="w-100 min-h-500px h-auto position-relative" >
					</Group>
				</Group>
				<Group
					id="schoolInfoHousing"
					direction="horizontal"
					className="w-100 h-auto align-center position-relative" >
					<Image
						id="schoolInfoHousingImage"
						fallback={placeholder_banner}
						src="@firebase.firestore.schools.(@cookie.entityID).photo_housing"
						className="w-100 h-fill position-absolute cover" />
					<Group
						id="component1660054932203"
						direction="vertical"
						className="w-fill h-auto position-relative" >
					</Group>
					<Group
						id="schoolInfoHousingContent"
						direction="vertical"
						className="max-w-90 w-auto h-auto align-end position-relative bg-darken p-48px" >
						<Group
							id="uniInfoHousingTitleGroup1658518569672"
							direction="vertical"
							className="w-auto h-auto position-relative" >
							<Text
								id="uniInfoHousingTitle1658518569672"
								tag="h3"
								className="w-auto h-auto position-relative text-text-on-primary ff-bold" >
								{t('school.uniInfoHousingTitle1658518569672')}
							</Text>
						</Group>
						<List
							id="schoolInfoHousingList"
							src="@firebase.firestore.schools.(@cookie.entityID).housing"
							order={{"type":"asc","field":"position"}}
							className="w-auto h-auto position-relative" >
							<Group
								id="uniInfoHousingListRow1658518569672"
								direction="horizontal"
								className="w-auto h-auto position-relative mt-24px" >
								<Icon
									id="uniInfoHousingRowDot1658518569672"
									src="circle-medium"
									color="var(--text-on-primary)"
									className="w-auto h-auto position-relative"
								/>
								<Text
									id="uniInfoHousingRowDescription1658518569672"
									if={{"what":"@cookie.lang","is":"es"}}
									src="description_es"
									className="w-auto h-auto position-relative text-text-on-primary ff-regular" >
									{t('school.uniInfoHousingRowDescription1658518569672')}
								</Text>
								<Text
									id="uniInfoHousingRowDescriptionEN"
									if={{"what":"@cookie.lang","is_not":"en"}}
									src="description_en"
									className="w-auto h-auto position-relative text-text-on-primary ff-regular" >
									{t('school.uniInfoHousingRowDescriptionEN')}
								</Text>
							</Group>
						</List>
					</Group>
				</Group>
				<Group
					id="schoolInfoVideo"
					if={{"what":"@firebase.firestore.schools.(@cookie.entityID).video","is_not":null}}
					direction="vertical"
					className="w-100 h-auto align-center position-relative bg-gray_b" >
					<Group
						id="schoolVideoContent"
						direction="vertical"
						className="max-w-800px w-auto h-auto align-center position-relative p-24px" >
						<Text
							id="uniVideoTitle1658518569672"
							tag="h2"
							className="w-auto h-auto align-center position-relative text-primary ff-bold text-center" >
							{t('school.uniVideoTitle1658518569672')}
						</Text>
						<Video
							id="schoolVideoPreview"
							src="@firebase.firestore.schools.(@cookie.entityID).video"
						autoPlay={true}
						controls
						loop
						muted
							className="max-w-1000px w-90 h-auto align-center position-relative bg-gray mt-24px" />
					</Group>
				</Group>
				<Group
					id="schoolInfoCost"
					direction="vertical"
					className="w-100 h-auto align-center position-relative" >
					<Group
						id="schoolCostContent"
						direction="vertical"
						className="max-w-800px w-auto h-auto align-center position-relative p-24px" >
						<Text
							id="uniCostTitle1658518569672"
							tag="h2"
							className="w-auto h-auto align-center position-relative text-primary ff-bold text-center" >
							{t('school.uniCostTitle1658518569672')}
						</Text>
						<List
							id="schoolInfoCostList"
							src="@firebase.firestore.schools.(@cookie.entityID).costs"
							className="w-100 h-auto position-relative mt-24px" >
							<Group
								id="uniInfoCostRow1658518569672"
								direction="vertical"
								className="w-100 h-auto position-relative" >
								<Text
									id="entityInfoCostRowTitleES"
									if={{"what":"@cookie.lang","is":"es"}}
									src="title_es"
									className="w-100 h-auto position-relative text-primary-text ff-bold text-center" >
									{t('school.entityInfoCostRowTitleES')}
								</Text>
								<Text
									id="entityInfoCostRowTitleEN"
									if={{"what":"@cookie.lang","is_not":"es"}}
									src="title_en"
									className="w-100 h-auto position-relative text-primary-text ff-bold text-center" >
									{t('school.entityInfoCostRowTitleEN')}
								</Text>
								<Text
									id="entityInfoCostRowSubtitleES"
									if={{"what":"@cookie.lang","is":"es"}}
									src="subtitle_es"
									className="w-100 h-auto position-relative mb-24px text-secondary-text ff-regular text-center" >
									{t('school.entityInfoCostRowSubtitleES')}
								</Text>
								<Text
									id="entityInfoCostRowSubtitleEN"
									if={{"what":"@cookie.lang","is_not":"es"}}
									src="subtitle_en"
									className="w-100 h-auto position-relative mb-24px text-secondary-text ff-regular text-center" >
									{t('school.entityInfoCostRowSubtitleEN')}
								</Text>
							</Group>
						</List>
					</Group>
				</Group>
				<Group
					id="schoolInfoButtons"
					direction="vertical"
					className="w-320px h-auto align-center position-relative mt-48px" >
					<Text
						id="buttonFooterAddFav1658518569672"
						if={{"what":"@property.isFavorite","is_not":true}}
						onClick={buttonFooterAddFav1658518569672OnClick}
						className="w-100 h-auto border-radius-50px valign-center position-relative bg-primary p-8px mt-16px text-text-on-primary ff-medium text-center" >
						{t('school.buttonFooterAddFav1658518569672')}
					</Text>
					<Text
						id="buttonFooterRemoveFav1658518569672"
						if={{"what":"@property.isFavorite","is":true}}
						onClick={buttonFooterRemoveFav1658518569672OnClick}
						className="w-100 h-auto border-radius-50px valign-center position-relative bg-primary p-8px mt-16px text-text-on-primary ff-medium text-center" >
						{t('school.buttonFooterRemoveFav1658518569672')}
					</Text>
					<Text
						id="buttonFooterApply1658518569672"
						if={{"what":"@firebase.firestore.students.(@cookie.userID).applications.(@cookie.entityID).active","is_not":true}}
						onClick={buttonFooterApply1658518569672OnClick}
						className="w-100 h-auto border-radius-50px valign-center position-relative bg-accent p-8px mt-16px text-text-on-primary ff-medium text-center" >
						{t('school.buttonFooterApply1658518569672')}
					</Text>
					<Text
						id="buttonFooterReviewApplication1658518569672"
						if={{"what":"@firebase.firestore.students.(@cookie.userID).applications.(@cookie.universityID).active","is":true}}
						onClick={buttonFooterReviewApplication1658518569672OnClick}
						className="w-100 h-auto border-radius-50px valign-center position-relative bg-accent p-8px mt-16px text-text-on-primary text-center" >
						{t('school.buttonFooterReviewApplication1658518569672')}
					</Text>
				</Group>
				<Group
					id="uniInfoFooterSpacer1658518569672"
					direction="vertical"
					className="w-100 min-h-50px h-50px position-relative" >
				</Group>
				<Group
					id="uniInfoFooter1658518569672"
					direction="vertical"
					className="w-100 h-auto position-relative mt-48px" >
					<Group
						id="homeFooterBar1658518569672"
						direction="vertical"
						className="w-100 h-auto valign-end position-relative bg-footer_bg py-48px" >
						<Group
							id="homeFooterBarContent1658518569672"
							direction="auto"
							className="max-w-1200px w-100 h-auto align-center position-relative p-32px" >
							<Group
								id="homeFooterInfo1663113479180"
								direction="vertical"
								className="w-fill h-auto position-relative" >
								<Text
									id="homeFooterAbout1663113479180"
									tag="p"
									className="max-w-500px min-w-300px w-fill h-auto position-relative text-text-on-primary ff-medium" >
									{t('school.homeFooterAbout1663113479180')}
								</Text>
								<Group
									id="homeFooterPolicy1663113479180"
									direction="auto"
									className="w-fill h-auto position-relative" >
									<Text
										id="homeFooterPrivacy1663113479180"
										onClick={homeFooterPrivacy1663113479180OnClick}
										tag="small"
										className="max-w-100 w-auto h-auto position-relative pe-8px text-gray_b ff-medium" >
										{t('school.homeFooterPrivacy1663113479180')}
									</Text>
									<Text
										id="homeFooterPrivacySeparator1663113479180"
										tag="small"
										className="w-auto h-auto position-relative pe-8px text-gray_b ff-medium" >
										{t('school.homeFooterPrivacySeparator1663113479180')}
									</Text>
									<Text
										id="homeFooterCookies1663113479180"
										onClick={homeFooterCookies1663113479180OnClick}
										tag="small"
										className="max-w-100 w-auto h-auto position-relative pe-8px text-gray_b ff-medium" >
										{t('school.homeFooterCookies1663113479180')}
									</Text>
								</Group>
							</Group>
							<Group
								id="homeFooterSpacer1658518569672"
								direction="vertical"
								className="w-fill h-auto position-relative" >
							</Group>
							<Group
								id="homeFooterContact1658518569672"
								direction="vertical"
								className="max-w-400px min-w-300px w-fill h-auto position-relative" >
								<Image
									id="homeFooterLogo1658518569672"
									src={logo_72_white}
									className="w-auto min-h-72px h-72px align-end position-relative" />
								<Text
									id="homeFooterCities1658518569672"
									tag="p"
									className="w-auto h-auto align-end position-relative text-text-on-primary ff-medium text-end" >
									{t('school.homeFooterCities1658518569672')}
								</Text>
								<Text
									id="homeFooterTel1658518569672"
									onClick={homeFooterTel1658518569672OnClick}
									tag="p"
									className="w-auto h-auto align-end position-relative text-text-on-primary ff-medium text-end" >
									{t('school.homeFooterTel1658518569672')}
								</Text>
								<Text
									id="homeFooterEmail1658518569672"
									onClick={homeFooterEmail1658518569672OnClick}
									tag="p"
									className="w-auto h-auto align-end position-relative text-text-on-primary ff-medium text-end" >
									{t('school.homeFooterEmail1658518569672')}
								</Text>
								<Text
									id="homeFooterWeb1658518569672"
									onClick={homeFooterWeb1658518569672OnClick}
									tag="p"
									className="w-auto h-auto align-end position-relative text-text-on-primary ff-medium text-end" >
									{t('school.homeFooterWeb1658518569672')}
								</Text>
								<Group
									id="homeBlueSSNN1658518569672"
									direction="horizontal"
									className="w-auto h-auto align-end position-relative" >
									<Icon
										id="homeBlueInstagramIcon1658518569672"
										onClick={homeBlueInstagramIcon1658518569672OnClick}
										src="instagram"
										color="var(--text-on-primary)"
										className="w-auto h-auto position-relative me-12px"
									/>
									<Icon
										id="homeBlueFacebookIcon1658518569672"
										onClick={homeBlueFacebookIcon1658518569672OnClick}
										src="facebook"
										color="var(--text-on-primary)"
										className="w-auto h-auto position-relative me-12px"
									/>
									<Icon
										id="homeBlueTwitterIcon1658518569672"
										onClick={homeBlueTwitterIcon1658518569672OnClick}
										src="twitter"
										color="var(--text-on-primary)"
										className="w-auto h-auto position-relative me-12px"
									/>
									<Icon
										id="homeBlueYoutubeIcon1658518569672"
										onClick={homeBlueYoutubeIcon1658518569672OnClick}
										src="youtube"
										color="var(--text-on-primary)"
										className="w-auto h-auto position-relative"
									/>
								</Group>
							</Group>
						</Group>
					</Group>
				</Group>
			</Group>
      </FullscreenWrapper>
    </>
  );
};
