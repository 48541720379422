import React from 'react';
import { Container } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import { useFunctions, useRefs } from '../hooks';
import { FullscreenWrapper } from '../wrappers';

// Custom imports:
import { Group, Icon, Text, Image, Nav, Dropdown, List, Card, Button } from '../components';
import { useEffect } from 'react';
import logo_72_white from '../assets/icon/logo_72_white.png';
import placeholder_uni from '../assets/image/placeholder_uni.jpg';
import placeholder_logo from '../assets/image/placeholder_logo.svg';
import collage from '../assets/image/collage.jpg';
import logo_72 from '../assets/icon/logo_72.png';
import { useTranslation } from 'react-i18next';

export const ApplicationsView = () => {
  const { run } = useFunctions();
  // Custom exports:
	const { t } = useTranslation();

  // Custom functions:
	const applicationsOnLoaded = (options) => {
		run([{"function":"set","what":"@cookie.lang","value":"es","if":{"what":"@app.language","starts_with":"es","else":[{"function":"set","what":"@cookie.lang","value":"en"}]}}], options)
	}
	useEffect(applicationsOnLoaded, [])
	const homeBlueYoutubeIcon1649172254076OnClick = (options) => {
		run([{"function":"browser","url":"https:\/\/www.youtube.com\/channel\/UCmfIE4aab0Qjz5xuqD3Pbfg","target":"_maeyoutube"}], options)
	}
	const homeBlueTwitterIcon1649172254076OnClick = (options) => {
		run([{"function":"browser","url":"https:\/\/twitter.com\/mae_sports","target":"_maetwitter"}], options)
	}
	const homeBlueFacebookIcon1649172254076OnClick = (options) => {
		run([{"function":"browser","url":"https:\/\/www.facebook.com\/mae.sports\/","target":"_maefacebook"}], options)
	}
	const homeBlueInstagramIcon1649172254076OnClick = (options) => {
		run([{"function":"browser","url":"https:\/\/www.instagram.com\/mae.sports\/","target":"_maeinstagram"}], options)
	}
	const homeFooterWeb1649172254076OnClick = (options) => {
		run([{"function":"browser","url":"https:\/\/www.maeducation.net","target":"_self"}], options)
	}
	const homeFooterEmail1649172254076OnClick = (options) => {
		run([{"function":"browser","url":"mailto:info@maeducation.net"}], options)
	}
	const homeFooterTel1649172254076OnClick = (options) => {
		run([{"function":"browser","url":"tel:+34646516514"}], options)
	}
	const homeFooterCookies1663113357759OnClick = (options) => {
		run([{"function":"set","what":"@property.policy","value":"cookies_policy"},{"function":"popup","dialog":"terms"}], options)
	}
	const homeFooterPrivacy1663113357759OnClick = (options) => {
		run([{"function":"set","what":"@property.policy","value":"privacy_policy"},{"function":"popup","dialog":"terms"}], options)
	}
	const uniListButtonMore1649172254076OnClick = (options) => {
		run([{"function":"set","what":"@cookie.universityID","value":"@element.appsList.selected"},{"function":"set","what":"@cookie.university","value":"@database.universities.(@cookie.universityID)"},{"function":"set","what":"@property.isFavorite","value":"@database.universities.(@cookie.universityID).fav"},{"function":"goto","view":"uni_info"}], options)
	}
	const appListMessagesButtonOnClick = (options) => {
		run([{"function":"set","what":"@cookie.applicationID","value":"@element.appsList.selected"},{"function":"popup","dialog":"application_messages"}], options)
	}
	const Session_menuItemsSessionLogoutOnClick = (options) => {
		run([{"function":"goto","view":"logout"}], options)
	}
	const Session_menuItemsSessionSettingsOnClick = (options) => {
		run([{"function":"goto","view":"account"}], options)
	}
	const Student_schools_menuItemsStudentProfile1658518549491OnClick = (options) => {
		run([{"function":"goto","view":"profile_a"}], options)
	}
	const Student_schools_menuItemsStudentFaq1658518549491OnClick = (options) => {
		run([{"function":"goto","view":"faq"}], options)
	}
	const Student_schools_menuItemsSchoolsApplicationsOnClick = (options) => {
		run([{"function":"goto","view":"applications"}], options)
	}
	const Student_schools_menuItemsStudentSchoolsOnClick = (options) => {
		run([{"function":"goto","view":"schools"}], options)
	}
	const Student_menuItemsStudentProfileOnClick = (options) => {
		run([{"function":"goto","view":"stu_step_1"}], options)
	}
	const Student_menuItemsStudentFaqOnClick = (options) => {
		run([{"function":"goto","view":"faq"}], options)
	}
	const Student_menuItemsStudentApplicationsOnClick = (options) => {
		run([{"function":"goto","view":"applications"}], options)
	}
	const Student_menuItemsStudentUniversitiesOnClick = (options) => {
		run([{"function":"goto","view":"stu_main"}], options)
	}
	const homeBlueYoutubeIcon1650370109943OnClick = (options) => {
		run([{"function":"browser","url":"https:\/\/www.youtube.com\/channel\/UCmfIE4aab0Qjz5xuqD3Pbfg","target":"_maeyoutube"}], options)
	}
	const homeBlueTwitterIcon1650370109943OnClick = (options) => {
		run([{"function":"browser","url":"https:\/\/twitter.com\/mae_sports","target":"_maetwitter"}], options)
	}
	const homeBlueFacebookIcon1650370109943OnClick = (options) => {
		run([{"function":"browser","url":"https:\/\/www.facebook.com\/mae.sports\/","target":"_maefacebook"}], options)
	}
	const homeBlueInstagramIcon1650370109943OnClick = (options) => {
		run([{"function":"browser","url":"https:\/\/www.instagram.com\/mae.sports\/","target":"_maeinstagram"}], options)
	}
	const homeBlueEmail1650370109943OnClick = (options) => {
		run([{"function":"browser","url":"mailto:info@maeducationusa.com"}], options)
	}
	const homeBluePhone1650370109943OnClick = (options) => {
		run([{"function":"browser","url":"tel:+34646516514"}], options)
	}

  return (
    <>
      <Helmet>
				<title>{t('applications.title')}</title>
      </Helmet>
      <FullscreenWrapper>
          
			<Group
				id="stuAppsBody1649172254076"
				direction="vertical"
				className="w-100 min-h-100 fg-100 position-relative scrollable" >
				<Group
					id="stuAppsContent1649172254076"
					direction="vertical"
					className="w-100 h-auto align-center position-relative" >
					<Group
						id="stuApplicationsHeader"
						direction="vertical"
						className="w-100 h-auto position-relative" >
						<Group
							id="homeBlueBar1650370109943"
							direction="vertical"
							className="w-100 h-auto position-relative bg-primary" >
							<Group
								id="homeBlueContent1650370109943"
								direction="auto"
								className="w-100 h-auto align-center position-relative" >
								<Group
									id="homeBluePhone1650370109943"
									onClick={homeBluePhone1650370109943OnClick}
									direction="horizontal"
									className="w-auto h-auto align-center position-relative p-12px" >
									<Icon
										id="homeBluePhoneIcon1650370109943"
										src="phone"
										color="var(--text-on-primary)"
										className="w-auto h-auto position-relative"
									/>
									<Text
										id="homeBluePhoneNumber1650370109943"
										className="w-auto h-auto valign-center position-relative text-text-on-primary f-16px" >
										{t('applications.homeBluePhoneNumber1650370109943')}
									</Text>
								</Group>
								<Group
									id="homeBlueEmail1650370109943"
									onClick={homeBlueEmail1650370109943OnClick}
									direction="horizontal"
									className="w-auto h-auto align-center position-relative p-12px" >
									<Icon
										id="homeBlueEmailIcon1650370109943"
										src="email"
										color="var(--text-on-primary)"
										className="w-auto h-auto position-relative"
									/>
									<Text
										id="homeBlueEmailAddress1650370109943"
										className="w-auto h-auto valign-center position-relative text-text-on-primary f-16px" >
										{t('applications.homeBlueEmailAddress1650370109943')}
									</Text>
								</Group>
								<Group
									id="homeBlueSpacer1650370109943"
									direction="vertical"
									className="w-fill h-auto position-relative" >
								</Group>
								<Group
									id="homeBlueRightSide1650370109943"
									direction="vertical"
									className="w-auto h-auto align-center position-relative p-12px" >
									<Group
										id="homeBlueSSNN1650370109943"
										direction="horizontal"
										className="w-auto h-auto align-end position-relative" >
										<Icon
											id="homeBlueInstagramIcon1650370109943"
											onClick={homeBlueInstagramIcon1650370109943OnClick}
											src="instagram"
											color="var(--text-on-primary)"
											className="w-auto h-auto position-relative me-12px"
										/>
										<Icon
											id="homeBlueFacebookIcon1650370109943"
											onClick={homeBlueFacebookIcon1650370109943OnClick}
											src="facebook"
											color="var(--text-on-primary)"
											className="w-auto h-auto position-relative me-12px"
										/>
										<Icon
											id="homeBlueTwitterIcon1650370109943"
											onClick={homeBlueTwitterIcon1650370109943OnClick}
											src="twitter"
											color="var(--text-on-primary)"
											className="w-auto h-auto position-relative me-12px"
										/>
										<Icon
											id="homeBlueYoutubeIcon1650370109943"
											onClick={homeBlueYoutubeIcon1650370109943OnClick}
											src="youtube"
											color="var(--text-on-primary)"
											className="w-auto h-auto position-relative"
										/>
									</Group>
								</Group>
							</Group>
						</Group>
						<Group
							id="stuMainWhiteHeader1650370140273"
							direction="vertical"
							className="w-100 h-auto position-relative p-24px" >
							<Group
								id="homeHeaderContent1650370140273"
								direction="auto"
								className="w-100 h-auto align-center valign-center position-relative" >
								<Group
									id="homeNavBar1650370140273"
									direction="horizontal"
									className="min-w-320px w-fill h-auto align-end valign-center position-relative" >
									<Image
										id="homeLogo1650370140273"
										src={logo_72}
										className="w-auto min-h-64px h-64px align-start valign-center position-relative" />
									<Group
										id="component1659626406626"
										direction="vertical"
										className="w-fill h-auto position-relative" >
									</Group>
								</Group>
								<Group
									id="homeNavBarContent1650370140273"
									direction="auto"
									className="w-auto h-auto align-end valign-center position-relative" >
									<Nav
										id="homeMenu1650370140273"
										if={{"what":"@cookie.type","is":"student"}}
										className="w-auto h-auto position-relative"
										direction="horizontal" >
										<Nav.Item title={t('student_menu.items.studentUniversities.title')} onClick={Student_menuItemsStudentUniversitiesOnClick} href="stu_main" />
										<Nav.Item title={t('student_menu.items.studentApplications.title')} onClick={Student_menuItemsStudentApplicationsOnClick} href="applications" />
										<Nav.Item title={t('student_menu.items.studentFaq.title')} onClick={Student_menuItemsStudentFaqOnClick} href="faq" />
										<Nav.Item title={t('student_menu.items.studentProfile.title')} onClick={Student_menuItemsStudentProfileOnClick} href="stu_step_1" />
									</Nav>
									<Nav
										id="homeMenu1659626453974"
										if={{"what":"@cookie.type","is":"student_school"}}
										className="w-auto h-auto position-relative"
										direction="horizontal" >
										<Nav.Item title={t('student_schools_menu.items.studentSchools.title')} onClick={Student_schools_menuItemsStudentSchoolsOnClick} href="schools" />
										<Nav.Item title={t('student_schools_menu.items.schoolsApplications.title')} onClick={Student_schools_menuItemsSchoolsApplicationsOnClick} href="applications" />
										<Nav.Item title={t('student_schools_menu.items.studentFaq1658518549491.title')} onClick={Student_schools_menuItemsStudentFaq1658518549491OnClick} href="faq" />
										<Nav.Item title={t('student_schools_menu.items.studentProfile1658518549491.title')} onClick={Student_schools_menuItemsStudentProfile1658518549491OnClick} href="profile_a" />
									</Nav>
									<Dropdown
										id="applicationsAccountMenu"
										className="w-auto h-auto border-radius-32px position-relative"
										variant="primary"
										title={<>

										<Text
											id="applicationsAccountMenuTitle"
											className="w-auto h-auto position-relative ff-regular" >
											{t('applications.applicationsAccountMenuTitle')}
										</Text>
									</>} >
										<Dropdown.Item title={t('session_menu.items.sessionSettings.title')} onClick={Session_menuItemsSessionSettingsOnClick} href="account" />
										<Dropdown.Item title={t('session_menu.items.sessionLogout.title')} onClick={Session_menuItemsSessionLogoutOnClick} href="logout" />
									</Dropdown>
								</Group>
							</Group>
						</Group>
						<Group
							id="appsListHeader1649172254076"
							direction="vertical"
							className="w-100 min-h-250px h-250px position-relative" >
							<Image
								id="appsListHeaderImage1649172254076"
								src={collage}
								className="w-100 min-h-240px h-240px position-relative cover" />
							<Group
								id="appListTitleGroup1649172254076"
								direction="auto"
								className="w-90 h-auto align-center valign-end position-absolute-bottom bg-lighten p-24px" >
								<Text
									id="appsListTitle1649172254076"
									tag="h2"
									className="min-w-300px w-fill h-auto position-relative text-primary ff-bold" >
									{t('applications.appsListTitle1649172254076')}
								</Text>
							</Group>
						</Group>
					</Group>
					<List
						id="appsList"
						src="@firebase.firestore.students.(@cookie.userID).applications"
						order={{"type":"asc","field":"name"}}
						columns={"auto"}
						className="w-90 h-auto align-center position-relative mt-24px" >
						<Card
							id="appsCard1649172254076"
							className="min-w-300px w-fill h-auto position-relative m-8px" >
							<Card.Body>
									<Group
										id="uniLogoGroup1649172254076"
										direction="horizontal"
										className="w-100 h-auto border-gray border-bottom position-relative" >
										<Text
											id="appsListName1649172254076"
											tag="b"
											src="name"
											className="w-fill h-auto valign-center position-relative ff-bold" >
											{t('applications.appsListName1649172254076')}
										</Text>
										<Image
											id="appsListLogo1649172254076"
											fallback={placeholder_logo}
											src="logo"
											className="w-auto min-h-52px h-52px align-center valign-center position-relative fill" />
									</Group>
									<Text
										id="appsCity1649172254076"
										tag="b"
										src="city"
										className="w-100 h-auto position-relative mt-8px text-primary ff-regular" >
										{t('applications.appsCity1649172254076')}
									</Text>
									<Image
										id="component1649172254076"
										fallback={placeholder_uni}
										src="photo"
										className="w-100 min-h-150px h-150px position-relative mt-12px cover" />
									<Text
										id="appsStatus"
										className="w-100 h-auto position-relative mt-8px text-accent ff-bold" >
										{t('applications.appsStatus')}
									</Text>
									<Group
										id="appsListButtons1649172254076"
										direction="horizontal"
										className="w-auto h-auto border-top align-end position-relative pt-8px mt-24px" >
										<Button
											id="appListMessagesButton"
											onClick={appListMessagesButtonOnClick}
											variant="primary"
											className="w-auto h-auto border-radius-50px align-end position-relative me-8px" >
											{t('applications.appListMessagesButton')}
										</Button>
										<Button
											id="uniListButtonMore1649172254076"
											onClick={uniListButtonMore1649172254076OnClick}
											variant="primary"
											className="w-auto h-auto border-radius-50px align-end position-relative" >
											{t('applications.uniListButtonMore1649172254076')}
										</Button>
									</Group>
							</Card.Body>
						</Card>
					</List>
					<Group
						id="footerSpacer1649172254076"
						direction="vertical"
						className="w-100 min-h-300px h-fill position-relative" >
					</Group>
					<Group
						id="stuMainFooter1649172254076"
						direction="vertical"
						className="w-100 h-auto position-relative mt-48px" >
						<Group
							id="homeFooterBar1649172254076"
							direction="vertical"
							className="w-100 h-auto valign-end position-relative bg-footer_bg py-48px" >
							<Group
								id="homeFooterBarContent1649172254076"
								direction="auto"
								className="max-w-1200px w-100 h-auto align-center position-relative p-32px" >
								<Group
									id="homeFooterInfo1663113357759"
									direction="vertical"
									className="w-fill h-auto position-relative" >
									<Text
										id="homeFooterAbout1663113357759"
										tag="p"
										className="max-w-500px min-w-300px w-fill h-auto position-relative text-text-on-primary ff-medium" >
										{t('applications.homeFooterAbout1663113357759')}
									</Text>
									<Group
										id="homeFooterPolicy1663113357759"
										direction="auto"
										className="w-fill h-auto position-relative" >
										<Text
											id="homeFooterPrivacy1663113357759"
											onClick={homeFooterPrivacy1663113357759OnClick}
											tag="small"
											className="max-w-100 w-auto h-auto position-relative pe-8px text-gray_b ff-medium" >
											{t('applications.homeFooterPrivacy1663113357759')}
										</Text>
										<Text
											id="homeFooterPrivacySeparator1663113357759"
											tag="small"
											className="w-auto h-auto position-relative pe-8px text-gray_b ff-medium" >
											{t('applications.homeFooterPrivacySeparator1663113357759')}
										</Text>
										<Text
											id="homeFooterCookies1663113357759"
											onClick={homeFooterCookies1663113357759OnClick}
											tag="small"
											className="max-w-100 w-auto h-auto position-relative pe-8px text-gray_b ff-medium" >
											{t('applications.homeFooterCookies1663113357759')}
										</Text>
									</Group>
								</Group>
								<Group
									id="homeFooterSpacer1649172254076"
									direction="vertical"
									className="w-fill h-auto position-relative" >
								</Group>
								<Group
									id="homeFooterContact1649172254076"
									direction="vertical"
									className="max-w-400px min-w-300px w-fill h-auto position-relative" >
									<Image
										id="homeFooterLogo1649172254076"
										src={logo_72_white}
										className="w-auto min-h-72px h-72px align-end position-relative" />
									<Text
										id="homeFooterCities1649172254076"
										tag="p"
										className="w-auto h-auto align-end position-relative text-text-on-primary ff-medium text-end" >
										{t('applications.homeFooterCities1649172254076')}
									</Text>
									<Text
										id="homeFooterTel1649172254076"
										onClick={homeFooterTel1649172254076OnClick}
										tag="p"
										className="w-auto h-auto align-end position-relative text-text-on-primary ff-medium text-end" >
										{t('applications.homeFooterTel1649172254076')}
									</Text>
									<Text
										id="homeFooterEmail1649172254076"
										onClick={homeFooterEmail1649172254076OnClick}
										tag="p"
										className="w-auto h-auto align-end position-relative text-text-on-primary ff-medium text-end" >
										{t('applications.homeFooterEmail1649172254076')}
									</Text>
									<Text
										id="homeFooterWeb1649172254076"
										onClick={homeFooterWeb1649172254076OnClick}
										tag="p"
										className="w-auto h-auto align-end position-relative text-text-on-primary ff-medium text-end" >
										{t('applications.homeFooterWeb1649172254076')}
									</Text>
									<Group
										id="homeBlueSSNN1649172254076"
										direction="horizontal"
										className="w-auto h-auto align-end position-relative" >
										<Icon
											id="homeBlueInstagramIcon1649172254076"
											onClick={homeBlueInstagramIcon1649172254076OnClick}
											src="instagram"
											color="var(--text-on-primary)"
											className="w-auto h-auto position-relative me-12px"
										/>
										<Icon
											id="homeBlueFacebookIcon1649172254076"
											onClick={homeBlueFacebookIcon1649172254076OnClick}
											src="facebook"
											color="var(--text-on-primary)"
											className="w-auto h-auto position-relative me-12px"
										/>
										<Icon
											id="homeBlueTwitterIcon1649172254076"
											onClick={homeBlueTwitterIcon1649172254076OnClick}
											src="twitter"
											color="var(--text-on-primary)"
											className="w-auto h-auto position-relative me-12px"
										/>
										<Icon
											id="homeBlueYoutubeIcon1649172254076"
											onClick={homeBlueYoutubeIcon1649172254076OnClick}
											src="youtube"
											color="var(--text-on-primary)"
											className="w-auto h-auto position-relative"
										/>
									</Group>
								</Group>
							</Group>
						</Group>
					</Group>
				</Group>
			</Group>
      </FullscreenWrapper>
    </>
  );
};
