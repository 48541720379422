import React from 'react';
import { Container } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import { useFunctions, useRefs } from '../hooks';
import { Private_adminWrapper } from '../wrappers';

// Custom imports:
import { Group, Text, Input, List, Icon } from '../components';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

export const Admin_faqView = () => {
  const { run } = useFunctions();
  // Custom exports:
	const { t } = useTranslation();

  // Custom functions:
	const admin_faqOnLoaded = (options) => {
		run([{"function":"set","what":"@property.lang","value":"es"},{"function":"clone","what":"@firebase.firestore.data.faq.categories","into":"@database.faqcat"}], options)
	}
	useEffect(admin_faqOnLoaded, [])
	const adminButtonAddFaqOnClick = (options) => {
		run([{"function":"add","what":{"question":"Question","answer":"Answer","position":1000,"active":false,"lang":"@property.lang"},"to":"@firebase.firestore.faq"}], options)
	}
	const text1663628991964OnClick = (options) => {
		run([{"function":"set","what":"@property.post","value":"@element.adminFaqList.selected"},{"function":"set","what":"@property.whatTranslate","value":"faq"},{"function":"popup","dialog":"translate"}], options)
	}
	const faqAdminDeleteButtonOnClick = (options) => {
		run([{"function":"set","what":"@property.route","value":"faq.(@element.adminFaqList.selected)"},{"function":"popup","dialog":"remove_dialog"}], options)
	}

  return (
    <>
      <Helmet>
				<title>{t('admin_faq.title')}</title>
      </Helmet>
      <Private_adminWrapper>
          
			<Group
				id="adminFaqBody"
				direction="vertical"
				className="w-100 h-fill position-relative p-24px scrollable" >
				<Group
					id="adminFaqToobar"
					direction="horizontal"
					className="w-100 h-auto position-relative" >
					<Text
						id="adminFaqListContent"
						tag="h2"
						className="w-fill h-auto position-relative text-primary ff-bold" >
						{t('admin_faq.adminFaqListContent')}
					</Text>
					<Input.Select
						id="adminFaqLangSelect"
						name="select"
						src="@property.lang"
						className="w-auto h-auto position-relative" >
						<option value={"es"}>{t('admin_faq.adminFaqLangSelect.opt0')}</option>
						<option value={"en"}>{t('admin_faq.adminFaqLangSelect.opt1')}</option>
					</Input.Select>
				</Group>
				<List
					id="adminFaqList"
					src="@firebase.firestore.faq"
					filter={{"what":"lang","is":"@property.lang"}}
					order={{"type":"asc","field":"position"}}
					className="w-100 h-auto position-relative mt-24px" >
					<Group
						id="adminFaqCard"
						direction="vertical"
						className="w-100 h-auto border-radius-15px position-relative bg-light p-24px mb-16px" >
						<Group
							id="adminFaqRowQuestionGroup"
							direction="horizontal"
							className="w-auto h-auto align-end position-relative" >
							<Text
								id="faqAdminPositionLabel"
								className="w-auto h-auto valign-center position-relative pe-12px" >
								{t('admin_faq.faqAdminPositionLabel')}
							</Text>
							<Input.Number
								id="faqAdminPosition"
								name="text"
								src="@firebase.firestore.faq.(@field.id).position"
								className="w-80px h-auto valign-center position-relative me-32px" />
							<Input.Select
								id="faqCategories"
								name="select"
								src="@firebase.firestore.faq.(@field.id).category"
								dataSrc="@database.faqcat"
								className="w-auto h-auto valign-center position-relative me-16px" >
								<option value={"-"}>{t('admin_faq.faqCategories.opt0')}</option>
							</Input.Select>
							<Text
								id="faqAdminActiveLabel"
								className="w-auto h-auto valign-center position-relative pe-12px" >
								{t('admin_faq.faqAdminActiveLabel')}
							</Text>
							<Input.Switch
								id="faqAdminActive"
								name="switch"
								src="@firebase.firestore.faq.(@field.id).active"
								className="w-auto h-auto valign-center position-relative me-16px" />
							<Icon
								id="faqAdminDeleteButton"
								onClick={faqAdminDeleteButtonOnClick}
								src="trash-can-outline"
								className="w-auto h-auto valign-center position-relative"
							/>
						</Group>
						<Input.Text
							id="faqAdminQuestion"
							name="text"
							src="@firebase.firestore.faq.(@field.id).question"
							className="w-100 h-auto valign-center position-relative mt-24px" />
						<Input.TextArea
							id="faqAdminQuestion1658185985116"
							name="text"
							src="@firebase.firestore.faq.(@field.id).answer"
							className="w-100 h-auto valign-center position-relative mt-24px" />
						<Text
							id="text1663628991964"
							onClick={text1663628991964OnClick}
							tag="small"
							className="w-auto h-auto align-end position-relative m-6px text-secondary-text" >
							{t('admin_faq.text1663628991964')}
						</Text>
					</Group>
				</List>
				<Group
					id="adminFaqContent"
					direction="vertical"
					className="max-w-1200px w-100 h-auto align-center position-relative p-24px" >
				</Group>
			</Group>
			<Group
				id="adminFaqFooter"
				direction="horizontal"
				className="w-100 h-auto position-relative p-16px" >
				<Group
					id="adminFaqFooterSpacer"
					direction="vertical"
					className="w-fill h-auto position-relative" >
				</Group>
				<Text
					id="adminButtonAddFaq"
					onClick={adminButtonAddFaqOnClick}
					className="w-auto h-auto border-radius-50px position-relative bg-accent p-12px text-text-on-accent" >
					{t('admin_faq.adminButtonAddFaq')}
				</Text>
			</Group>
      </Private_adminWrapper>
    </>
  );
};
