import React from 'react';
import { useDialogs } from '../hooks';
import { useFunctions } from '../hooks';

// Custom imports:
import { Modal, Text, Button } from '../components';
import { useTranslation } from 'react-i18next';

const Account_deletionDialog = () => {
    const { run } = useFunctions();
    // Custom exports:
	const { t } = useTranslation();

    // Custom functions:
	const account_deletion_buttons_positiveOnClick = (options) => {
		run([{"function":"request","url":"https:\/\/europe-west1-education-74543.cloudfunctions.net\/deleteAccount","params":{"userID":"@cookie.userID"},"method":"post","onerror":[{"function":"set","what":"@property.error","value":"Ha ocurrido un error al intentar eliminar tu cuenta. Comprueba tu conexi\u00f3n a Internet o pru\u00e9balo m\u00e1s tarde. Si el problema persiste, contacta con nosotros."},{"function":"popup","dialog":"error"}],"response":"json-api","onsuccess":[{"function":"goto","view":"logout"}]},{"function":"hide","dialog":"account_deletion"}], options)
	}
	const account_deletion_buttons_negativeOnClick = (options) => {
		run([{"function":"hide","dialog":"account_deletion"}], options)
	}

  return (
    
    
			<Modal id="account_deletion">
					<Modal.Body>
						<Text
							id="deletionInstructions"
							className="w-100 h-auto position-relative ff-regular" >
							{t('account_deletion.deletionInstructions')}
						</Text>
						<Text
							id="deletionInstructions1659689437972"
							className="w-100 h-auto position-relative mt-24px text-accent ff-bold" >
							{t('account_deletion.deletionInstructions1659689437972')}
						</Text>
					</Modal.Body>
					<Modal.Footer>
						<Button
							id="account_deletion_buttons_negative"
							onClick={account_deletion_buttons_negativeOnClick}
							variant="transparent"
							className="w-auto h-auto position-relative" >
							{t('account_deletion.account_deletion_buttons_negative')}
						</Button>
						<Button
							id="account_deletion_buttons_positive"
							onClick={account_deletion_buttons_positiveOnClick}
							variant="primary"
							className="w-auto h-auto position-relative" >
							{t('account_deletion.account_deletion_buttons_positive')}
						</Button>
					</Modal.Footer>
			</Modal>
    
  );
};

export default Account_deletionDialog;
