import React from 'react';
import { useFunctions } from '../hooks';

// Custom imports:
import { Offcanvas, Text, List, Group } from '../components';
import { useTranslation } from 'react-i18next';

export const TranslateDialog = () => {
    const { run } = useFunctions();
  // Custom exports:
	const { t } = useTranslation();

  // Custom functions:
	const translateListNameOnClick = (options) => {
		run([{"function":"set","what":"@property.languageTranslate","value":"@element.translateList.selected"},{"function":"do","what":[{"function":"request","url":"https:\/\/europe-west1-education-74543.cloudfunctions.net\/translate\n","response":"json-api","method":"post","into":"@database.response","params":{"language":"@property.languageTranslate","what":"@property.whatTranslate","post":"@property.post"}}],"comments":"FAQ"},{"function":"hide","dialog":"translate"}], options)
	}

  return (
    
    
			<Offcanvas id="translate" placement="end" backdrop={true} scrollable>
					<Offcanvas.Body>
						<Text
							id="translateInstructions"
							className="w-100 h-auto position-relative" >
							{t('translate.translateInstructions')}
						</Text>
						<List
							id="translateList"
							src="@database.i18n.langs"
							className="w-100 h-auto position-relative mt-24px" >
							<Group
								id="translateListRow"
								direction="vertical"
								className="w-100 h-auto position-relative" >
								<Text
									id="translateListName"
									onClick={translateListNameOnClick}
									src="name"
									className="w-100 h-auto position-relative p-16px" >
									{t('translate.translateListName')}
								</Text>
							</Group>
						</List>
					</Offcanvas.Body>
			</Offcanvas>
    
  );
};

export default TranslateDialog;
