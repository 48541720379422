import React from 'react';
import { Container } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import { useFunctions, useRefs } from '../hooks';
import { FullscreenWrapper } from '../wrappers';

// Custom imports:
import { Text } from '../components';
import { useTranslation } from 'react-i18next';

export const ForbiddenView = () => {
  const { run } = useFunctions();
  // Custom exports:
	const { t } = useTranslation();

  // Custom functions:
	const component1643824892298OnClick = (options) => {
		run([{"function":"goto","view":"home"}], options)
	}

  return (
    <>
      <Helmet>
				<title>{t('forbidden.title')}</title>
      </Helmet>
      <FullscreenWrapper>
          
			<Text
				id="component1643824892298"
				onClick={component1643824892298OnClick}
				className="w-100 h-auto position-relative" >
				{t('forbidden.component1643824892298')}
			</Text>
      </FullscreenWrapper>
    </>
  );
};
