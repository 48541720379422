import React from 'react';
import Badge from 'react-bootstrap/Badge';
import BootstrapNav from 'react-bootstrap/Nav';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Component from './Component';
import Icon from './Icon';
import Image from './Image';
import { useFunctions, useSource } from '../hooks';

const ItemBadge = ({ filter, path, src }) => {
  const { value: srcBadge } = useSource(src, {
    filter,
  });
  const { value: elementValue } = useSource(path);
  let { badge: elementBadge } = elementValue || {};
  elementBadge = elementBadge || 0;
  const badge = srcBadge || elementBadge;
  if (!badge) {
    return null;
  }
  return (
    <Badge pill bg="accent" className="ms-2">
      {badge}
    </Badge>
  );
};

const NavItem = ({
  badge,
  badgeFilter,
  children,
  onClick,
  drop = 'bottom',
  href = '',
  icon = '',
  id,
  image = '',
  isDropDownChild = false,
  path,
  title,
  type,
  ...props
}) => {
  const { set } = useFunctions();
  if (children) {
    children = Array.isArray(children) ? children : [children];
    return (
      <NavDropdown title={title} drop={drop}>
        {children.map((child) => ({
          ...child,
          props: {
            ...child.props,
            drop: 'end',
            isDropDownChild: true,
          },
        }))}
      </NavDropdown>
    );
  }

  const handleClick = async () => {
    await set({ what: badge || `@menu.${path}.${id}.badge`, value: 0 });
    onClick?.();
  };

  const Item = isDropDownChild ? NavDropdown.Item : BootstrapNav.Item;
  const ItemIcon = image ? Image : Icon;
  const regexp = new RegExp(`^/(${href})$`);
  const isActive = href && window.location.pathname.match(regexp);

  return (
    <Item onClick={handleClick}>
      {isDropDownChild ? (
        <>
          {(icon || image) && <ItemIcon src={icon || image} />}
          <span>{title}</span>
          <ItemBadge
            src={badge}
            path={`@menu.${path}.${id}`}
            filter={badgeFilter}
          />
        </>
      ) : (
        <BootstrapNav.Link className={isActive ? 'active' : ''}>
          {(icon || image) && <ItemIcon src={icon || image} />}
          <span>{title}</span>
          <ItemBadge
            src={badge}
            path={`@menu.${path}.${id}`}
            filter={badgeFilter}
          />
        </BootstrapNav.Link>
      )}
    </Item>
  );
};

export const Nav = ({ children = [], className, direction, id, ...props }) => {
  const directionClass = direction === 'vertical' ? 'flex-column' : '';
  children = Array.isArray(children) ? children : [children];
  return (
    <Component
      {...props}
      className={`${className} ${directionClass}`.trim()}
      component={BootstrapNav}
    >
      {children?.map((child) => {
        const { props } = child;
        return { ...child, props: { ...props, path: id } };
      })}
    </Component>
  );
};

Nav.Item = NavItem;

Nav.displayName = 'Nav';
export default Nav;
