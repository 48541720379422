import React from 'react';
import { Container } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import { useFunctions, useRefs } from '../hooks';
import { FullscreenWrapper } from '../wrappers';

// Custom imports:
import { Group, Icon, Text, Image, Nav, Dropdown, Input } from '../components';
import { useEffect } from 'react';
import logo_72_white from '../assets/icon/logo_72_white.png';
import logo_72 from '../assets/icon/logo_72.png';
import { useTranslation } from 'react-i18next';

export const Stu_step_3View = () => {
  const { run } = useFunctions();
  // Custom exports:
	const { t } = useTranslation();

  // Custom functions:
	const stu_step_3OnLoaded = (options) => {
		run([{"function":"set","what":"@cookie.stuOption1","value":"@firebase.firestore.students.(@cookie.userID).option1"},{"function":"set","what":"@cookie.stuOption2","value":"@firebase.firestore.students.(@cookie.userID).option2"},{"function":"set","what":"@cookie.stuInvestment","value":"@firebase.firestore.students.(@cookie.userID).investment"},{"function":"set","what":"@cookie.step3","value":"@firebase.firestore.students.(@cookie.userID).step3"}], options)
	}
	useEffect(stu_step_3OnLoaded, [])
	const homeBlueYoutubeIcon1647956673883OnClick = (options) => {
		run([{"function":"browser","url":"https:\/\/www.youtube.com\/channel\/UCmfIE4aab0Qjz5xuqD3Pbfg","target":"_maeyoutube"}], options)
	}
	const homeBlueTwitterIcon1647956673883OnClick = (options) => {
		run([{"function":"browser","url":"https:\/\/twitter.com\/mae_sports","target":"_maetwitter"}], options)
	}
	const homeBlueFacebookIcon1647956673883OnClick = (options) => {
		run([{"function":"browser","url":"https:\/\/www.facebook.com\/mae.sports\/","target":"_maefacebook"}], options)
	}
	const homeBlueInstagramIcon1647956673883OnClick = (options) => {
		run([{"function":"browser","url":"https:\/\/www.instagram.com\/mae.sports\/","target":"_maeinstagram"}], options)
	}
	const homeFooterWeb1647956673883OnClick = (options) => {
		run([{"function":"browser","url":"https:\/\/www.maeducation.net","target":"_self"}], options)
	}
	const homeFooterEmail1647956673883OnClick = (options) => {
		run([{"function":"browser","url":"mailto:info@maeducation.net"}], options)
	}
	const homeFooterTel1647956673883OnClick = (options) => {
		run([{"function":"browser","url":"tel:+34646516514"}], options)
	}
	const homeFooterCookies1663113686746OnClick = (options) => {
		run([{"function":"set","what":"@property.policy","value":"cookies_policy"},{"function":"popup","dialog":"terms"}], options)
	}
	const homeFooterPrivacy1663113686746OnClick = (options) => {
		run([{"function":"set","what":"@property.policy","value":"privacy_policy"},{"function":"popup","dialog":"terms"}], options)
	}
	const formInvestmentOnLostFocus = (options) => {
		run([{"function":"set","what":"@firebase.firestore.students.(@cookie.userID).investment","value":"@cookie.stuInvestment"},{"function":"set","what":"@property.step3","value":true,"if":{"what":"@cookie.stuOption1","is_not":null,"and":{"what":"@cookie.stuOption2","is_not":null,"and":{"what":"@cookie.stuInvestment","is_not":null}},"else":[{"function":"set","what":"@property.step3","value":false}]}},{"function":"set","what":"@firebase.firestore.students.(@cookie.userID).step3","value":"@property.step3","if":{"what":"@property.step3","is_not":"@cookie.step3"}},{"function":"set","what":"@cookie.step3","value":"@property.step3","if":{"what":"@property.step3","is_not":"@cookie.step3"}}], options)
	}
	const formOption2OnLostFocus = (options) => {
		run([{"function":"set","what":"@firebase.firestore.students.(@cookie.userID).option2","value":"@cookie.stuOption2"},{"function":"set","what":"@property.step3","value":true,"if":{"what":"@cookie.stuOption1","is_not":null,"and":{"what":"@cookie.stuOption2","is_not":null,"and":{"what":"@cookie.stuInvestment","is_not":null}},"else":[{"function":"set","what":"@property.step3","value":false}]}},{"function":"set","what":"@firebase.firestore.students.(@cookie.userID).step3","value":"@property.step3","if":{"what":"@property.step3","is_not":"@cookie.step3"}},{"function":"set","what":"@cookie.step3","value":"@property.step3","if":{"what":"@property.step3","is_not":"@cookie.step3"}}], options)
	}
	const formOption1OnLostFocus = (options) => {
		run([{"function":"set","what":"@firebase.firestore.students.(@cookie.userID).option1","value":"@cookie.stuOption1"},{"function":"set","what":"@property.step3","value":true,"if":{"what":"@cookie.stuOption1","is_not":null,"and":{"what":"@cookie.stuOption2","is_not":null,"and":{"what":"@cookie.stuInvestment","is_not":null}},"else":[{"function":"set","what":"@property.step3","value":false}]}},{"function":"set","what":"@firebase.firestore.students.(@cookie.userID).step3","value":"@property.step3","if":{"what":"@property.step3","is_not":"@cookie.step3"}},{"function":"set","what":"@cookie.step3","value":"@property.step3","if":{"what":"@property.step3","is_not":"@cookie.step3"}}], options)
	}
	const step3_but2GroupOnClick = (options) => {
		run([{"function":"goto","view":"stu_step_2"}], options)
	}
	const step3_but1GroupOnClick = (options) => {
		run([{"function":"goto","view":"stu_step_1"}], options)
	}
	const Session_menuItemsSessionLogoutOnClick = (options) => {
		run([{"function":"goto","view":"logout"}], options)
	}
	const Session_menuItemsSessionSettingsOnClick = (options) => {
		run([{"function":"goto","view":"account"}], options)
	}
	const Student_menuItemsStudentProfileOnClick = (options) => {
		run([{"function":"goto","view":"stu_step_1"}], options)
	}
	const Student_menuItemsStudentFaqOnClick = (options) => {
		run([{"function":"goto","view":"faq"}], options)
	}
	const Student_menuItemsStudentApplicationsOnClick = (options) => {
		run([{"function":"goto","view":"applications"}], options)
	}
	const Student_menuItemsStudentUniversitiesOnClick = (options) => {
		run([{"function":"goto","view":"stu_main"}], options)
	}
	const homeBlueYoutubeIcon1650455117936OnClick = (options) => {
		run([{"function":"browser","url":"https:\/\/www.youtube.com\/channel\/UCmfIE4aab0Qjz5xuqD3Pbfg","target":"_maeyoutube"}], options)
	}
	const homeBlueTwitterIcon1650455117936OnClick = (options) => {
		run([{"function":"browser","url":"https:\/\/twitter.com\/mae_sports","target":"_maetwitter"}], options)
	}
	const homeBlueFacebookIcon1650455117936OnClick = (options) => {
		run([{"function":"browser","url":"https:\/\/www.facebook.com\/mae.sports\/","target":"_maefacebook"}], options)
	}
	const homeBlueInstagramIcon1650455117936OnClick = (options) => {
		run([{"function":"browser","url":"https:\/\/www.instagram.com\/mae.sports\/","target":"_maeinstagram"}], options)
	}
	const homeBlueEmail1650455117936OnClick = (options) => {
		run([{"function":"browser","url":"mailto:info@maeducationusa.com"}], options)
	}
	const homeBluePhone1650455117936OnClick = (options) => {
		run([{"function":"browser","url":"tel:+34646516514"}], options)
	}

  return (
    <>
      <Helmet>
				<title>{t('stu_step_3.title')}</title>
      </Helmet>
      <FullscreenWrapper>
          
			<Group
				id="stuStep3Body"
				direction="vertical"
				className="w-100 min-h-100 fg-100 position-relative scrollable" >
				<Group
					id="stuMainHeader1650455117936"
					direction="vertical"
					className="w-100 h-auto position-relative" >
					<Group
						id="homeBlueBar1650455117936"
						direction="vertical"
						className="w-100 h-auto position-relative bg-primary" >
						<Group
							id="homeBlueContent1650455117936"
							direction="auto"
							className="w-100 h-auto align-center position-relative" >
							<Group
								id="homeBluePhone1650455117936"
								onClick={homeBluePhone1650455117936OnClick}
								direction="horizontal"
								className="w-auto h-auto align-center position-relative p-12px" >
								<Icon
									id="homeBluePhoneIcon1650455117936"
									src="phone"
									color="var(--text-on-primary)"
									className="w-auto h-auto position-relative"
								/>
								<Text
									id="homeBluePhoneNumber1650455117936"
									className="w-auto h-auto valign-center position-relative text-text-on-primary f-16px" >
									{t('stu_step_3.homeBluePhoneNumber1650455117936')}
								</Text>
							</Group>
							<Group
								id="homeBlueEmail1650455117936"
								onClick={homeBlueEmail1650455117936OnClick}
								direction="horizontal"
								className="w-auto h-auto align-center position-relative p-12px" >
								<Icon
									id="homeBlueEmailIcon1650455117936"
									src="email"
									color="var(--text-on-primary)"
									className="w-auto h-auto position-relative"
								/>
								<Text
									id="homeBlueEmailAddress1650455117936"
									className="w-auto h-auto valign-center position-relative text-text-on-primary f-16px" >
									{t('stu_step_3.homeBlueEmailAddress1650455117936')}
								</Text>
							</Group>
							<Group
								id="homeBlueSpacer1650455117936"
								direction="vertical"
								className="w-fill h-auto position-relative" >
							</Group>
							<Group
								id="homeBlueRightSide1650455117936"
								direction="vertical"
								className="w-auto h-auto align-center position-relative p-12px" >
								<Group
									id="homeBlueSSNN1650455117936"
									direction="horizontal"
									className="w-auto h-auto align-end position-relative" >
									<Icon
										id="homeBlueInstagramIcon1650455117936"
										onClick={homeBlueInstagramIcon1650455117936OnClick}
										src="instagram"
										color="var(--text-on-primary)"
										className="w-auto h-auto position-relative me-12px"
									/>
									<Icon
										id="homeBlueFacebookIcon1650455117936"
										onClick={homeBlueFacebookIcon1650455117936OnClick}
										src="facebook"
										color="var(--text-on-primary)"
										className="w-auto h-auto position-relative me-12px"
									/>
									<Icon
										id="homeBlueTwitterIcon1650455117936"
										onClick={homeBlueTwitterIcon1650455117936OnClick}
										src="twitter"
										color="var(--text-on-primary)"
										className="w-auto h-auto position-relative me-12px"
									/>
									<Icon
										id="homeBlueYoutubeIcon1650455117936"
										onClick={homeBlueYoutubeIcon1650455117936OnClick}
										src="youtube"
										color="var(--text-on-primary)"
										className="w-auto h-auto position-relative"
									/>
								</Group>
							</Group>
						</Group>
					</Group>
					<Group
						id="stuMainWhiteHeader1650455117936"
						direction="vertical"
						className="w-100 h-auto position-relative p-24px" >
						<Group
							id="homeHeaderContent1650455117936"
							direction="auto"
							className="w-100 h-auto align-center valign-center position-relative" >
							<Group
								id="homeNavBar1650455117936"
								direction="horizontal"
								className="min-w-320px w-fill h-auto align-start valign-center position-relative" >
								<Image
									id="homeLogo1653665641843"
									src={logo_72}
									className="w-auto min-h-64px h-64px valign-center position-relative" />
							</Group>
							<Group
								id="homeNavBarContent1650455117936"
								direction="auto"
								className="w-auto h-auto align-end valign-center position-relative" >
								<Nav
									id="homeMenu1650455117936"
									className="w-auto h-auto position-relative"
									direction="horizontal" >
									<Nav.Item title={t('student_menu.items.studentUniversities.title')} onClick={Student_menuItemsStudentUniversitiesOnClick} href="stu_main" />
									<Nav.Item title={t('student_menu.items.studentApplications.title')} onClick={Student_menuItemsStudentApplicationsOnClick} href="applications" />
									<Nav.Item title={t('student_menu.items.studentFaq.title')} onClick={Student_menuItemsStudentFaqOnClick} href="faq" />
									<Nav.Item title={t('student_menu.items.studentProfile.title')} onClick={Student_menuItemsStudentProfileOnClick} href="stu_step_1" />
								</Nav>
								<Dropdown
									id="stepAccountMenu3"
									className="w-auto h-auto border-radius-32px valign-center position-relative"
									variant="primary"
									title={<>

									<Text
										id="stepAccountMenu3Title"
										className="w-auto h-auto position-relative ff-regular" >
										{t('stu_step_3.stepAccountMenu3Title')}
									</Text>
								</>} >
									<Dropdown.Item title={t('session_menu.items.sessionSettings.title')} onClick={Session_menuItemsSessionSettingsOnClick} href="account" />
									<Dropdown.Item title={t('session_menu.items.sessionLogout.title')} onClick={Session_menuItemsSessionLogoutOnClick} href="logout" />
								</Dropdown>
							</Group>
						</Group>
					</Group>
				</Group>
				<Group
					id="tabsStep3"
					direction="horizontal"
					className="w-100 h-auto position-relative" >
					<Group
						id="tabsLeftStep1651413507270"
						direction="vertical"
						className="w-24px min-h-100 h-100 border-gray_b border-bottom position-relative" >
					</Group>
					<Group
						id="step3_but1Group"
						onClick={step3_but1GroupOnClick}
						direction="horizontal"
						className="max-w-30 w-auto min-h-64px h-64px border-top border-bottom border-start border-end border-radius-tl10px-tr10px align-center position-relative bg-light p-8px" >
						<Icon
							id="step1_but1_ko1651413550160"
							if={{"what":"@firebase.firestore.students.(@cookie.userID).step1","is_not":true}}
							src="alert"
							size={1}
							color="var(--accent)"
							className="w-auto h-auto align-end valign-start position-absolute p-4px ms-8px"
						/>
						<Text
							id="step1_but1_ok1651413550160"
							tag="b"
							className="max-w-100 w-auto h-auto border-radius-50px valign-center position-relative py-6px text-primary ff-bold text-center" >
							{t('stu_step_3.step1_but1_ok1651413550160')}
						</Text>
					</Group>
					<Group
						id="tabsMid1Step3"
						direction="vertical"
						className="w-16px min-h-100 h-100 border-gray_b border-bottom position-relative" >
					</Group>
					<Group
						id="step3_but2Group"
						onClick={step3_but2GroupOnClick}
						direction="horizontal"
						className="max-w-30 w-auto min-h-64px h-64px border-top border-bottom border-start border-end border-radius-tl10px-tr10px align-center position-relative bg-light p-8px" >
						<Icon
							id="step1_but2_ko1651413507270"
							if={{"what":"@firebase.firestore.students.(@cookie.userID).step2","is_not":true}}
							src="alert"
							size={1}
							color="var(--accent)"
							className="w-auto h-auto align-end valign-start position-absolute p-4px ms-8px"
						/>
						<Text
							id="step1_but2_ok1651413507270"
							tag="b"
							className="max-w-100 w-auto h-auto valign-center position-relative px-16px py-6px text-dark ff-bold text-center" >
							{t('stu_step_3.step1_but2_ok1651413507270')}
						</Text>
					</Group>
					<Group
						id="tabsMid2Step3"
						direction="vertical"
						className="w-16px min-h-100 h-100 border-gray_b border-bottom position-relative" >
					</Group>
					<Group
						id="step3_but3Group"
						direction="horizontal"
						className="max-w-30 w-auto min-h-64px h-64px border-top border-start border-end border-radius-tl10px-tr10px align-center position-relative p-8px" >
						<Icon
							id="step1_but3_ko1651413507270"
							if={{"what":"@firebase.firestore.students.(@cookie.userID).step3","is_not":true}}
							src="alert"
							size={1}
							color="var(--accent)"
							className="w-auto h-auto align-end valign-start position-absolute p-4px"
						/>
						<Text
							id="step1_but3_ok1651413507270"
							tag="b"
							className="max-w-100 w-auto h-auto valign-center position-relative px-16px py-6px text-dark ff-bold text-center" >
							{t('stu_step_3.step1_but3_ok1651413507270')}
						</Text>
					</Group>
					<Group
						id="tabsRightStep1651413507270"
						direction="vertical"
						className="w-fill min-h-100 h-100 border-gray_b border-bottom position-relative" >
					</Group>
				</Group>
				<Group
					id="stuStep3Content"
					direction="vertical"
					className="w-100 h-auto align-center position-relative p-24px" >
					<Text
						id="step3Title"
						tag="h1"
						className="w-100 h-auto position-relative pt-40px text-primary f-32px ff-bold" >
						{t('stu_step_3.step3Title')}
					</Text>
					<Group
						id="step3form"
						direction="auto"
						className="w-100 h-auto position-relative" >
						<Group
							id="formWishMargin"
							direction="auto"
							className="w-100 h-auto position-relative pe-32px mt-32px" >
							<Group
								id="formOption1Group"
								direction="vertical"
								className="max-w-500px w-100 h-auto position-relative me-32px" >
								<Input.Text
									id="formOption1"
									label={t('stu_step_3.formOption1.label')}
									name="text"
									src="@cookie.stuOption1"
								onBlur={formOption1OnLostFocus}
									className="w-100 h-auto position-relative mt-24px" />
								<Icon
									id="iconMandatory_1649462164912"
									if={{"what":"@cookie.stuSchool","is":null,"or":{"what":"@cookie.stuSchool.length","is":0}}}
									src="asterisk"
									color="var(--danger)"
									className="w-auto h-auto align-end valign-end position-absolute-bottom me-6px mb-6px"
								/>
							</Group>
							<Group
								id="formOption2Group"
								direction="vertical"
								className="max-w-500px w-100 h-auto position-relative me-32px" >
								<Input.Text
									id="formOption2"
									label={t('stu_step_3.formOption2.label')}
									name="text"
									src="@cookie.stuOption2"
								onBlur={formOption2OnLostFocus}
									className="w-100 h-auto position-relative mt-24px" />
								<Icon
									id="iconMandatory_1649462231947"
									if={{"what":"@cookie.stuSchool","is":null,"or":{"what":"@cookie.stuSchool.length","is":0}}}
									src="asterisk"
									color="var(--danger)"
									className="w-auto h-auto align-end valign-end position-absolute-bottom me-6px mb-6px"
								/>
							</Group>
						</Group>
						<Group
							id="formInvestmentMargin"
							direction="vertical"
							className="max-w-500px w-100 h-auto position-relative pe-32px mt-64px" >
							<Input.Number
								id="formInvestment"
								label={t('stu_step_3.formInvestment.label')}
								name="text"
								src="@cookie.stuInvestment"
							onBlur={formInvestmentOnLostFocus}
								className="w-100 h-auto position-relative" />
							<Icon
								id="iconMandatory_1649462916745"
								if={{"what":"@cookie.stuInvestment","is":null,"or":{"what":"@cookie.stuInvestment.length","is":0}}}
								src="asterisk"
								color="var(--danger)"
								className="w-auto h-auto align-end valign-end position-absolute-bottom me-6px mb-6px"
							/>
						</Group>
					</Group>
				</Group>
				<Group
					id="uniInfoFooter1647956673883"
					direction="vertical"
					className="w-100 h-auto position-relative mt-48px" >
					<Group
						id="footerSpacer3"
						direction="vertical"
						className="w-100 min-h-300px h-300px position-relative" >
					</Group>
					<Group
						id="homeFooterBar1647956673883"
						direction="vertical"
						className="w-100 h-auto valign-end position-relative bg-footer_bg py-48px" >
						<Group
							id="homeFooterBarContent1647956673883"
							direction="auto"
							className="max-w-1200px w-100 h-auto align-center position-relative p-32px" >
							<Group
								id="homeFooterInfo1663113686746"
								direction="vertical"
								className="w-fill h-auto position-relative" >
								<Text
									id="homeFooterAbout1663113686746"
									tag="p"
									className="max-w-500px min-w-300px w-fill h-auto position-relative text-text-on-primary ff-medium" >
									{t('stu_step_3.homeFooterAbout1663113686746')}
								</Text>
								<Group
									id="homeFooterPolicy1663113686746"
									direction="auto"
									className="w-fill h-auto position-relative" >
									<Text
										id="homeFooterPrivacy1663113686746"
										onClick={homeFooterPrivacy1663113686746OnClick}
										tag="small"
										className="max-w-100 w-auto h-auto position-relative pe-8px text-gray_b ff-medium" >
										{t('stu_step_3.homeFooterPrivacy1663113686746')}
									</Text>
									<Text
										id="homeFooterPrivacySeparator1663113686746"
										tag="small"
										className="w-auto h-auto position-relative pe-8px text-gray_b ff-medium" >
										{t('stu_step_3.homeFooterPrivacySeparator1663113686746')}
									</Text>
									<Text
										id="homeFooterCookies1663113686746"
										onClick={homeFooterCookies1663113686746OnClick}
										tag="small"
										className="max-w-100 w-auto h-auto position-relative pe-8px text-gray_b ff-medium" >
										{t('stu_step_3.homeFooterCookies1663113686746')}
									</Text>
								</Group>
							</Group>
							<Group
								id="homeFooterSpacer1647956673883"
								direction="vertical"
								className="w-fill h-auto position-relative" >
							</Group>
							<Group
								id="homeFooterContact1647956673883"
								direction="vertical"
								className="max-w-400px min-w-300px w-fill h-auto position-relative" >
								<Image
									id="homeFooterLogo1647956673883"
									src={logo_72_white}
									className="w-auto min-h-72px h-72px align-end position-relative" />
								<Text
									id="homeFooterCities1647956673883"
									tag="p"
									className="w-auto h-auto align-end position-relative text-text-on-primary ff-medium text-end" >
									{t('stu_step_3.homeFooterCities1647956673883')}
								</Text>
								<Text
									id="homeFooterTel1647956673883"
									onClick={homeFooterTel1647956673883OnClick}
									tag="p"
									className="w-auto h-auto align-end position-relative text-text-on-primary ff-medium text-end" >
									{t('stu_step_3.homeFooterTel1647956673883')}
								</Text>
								<Text
									id="homeFooterEmail1647956673883"
									onClick={homeFooterEmail1647956673883OnClick}
									tag="p"
									className="w-auto h-auto align-end position-relative text-text-on-primary ff-medium text-end" >
									{t('stu_step_3.homeFooterEmail1647956673883')}
								</Text>
								<Text
									id="homeFooterWeb1647956673883"
									onClick={homeFooterWeb1647956673883OnClick}
									tag="p"
									className="w-auto h-auto align-end position-relative text-text-on-primary ff-medium text-end" >
									{t('stu_step_3.homeFooterWeb1647956673883')}
								</Text>
								<Group
									id="homeBlueSSNN1647956673883"
									direction="horizontal"
									className="w-auto h-auto align-end position-relative" >
									<Icon
										id="homeBlueInstagramIcon1647956673883"
										onClick={homeBlueInstagramIcon1647956673883OnClick}
										src="instagram"
										color="var(--text-on-primary)"
										className="w-auto h-auto position-relative me-12px"
									/>
									<Icon
										id="homeBlueFacebookIcon1647956673883"
										onClick={homeBlueFacebookIcon1647956673883OnClick}
										src="facebook"
										color="var(--text-on-primary)"
										className="w-auto h-auto position-relative me-12px"
									/>
									<Icon
										id="homeBlueTwitterIcon1647956673883"
										onClick={homeBlueTwitterIcon1647956673883OnClick}
										src="twitter"
										color="var(--text-on-primary)"
										className="w-auto h-auto position-relative me-12px"
									/>
									<Icon
										id="homeBlueYoutubeIcon1647956673883"
										onClick={homeBlueYoutubeIcon1647956673883OnClick}
										src="youtube"
										color="var(--text-on-primary)"
										className="w-auto h-auto position-relative"
									/>
								</Group>
							</Group>
						</Group>
					</Group>
				</Group>
			</Group>
      </FullscreenWrapper>
    </>
  );
};
