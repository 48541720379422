import React from 'react';
import BootstrapCard from 'react-bootstrap/Card';
import Component from './Component';
import Image from './Image';
import Text from './Text';

const CardImg = (props) => {
  return <Image {...props} tag={BootstrapCard.Img} />;
};

const CardText = (props) => {
  return <Text {...props} tag={BootstrapCard.Text} />;
};

const CardTitle = (props) => {
  return <Text {...props} tag={BootstrapCard.Title} />;
};

const CardSubtitle = (props) => {
  return <Text {...props} tag={BootstrapCard.Subtitle} />;
};

export const CardBody = ({ children, itemData, itemDataSrc, ...props }) => {
  children = Array.isArray(children) ? children : [children];
  return (
    <BootstrapCard.Body {...props} className="d-flex flex-column">
      {itemData
        ? children
            .filter((child) => child)
            .map(({ props, ...child }) => ({
              ...child,
              props: {
                ...props,
                itemData,
                itemDataSrc: props?.src ? itemData[props?.src] : props?.src,
              },
            }))
        : children}
    </BootstrapCard.Body>
  );
};

export const Card = ({ children, itemData, ...props }) => {
  children = Array.isArray(children) ? children : [children];
  return (
    <Component {...props} component={BootstrapCard}>
      {itemData
        ? children.map(({ props, ...child }) => ({
            ...child,
            props: {
              ...props,
              itemData,
              itemDataSrc: props?.src ? itemData[props?.src] : props?.src,
            },
          }))
        : children}
    </Component>
  );
};

Card.Body = CardBody;
Card.Img = CardImg;
Card.Subtitle = CardSubtitle;
Card.Text = CardText;
Card.Title = CardTitle;

Card.displayName = 'Card';
export default Card;
