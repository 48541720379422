import React from 'react';
import { useDialogs } from '../hooks';
import { useFunctions } from '../hooks';

// Custom imports:
import { Modal, Text, Button } from '../components';
import { useTranslation } from 'react-i18next';

const Signup_wrongDialog = () => {
    const { run } = useFunctions();
    // Custom exports:
	const { t } = useTranslation();

    // Custom functions:
	const signup_wrong_buttons_positiveOnClick = (options) => {
		run([{"function":"hide","dialog":"signup_wrong"}], options)
	}

  return (
    
    
			<Modal id="signup_wrong">
					<Modal.Body>
						<Text
							id="signupErrorMsg"
							className="w-100 h-auto position-relative" >
							{t('signup_wrong.signupErrorMsg')}
						</Text>
					</Modal.Body>
					<Modal.Footer>
						<Button
							id="signup_wrong_buttons_positive"
							onClick={signup_wrong_buttons_positiveOnClick}
							variant="primary"
							className="w-auto h-auto position-relative" >
							{t('signup_wrong.signup_wrong_buttons_positive')}
						</Button>
					</Modal.Footer>
			</Modal>
    
  );
};

export default Signup_wrongDialog;
