import React from 'react';
import { useDialogs } from '../hooks';
import { useFunctions } from '../hooks';

// Custom imports:
import { Modal, Text, Input, Button } from '../components';
import { useTranslation } from 'react-i18next';

const Admin_newrankingDialog = () => {
    const { run } = useFunctions();
    // Custom exports:
	const { t } = useTranslation();

    // Custom functions:
	const admin_newranking_buttons_positiveOnClick = (options) => {
		run([{"function":"add","what":{"name":"@property.rankingDescription"},"to":"@firebase.firestore.schools.(@cookie.universityID).rankings"},{"function":"hide","dialog":"admin_newranking"}], options)
	}

  return (
    
    
			<Modal id="admin_newranking">
					<Modal.Body>
						<Text
							id="component1646047517913"
							className="w-100 h-auto position-relative" >
							{t('admin_newranking.component1646047517913')}
						</Text>
						<Input.Text
							id="newSchoolName1646047517913"
							name="text"
							src="@property.rankingDescription"
							className="w-auto h-auto position-relative mt-24px" />
					</Modal.Body>
					<Modal.Footer>
						<Button
							id="admin_newranking_buttons_positive"
							onClick={admin_newranking_buttons_positiveOnClick}
							variant="primary"
							className="w-auto h-auto position-relative" >
							{t('admin_newranking.admin_newranking_buttons_positive')}
						</Button>
					</Modal.Footer>
			</Modal>
    
  );
};

export default Admin_newrankingDialog;
