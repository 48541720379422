// Custom imports:
import steps from '../../assets/data/steps.json';
import config from '../../assets/data/config.json';
import student from '../../assets/data/student.json';
import students from '../../assets/data/students.json';
import response from '../../assets/data/response.json';
import i18n from '../../assets/data/i18n.json';
import entity from '../../assets/data/entity.json';
import university from '../../assets/data/university.json';
import schools from '../../assets/data/schools.json';
import universities from '../../assets/data/universities.json';
import profile from '../../assets/data/profile_progress.json';
import faqcat from '../../assets/data/faqcat.json';
import unidata from '../../assets/data/uni_fields.json';

export const IS_READONLY = {
  // Readonly databases:
	i18n: true,
};

export const DATABASES = {
  INITIAL_STATE: {
    database: {
      // Databases:
			steps,
			config,
			student,
			students,
			response,
			i18n,
			entity,
			university,
			schools,
			universities,
			profile,
			faqcat,
			unidata,
    },
  },

  SET: 'DATABASES_SET',
};

export const DatabasesReducer = (draft, action) => {
  const { payload, type } = action;
  switch (type) {
    case DATABASES.SET:
      draft.database = {
        ...draft.database,
        ...payload,
      };
      break;

    default:
      return draft;
  }
};
