import React from 'react';
import { useDialogs } from '../hooks';
import { useFunctions } from '../hooks';

// Custom imports:
import { Modal, Group, Input, Text } from '../components';
import { useTranslation } from 'react-i18next';

const School_send_applicationDialog = () => {
    const { run } = useFunctions();
    // Custom exports:
	const { t } = useTranslation();

    // Custom functions:
	const buttonSendApplication1660087039195OnClick = (options) => {
		run([{"function":"request","url":"https:\/\/europe-west1-education-74543.cloudfunctions.net\/sendApplication\n","method":"post","response":"json-api","into":"@database.response","onerror":[{"function":"set","what":"@property.application","value":null},{"function":"set","what":"@property.msg","value":"error_send_application"},{"function":"popup","dialog":"msg_i18n"}],"params":{"name":"@database.schools.(@cookie.entityID).name","active":true,"city":"@database.schools.(@cookie.entityID).city","logo":"@database.schools.(@cookie.entityID).logo","photo":"@database.schools.(@cookie.entityID).photo_school","userID":"@cookie.userID","schoolYear":"@cookie.applySchoolYear","type":"school","enrollmentYear":"@cookie.applyEnrollment","entityID":"@cookie.entityID"},"if":{"what":"@cookie.applyEnrollment","is_not":null,"and":{"what":"@cookie.applySchoolYear","is_not":"none"},"else":[{"function":"set","what":"@property.error","value":"You must enter both values"},{"function":"popup","dialog":"error"}]},"onsuccess":[{"function":"set","what":"@property.application","value":null},{"function":"set","what":"@property.msg","value":"maximum_applications","if":{"what":"@database.response.message","is":"maximum_reached","else":[{"function":"set","what":"@property.msg","value":"application_sent"}]}},{"function":"popup","dialog":"msg_i18n"}]},{"function":"hide","dialog":"school_send_application"}], options)
	}
	const buttonCloseSendApplication1660087039195OnClick = (options) => {
		run([{"function":"hide","dialog":"stu_send_application"}], options)
	}

  return (
    
    
			<Modal id="school_send_application" centered>
					<Modal.Body>
						<Group
							id="schoolSendApplicationBody"
							direction="vertical"
							className="w-100 min-h-100 fg-100 position-relative" >
							<Input.Text
								id="applyYearSelect"
								label={t('school_send_application.applyYearSelect.label')}
								name="text"
								src="@cookie.applyEnrollment"
								className="w-100 h-auto position-relative" />
							<Input.Select
								id="applyCourseSelect"
								label={t('school_send_application.applyCourseSelect.label')}
								name="select"
								src="@cookie.applySchoolYear"
								className="w-100 h-auto position-relative mt-24px" >
								<option value={"none"}>{t('school_send_application.applyCourseSelect.opt0')}</option>
								<option value={"eso_2"}>{t('school_send_application.applyCourseSelect.opt1')}</option>
								<option value={"eso_3"}>{t('school_send_application.applyCourseSelect.opt2')}</option>
								<option value={"eso_4"}>{t('school_send_application.applyCourseSelect.opt3')}</option>
								<option value={"bac_1"}>{t('school_send_application.applyCourseSelect.opt4')}</option>
								<option value={"bac_2"}>{t('school_send_application.applyCourseSelect.opt5')}</option>
							</Input.Select>
							<Text
								id="buttonCloseSendApplication1660087039195"
								onClick={buttonCloseSendApplication1660087039195OnClick}
								className="w-100 h-auto border-radius-50px position-relative bg-gray p-12px mt-32px text-text-on-accent ff-bold text-center" >
								{t('school_send_application.buttonCloseSendApplication1660087039195')}
							</Text>
							<Text
								id="buttonSendApplication1660087039195"
								onClick={buttonSendApplication1660087039195OnClick}
								className="w-100 h-auto border-radius-50px position-relative bg-accent p-12px mt-16px text-text-on-accent ff-bold text-center" >
								{t('school_send_application.buttonSendApplication1660087039195')}
							</Text>
						</Group>
					</Modal.Body>
			</Modal>
    
  );
};

export default School_send_applicationDialog;
