import React from 'react';
import { Container } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import { useFunctions, useRefs } from '../hooks';
import { Private_adminWrapper } from '../wrappers';

// Custom imports:
import { Group, Text, Input, Icon, List, Card, Image, Button } from '../components';
import { useEffect } from 'react';
import placeholder_logo from '../assets/image/placeholder_logo.svg';
import { useTranslation } from 'react-i18next';

export const Admin_mainView = () => {
  const { run } = useFunctions();
  // Custom exports:
	const { t } = useTranslation();

  // Custom functions:
	const admin_mainOnLoaded = (options) => {
		run([{"function":"set","what":"@cookie.lang","value":"es","if":{"what":"@app.language","starts_with":"es","else":[{"function":"set","what":"@cookie.lang","value":"en"}]}},{"function":"clone","what":"@firebase.firestore.schools","into":"@database.universities","filter":{"what":"entity","is_not":"school"}}], options)
	}
	useEffect(admin_mainOnLoaded, [])
	const adminButtonAddSchoolOnClick = (options) => {
		run([{"function":"set","what":"@property.entity","value":"university"},{"function":"set","what":"@property.database","value":"universities"},{"function":"popup","dialog":"admin_newschool"}], options)
	}
	const uniListButtonEditOnClick = (options) => {
		run([{"function":"set","what":"@cookie.universityID","value":"@element.adminUniList.selected"},{"function":"set","what":"@cookie.university","value":"@element.adminUniList.value"},{"function":"goto","view":"uni_edit"}], options)
	}
	const uniSearchInput1656449280749OnChanged = (options) => {
		run([{"function":"set","what":"@property.keywords","value":"@property.search.lower"}], options)
	}

  return (
    <>
      <Helmet>
				<title>{t('admin_main.title')}</title>
      </Helmet>
      <Private_adminWrapper>
          
			<Group
				id="adminMainBody"
				direction="vertical"
				className="w-100 h-fill position-relative p-24px scrollable" >
				<Group
					id="adminToobar"
					direction="horizontal"
					className="w-100 h-auto position-relative" >
					<Text
						id="adminUniListContent"
						tag="h2"
						className="w-fill h-auto position-relative text-primary ff-bold" >
						{t('admin_main.adminUniListContent')}
					</Text>
					<Group
						id="uniSearchGroup1656449280749"
						direction="horizontal"
						className="max-w-250px min-w-150px w-fill h-auto valign-center position-relative py-4px" >
						<Input.Text
							id="uniSearchInput1656449280749"
							name="text"
							src="@property.search"
						onChange={uniSearchInput1656449280749OnChanged}
							className="w-fill h-auto position-relative" />
						<Icon
							id="uniSearchIcon1656449280749"
							src="magnify"
							color="var(--gray)"
							className="w-auto h-auto align-end valign-center position-absolute me-8px"
						/>
					</Group>
				</Group>
				<List
					id="adminUniList"
					src="@database.universities"
					filter={{"what":"search_words","includes":"@property.keywords"}}
					order={{"type":"asc","field":"name"}}
					columns={"auto"}
					className="w-100 h-auto position-relative mt-24px" >
					<Card
						id="adminUniCard"
						className="w-300px h-auto position-relative m-8px" >
						<Card.Body>
								<Group
									id="uniLogoGroup1645543369514"
									direction="horizontal"
									className="w-100 h-auto border-gray border-bottom position-relative" >
									<Text
										id="uniListName1645543369514"
										tag="b"
										src="name"
										className="w-fill h-auto valign-center position-relative ff-bold" >
										{t('admin_main.uniListName1645543369514')}
									</Text>
									<Image
										id="uniListLogo1645543369514"
										fallback={placeholder_logo}
										src="logo"
										className="w-auto min-h-52px h-52px align-center valign-center position-relative fill" />
								</Group>
								<Text
									id="uniCity1645543369514"
									tag="b"
									src="city"
									className="w-100 h-auto position-relative mt-8px text-primary ff-regular" >
									{t('admin_main.uniCity1645543369514')}
								</Text>
								<Group
									id="uniRankingGroup1645543369514"
									direction="horizontal"
									className="w-100 h-auto position-relative mt-16px" >
									<Icon
										id="uniRankingIcon1645543369514"
										src="podium"
										className="w-auto h-auto position-relative"
									/>
									<Text
										id="uniRankingScore1645543369514"
										src="ranking"
										className="w-fill h-auto valign-center position-relative ms-12px ff-regular" >
										{t('admin_main.uniRankingScore1645543369514')}
									</Text>
								</Group>
								<Group
									id="uniStudentsGroup1645543369514"
									direction="horizontal"
									className="w-100 h-auto position-relative" >
									<Icon
										id="uniStudentsIcon1645543369514"
										src="account-group"
										className="w-auto h-auto position-relative"
									/>
									<Text
										id="uniStudentsScore1645543369514"
										src="students"
										className="w-auto h-auto valign-center position-relative ms-12px ff-regular" >
										{t('admin_main.uniStudentsScore1645543369514')}
									</Text>
								</Group>
								<Group
									id="uniTuitionGroup1645543369514"
									direction="horizontal"
									className="w-100 h-auto position-relative" >
									<Icon
										id="uniTuitionIcon1645543369514"
										src="piggy-bank-outline"
										className="w-auto h-auto position-relative"
									/>
									<Text
										id="uniTuitionScore1645543369514"
										src="tuition"
										className="w-auto h-auto valign-center position-relative ms-12px ff-regular" >
										{t('admin_main.uniTuitionScore1645543369514')}
									</Text>
									<Text
										id="uniTuitionCurrency1645543369514"
										className="w-auto h-auto valign-center position-relative ms-4px ff-regular" >
										{t('admin_main.uniTuitionCurrency1645543369514')}
									</Text>
								</Group>
								<Group
									id="uniAdminListButtonsGroup"
									direction="horizontal"
									className="w-auto h-auto border-top align-end position-relative pt-8px mt-24px" >
									<Button
										id="uniListButtonEdit"
										onClick={uniListButtonEditOnClick}
										variant="primary"
										className="w-auto h-auto border-radius-50px align-end position-relative" >
										{t('admin_main.uniListButtonEdit')}
									</Button>
								</Group>
						</Card.Body>
					</Card>
				</List>
				<Group
					id="adminMainContent"
					direction="vertical"
					className="max-w-1200px w-100 h-auto align-center position-relative p-24px" >
				</Group>
			</Group>
			<Group
				id="adminFooter"
				direction="horizontal"
				className="w-100 h-auto position-relative p-16px" >
				<Group
					id="adminFooterSpacer"
					direction="vertical"
					className="w-fill h-auto position-relative" >
				</Group>
				<Text
					id="adminButtonAddSchool"
					onClick={adminButtonAddSchoolOnClick}
					className="w-auto h-auto border-radius-50px position-relative bg-accent p-12px text-text-on-accent" >
					{t('admin_main.adminButtonAddSchool')}
				</Text>
			</Group>
      </Private_adminWrapper>
    </>
  );
};
