import React from 'react';
import { Container } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import { useFunctions, useRefs } from '../hooks';
import { FullscreenWrapper } from '../wrappers';

// Custom imports:
import { Group, Image, Text, Input, Button } from '../components';
import logo_72 from '../assets/icon/logo_72.png';
import { useTranslation } from 'react-i18next';

export const Stu_signupView = () => {
  const { run } = useFunctions();
  // Custom exports:
	const { t } = useTranslation();

  // Custom functions:
	const buttonSignupGoogleOnClick = (options) => {
		run([{"function":"login","provider":"firebase.google","onvalid":[{"function":"add","what":{"type":"@cookie.studentType"},"to":"@firebase.firestore.users","key":"@firebase.user.id"},{"function":"add","what":{"name":"@cookie.name","lastname":"@cookie.lastname","city":"@cookie.city","phone":"@cookie.phone","school":"@cookie.school","schoolyear":"@cookie.schoolyear","birthdate":"@cookie.birthdate","complete":false,"email":"@cookie.email"},"to":"@firebase.firestore.students","key":"@firebase.user.id"},{"function":"set","what":"@cookie.type","value":"@cookie.studentType"},{"function":"set","what":"@cookie.email","value":"@firebase.user.email"},{"function":"set","what":"@cookie.userID","value":"@firebase.user.id"},{"function":"set","what":"@cookie.avatar","value":"https:\/\/s.gravatar.com\/avatar\/(@cookie.email.md5)?s=80"},{"function":"set","what":"@cookie.name","value":"@firebase.firestore.students.(@firebase.user.id).name"},{"function":"goto","view":"stu_main"}],"oninvalid":[{"function":"popup","dialog":"signup_wrong"}]}], options)
	}
	const buttonSignupOnClick = (options) => {
		run([{"function":"signup","provider":"firebase","email":"@cookie.email","onvalid":[{"function":"add","what":{"type":"@cookie.studentType"},"to":"@firebase.firestore.users","key":"@firebase.user.id"},{"function":"add","what":{"name":"@cookie.name","lastname":"@cookie.lastname","city":"@cookie.city","phone":"@cookie.phone","school":"@cookie.school","schoolyear":"@cookie.schoolyear","birthdate":"@cookie.birthdate","complete":false,"email":"@cookie.email"},"to":"@firebase.firestore.students","key":"@firebase.user.id"},{"function":"set","what":"@cookie.type","value":"@cookie.studentType"},{"function":"set","what":"@cookie.email","value":"@firebase.user.email"},{"function":"set","what":"@cookie.userID","value":"@firebase.user.id"},{"function":"set","what":"@cookie.avatar","value":"https:\/\/s.gravatar.com\/avatar\/(@cookie.email.md5)?s=24"},{"function":"set","what":"@cookie.name","value":"@firebase.firestore.students.(@firebase.user.id).name"},{"function":"goto","view":"stu_step_1","if":{"what":"@cookie.studentType","is":"student"}},{"function":"goto","view":"profile_a","if":{"what":"@cookie.studentType","is":"student_school"}}],"oninvalid":[{"function":"popup","dialog":"signup_wrong"}],"pass":"@property.password","if":{"what":"@cookie.terms","is":true,"else":[{"function":"popup","dialog":"terms_accept"}]}}], options)
	}
	const termsCookieLabelOnClick = (options) => {
		run([{"function":"set","what":"@property.policy","value":"cookies_policy"},{"function":"popup","dialog":"terms"}], options)
	}
	const termsLabelPrivacyOnClick = (options) => {
		run([{"function":"set","what":"@property.policy","value":"privacy_policy"},{"function":"popup","dialog":"terms"}], options)
	}

  return (
    <>
      <Helmet>
				<title>{t('stu_signup.title')}</title>
      </Helmet>
      <FullscreenWrapper>
          
			<Group
				id="signupBody"
				direction="vertical"
				className="w-100 min-h-100 fg-100 position-relative p-24px" >
				<Group
					id="signupForm"
					direction="vertical"
					className="w-auto h-auto align-center valign-center position-absolute p-16px" >
					<Image
						id="signupLogo"
						src={logo_72}
						className="w-128px h-auto align-center position-relative" />
					<Text
						id="signupCatch"
						if={{"what":"@cookie.studentType","is":"student"}}
						tag="h3"
						className="w-600px h-auto position-relative p-24px text-primary ff-bold text-center" >
						{t('stu_signup.signupCatch')}
					</Text>
					<Text
						id="signupCatchSchool"
						if={{"what":"@cookie.studentType","is":"student_school"}}
						tag="h3"
						className="w-600px h-auto position-relative p-24px text-primary ff-bold text-center" >
						{t('stu_signup.signupCatchSchool')}
					</Text>
					<Text
						id="signupInstructions"
						tag="p"
						className="w-600px h-auto position-relative p-24px text-primary-text ff-regular text-center" >
						{t('stu_signup.signupInstructions')}
					</Text>
					<Input.Text
						id="signupPassword"
						name="text"
						src="@property.password"
					size="lg"
						className="max-w-600px w-80 h-auto align-center position-relative p-8px" />
					<Group
						id="termsGroup"
						direction="horizontal"
						className="w-auto h-auto align-center position-relative mt-24px" >
						<Input.Switch
							id="termsSwitch"
							name="switch"
							src="@cookie.terms"
							className="w-auto h-auto position-relative" />
						<Group
							id="component1662827179355"
							direction="auto"
							className="max-w-80 w-auto h-auto position-relative" >
							<Text
								id="termsLabel1662827192490"
								className="w-auto h-auto position-relative pe-6px" >
								{t('stu_signup.termsLabel1662827192490')}
							</Text>
							<Text
								id="termsLabelPrivacy"
								onClick={termsLabelPrivacyOnClick}
								className="w-auto h-auto position-relative pe-6px text-primary" >
								{t('stu_signup.termsLabelPrivacy')}
							</Text>
							<Text
								id="termsLabelAnd"
								className="w-auto h-auto position-relative" >
								{t('stu_signup.termsLabelAnd')}
							</Text>
							<Text
								id="termsCookieLabel"
								onClick={termsCookieLabelOnClick}
								className="w-auto h-auto position-relative pe-6px text-primary" >
								{t('stu_signup.termsCookieLabel')}
							</Text>
						</Group>
					</Group>
					<Button
						id="buttonSignup"
						onClick={buttonSignupOnClick}
						variant="primary"
						className="w-auto h-auto border-radius-100px align-center position-relative mt-24px" >
						{t('stu_signup.buttonSignup')}
					</Button>
					<Text
						id="signupInstructionsGoogle"
						tag="p"
						className="w-600px h-auto position-relative p-24px text-primary-text ff-regular text-center" >
						{t('stu_signup.signupInstructionsGoogle')}
					</Text>
					<Button
						id="buttonSignupGoogle"
						onClick={buttonSignupGoogleOnClick}
						variant="black"
						className="w-auto h-auto border-radius-100px align-center position-relative" >
						{t('stu_signup.buttonSignupGoogle')}
					</Button>
				</Group>
			</Group>
      </FullscreenWrapper>
    </>
  );
};
