import React from 'react';
import { Container } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import { useFunctions, useRefs } from '../hooks';
import { FullscreenWrapper } from '../wrappers';

// Custom imports:
import { Group, Image, Text, Input, Button } from '../components';
import undraw_exams from '../assets/image/undraw_exams.svg';
import logo_72_white from '../assets/icon/logo_72_white.png';
import { useTranslation } from 'react-i18next';

export const Stu_loginView = () => {
  const { run } = useFunctions();
  // Custom exports:
	const { t } = useTranslation();

  // Custom functions:
	const gotoSignupLinkOnClick = (options) => {
		run([{"function":"set","what":"@property.mustRegister","value":true},{"function":"goto","view":"home"}], options)
	}
	const loginButtonOnClick = (options) => {
		run([{"function":"login","provider":"firebase.email","email":"@cookie.email","pass":"@property.password","onvalid":[{"function":"set","what":"@cookie.type","value":"@firebase.firestore.users.(@firebase.user.id).type"},{"function":"set","what":"@cookie.email","value":"@firebase.user.email"},{"function":"set","what":"@cookie.avatar","value":"https:\/\/s.gravatar.com\/avatar\/(@cookie.email.md5)?s=24"},{"function":"set","what":"@cookie.name","value":"@firebase.firestore.students.(@firebase.user.id).name"},{"function":"set","what":"@cookie.userID","value":"@firebase.user.id"},{"function":"goto","view":"stu_main","if":{"what":"@cookie.type","is":"student"}},{"function":"goto","view":"schools","if":{"what":"@cookie.type","is":"student_school"}},{"function":"goto","view":"admin_main","if":{"what":"@cookie.type","is":"admin"}}],"oninvalid":[{"function":"popup","dialog":"invalid_login"}]}], options)
	}
	const loginPasswordRecoveryLinkOnClick = (options) => {
		run([{"function":"popup","dialog":"recover_password"}], options)
	}
	const loginLogoOnClick = (options) => {
		run([{"function":"goto","view":"home"}], options)
	}

  return (
    <>
      <Helmet>
				<title>{t('stu_login.title')}</title>
      </Helmet>
      <FullscreenWrapper>
          
			<Group
				id="loginWrapper"
				direction="auto"
				className="w-100 min-h-100 fg-100 position-relative scrollable" >
				<Group
					id="leftWrapper"
					direction="vertical"
					className="min-w-400px w-fill min-h-100vh h-auto position-relative bg-accent-to-primary to-right p-32px" >
					<Image
						id="loginLogo"
						onClick={loginLogoOnClick}
						src={logo_72_white}
						className="max-w-50 w-200px h-auto position-absolute" />
					<Text
						id="loginClaim"
						className="w-100 h-auto position-relative mt-188px text-white f-32px ff-bold text-center" >
						{t('stu_login.loginClaim')}
					</Text>
					<Image
						id="loginFigure"
						src={undraw_exams}
						className="w-100 h-auto position-relative" />
				</Group>
				<Group
					id="rightWrapper"
					direction="vertical"
					className="min-w-400px w-fill min-h-100vh h-auto position-relative bg-white px-24px py-220px" >
					<Group
						id="loginWrapper"
						direction="vertical"
						className="w-fill h-auto align-center position-relative" >
						<Text
							id="loginTitle"
							className="w-100 h-auto position-relative f-32px ff-hurmegeometricsans3black" >
							{t('stu_login.loginTitle')}
						</Text>
						<Input.Email
							id="inputEmail"
							label={t('stu_login.inputEmail.label')}
							name="email"
							src="@cookie.email"
							className="w-100 h-auto position-relative mt-64px" />
						<Input.Password
							id="inputPassword"
							label={t('stu_login.inputPassword.label')}
							name="password"
							src="@property.password"
							className="w-100 h-auto position-relative mt-24px" />
						<Group
							id="loginPasswordRecovery"
							direction="horizontal"
							className="w-auto h-auto align-end position-relative mt-12px" >
							<Text
								id="loginPasswordRecoveryLink"
								onClick={loginPasswordRecoveryLinkOnClick}
								tag="u"
								className="w-auto h-auto position-relative text-primary ff-regular" >
								{t('stu_login.loginPasswordRecoveryLink')}
							</Text>
						</Group>
						<Button
							id="loginButton"
							onClick={loginButtonOnClick}
							variant="primary"
							className="w-100 h-auto align-end position-relative mt-24px" >
							{t('stu_login.loginButton')}
						</Button>
						<Group
							id="loginGotoSignupGroup"
							direction="horizontal"
							className="w-100 h-auto position-relative mt-32px" >
							<Text
								id="gotoSignupInstructions"
								className="w-auto h-auto position-relative text-primary ff-regular" >
								{t('stu_login.gotoSignupInstructions')}
							</Text>
							<Text
								id="gotoSignupLink"
								onClick={gotoSignupLinkOnClick}
								className="w-auto h-auto position-relative ms-12px text-primary ff-black" >
								{t('stu_login.gotoSignupLink')}
							</Text>
						</Group>
					</Group>
				</Group>
			</Group>
      </FullscreenWrapper>
    </>
  );
};
