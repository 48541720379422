import React from 'react';
import { useDialogs } from '../hooks';
import { useFunctions } from '../hooks';

// Custom imports:
import { Modal, Group, Input, Text } from '../components';
import { useTranslation } from 'react-i18next';

const Admin_edit_applicationDialog = () => {
    const { run } = useFunctions();
    // Custom exports:
	const { t } = useTranslation();

    // Custom functions:
	const buttonSendApplication1649675610216OnClick = (options) => {
		run([{"function":"set","what":"@firebase.firestore.students.(@cookie.studentID).applications.(@cookie.applicationID).status","value":"@cookie.status"},{"function":"add","what":{"created_at":"@app.timestamp","status":"@cookie.status","note":"@property.applicationNote"},"to":"@firebase.firestore.students.(@cookie.studentID).applications.(@cookie.applicationID).messages"},{"function":"hide","dialog":"admin_edit_application"}], options)
	}
	const buttonCloseSendApplication1649675610216OnClick = (options) => {
		run([{"function":"hide","dialog":"stu_send_application"}], options)
	}

  return (
    
    
			<Modal id="admin_edit_application" centered>
					<Modal.Body>
						<Group
							id="editApplicationBody"
							direction="vertical"
							className="w-100 min-h-100 fg-100 position-relative" >
							<Input.Select
								id="applySchoolSelect1649675610216"
								label={t('admin_edit_application.applySchoolSelect1649675610216.label')}
								name="select"
								src="@cookie.status"
								dataSrc="@firebase.firestore.data.values.application_status"
								mapSrc={{"label":"es","value":"value"}}
								order={{"type":"asc","field":"position"}}
								className="w-100 h-auto position-relative" >
							</Input.Select>
							<Input.TextArea
								id="applicationStatusNote"
								label={t('admin_edit_application.applicationStatusNote.label')}
								name="text"
								src="@property.applicationNote"
								className="w-100 h-auto position-relative" />
							<Text
								id="buttonCloseSendApplication1649675610216"
								onClick={buttonCloseSendApplication1649675610216OnClick}
								className="w-100 h-auto border-radius-50px position-relative bg-gray p-12px mt-32px text-text-on-accent ff-bold text-center" >
								{t('admin_edit_application.buttonCloseSendApplication1649675610216')}
							</Text>
							<Text
								id="buttonSendApplication1649675610216"
								onClick={buttonSendApplication1649675610216OnClick}
								className="w-100 h-auto border-radius-50px position-relative bg-accent p-12px mt-16px text-text-on-accent ff-bold text-center" >
								{t('admin_edit_application.buttonSendApplication1649675610216')}
							</Text>
						</Group>
					</Modal.Body>
			</Modal>
    
  );
};

export default Admin_edit_applicationDialog;
