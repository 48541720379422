import React from 'react';
import { useDialogs } from '../hooks';
import { useFunctions } from '../hooks';

// Custom imports:
import { Modal, Group, Text, Input } from '../components';
import { useTranslation } from 'react-i18next';

const Select_degreeDialog = () => {
    const { run } = useFunctions();
    // Custom exports:
	const { t } = useTranslation();

    // Custom functions:
	const buttonDegreeSaveOnClick = (options) => {
		run([{"function":"add","what":{"name":"@property.selectedDegree"},"to":"@firebase.firestore.(@property.route)","if":{"what":"@property.currentSchool","is":null}},{"function":"set","what":"@firebase.firestore.(@property.route)","value":"@property.selectedDegree"},{"function":"hide","dialog":"select_degree"}], options)
	}
	const buttonCloseDegreeOnClick = (options) => {
		run([{"function":"hide","dialog":"select_degree"}], options)
	}

  return (
    
    
			<Modal id="select_degree" centered>
					<Modal.Body>
						<Group
							id="component1645794793266"
							direction="vertical"
							className="w-100 min-h-100 fg-100 position-relative" >
							<Text
								id="selectDegreeInstructions"
								className="w-100 h-auto position-relative ff-regular" >
								{t('select_degree.selectDegreeInstructions')}
							</Text>
							<Input.Text
								id="customDegreeName"
								name="text"
								src="@property.selectedDegree"
								className="w-auto h-auto position-relative mt-32px" />
							<Text
								id="buttonCloseDegree"
								onClick={buttonCloseDegreeOnClick}
								className="w-100 h-auto border-radius-50px position-relative bg-gray p-12px mt-32px text-text-on-accent ff-bold text-center" >
								{t('select_degree.buttonCloseDegree')}
							</Text>
							<Text
								id="buttonDegreeSave"
								onClick={buttonDegreeSaveOnClick}
								className="w-100 h-auto border-radius-50px position-relative bg-accent p-12px mt-16px text-text-on-accent ff-bold text-center" >
								{t('select_degree.buttonDegreeSave')}
							</Text>
						</Group>
					</Modal.Body>
			</Modal>
    
  );
};

export default Select_degreeDialog;
