// Custom imports:
import Cookies from 'js-cookie';

export const FIREBASE = {
  INITIAL_STATE: {
    // Custom states:
		user: JSON.parse(Cookies.get('__fbu') || '{}'),
		firestore: {},
		storage: {},
  },
  // Custom consts:
		LOGOUT: 'FIREBASE_LOGOUT',
		LOGIN: 'FIREBASE_LOGIN',
		DELETE: 'FIREBASE_DELETE',
		SET: 'FIREBASE_SET',
		GET: 'FIREBASE_GET',
		STORAGE_GET: 'FIREBASE_STORAGE_GET',
};

export const FirebaseReducer = (draft, action) => {
  const { payload, type } = action;
  switch (type) {
    // Custom case:
    case FIREBASE.LOGIN:
      draft.user = payload.user;
      Cookies.set('__fbu', JSON.stringify(payload.user));
      break;

    case FIREBASE.LOGOUT:
      draft.user = {};
      Cookies.remove('__fbu');
      break;

    case FIREBASE.GET:
        const firestoreObj1 = draft.firestore._clone();
        const firestoreObj2 = { ...(payload.data || {}) };
        let draftFirestore = firestoreObj1._clone();
        draftFirestore = draftFirestore._merge(firestoreObj2);
        if (!firestoreObj1._equals(draftFirestore)) {
            draft.firestore = draftFirestore;
          }
        break;
    case FIREBASE.DELETE:
        let firestoreDeleted = draft.firestore._clone();
        firestoreDeleted = firestoreDeleted._delete(payload.keys.join('.'));
        draft.firestore = firestoreDeleted;
        break;
    case FIREBASE.STORAGE_GET:
      const storageObj1 = draft.storage._clone();
      const storageObj2 = { ...(payload.data || {}) };
      let draftStorage = storageObj1._clone();
      draftStorage = draftStorage._merge(storageObj2, 'path');
      if (!storageObj1._equals(draftStorage)) {
        draft.storage = draftStorage;
      }
      break;
    
    default:
      return draft;
  }
};
