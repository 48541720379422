import React from 'react';
import { Container } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import { useFunctions, useRefs } from '../hooks';
import { FullscreenWrapper } from '../wrappers';

// Custom imports:
import { Group, Input, Button } from '../components';
import { useTranslation } from 'react-i18next';

export const Signup_universityView = () => {
  const { run } = useFunctions();
  // Custom exports:
	const { t } = useTranslation();

  // Custom functions:
	const uniSuButtonOnClick = (options) => {
		run([{"function":"signup","provider":"firebase","email":"@property.email","pass":"@property.password","onvalid":[{"function":"add","what":{"type":"university","verified":false},"to":"@firebase.firestore.users","key":"@firebase.user.id"},{"function":"goto","view":"uni_unverified"}],"onweakpassword":[{"function":"set","what":"@property.error","value":"Weak password"},{"function":"popup","dialog":"error"}],"oninvalid":[{"function":"set","what":"@property.error","value":"Signup error"},{"function":"popup","dialog":"error"}],"onexists":[{"function":"set","what":"@property.error","value":"User exists"},{"function":"popup","dialog":"error"}],"if":{"what":"@property.email","is_not":null}}], options)
	}

  return (
    <>
      <Helmet>
				<title>{t('signup_university.title')}</title>
      </Helmet>
      <FullscreenWrapper>
          
			<Group
				id="uniSignupForm"
				direction="vertical"
				className="w-100 h-auto position-relative" >
				<Input.Text
					id="uniSuEmail"
					name="text"
					src="@property.email"
				placeholder={t('signup_university.uniSuEmail.hint')}
					className="w-auto h-auto position-relative" />
				<Input.Text
					id="uniSuPass"
					name="text"
					src="@property.password"
				placeholder={t('signup_university.uniSuPass.hint')}
					className="w-auto h-auto position-relative" />
				<Button
					id="uniSuButton"
					onClick={uniSuButtonOnClick}
					variant="primary"
					className="w-auto h-auto position-relative" >
					{t('signup_university.uniSuButton')}
				</Button>
			</Group>
      </FullscreenWrapper>
    </>
  );
};
