import React from 'react';
import { Container } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import { useFunctions, useRefs } from '../hooks';
import { Home_wrapperWrapper } from '../wrappers';

// Custom imports:
import { Group, Icon, Text, Image, Nav, Button, Dropdown, Input } from '../components';
import { useEffect } from 'react';
import logo_72_white from '../assets/icon/logo_72_white.png';
import flags from '../assets/image/flags.jpg';
import high_cert from '../assets/image/high_cert.svg';
import high_fav from '../assets/image/high_fav.svg';
import high_profile from '../assets/image/high_profile.svg';
import high_uni from '../assets/image/high_uni.svg';
import high_login from '../assets/image/high_login.svg';
import john_hopkins_uni_library from '../assets/image/john_hopkins_uni_library.jpg';
import flag_es from '../assets/assets/flag_es.png';
import logo_72 from '../assets/icon/logo_72.png';
import { useTranslation } from 'react-i18next';

export const HomeView = () => {
  const { run } = useFunctions();
  // Custom exports:
	const { t } = useTranslation();

  // Custom functions:
	const homeOnLoaded = (options) => {
		run([{"function":"set","what":"@cookie.flag","value":"flag_es.png","if":{"what":"@app.language","starts_with":"es","else":[{"function":"set","what":"@cookie.flag","value":"flag_en.png"}]}},{"function":"set","what":"@cookie.langName","value":"Espa\u00f1ol","if":{"what":"@app.language","starts_with":"es","else":[{"function":"set","what":"@cookie.langName","value":"English"}]}},{"function":"goto","view":"stu_main","if":{"what":"@firebase.user.id","is_not":null,"and":{"what":"@cookie.type","is":"student"}}},{"function":"goto","view":"admin_main","if":{"what":"@firebase.user.id","is_not":null,"and":{"what":"@cookie.type","is":"admin"}}},{"function":"scroll","to":"@element.homeForm","if":{"what":"@property.mustRegister","is":true}},{"function":"set","what":"@property.mustRegister","value":false},{"function":"set","what":"@property.unisLoaded","value":false}], options)
	}
	useEffect(homeOnLoaded, [])
	const homeBlueYoutubeIcon1647886785636OnClick = (options) => {
		run([{"function":"browser","url":"https:\/\/www.youtube.com\/channel\/UCmfIE4aab0Qjz5xuqD3Pbfg","target":"_maeyoutube"}], options)
	}
	const homeBlueTwitterIcon1647886785636OnClick = (options) => {
		run([{"function":"browser","url":"https:\/\/twitter.com\/mae_sports","target":"_maetwitter"}], options)
	}
	const homeBlueFacebookIcon1647886785636OnClick = (options) => {
		run([{"function":"browser","url":"https:\/\/www.facebook.com\/mae.sports\/","target":"_maefacebook"}], options)
	}
	const homeBlueInstagramIcon1647886785636OnClick = (options) => {
		run([{"function":"browser","url":"https:\/\/www.instagram.com\/mae.sports\/","target":"_maeinstagram"}], options)
	}
	const homeFooterWebOnClick = (options) => {
		run([{"function":"browser","url":"https:\/\/www.maeducation.net","target":"_self"}], options)
	}
	const homeFooterEmailOnClick = (options) => {
		run([{"function":"browser","url":"mailto:info@maeducation.net"}], options)
	}
	const homeFooterTelOnClick = (options) => {
		run([{"function":"browser","url":"tel:+34646516514"}], options)
	}
	const homeFooterCookiesOnClick = (options) => {
		run([{"function":"set","what":"@property.policy","value":"cookies_policy"},{"function":"popup","dialog":"terms"}], options)
	}
	const homeFooterPrivacyOnClick = (options) => {
		run([{"function":"set","what":"@property.policy","value":"privacy_policy"},{"function":"popup","dialog":"terms"}], options)
	}
	const homeFormButtonUniOnClick = (options) => {
		run([{"function":"popup","dialog":"signup_error","if":{"what":"@cookie.name","is":null,"or":{"what":"@cookie.lastname","is":null,"or":{"what":"@cookie.birthdate","is":null,"or":{"what":"@cookie.city","is":null,"or":{"what":"@cookie.email","is":null,"or":{"what":"@cookie.phone","is":null,"or":{"what":"@cookie.school","is":null,"or":{"what":"@cookie.schoolyear","is":null}}}}}}},"else":[{"function":"set","what":"@cookie.studentType","value":"student"},{"function":"goto","view":"stu_signup"}]}}], options)
	}
	const homeFormButtonSchoolOnClick = (options) => {
		run([{"function":"popup","dialog":"signup_error","if":{"what":"@cookie.name","is":null,"or":{"what":"@cookie.lastname","is":null,"or":{"what":"@cookie.birthdate","is":null,"or":{"what":"@cookie.city","is":null,"or":{"what":"@cookie.email","is":null,"or":{"what":"@cookie.phone","is":null,"or":{"what":"@cookie.school","is":null,"or":{"what":"@cookie.schoolyear","is":null}}}}}}},"else":[{"function":"set","what":"@cookie.studentType","value":"student_school"},{"function":"goto","view":"stu_signup"}]}}], options)
	}
	const Lang_menuItemsOptENOnClick = (options) => {
		run([{"function":"set","what":"@app.language","value":"en"},{"function":"set","what":"@cookie.flag","value":"flag_en.png"},{"function":"set","what":"@cookie.langName","value":"English"}], options)
	}
	const Lang_menuItemsOptESOnClick = (options) => {
		run([{"function":"set","what":"@app.language","value":"es"},{"function":"set","what":"@cookie.flag","value":"flag_es.png"},{"function":"set","what":"@cookie.langName","value":"Espa\u00f1ol"}], options)
	}
	const headerFreeButtonOnClick = (options) => {
		run([{"function":"scroll","to":"@element.homeForm"}], options)
	}
	const Main_menuItemsOption1OnClick = (options) => {
		run([{"function":"goto","view":"stu_login"}], options)
	}
	const homeBlueYoutubeIconOnClick = (options) => {
		run([{"function":"browser","url":"https:\/\/www.youtube.com\/channel\/UCmfIE4aab0Qjz5xuqD3Pbfg","target":"_maeyoutube"}], options)
	}
	const homeBlueTwitterIconOnClick = (options) => {
		run([{"function":"browser","url":"https:\/\/twitter.com\/mae_sports","target":"_maetwitter"}], options)
	}
	const homeBlueFacebookIconOnClick = (options) => {
		run([{"function":"browser","url":"https:\/\/www.facebook.com\/mae.sports\/","target":"_maefacebook"}], options)
	}
	const homeBlueInstagramIconOnClick = (options) => {
		run([{"function":"browser","url":"https:\/\/www.instagram.com\/mae.sports\/","target":"_maeinstagram"}], options)
	}
	const homeBlueEmailOnClick = (options) => {
		run([{"function":"browser","url":"mailto:info@maeducationusa.com"}], options)
	}
	const homeBluePhoneOnClick = (options) => {
		run([{"function":"browser","url":"tel:+34646516514"}], options)
	}

  return (
    <>
      <Helmet>
				<title>{t('home.title')}</title>
      </Helmet>
      <Home_wrapperWrapper>
          
			<Group
				id="homeBody"
				direction="vertical"
				className="w-100 min-h-100vh h-100vh position-relative scrollable" >
				<Group
					id="homeMain"
					direction="vertical"
					className="w-100 min-h-100vh h-auto position-relative" >
					<Group
						id="homeBlueBar"
						direction="vertical"
						className="w-100 h-auto position-relative bg-primary" >
						<Group
							id="homeBlueContent"
							direction="auto"
							className="max-w-1200px w-100 h-auto align-center position-relative" >
							<Group
								id="homeBluePhone"
								onClick={homeBluePhoneOnClick}
								direction="horizontal"
								className="w-auto h-auto align-center position-relative p-12px" >
								<Icon
									id="homeBluePhoneIcon"
									src="phone"
									color="var(--text-on-primary)"
									className="w-auto h-auto position-relative"
								/>
								<Text
									id="homeBluePhoneNumber"
									className="w-auto h-auto valign-center position-relative text-text-on-primary f-16px" >
									{t('home.homeBluePhoneNumber')}
								</Text>
							</Group>
							<Group
								id="homeBlueEmail"
								onClick={homeBlueEmailOnClick}
								direction="horizontal"
								className="w-auto h-auto align-center position-relative p-12px" >
								<Icon
									id="homeBlueEmailIcon"
									src="email"
									color="var(--text-on-primary)"
									className="w-auto h-auto position-relative"
								/>
								<Text
									id="homeBlueEmailAddress"
									className="w-auto h-auto valign-center position-relative text-text-on-primary f-16px" >
									{t('home.homeBlueEmailAddress')}
								</Text>
							</Group>
							<Group
								id="homeBlueSpacer"
								direction="vertical"
								className="w-fill h-auto position-relative" >
							</Group>
							<Group
								id="homeBlueRightSide"
								direction="vertical"
								className="w-auto h-auto align-center position-relative p-12px" >
								<Group
									id="homeBlueSSNN"
									direction="horizontal"
									className="w-auto h-auto align-end position-relative" >
									<Icon
										id="homeBlueInstagramIcon"
										onClick={homeBlueInstagramIconOnClick}
										src="instagram"
										color="var(--text-on-primary)"
										className="w-auto h-auto position-relative me-12px"
									/>
									<Icon
										id="homeBlueFacebookIcon"
										onClick={homeBlueFacebookIconOnClick}
										src="facebook"
										color="var(--text-on-primary)"
										className="w-auto h-auto position-relative me-12px"
									/>
									<Icon
										id="homeBlueTwitterIcon"
										onClick={homeBlueTwitterIconOnClick}
										src="twitter"
										color="var(--text-on-primary)"
										className="w-auto h-auto position-relative me-12px"
									/>
									<Icon
										id="homeBlueYoutubeIcon"
										onClick={homeBlueYoutubeIconOnClick}
										src="youtube"
										color="var(--text-on-primary)"
										className="w-auto h-auto position-relative"
									/>
								</Group>
							</Group>
						</Group>
					</Group>
					<Group
						id="homeHeader"
						direction="vertical"
						className="w-100 h-auto position-relative p-24px" >
						<Group
							id="homeHeaderContent"
							direction="auto"
							className="max-w-1200px w-100 h-auto align-center valign-center position-relative" >
							<Group
								id="homeLogoWrapper"
								direction="vertical"
								className="min-w-330px w-fill h-auto position-relative" >
								<Image
									id="homeLogo"
									src={logo_72}
									className="w-auto min-h-64px h-64px valign-center position-relative" />
							</Group>
							<Group
								id="homeNavBarContent"
								direction="auto"
								className="w-auto h-auto valign-center position-relative" >
								<Nav
									id="homeMenu"
									className="w-auto h-auto valign-center position-relative my-4px"
									direction="horizontal" >
									<Nav.Item title={t('main_menu.items.option1.title')} onClick={Main_menuItemsOption1OnClick} href="stu_login" />
								</Nav>
								<Button
									id="headerFreeButton"
									onClick={headerFreeButtonOnClick}
									variant="accent"
									className="min-w-150px w-auto h-auto valign-center position-relative my-4px" >
									{t('home.headerFreeButton')}
								</Button>
								<Dropdown
									id="langDropdown"
									className="w-auto h-auto border-radius-40px valign-center position-relative mx-24px my-4px"
									variant="primary"
									drop="down"
									align="end"
									title={<>

									<Image
										id="langDropdownIcon"
										fallback={flag_es}
										src="@cookie.flag"
										className="w-24px h-auto position-relative" />
									<Text
										id="langDropdownTitle"
										src="@cookie.langName"
										className="w-auto h-auto position-relative ff-regular" >
										{t('home.langDropdownTitle')}
									</Text>
								</>} >
									<Dropdown.Item title={t('lang_menu.items.optES.title')} onClick={Lang_menuItemsOptESOnClick} />
									<Dropdown.Item title={t('lang_menu.items.optEN.title')} onClick={Lang_menuItemsOptENOnClick} />
								</Dropdown>
							</Group>
						</Group>
					</Group>
					<Group
						id="homeBanner"
						direction="horizontal"
						className="w-100 h-fill position-relative" >
						<Image
							id="bannerImage"
							src={john_hopkins_uni_library}
							className="w-100 min-h-100 h-100 position-relative cover" />
						<Group
							id="bannerTextGroup"
							direction="vertical"
							className="max-w-1200px w-auto h-auto align-center valign-center position-absolute" >
							<Text
								id="bannerText"
								tag="h2"
								className="w-auto h-auto align-center valign-center position-relative bg-black-trans p-20px text-text-on-primary ff-bold text-center" >
								{t('home.bannerText')}
							</Text>
							<Text
								id="bannerText2"
								tag="h2"
								className="max-w-1200px w-auto h-auto align-center valign-center position-relative bg-black-trans p-24px text-text-on-primary ff-bold text-center" >
								{t('home.bannerText2')}
							</Text>
						</Group>
					</Group>
				</Group>
				<Group
					id="homeInfo"
					direction="vertical"
					className="w-100 h-auto align-center position-relative mt-32px" >
					<Group
						id="homeHighlights"
						direction="vertical"
						className="w-100 h-auto position-relative" >
						<Group
							id="highlightsTitleGroup"
							direction="vertical"
							className="w-100 h-auto align-center position-relative p-32px" >
							<Text
								id="highlightsTitle"
								className="max-w-1000px w-100 h-auto align-center position-relative text-accent f-32px ff-bold text-center" >
								{t('home.highlightsTitle')}
							</Text>
						</Group>
						<Group
							id="highlightsContent"
							direction="auto"
							className="w-100 h-auto align-center position-relative" >
							<Group
								id="highlight_1"
								direction="vertical"
								className="min-w-240px w-fill h-auto position-relative p-24px" >
								<Image
									id="hl_img1"
									src={high_login}
									className="w-auto min-h-96px h-96px align-center position-relative" />
								<Text
									id="hl_txt1"
									className="w-100 h-auto position-relative p-24px text-primary-text ff-medium text-center" >
									{t('home.hl_txt1')}
								</Text>
							</Group>
							<Group
								id="highlight_2"
								direction="vertical"
								className="min-w-240px w-fill h-auto position-relative p-24px" >
								<Image
									id="hl_img2"
									src={high_uni}
									className="w-auto min-h-96px h-96px align-center position-relative" />
								<Text
									id="hl_txt2"
									className="w-100 h-auto position-relative p-24px text-primary-text ff-medium text-center" >
									{t('home.hl_txt2')}
								</Text>
							</Group>
							<Group
								id="highlight_3"
								direction="vertical"
								className="min-w-240px w-fill h-auto position-relative p-24px" >
								<Image
									id="hl_img3"
									src={high_profile}
									className="w-auto min-h-96px h-96px align-center position-relative" />
								<Text
									id="hl_txt3"
									className="w-100 h-auto position-relative p-24px text-primary-text ff-medium text-center" >
									{t('home.hl_txt3')}
								</Text>
							</Group>
							<Group
								id="highlight_4"
								direction="vertical"
								className="min-w-240px w-fill h-auto position-relative p-24px" >
								<Image
									id="hl_img4"
									src={high_fav}
									className="w-auto min-h-96px h-96px align-center position-relative" />
								<Text
									id="hl_txt4"
									className="w-100 h-auto position-relative p-24px text-primary-text ff-medium text-center" >
									{t('home.hl_txt4')}
								</Text>
							</Group>
							<Group
								id="highlight_5"
								direction="vertical"
								className="min-w-240px w-fill h-auto position-relative p-24px" >
								<Image
									id="hl_img5"
									src={high_cert}
									className="w-auto min-h-96px h-96px align-center position-relative" />
								<Text
									id="hl_txt5"
									className="w-100 h-auto position-relative p-24px text-primary-text ff-medium text-center" >
									{t('home.hl_txt5')}
								</Text>
							</Group>
						</Group>
					</Group>
					<Group
						id="homeNeedIf"
						direction="auto"
						className="w-100 h-auto position-relative bg-primary mt-32px" >
						<Image
							id="homeFlagsImage"
							src={flags}
							className="min-w-400px w-fill min-h-450px h-450px position-relative cover" />
						<Group
							id="needIfInfo"
							direction="vertical"
							className="w-fill h-auto valign-center position-relative p-24px" >
							<Text
								id="lookingFor"
								className="w-100 h-auto position-relative text-text-on-primary f-26px ff-bold" >
								{t('home.lookingFor')}
							</Text>
							<Group
								id="need_1"
								direction="horizontal"
								className="w-100 h-auto position-relative mt-32px" >
								<Text
									id="need_1_if"
									tag="h3"
									className="w-auto h-auto position-relative text-text-on-primary ff-regular" >
									{t('home.need_1_if')}
								</Text>
								<Text
									id="need_1_ifthis"
									tag="h3"
									className="w-fill h-auto position-relative ps-12px text-text-on-primary ff-regular" >
									{t('home.need_1_ifthis')}
								</Text>
							</Group>
							<Group
								id="need_2"
								direction="horizontal"
								className="w-100 h-auto position-relative" >
								<Text
									id="need_2_if"
									tag="h3"
									className="w-auto h-auto position-relative text-text-on-primary ff-regular" >
									{t('home.need_2_if')}
								</Text>
								<Text
									id="need_2_ifthis"
									tag="h3"
									className="w-fill h-auto position-relative ps-12px text-text-on-primary ff-regular" >
									{t('home.need_2_ifthis')}
								</Text>
							</Group>
							<Group
								id="need_3"
								direction="horizontal"
								className="w-100 h-auto position-relative" >
								<Text
									id="need_3_if"
									tag="h3"
									className="w-auto h-auto position-relative text-text-on-primary ff-regular" >
									{t('home.need_3_if')}
								</Text>
								<Text
									id="need_3_ifthis"
									tag="h3"
									className="w-fill h-auto position-relative ps-12px text-text-on-primary ff-regular" >
									{t('home.need_3_ifthis')}
								</Text>
							</Group>
							<Group
								id="need_4"
								direction="horizontal"
								className="w-100 h-auto position-relative" >
								<Text
									id="need_4_if"
									tag="h3"
									className="w-auto h-auto position-relative text-text-on-primary ff-regular" >
									{t('home.need_4_if')}
								</Text>
								<Text
									id="need_4_ifthis"
									tag="h3"
									className="w-fill h-auto position-relative ps-12px text-text-on-primary ff-regular" >
									{t('home.need_4_ifthis')}
								</Text>
							</Group>
							<Group
								id="need_5"
								direction="horizontal"
								className="w-100 h-auto position-relative" >
								<Text
									id="need_5_if"
									tag="h3"
									className="w-auto h-auto position-relative text-text-on-primary ff-regular" >
									{t('home.need_5_if')}
								</Text>
								<Text
									id="need_5_ifthis"
									tag="h3"
									className="w-fill h-auto position-relative ps-12px text-text-on-primary ff-regular" >
									{t('home.need_5_ifthis')}
								</Text>
							</Group>
						</Group>
					</Group>
					<Group
						id="homeForm"
						direction="vertical"
						className="max-w-800px w-100 h-auto align-center position-relative mt-32px" >
						<Text
							id="homeFormTitle"
							className="w-100 h-auto position-relative p-24px text-primary f-32px ff-bold text-center" >
							{t('home.homeFormTitle')}
						</Text>
						<Text
							id="homeFormSubtitle"
							className="w-100 h-auto position-relative p-24px text-primary f-26px ff-bold text-center" >
							{t('home.homeFormSubtitle')}
						</Text>
						<Group
							id="homeFormInputs"
							direction="vertical"
							className="w-100 h-auto position-relative p-24px mt-24px" >
							<Input.Text
								id="homeFormName"
								label={t('home.homeFormName.label')}
								name="text"
								src="@cookie.name"
								className="w-100 h-auto position-relative" />
							<Input.Text
								id="homeFormLastName"
								label={t('home.homeFormLastName.label')}
								name="text"
								src="@cookie.lastname"
								className="w-100 h-auto position-relative" />
							<Input.Date
								id="homeFormBirthday"
								label={t('home.homeFormBirthday.label')}
								name="text"
								src="@cookie.birthdate"
								className="w-100 h-auto position-relative" />
							<Input.Text
								id="homeFormCity"
								label={t('home.homeFormCity.label')}
								name="text"
								src="@cookie.city"
								className="w-100 h-auto position-relative" />
							<Input.Text
								id="homeFormEmail"
								label={t('home.homeFormEmail.label')}
								name="text"
								src="@cookie.email"
								className="w-100 h-auto position-relative mt-48px" />
							<Input.Text
								id="homeFormPhone"
								label={t('home.homeFormPhone.label')}
								name="text"
								src="@cookie.phone"
								className="w-100 h-auto position-relative" />
							<Input.Text
								id="homeFormSchool"
								label={t('home.homeFormSchool.label')}
								name="text"
								src="@cookie.school"
								className="w-100 h-auto position-relative mt-48px" />
							<Input.Text
								id="homeFormYear"
								label={t('home.homeFormYear.label')}
								name="text"
								src="@cookie.schoolyear"
								className="w-100 h-auto position-relative" />
							<Input.Select
								id="homeFormInterested"
								label={t('home.homeFormInterested.label')}
								name="text"
								src="@cookie.studentType"
								className="w-100 h-auto position-relative" >
								<option value={"student_school"}>{t('home.homeFormInterested.opt0')}</option>
								<option value={"student"}>{t('home.homeFormInterested.opt1')}</option>
							</Input.Select>
							<Group
								id="homeFormButtons"
								direction="horizontal"
								className="w-auto h-auto align-center position-relative" >
								<Button
									id="homeFormButtonSchool"
									onClick={homeFormButtonSchoolOnClick}
									variant="primary"
									className="w-auto h-auto align-end position-relative me-8px mt-32px" >
									{t('home.homeFormButtonSchool')}
								</Button>
								<Button
									id="homeFormButtonUni"
									onClick={homeFormButtonUniOnClick}
									variant="primary"
									className="w-auto h-auto align-end position-relative mt-32px" >
									{t('home.homeFormButtonUni')}
								</Button>
							</Group>
						</Group>
					</Group>
				</Group>
				<Group
					id="homeFooter"
					direction="vertical"
					className="w-100 h-auto position-relative mt-48px" >
					<Group
						id="homeFooterBar"
						direction="vertical"
						className="w-100 h-auto valign-end position-relative bg-primary py-48px" >
						<Group
							id="homeFooterBarContent"
							direction="auto"
							className="max-w-1200px w-100 h-auto align-center position-relative p-32px" >
							<Group
								id="homeFooterInfo"
								direction="vertical"
								className="w-fill h-auto position-relative" >
								<Text
									id="homeFooterAbout"
									tag="p"
									className="max-w-500px min-w-300px w-fill h-auto position-relative text-text-on-primary ff-medium" >
									{t('home.homeFooterAbout')}
								</Text>
								<Group
									id="homeFooterPolicy"
									direction="auto"
									className="w-fill h-auto position-relative" >
									<Text
										id="homeFooterPrivacy"
										onClick={homeFooterPrivacyOnClick}
										tag="small"
										className="max-w-100 w-auto h-auto position-relative pe-8px text-gray_b ff-medium" >
										{t('home.homeFooterPrivacy')}
									</Text>
									<Text
										id="homeFooterPrivacySeparator"
										tag="small"
										className="w-auto h-auto position-relative pe-8px text-gray_b ff-medium" >
										{t('home.homeFooterPrivacySeparator')}
									</Text>
									<Text
										id="homeFooterCookies"
										onClick={homeFooterCookiesOnClick}
										tag="small"
										className="max-w-100 w-auto h-auto position-relative pe-8px text-gray_b ff-medium" >
										{t('home.homeFooterCookies')}
									</Text>
								</Group>
							</Group>
							<Group
								id="homeFooterSpacer"
								direction="vertical"
								className="w-fill h-auto position-relative" >
							</Group>
							<Group
								id="homeFooterContact"
								direction="vertical"
								className="max-w-400px min-w-300px w-fill h-auto position-relative" >
								<Image
									id="homeFooterLogo"
									src={logo_72_white}
									className="w-auto min-h-72px h-72px align-end position-relative" />
								<Text
									id="homeFooterCities"
									tag="p"
									className="w-auto h-auto align-end position-relative text-text-on-primary ff-medium text-end" >
									{t('home.homeFooterCities')}
								</Text>
								<Text
									id="homeFooterTel"
									onClick={homeFooterTelOnClick}
									tag="p"
									className="w-auto h-auto align-end position-relative text-text-on-primary ff-medium text-end" >
									{t('home.homeFooterTel')}
								</Text>
								<Text
									id="homeFooterEmail"
									onClick={homeFooterEmailOnClick}
									tag="p"
									className="w-auto h-auto align-end position-relative text-text-on-primary ff-medium text-end" >
									{t('home.homeFooterEmail')}
								</Text>
								<Text
									id="homeFooterWeb"
									onClick={homeFooterWebOnClick}
									tag="p"
									className="w-auto h-auto align-end position-relative text-text-on-primary ff-medium text-end" >
									{t('home.homeFooterWeb')}
								</Text>
								<Group
									id="homeBlueSSNN1647886785636"
									direction="horizontal"
									className="w-auto h-auto align-end position-relative" >
									<Icon
										id="homeBlueInstagramIcon1647886785636"
										onClick={homeBlueInstagramIcon1647886785636OnClick}
										src="instagram"
										color="var(--text-on-primary)"
										className="w-auto h-auto position-relative me-12px"
									/>
									<Icon
										id="homeBlueFacebookIcon1647886785636"
										onClick={homeBlueFacebookIcon1647886785636OnClick}
										src="facebook"
										color="var(--text-on-primary)"
										className="w-auto h-auto position-relative me-12px"
									/>
									<Icon
										id="homeBlueTwitterIcon1647886785636"
										onClick={homeBlueTwitterIcon1647886785636OnClick}
										src="twitter"
										color="var(--text-on-primary)"
										className="w-auto h-auto position-relative me-12px"
									/>
									<Icon
										id="homeBlueYoutubeIcon1647886785636"
										onClick={homeBlueYoutubeIcon1647886785636OnClick}
										src="youtube"
										color="var(--text-on-primary)"
										className="w-auto h-auto position-relative"
									/>
								</Group>
							</Group>
						</Group>
					</Group>
				</Group>
			</Group>
      </Home_wrapperWrapper>
    </>
  );
};
