import React from 'react';
import { Container } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import { useFunctions, useRefs } from '../hooks';
import { FullscreenWrapper } from '../wrappers';

// Custom imports:
import { Group, Icon, Image, Text, Input, List, Card, Button } from '../components';
import { useEffect } from 'react';
import placeholder_uni from '../assets/image/placeholder_uni.jpg';
import placeholder_logo from '../assets/image/placeholder_logo.svg';
import user_placeholder from '../assets/image/user_placeholder.svg';
import logo_72_white from '../assets/icon/logo_72_white.png';
import { useTranslation } from 'react-i18next';

export const Admin_studentView = () => {
  const { run } = useFunctions();
  // Custom exports:
	const { t } = useTranslation();

  // Custom functions:
	const admin_studentOnLoaded = (options) => {
		run([{"function":"set","what":"@property.email","value":"@database.student.email"},{"function":"set","what":"@property.hash","value":"@property.email.sha256"},{"function":"set","what":"@cookie.studentNotes","value":"@database.student.notes"},{"function":"set","what":"@database.student.gradesEso3Doc","value":"@firebase.storage.students\/(@cookie.studentID)\/eso3.fil"},{"function":"set","what":"@database.student.gradesEso3DocEng","value":"@firebase.storage.students\/(@cookie.studentID)\/eso3eng.fil"},{"function":"set","what":"@database.student.gradesEso4Doc","value":"@firebase.storage.students\/(@cookie.studentID)\/eso4.fil"},{"function":"set","what":"@database.student.gradesEso4DocEng","value":"@firebase.storage.students\/(@cookie.studentID)\/eso4eng.fil"},{"function":"set","what":"@database.student.gradesBac1Doc","value":"@firebase.storage.students\/(@cookie.studentID)\/bac1.fil"},{"function":"set","what":"@database.student.gradesBac1DocEng","value":"@firebase.storage.students\/(@cookie.studentID)\/bac1eng.fil"},{"function":"set","what":"@database.student.gradesBac2Doc","value":"@firebase.storage.students\/(@cookie.studentID)\/bac2.fil"},{"function":"set","what":"@database.student.gradesBac2DocEng","value":"@firebase.storage.students\/(@cookie.studentID)\/bac2eng.fil"}], options)
	}
	useEffect(admin_studentOnLoaded, [])
	const uniListButtonMore1649675430698OnClick = (options) => {
		run([{"function":"set","what":"@cookie.applicationID","value":"@element.adminAppList.selected"},{"function":"set","what":"@cookie.application","value":"@element.adminAppList.value"},{"function":"set","what":"@cookie.status","value":"@cookie.application.status"},{"function":"popup","dialog":"admin_edit_application"}], options)
	}
	const studentDocDownloadOnClick = (options) => {
		run([{"function":"download","url":"@firebase.storage.students\/doc_(@field.id).fil"}], options)
	}
	const studentMumEmailOnClick = (options) => {
		run([{"function":"browser","url":"mailto:(@database.student.mum_email),"}], options)
	}
	const studentDadEmailOnClick = (options) => {
		run([{"function":"browser","url":"mailto:(@database.student.dad_email),"}], options)
	}
	const studentPassportDownloadOnClick = (options) => {
		run([{"function":"download","url":"@database.student.passportPhoto"}], options)
	}
	const studentDniBackDownloadOnClick = (options) => {
		run([{"function":"download","url":"@database.student.dniBackPhoto"}], options)
	}
	const studentDniFrontDownloadOnClick = (options) => {
		run([{"function":"download","url":"@database.student.dniFrontPhoto"}], options)
	}
	const studentLetterMathDownloadOnClick = (options) => {
		run([{"function":"download","url":"@database.student.letter_math"}], options)
	}
	const studentLetterTutorDownloadOnClick = (options) => {
		run([{"function":"download","url":"@database.student.letter_tutor"}], options)
	}
	const studentLetterEnglishDownloadOnClick = (options) => {
		run([{"function":"download","url":"@database.student.letter_english"}], options)
	}
	const studentSchoolGradePrevYearDownloadEngOnClick = (options) => {
		run([{"function":"download","url":"@database.student.gradesPrevYearDocEng"}], options)
	}
	const studentSchoolGradePrevYearDownloadOnClick = (options) => {
		run([{"function":"download","url":"@database.student.gradesPrevYearDoc"}], options)
	}
	const studentSchoolGradeLastYearDownload1665048590860OnClick = (options) => {
		run([{"function":"download","url":"@database.student.gradesLastYearDocEng"}], options)
	}
	const studentSchoolGradeLastYearDownloadOnClick = (options) => {
		run([{"function":"download","url":"@database.student.gradesLastYearDoc"}], options)
	}
	const studentSatDownloadOnClick = (options) => {
		run([{"function":"download","url":"@database.student.satDoc"}], options)
	}
	const studentEnglishDownloadOnClick = (options) => {
		run([{"function":"download","url":"@database.student.englishDoc"}], options)
	}
	const studentIgcseDownloadOnClick = (options) => {
		run([{"function":"download","url":"@database.student.igcseDoc"}], options)
	}
	const studentGradesAsDownloadOnClick = (options) => {
		run([{"function":"download","url":"@database.student.gradesAsDoc"}], options)
	}
	const studentGradeBac2ENDownloadOnClick = (options) => {
		run([{"function":"download","url":"@database.student.gradesBac2DocEng"}], options)
	}
	const studentGradeBac2DownloadOnClick = (options) => {
		run([{"function":"download","url":"@database.student.gradesBac2Doc"}], options)
	}
	const studentGradeBac1ENDownloadOnClick = (options) => {
		run([{"function":"download","url":"@database.student.gradesBac1DocEng"}], options)
	}
	const studentGradeBac1DownloadOnClick = (options) => {
		run([{"function":"download","url":"@database.student.gradesBac1Doc"}], options)
	}
	const studentGradeEso4ENDownloadOnClick = (options) => {
		run([{"function":"download","url":"@database.student.gradesEso4DocEng"}], options)
	}
	const studentGradeEso4DownloadOnClick = (options) => {
		run([{"function":"download","url":"@database.student.gradesEso4Doc"}], options)
	}
	const studentGradeEso3ENDownloadOnClick = (options) => {
		run([{"function":"download","url":"@database.student.gradesEso3DocEng"}], options)
	}
	const studentGradeEso3DownloadOnClick = (options) => {
		run([{"function":"download","url":"@database.student.gradesEso3Doc"}], options)
	}
	const studentNotesInputOnLostFocus = (options) => {
		run([{"function":"set","what":"@firebase.firestore.students.(@cookie.studentID).notes","value":"@cookie.studentNotes"},{"function":"set","what":"@database.students.(@cookie.studentID).notes","value":"@cookie.studentNotes"}], options)
	}
	const studentProfilePhoneGroupOnClick = (options) => {
		run([{"function":"browser","url":"mailto:(@database.student.email)"}], options)
	}
	const studentProfileEmailGroupOnClick = (options) => {
		run([{"function":"browser","url":"mailto:(@database.student.email)"}], options)
	}
	const uniWrapperLogo1649617135776OnClick = (options) => {
		run([{"function":"back"}], options)
	}
	const uniWrapperBackIcon1649617135776OnClick = (options) => {
		run([{"function":"back"}], options)
	}

  return (
    <>
      <Helmet>
				<title>{t('admin_student.title')}</title>
      </Helmet>
      <FullscreenWrapper>
          
			<Group
				id="adminStudentHeader"
				direction="horizontal"
				className="w-100 h-auto position-relative bg-primary p-20px" >
				<Icon
					id="uniWrapperBackIcon1649617135776"
					onClick={uniWrapperBackIcon1649617135776OnClick}
					src="arrow-left"
					color="var(--text-on-primary)"
					className="w-auto h-auto valign-center position-relative"
				/>
				<Image
					id="uniWrapperLogo1649617135776"
					onClick={uniWrapperLogo1649617135776OnClick}
					src={logo_72_white}
					className="w-72px h-auto position-relative" />
				<Group
					id="uniWrapperSpacer1649617135776"
					direction="vertical"
					className="w-fill h-auto valign-center position-relative" >
					<Text
						id="looksForUni"
						if={{"what":"@database.student.type","is":"student"}}
						className="w-auto h-auto align-end position-relative text-text-on-primary ff-medium text-end" >
						{t('admin_student.looksForUni')}
					</Text>
					<Text
						id="looksForUni1662832001100"
						if={{"what":"@database.student.type","is":"student_school"}}
						className="w-auto h-auto align-end position-relative text-text-on-primary ff-medium text-end" >
						{t('admin_student.looksForUni1662832001100')}
					</Text>
				</Group>
			</Group>
			<Group
				id="studentInfoBody"
				direction="auto"
				className="w-100 h-fill position-relative" >
				<Group
					id="studentInfoPersonal"
					direction="vertical"
					className="max-w-400px min-w-300px w-fill min-h-100 h-100 position-relative bg-light p-24px scrollable" >
					<Image
						id="studentProfilePicture"
						fallback={user_placeholder}
						src="https://www.gravatar.com/avatar/(@database.student.hash)?s=300"
						className="w-50 h-auto border-top border-bottom border-start border-end border-radius-250px align-center position-relative" />
					<Text
						id="studentProfileName"
						tag="h3"
						className="w-100 h-auto position-relative mt-24px text-primary ff-bold text-center" >
						{t('admin_student.studentProfileName')}
					</Text>
					<Group
						id="studentProfileContact"
						direction="vertical"
						className="w-100 h-auto position-relative mt-24px" >
						<Group
							id="studentProfileEmailGroup"
							onClick={studentProfileEmailGroupOnClick}
							direction="horizontal"
							className="w-100 h-auto position-relative" >
							<Icon
								id="studentProfileEmailIcon"
								src="email"
								color="var(--primary-text)"
								className="w-auto h-auto position-relative me-16px"
							/>
							<Text
								id="studentProfileEmailValue"
								src="@database.student.email"
								className="w-100 h-auto position-relative text-primary-text ff-regular" >
								{t('admin_student.studentProfileEmailValue')}
							</Text>
						</Group>
						<Group
							id="studentProfilePhoneGroup"
							onClick={studentProfilePhoneGroupOnClick}
							direction="horizontal"
							className="w-100 h-auto position-relative mt-8px" >
							<Icon
								id="studentProfilePhoneIcon"
								src="phone-classic"
								color="var(--primary-text)"
								className="w-auto h-auto position-relative me-16px"
							/>
							<Text
								id="studentProfilePhoneValue"
								src="@database.student.phone"
								className="w-100 h-auto position-relative text-primary-text ff-regular" >
								{t('admin_student.studentProfilePhoneValue')}
							</Text>
						</Group>
						<Group
							id="studentProfileIdGroup"
							direction="horizontal"
							className="w-100 h-auto position-relative mt-8px" >
							<Icon
								id="studentProfileIdIcon"
								src="identifier"
								color="var(--primary-text)"
								className="w-auto h-auto position-relative me-16px"
							/>
							<Text
								id="studentProfileIdValue"
								src="@cookie.studentID"
								className="w-100 h-auto position-relative text-primary-text ff-regular" >
								{t('admin_student.studentProfileIdValue')}
							</Text>
						</Group>
					</Group>
					<Group
						id="studentProfileWhereGroup"
						direction="vertical"
						className="w-100 h-auto position-relative" >
						<Text
							id="studentProfileAddressLabel"
							tag="small"
							className="w-100 h-auto position-relative mt-24px text-primary ff-bold" >
							{t('admin_student.studentProfileAddressLabel')}
						</Text>
						<Text
							id="studentProfileAddress"
							src="@database.student.address"
							className="w-45 h-auto position-relative mt-8px text-primary-text ff-regular" >
							{t('admin_student.studentProfileAddress')}
						</Text>
						<Text
							id="studentProfileNationalityLabel"
							tag="small"
							className="w-100 h-auto position-relative mt-24px text-primary ff-bold" >
							{t('admin_student.studentProfileNationalityLabel')}
						</Text>
						<Text
							id="studentProfileNationality"
							className="w-100 h-auto position-relative mt-8px text-primary-text ff-regular" >
							{t('admin_student.studentProfileNationality')}
						</Text>
					</Group>
					<Group
						id="studentProfileBirthdateGroup"
						direction="vertical"
						className="w-100 h-auto position-relative" >
						<Text
							id="studentProfileBirthLabel"
							tag="small"
							className="w-100 h-auto position-relative mt-24px text-primary ff-bold" >
							{t('admin_student.studentProfileBirthLabel')}
						</Text>
						<Text
							id="studentProfileBirthdate"
							format={{"type":"date","year":"numeric","day":"numeric","month":"long"}}
							src="@database.student.birthdate"
							className="w-100 h-auto position-relative mt-8px text-primary-text ff-regular" >
							{t('admin_student.studentProfileBirthdate')}
						</Text>
					</Group>
					<Group
						id="studentNotesGroup"
						direction="vertical"
						className="w-100 h-auto position-relative" >
						<Text
							id="studentNotesLabel"
							tag="small"
							className="w-100 h-auto position-relative mt-24px text-primary ff-bold" >
							{t('admin_student.studentNotesLabel')}
						</Text>
						<Input.TextArea
							id="studentNotesInput"
							name="text"
							src="@cookie.studentNotes"
						onBlur={studentNotesInputOnLostFocus}
							className="w-100 h-auto position-relative mt-8px" />
					</Group>
				</Group>
				<Group
					id="studentInfoData"
					direction="vertical"
					className="min-w-300px w-fill min-h-100 h-100 position-relative p-24px scrollable" >
					<Group
						id="studentUniversityGroup"
						if={{"what":"@database.student.type","is":"student"}}
						direction="vertical"
						className="w-100 h-auto position-relative" >
						<Group
							id="studentSchoolsGroup"
							direction="vertical"
							className="w-100 h-auto position-relative" >
							<Text
								id="studentSchoolsTitle"
								tag="h3"
								className="w-100 h-auto position-relative text-primary ff-bold" >
								{t('admin_student.studentSchoolsTitle')}
							</Text>
							<Text
								id="studentSchoolsMain"
								className="w-100 h-auto position-relative mt-16px text-primary-text ff-regular" >
								{t('admin_student.studentSchoolsMain')}
							</Text>
							<Text
								id="studentSchoolsOther"
								if={{"what":"@database.student.otherSchools","is_not":null}}
								className="w-100 h-auto position-relative mt-16px text-primary-text ff-regular" >
								{t('admin_student.studentSchoolsOther')}
							</Text>
						</Group>
						<Group
							id="studentGradesGroup"
							direction="vertical"
							className="w-100 h-auto position-relative mt-32px" >
							<Text
								id="studentGradesTitle"
								tag="h3"
								className="w-100 h-auto position-relative text-primary ff-bold" >
								{t('admin_student.studentGradesTitle')}
							</Text>
							<Group
								id="studentGradesGroups"
								direction="auto"
								className="w-100 h-auto position-relative mt-16px" >
								<Group
									id="studentGradesEso3Group"
									direction="horizontal"
									className="w-auto h-auto border-top border-bottom border-start border-end border-radius-10px position-relative p-16px me-16px" >
									<Text
										id="studentGradesEso3Score"
										className="w-auto h-auto position-relative" >
										{t('admin_student.studentGradesEso3Score')}
									</Text>
									<Text
										id="studentGradesEso3ES"
										className="w-auto h-auto position-relative ps-16px" >
										{t('admin_student.studentGradesEso3ES')}
									</Text>
									<Icon
										id="studentGradeEso3Download"
										if={{"what":"@database.student.gradesEso3Doc","is_not":null}}
										onClick={studentGradeEso3DownloadOnClick}
										src="download"
										className="w-auto h-auto position-relative"
									/>
									<Icon
										id="studentGradeEso3Missing"
										if={{"what":"@database.student.gradesEso3Doc","is":null}}
										src="alert"
										className="w-auto h-auto position-relative"
									/>
									<Text
										id="studentGradesEso3EN"
										className="w-auto h-auto position-relative ps-16px" >
										{t('admin_student.studentGradesEso3EN')}
									</Text>
									<Icon
										id="studentGradeEso3ENDownload"
										if={{"what":"@database.student.gradesEso3DocEng","is_not":null}}
										onClick={studentGradeEso3ENDownloadOnClick}
										src="download"
										className="w-auto h-auto position-relative"
									/>
									<Icon
										id="studentGradeEso3ENMissing"
										if={{"what":"@database.student.gradesEso3DocEng","is":null}}
										src="alert"
										className="w-auto h-auto position-relative"
									/>
								</Group>
								<Group
									id="studentGradesEso4Group"
									direction="horizontal"
									className="w-auto h-auto border-top border-bottom border-start border-end border-radius-10px position-relative p-16px me-16px" >
									<Text
										id="studentGradesEso4Score"
										className="w-auto h-auto position-relative" >
										{t('admin_student.studentGradesEso4Score')}
									</Text>
									<Text
										id="studentGradesEso4ES"
										className="w-auto h-auto position-relative ps-16px" >
										{t('admin_student.studentGradesEso4ES')}
									</Text>
									<Icon
										id="studentGradeEso4Download"
										if={{"what":"@database.student.gradesEso4Doc","is_not":null}}
										onClick={studentGradeEso4DownloadOnClick}
										src="download"
										className="w-auto h-auto position-relative"
									/>
									<Icon
										id="studentGradeEso4Missing"
										if={{"what":"@database.student.gradesEso4Doc","is":null}}
										src="alert"
										className="w-auto h-auto position-relative"
									/>
									<Text
										id="studentGradesEso4EN"
										className="w-auto h-auto position-relative ps-16px" >
										{t('admin_student.studentGradesEso4EN')}
									</Text>
									<Icon
										id="studentGradeEso4ENDownload"
										if={{"what":"@database.student.gradesEso4DocEng","is_not":null}}
										onClick={studentGradeEso4ENDownloadOnClick}
										src="download"
										className="w-auto h-auto position-relative"
									/>
									<Icon
										id="studentGradeEso4ENMissing"
										if={{"what":"@database.student.gradesEso4DocEng","is":null}}
										src="alert"
										className="w-auto h-auto position-relative"
									/>
								</Group>
								<Group
									id="studentGradesBac1Group"
									direction="horizontal"
									className="w-auto h-auto border-top border-bottom border-start border-end border-radius-10px position-relative p-16px me-16px" >
									<Text
										id="studentGradesBac1Score"
										className="w-auto h-auto position-relative" >
										{t('admin_student.studentGradesBac1Score')}
									</Text>
									<Text
										id="studentGradesBac1ES"
										className="w-auto h-auto position-relative ps-16px" >
										{t('admin_student.studentGradesBac1ES')}
									</Text>
									<Icon
										id="studentGradeBac1Download"
										if={{"what":"@database.student.gradesBac1Doc","is_not":null}}
										onClick={studentGradeBac1DownloadOnClick}
										src="download"
										className="w-auto h-auto position-relative"
									/>
									<Icon
										id="studentGradeBac1Missing"
										if={{"what":"@database.student.gradesBac1Doc","is":null}}
										src="alert"
										className="w-auto h-auto position-relative"
									/>
									<Text
										id="studentGradesBac1EN"
										className="w-auto h-auto position-relative ps-16px" >
										{t('admin_student.studentGradesBac1EN')}
									</Text>
									<Icon
										id="studentGradeBac1ENDownload"
										if={{"what":"@database.student.gradesBac1DocEng","is_not":null}}
										onClick={studentGradeBac1ENDownloadOnClick}
										src="download"
										className="w-auto h-auto position-relative"
									/>
									<Icon
										id="studentGradeBac1ENMissing"
										if={{"what":"@database.student.gradesBac1DocEng","is":null}}
										src="alert"
										className="w-auto h-auto position-relative"
									/>
								</Group>
								<Group
									id="studentGradesBac2Group"
									direction="horizontal"
									className="w-auto h-auto border-top border-bottom border-start border-end border-radius-10px position-relative p-16px me-16px" >
									<Text
										id="studentGradesBac2Score"
										className="w-auto h-auto position-relative" >
										{t('admin_student.studentGradesBac2Score')}
									</Text>
									<Text
										id="studentGradesBac2ES"
										className="w-auto h-auto position-relative ps-16px" >
										{t('admin_student.studentGradesBac2ES')}
									</Text>
									<Icon
										id="studentGradeBac2Download"
										if={{"what":"@database.student.gradesBac2Doc","is_not":null}}
										onClick={studentGradeBac2DownloadOnClick}
										src="download"
										className="w-auto h-auto position-relative"
									/>
									<Icon
										id="studentGradeBac2Missing"
										if={{"what":"@database.student.gradesBac2Doc","is":null}}
										src="alert"
										className="w-auto h-auto position-relative"
									/>
									<Text
										id="studentGradesBac2EN"
										className="w-auto h-auto position-relative ps-16px" >
										{t('admin_student.studentGradesBac2EN')}
									</Text>
									<Icon
										id="studentGradeBac2ENDownload"
										if={{"what":"@database.student.gradesBac2DocEng","is_not":null}}
										onClick={studentGradeBac2ENDownloadOnClick}
										src="download"
										className="w-auto h-auto position-relative"
									/>
									<Icon
										id="studentGradeBac2ENMissing"
										if={{"what":"@database.student.gradesBac2DocEng","is":null}}
										src="alert"
										className="w-auto h-auto position-relative"
									/>
								</Group>
							</Group>
						</Group>
						<Group
							id="studentOtherGradesGroup"
							direction="vertical"
							className="w-100 h-auto position-relative mt-32px" >
							<Text
								id="studentOtherGradesTitle"
								tag="h3"
								className="w-100 h-auto position-relative text-primary ff-bold" >
								{t('admin_student.studentOtherGradesTitle')}
							</Text>
							<Group
								id="studentOtherGradesGrid"
								direction="auto"
								className="w-100 h-auto position-relative mt-16px" >
								<Group
									id="studentAsGroup"
									direction="horizontal"
									className="w-auto h-auto border-top border-bottom border-start border-end border-radius-10px position-relative p-16px me-16px" >
									<Text
										id="studentGradesAsScore"
										className="w-auto h-auto position-relative me-16px" >
										{t('admin_student.studentGradesAsScore')}
									</Text>
									<Icon
										id="studentGradesAsDownload"
										if={{"what":"@database.student.gradesAsDoc","is_not":null}}
										onClick={studentGradesAsDownloadOnClick}
										src="download"
										className="w-auto h-auto position-relative"
									/>
									<Icon
										id="studentGradesAsMissing"
										if={{"what":"@database.student.gradesAsDoc","is":null}}
										src="alert"
										className="w-auto h-auto position-relative"
									/>
								</Group>
								<Group
									id="studentIgcseGroup"
									direction="horizontal"
									className="w-auto h-auto border-top border-bottom border-start border-end border-radius-10px position-relative p-16px me-16px" >
									<Text
										id="studentIgcseScore"
										className="w-auto h-auto position-relative me-16px" >
										{t('admin_student.studentIgcseScore')}
									</Text>
									<Icon
										id="studentIgcseDownload"
										if={{"what":"@database.student.igcseDoc","is_not":null}}
										onClick={studentIgcseDownloadOnClick}
										src="download"
										className="w-auto h-auto position-relative"
									/>
									<Icon
										id="studentIgcseMissing"
										if={{"what":"@database.student.igcseDoc","is":null}}
										src="alert"
										className="w-auto h-auto position-relative"
									/>
								</Group>
								<Group
									id="studentEnglishGroup"
									direction="horizontal"
									className="w-auto h-auto border-top border-bottom border-start border-end border-radius-10px position-relative p-16px me-16px" >
									<Text
										id="studentEnglishScore"
										className="w-auto h-auto position-relative me-16px" >
										{t('admin_student.studentEnglishScore')}
									</Text>
									<Icon
										id="studentEnglishDownload"
										if={{"what":"@database.student.englishDoc","is_not":null}}
										onClick={studentEnglishDownloadOnClick}
										src="download"
										className="w-auto h-auto position-relative"
									/>
									<Icon
										id="studentEnglishMissing"
										if={{"what":"@database.student.englishDoc","is":null}}
										src="alert"
										className="w-auto h-auto position-relative"
									/>
								</Group>
								<Group
									id="studentSatGroup"
									direction="horizontal"
									className="w-auto h-auto border-top border-bottom border-start border-end border-radius-10px position-relative p-16px me-16px" >
									<Text
										id="studentSatScore"
										className="w-auto h-auto position-relative me-16px" >
										{t('admin_student.studentSatScore')}
									</Text>
									<Icon
										id="studentSatDownload"
										if={{"what":"@database.student.satDoc","is_not":null}}
										onClick={studentSatDownloadOnClick}
										src="download"
										className="w-auto h-auto position-relative"
									/>
									<Icon
										id="studentSatMissing"
										if={{"what":"@database.student.satDoc","is":null}}
										src="alert"
										className="w-auto h-auto position-relative"
									/>
								</Group>
							</Group>
						</Group>
					</Group>
					<Group
						id="studentSchoolGroup"
						if={{"what":"@database.student.type","is":"student_school","or":{"what":true,"is":true}}}
						direction="vertical"
						className="w-100 h-auto position-relative" >
						<Group
							id="studentSchoolsSchoolGroup"
							direction="vertical"
							className="w-100 h-auto position-relative" >
							<Text
								id="studentSchoolsTitle1662833350837"
								tag="h3"
								className="w-100 h-auto position-relative text-primary ff-bold" >
								{t('admin_student.studentSchoolsTitle1662833350837')}
							</Text>
							<Text
								id="studentSchoolsMain1662833350837"
								className="w-100 h-auto position-relative mt-16px text-primary-text ff-regular" >
								{t('admin_student.studentSchoolsMain1662833350837')}
							</Text>
						</Group>
						<Group
							id="studentSchoolGradesGroup"
							direction="vertical"
							className="w-100 h-auto position-relative mt-32px" >
							<Text
								id="studentSchoolGradesTitle"
								tag="h3"
								className="w-100 h-auto position-relative text-primary ff-bold" >
								{t('admin_student.studentSchoolGradesTitle')}
							</Text>
							<Group
								id="studentSchoolGradesGroups"
								direction="vertical"
								className="w-100 h-auto position-relative mt-16px" >
								<Group
									id="studentSchoolGradesLastYearGroup"
									direction="horizontal"
									className="w-auto h-auto border-top border-bottom border-start border-end border-radius-10px position-relative p-16px me-16px" >
									<Text
										id="studentSchoolGradesLastYearCourse"
										tag="b"
										className="w-auto h-auto position-relative" >
										{t('admin_student.studentSchoolGradesLastYearCourse')}
									</Text>
									<Group
										id="prevYearEs"
										direction="horizontal"
										className="w-100 h-auto position-relative" >
										<Text
											id="studentSchoolGradesLastYear"
											className="w-auto h-auto position-relative ps-16px" >
											{t('admin_student.studentSchoolGradesLastYear')}
										</Text>
										<Icon
											id="studentSchoolGradeLastYearDownload"
											if={{"what":"@database.student.gradesLastYearDoc","is_not":null}}
											onClick={studentSchoolGradeLastYearDownloadOnClick}
											src="download"
											className="w-auto h-auto position-relative"
										/>
										<Icon
											id="studentSchoolGradeLastYearMissing"
											if={{"what":"@database.student.gradesLastYearDoc","is":null}}
											src="alert"
											className="w-auto h-auto position-relative"
										/>
									</Group>
									<Group
										id="prevYearEn"
										direction="horizontal"
										className="w-100 h-auto position-relative" >
										<Text
											id="studentSchoolGradesLastYear1665048590859"
											className="w-auto h-auto position-relative ps-16px" >
											{t('admin_student.studentSchoolGradesLastYear1665048590859')}
										</Text>
										<Icon
											id="studentSchoolGradeLastYearDownload1665048590860"
											if={{"what":"@database.student.gradesLastYearDocEng","is_not":null}}
											onClick={studentSchoolGradeLastYearDownload1665048590860OnClick}
											src="download"
											className="w-auto h-auto position-relative"
										/>
										<Icon
											id="studentSchoolGradeLastYearMissing1665048590860"
											if={{"what":"@database.student.gradesLastYearDocEng","is":null}}
											src="alert"
											className="w-auto h-auto position-relative"
										/>
									</Group>
								</Group>
								<Group
									id="studentSchoolGradesPrevYearGroup"
									direction="horizontal"
									className="w-auto h-auto border-top border-bottom border-start border-end border-radius-10px position-relative p-16px me-16px" >
									<Text
										id="studentSchoolGradesPrevYearCourse"
										tag="b"
										className="w-auto h-auto position-relative" >
										{t('admin_student.studentSchoolGradesPrevYearCourse')}
									</Text>
									<Group
										id="groupLastYear"
										direction="horizontal"
										className="w-100 h-auto position-relative" >
										<Text
											id="studentSchoolGradesPrevYear"
											className="w-auto h-auto position-relative ps-16px" >
											{t('admin_student.studentSchoolGradesPrevYear')}
										</Text>
										<Icon
											id="studentSchoolGradePrevYearDownload"
											if={{"what":"@database.student.gradesPrevYearDoc","is_not":null}}
											onClick={studentSchoolGradePrevYearDownloadOnClick}
											src="download"
											className="w-auto h-auto position-relative"
										/>
										<Icon
											id="studentSchoolGradePrevYearMissing"
											if={{"what":"@database.student.gradesPrevYearDoc","is":null}}
											src="alert"
											className="w-auto h-auto position-relative"
										/>
									</Group>
									<Group
										id="groupLastYearEng"
										direction="horizontal"
										className="w-100 h-auto position-relative" >
										<Text
											id="studentSchoolGradesPrevYear1665048743737"
											className="w-auto h-auto position-relative ps-16px" >
											{t('admin_student.studentSchoolGradesPrevYear1665048743737')}
										</Text>
										<Icon
											id="studentSchoolGradePrevYearDownloadEng"
											if={{"what":"@database.student.gradesPrevYearDocEng","is_not":null}}
											onClick={studentSchoolGradePrevYearDownloadEngOnClick}
											src="download"
											className="w-auto h-auto position-relative"
										/>
										<Icon
											id="studentSchoolGradePrevYearMissing1665048743737"
											if={{"what":"@database.student.gradesPrevYearDocEng","is":null}}
											src="alert"
											className="w-auto h-auto position-relative"
										/>
									</Group>
								</Group>
							</Group>
						</Group>
						<Group
							id="studentLettersGroup"
							direction="vertical"
							className="w-100 h-auto position-relative mt-32px" >
							<Text
								id="studentLettersTitle"
								tag="h3"
								className="w-100 h-auto position-relative text-primary ff-bold" >
								{t('admin_student.studentLettersTitle')}
							</Text>
							<Group
								id="studentLettersGroups"
								direction="auto"
								className="w-100 h-auto position-relative mt-16px" >
								<Group
									id="studentLetterTeacher"
									direction="horizontal"
									className="w-auto h-auto border-top border-bottom border-start border-end border-radius-10px position-relative p-16px me-16px" >
									<Text
										id="studentLetterEnglishLabel"
										className="w-auto h-auto position-relative" >
										{t('admin_student.studentLetterEnglishLabel')}
									</Text>
									<Icon
										id="studentLetterEnglishDownload"
										if={{"what":"@database.student.letter_english","is_not":null}}
										onClick={studentLetterEnglishDownloadOnClick}
										src="download"
										className="w-auto h-auto position-relative"
									/>
									<Icon
										id="studentLetterEnglishMissing"
										if={{"what":"@database.student.letter_english","is":null}}
										src="alert"
										className="w-auto h-auto position-relative"
									/>
								</Group>
								<Group
									id="studentLetterTutor"
									direction="horizontal"
									className="w-auto h-auto border-top border-bottom border-start border-end border-radius-10px position-relative p-16px me-16px" >
									<Text
										id="studentLetterTutorLabel"
										className="w-auto h-auto position-relative" >
										{t('admin_student.studentLetterTutorLabel')}
									</Text>
									<Icon
										id="studentLetterTutorDownload"
										if={{"what":"@database.student.letter_tutor","is_not":null}}
										onClick={studentLetterTutorDownloadOnClick}
										src="download"
										className="w-auto h-auto position-relative"
									/>
									<Icon
										id="studentLetterTutorMissing"
										if={{"what":"@database.student.letter_tutor","is":null}}
										src="alert"
										className="w-auto h-auto position-relative"
									/>
								</Group>
								<Group
									id="studentLetterMath"
									direction="horizontal"
									className="w-auto h-auto border-top border-bottom border-start border-end border-radius-10px position-relative p-16px me-16px" >
									<Text
										id="studentLetterMathLabel"
										className="w-auto h-auto position-relative" >
										{t('admin_student.studentLetterMathLabel')}
									</Text>
									<Icon
										id="studentLetterMathDownload"
										if={{"what":"@database.student.letter_math","is_not":null}}
										onClick={studentLetterMathDownloadOnClick}
										src="download"
										className="w-auto h-auto position-relative"
									/>
									<Icon
										id="studentLetterMathMissing"
										if={{"what":"@database.student.letter_math","is":null}}
										src="alert"
										className="w-auto h-auto position-relative"
									/>
								</Group>
							</Group>
						</Group>
					</Group>
					<Group
						id="studentDocsGroup"
						direction="vertical"
						className="w-100 h-auto position-relative mt-32px" >
						<Text
							id="studentDocsTitle"
							tag="h3"
							className="w-100 h-auto position-relative text-primary ff-bold" >
							{t('admin_student.studentDocsTitle')}
						</Text>
						<Group
							id="studentDocsGroup"
							direction="auto"
							className="w-100 h-auto position-relative mt-16px" >
							<Group
								id="studentDniGroup"
								direction="horizontal"
								className="w-auto h-auto border-top border-bottom border-start border-end border-radius-10px position-relative p-16px me-16px" >
								<Text
									id="studentDniValue"
									className="w-auto h-auto position-relative" >
									{t('admin_student.studentDniValue')}
								</Text>
								<Text
									id="studentDniFront"
									className="w-auto h-auto position-relative ps-16px" >
									{t('admin_student.studentDniFront')}
								</Text>
								<Icon
									id="studentDniFrontDownload"
									if={{"what":"@database.student.dniFrontPhoto","is_not":null}}
									onClick={studentDniFrontDownloadOnClick}
									src="download"
									className="w-auto h-auto position-relative"
								/>
								<Icon
									id="studentDniFrontMissing"
									if={{"what":"@database.student.dniFrontPhoto","is":null}}
									src="alert"
									className="w-auto h-auto position-relative"
								/>
								<Text
									id="studentDniBack"
									className="w-auto h-auto position-relative ps-16px" >
									{t('admin_student.studentDniBack')}
								</Text>
								<Icon
									id="studentDniBackDownload"
									if={{"what":"@database.student.dniBackPhoto","is_not":null}}
									onClick={studentDniBackDownloadOnClick}
									src="download"
									className="w-auto h-auto position-relative"
								/>
								<Icon
									id="studentDniBackMissing"
									if={{"what":"@database.student.dniBackPhoto","is":null}}
									src="alert"
									className="w-auto h-auto position-relative"
								/>
							</Group>
							<Group
								id="studentPassportGroup"
								direction="horizontal"
								className="w-auto h-auto border-top border-bottom border-start border-end border-radius-10px position-relative p-16px me-16px" >
								<Text
									id="studentPassport"
									className="w-auto h-auto position-relative" >
									{t('admin_student.studentPassport')}
								</Text>
								<Icon
									id="studentPassportDownload"
									if={{"what":"@database.student.passportPhoto","is_not":null}}
									onClick={studentPassportDownloadOnClick}
									src="download"
									className="w-auto h-auto position-relative"
								/>
								<Icon
									id="studentPassportMissing"
									if={{"what":"@database.student.passportPhoto","is":null}}
									src="alert"
									className="w-auto h-auto position-relative"
								/>
							</Group>
						</Group>
						<Group
							id="studentDadGroup"
							if={{"what":"@database.student.type","is":"student_school"}}
							direction="vertical"
							className="w-100 h-auto position-relative mt-16px" >
							<Text
								id="studentDadTitle"
								tag="b"
								className="w-auto h-auto position-relative ps-16px" >
								{t('admin_student.studentDadTitle')}
							</Text>
							<Text
								id="studentDadTitle1662837271210"
								className="w-auto h-auto position-relative ps-16px" >
								{t('admin_student.studentDadTitle1662837271210')}
							</Text>
							<Text
								id="studentDadDNI"
								className="w-auto h-auto position-relative ps-16px" >
								{t('admin_student.studentDadDNI')}
							</Text>
							<Text
								id="studentDadEmail"
								onClick={studentDadEmailOnClick}
								className="w-auto h-auto position-relative ps-16px" >
								{t('admin_student.studentDadEmail')}
							</Text>
							<Text
								id="studentDadPhone"
								className="w-auto h-auto position-relative ps-16px" >
								{t('admin_student.studentDadPhone')}
							</Text>
							<Text
								id="studentDadAddress"
								className="w-auto h-auto position-relative ps-16px" >
								{t('admin_student.studentDadAddress')}
							</Text>
						</Group>
						<Group
							id="studentMumGroup"
							if={{"what":"@database.student.type","is":"student_school"}}
							direction="vertical"
							className="w-100 h-auto position-relative mt-16px" >
							<Text
								id="studentMumTitle"
								tag="b"
								className="w-auto h-auto position-relative ps-16px" >
								{t('admin_student.studentMumTitle')}
							</Text>
							<Text
								id="studentMumTitle"
								className="w-auto h-auto position-relative ps-16px" >
								{t('admin_student.studentMumTitle')}
							</Text>
							<Text
								id="studentMumDNI"
								className="w-auto h-auto position-relative ps-16px" >
								{t('admin_student.studentMumDNI')}
							</Text>
							<Text
								id="studentMumEmail"
								onClick={studentMumEmailOnClick}
								className="w-auto h-auto position-relative ps-16px" >
								{t('admin_student.studentMumEmail')}
							</Text>
							<Text
								id="studentMumPhone"
								className="w-auto h-auto position-relative ps-16px" >
								{t('admin_student.studentMumPhone')}
							</Text>
							<Text
								id="studentMumAddress"
								className="w-auto h-auto position-relative ps-16px" >
								{t('admin_student.studentMumAddress')}
							</Text>
						</Group>
					</Group>
					<Group
						id="studentOtherDocsGroup"
						direction="vertical"
						className="w-100 h-auto position-relative mt-32px" >
						<Text
							id="studentOtherDocsTitle"
							tag="h3"
							className="w-100 h-auto position-relative text-primary ff-bold" >
							{t('admin_student.studentOtherDocsTitle')}
						</Text>
						<List
							id="studentOtherDocsList"
							direction="auto"
							src="@firebase.firestore.students.(@cookie.studentID).docs"
							columns={"auto"}
							className="w-100 h-auto position-relative mt-16px" >
							<Group
								id="studentExtraDocGroup"
								direction="horizontal"
								className="w-auto h-auto border-top border-bottom border-start border-end border-radius-10px position-relative p-16px me-16px" >
								<Text
									id="studentOtherDocDescription"
									className="w-auto h-auto position-relative me-16px" >
									{t('admin_student.studentOtherDocDescription')}
								</Text>
								<Icon
									id="studentDocDownload"
									if={{"what":"@field.icon","is":"check.svg"}}
									onClick={studentDocDownloadOnClick}
									src="download"
									className="w-auto h-auto position-relative"
								/>
								<Icon
									id="studentDocMissing"
									if={{"what":"@field.icon","is_not":"check.svg"}}
									src="alert"
									className="w-auto h-auto position-relative"
								/>
							</Group>
						</List>
					</Group>
					<Group
						id="studentPreferencesUniversityGroup"
						if={{"what":"@database.student.type","is":"student"}}
						direction="vertical"
						className="w-100 h-auto position-relative mt-24px" >
						<Text
							id="studentPreferencesTitle"
							tag="h3"
							className="w-100 h-auto position-relative text-primary ff-bold" >
							{t('admin_student.studentPreferencesTitle')}
						</Text>
						<Text
							id="studentOption1"
							className="w-100 h-auto position-relative mt-16px text-primary-text ff-regular" >
							{t('admin_student.studentOption1')}
						</Text>
						<Text
							id="studentOption2"
							className="w-100 h-auto position-relative mt-16px text-primary-text ff-regular" >
							{t('admin_student.studentOption2')}
						</Text>
						<Text
							id="studentInvestment"
							className="w-100 h-auto position-relative mt-16px text-primary-text ff-regular" >
							{t('admin_student.studentInvestment')}
						</Text>
					</Group>
					<Group
						id="studentPreferencesSchoolGroup"
						if={{"what":"@database.student.type","is":"student_school"}}
						direction="vertical"
						className="w-100 h-auto position-relative mt-24px" >
						<Text
							id="studentPreferencesTitle1662833001297"
							tag="h3"
							className="w-100 h-auto position-relative text-primary ff-bold" >
							{t('admin_student.studentPreferencesTitle1662833001297')}
						</Text>
						<Text
							id="studentOption1662833001297"
							className="w-100 h-auto position-relative mt-16px text-primary-text ff-regular" >
							{t('admin_student.studentOption1662833001297')}
						</Text>
						<Text
							id="studentOption1662833001297"
							className="w-100 h-auto position-relative mt-16px text-primary-text ff-regular" >
							{t('admin_student.studentOption1662833001297')}
						</Text>
						<Text
							id="studentInvestment1662833001297"
							className="w-100 h-auto position-relative mt-16px text-primary-text ff-regular" >
							{t('admin_student.studentInvestment1662833001297')}
						</Text>
					</Group>
					<Group
						id="studentApplicationsGroup"
						direction="vertical"
						className="w-100 h-auto position-relative mt-48px" >
						<Text
							id="studentApplicationsTitle"
							tag="h3"
							className="w-100 h-auto position-relative text-primary ff-bold" >
							{t('admin_student.studentApplicationsTitle')}
						</Text>
						<List
							id="adminAppList"
							src="@firebase.firestore.students.(@cookie.studentID).applications"
							order={{"type":"asc","field":"name"}}
							columns={"auto"}
							className="w-90 h-auto position-relative mt-24px" >
							<Card
								id="appsCard1649675430698"
								className="min-w-300px w-fill h-auto position-relative m-8px" >
								<Card.Body>
										<Group
											id="uniLogoGroup1649675430698"
											direction="horizontal"
											className="w-100 h-auto border-gray border-bottom position-relative" >
											<Text
												id="appsListName1649675430698"
												tag="b"
												src="name"
												className="w-fill h-auto valign-center position-relative ff-bold" >
												{t('admin_student.appsListName1649675430698')}
											</Text>
											<Image
												id="appsListLogo1649675430698"
												fallback={placeholder_logo}
												src="logo"
												className="w-auto min-h-52px h-52px align-center valign-center position-relative fill" />
										</Group>
										<Text
											id="appsCity1649675430698"
											tag="b"
											src="city"
											className="w-100 h-auto position-relative mt-8px text-primary ff-regular" >
											{t('admin_student.appsCity1649675430698')}
										</Text>
										<Image
											id="component1649675430698"
											fallback={placeholder_uni}
											src="photo"
											className="w-100 min-h-150px h-150px position-relative mt-12px cover" />
										<Text
											id="adminAppStatus"
											className="w-100 h-auto position-relative mt-8px text-accent ff-bold" >
											{t('admin_student.adminAppStatus')}
										</Text>
										<Group
											id="appsListButtons1649675430698"
											direction="horizontal"
											className="w-auto h-auto border-top align-end position-relative pt-8px mt-24px" >
											<Button
												id="uniListButtonMore1649675430698"
												onClick={uniListButtonMore1649675430698OnClick}
												variant="primary"
												className="w-auto h-auto border-radius-50px align-end position-relative" >
												{t('admin_student.uniListButtonMore1649675430698')}
											</Button>
										</Group>
								</Card.Body>
							</Card>
						</List>
					</Group>
					<Group
						id="adminStudentBottomMargin"
						direction="vertical"
						className="w-100 min-h-64px h-64px position-relative" >
					</Group>
				</Group>
			</Group>
      </FullscreenWrapper>
    </>
  );
};
