import React from 'react';
import { Container } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import { useFunctions, useRefs } from '../hooks';
import { FullscreenWrapper } from '../wrappers';

// Custom imports:
import { Group, Icon, Text, Image, Nav, Dropdown, Input, Button } from '../components';
import { useEffect } from 'react';
import logo_72_white from '../assets/icon/logo_72_white.png';
import faq_header from '../assets/image/faq_header.jpg';
import logo_72 from '../assets/icon/logo_72.png';
import { useTranslation } from 'react-i18next';

export const AccountView = () => {
  const { run } = useFunctions();
  // Custom exports:
	const { t } = useTranslation();

  // Custom functions:
	const accountOnLoaded = (options) => {
		run([{"function":"clone","what":"@firebase.firestore.data.faq.categories","into":"@database.faqcat"}], options)
	}
	useEffect(accountOnLoaded, [])
	const homeBlueYoutubeIcon1659653742999OnClick = (options) => {
		run([{"function":"browser","url":"https:\/\/www.youtube.com\/channel\/UCmfIE4aab0Qjz5xuqD3Pbfg","target":"_maeyoutube"}], options)
	}
	const homeBlueTwitterIcon1659653742999OnClick = (options) => {
		run([{"function":"browser","url":"https:\/\/twitter.com\/mae_sports","target":"_maetwitter"}], options)
	}
	const homeBlueFacebookIcon1659653742999OnClick = (options) => {
		run([{"function":"browser","url":"https:\/\/www.facebook.com\/mae.sports\/","target":"_maefacebook"}], options)
	}
	const homeBlueInstagramIcon1659653742999OnClick = (options) => {
		run([{"function":"browser","url":"https:\/\/www.instagram.com\/mae.sports\/","target":"_maeinstagram"}], options)
	}
	const homeFooterWeb1659653742999OnClick = (options) => {
		run([{"function":"browser","url":"https:\/\/www.maeducation.net","target":"_self"}], options)
	}
	const homeFooterEmail1659653742999OnClick = (options) => {
		run([{"function":"browser","url":"mailto:info@maeducation.net"}], options)
	}
	const homeFooterTel1659653742999OnClick = (options) => {
		run([{"function":"browser","url":"tel:+34646516514"}], options)
	}
	const component1659658943274OnClick = (options) => {
		run([{"function":"popup","dialog":"account_deletion"}], options)
	}
	const component1661991063262OnClick = (options) => {
		run([{"function":"set","what":"@property.valid","value":true},{"function":"do","what":[{"function":"set","what":"@property.valid","value":false},{"function":"set","what":"@property.msg","value":"password_must_match"},{"function":"popup","dialog":"msg_i18n"}],"if":{"what":"@property.newPassword","is_not":null,"and":{"what":"@property.newPassword","is_not":"@property.newPasswordSec"}}},{"function":"set","what":"@firebase.user.password","value":"@property.newPassword","if":{"what":"@property.valid","is":true}}], options)
	}
	const universityTypeGroupOnClick = (options) => {
		run([{"function":"set","what":"@firebase.firestore.users.(@cookie.userID).type","value":"student"},{"function":"set","what":"@firebase.firestore.students.(@cookie.userID).type","value":"student"},{"function":"set","what":"@cookie.type","value":"student"}], options)
	}
	const component1659691447489OnClick = (options) => {
		run([{"function":"set","what":"@firebase.firestore.users.(@cookie.userID).type","value":"student_school"},{"function":"set","what":"@firebase.firestore.students.(@cookie.userID).type","value":"student_school"},{"function":"set","what":"@cookie.type","value":"student_school"}], options)
	}
	const Session_menuItemsSessionLogoutOnClick = (options) => {
		run([{"function":"goto","view":"logout"}], options)
	}
	const Session_menuItemsSessionSettingsOnClick = (options) => {
		run([{"function":"goto","view":"account"}], options)
	}
	const Student_schools_menuItemsStudentProfile1658518549491OnClick = (options) => {
		run([{"function":"goto","view":"profile_a"}], options)
	}
	const Student_schools_menuItemsStudentFaq1658518549491OnClick = (options) => {
		run([{"function":"goto","view":"faq"}], options)
	}
	const Student_schools_menuItemsSchoolsApplicationsOnClick = (options) => {
		run([{"function":"goto","view":"applications"}], options)
	}
	const Student_schools_menuItemsStudentSchoolsOnClick = (options) => {
		run([{"function":"goto","view":"schools"}], options)
	}
	const Student_menuItemsStudentProfileOnClick = (options) => {
		run([{"function":"goto","view":"stu_step_1"}], options)
	}
	const Student_menuItemsStudentFaqOnClick = (options) => {
		run([{"function":"goto","view":"faq"}], options)
	}
	const Student_menuItemsStudentApplicationsOnClick = (options) => {
		run([{"function":"goto","view":"applications"}], options)
	}
	const Student_menuItemsStudentUniversitiesOnClick = (options) => {
		run([{"function":"goto","view":"stu_main"}], options)
	}
	const faqBlueYoutubeIcon1659653742999OnClick = (options) => {
		run([{"function":"browser","url":"https:\/\/www.youtube.com\/channel\/UCmfIE4aab0Qjz5xuqD3Pbfg","target":"_maeyoutube"}], options)
	}
	const faqBlueTwitterIcon1659653742999OnClick = (options) => {
		run([{"function":"browser","url":"https:\/\/twitter.com\/mae_sports","target":"_maetwitter"}], options)
	}
	const faqBlueFacebookIcon1659653742999OnClick = (options) => {
		run([{"function":"browser","url":"https:\/\/www.facebook.com\/mae.sports\/","target":"_maefacebook"}], options)
	}
	const faqBlueInstagramIcon1659653742999OnClick = (options) => {
		run([{"function":"browser","url":"https:\/\/www.instagram.com\/mae.sports\/","target":"_maeinstagram"}], options)
	}
	const faqBlueEmail1659653742999OnClick = (options) => {
		run([{"function":"browser","url":"mailto:info@maeducationusa.com"}], options)
	}
	const faqBluePhone1659653742999OnClick = (options) => {
		run([{"function":"browser","url":"tel:+34646516514"}], options)
	}

  return (
    <>
      <Helmet>
				<title>{t('account.title')}</title>
      </Helmet>
      <FullscreenWrapper>
          
			<Group
				id="accountBody"
				direction="vertical"
				className="w-100 min-h-100 fg-100 position-relative scrollable" >
				<Group
					id="accountHeader"
					direction="vertical"
					className="w-100 h-auto position-relative" >
					<Group
						id="accountBlueBar"
						direction="vertical"
						className="w-100 h-auto position-relative bg-primary" >
						<Group
							id="accountBlueContent"
							direction="auto"
							className="w-100 h-auto align-center position-relative" >
							<Group
								id="faqBluePhone1659653742999"
								onClick={faqBluePhone1659653742999OnClick}
								direction="horizontal"
								className="w-auto h-auto align-center position-relative p-12px" >
								<Icon
									id="faqBluePhoneIcon1659653742999"
									src="phone"
									color="var(--text-on-primary)"
									className="w-auto h-auto position-relative"
								/>
								<Text
									id="faqBluePhoneNumber1659653742999"
									className="w-auto h-auto valign-center position-relative text-text-on-primary f-16px" >
									{t('account.faqBluePhoneNumber1659653742999')}
								</Text>
							</Group>
							<Group
								id="faqBlueEmail1659653742999"
								onClick={faqBlueEmail1659653742999OnClick}
								direction="horizontal"
								className="w-auto h-auto align-center position-relative p-12px" >
								<Icon
									id="faqBlueEmailIcon1659653742999"
									src="email"
									color="var(--text-on-primary)"
									className="w-auto h-auto position-relative"
								/>
								<Text
									id="faqBlueEmailAddress1659653742999"
									className="w-auto h-auto valign-center position-relative text-text-on-primary f-16px" >
									{t('account.faqBlueEmailAddress1659653742999')}
								</Text>
							</Group>
							<Group
								id="faqBlueSpacer1659653742999"
								direction="vertical"
								className="w-fill h-auto position-relative" >
							</Group>
							<Group
								id="faqBlueRightSide1659653742999"
								direction="vertical"
								className="w-auto h-auto align-center position-relative p-12px" >
								<Group
									id="faqBlueSSNN1659653742999"
									direction="horizontal"
									className="w-auto h-auto align-end position-relative" >
									<Icon
										id="faqBlueInstagramIcon1659653742999"
										onClick={faqBlueInstagramIcon1659653742999OnClick}
										src="instagram"
										color="var(--text-on-primary)"
										className="w-auto h-auto position-relative me-12px"
									/>
									<Icon
										id="faqBlueFacebookIcon1659653742999"
										onClick={faqBlueFacebookIcon1659653742999OnClick}
										src="facebook"
										color="var(--text-on-primary)"
										className="w-auto h-auto position-relative me-12px"
									/>
									<Icon
										id="faqBlueTwitterIcon1659653742999"
										onClick={faqBlueTwitterIcon1659653742999OnClick}
										src="twitter"
										color="var(--text-on-primary)"
										className="w-auto h-auto position-relative me-12px"
									/>
									<Icon
										id="faqBlueYoutubeIcon1659653742999"
										onClick={faqBlueYoutubeIcon1659653742999OnClick}
										src="youtube"
										color="var(--text-on-primary)"
										className="w-auto h-auto position-relative"
									/>
								</Group>
							</Group>
						</Group>
					</Group>
					<Group
						id="accountWhiteHeader"
						direction="vertical"
						className="w-100 h-auto position-relative p-24px" >
						<Group
							id="accountHeaderContent"
							direction="auto"
							className="w-100 h-auto align-center valign-center position-relative" >
							<Group
								id="accountNavBar"
								direction="horizontal"
								className="min-w-320px w-fill h-auto align-start valign-center position-relative" >
								<Image
									id="accountLogo"
									src={logo_72}
									className="w-auto min-h-64px h-64px valign-center position-relative" />
							</Group>
							<Group
								id="accountNavBarContent"
								direction="auto"
								className="w-auto h-auto align-end valign-center position-relative" >
								<Nav
									id="accountMenu"
									if={{"what":"@cookie.type","is":"student"}}
									className="w-auto h-auto position-relative"
									direction="horizontal" >
									<Nav.Item title={t('student_menu.items.studentUniversities.title')} onClick={Student_menuItemsStudentUniversitiesOnClick} href="stu_main" />
									<Nav.Item title={t('student_menu.items.studentApplications.title')} onClick={Student_menuItemsStudentApplicationsOnClick} href="applications" />
									<Nav.Item title={t('student_menu.items.studentFaq.title')} onClick={Student_menuItemsStudentFaqOnClick} href="faq" />
									<Nav.Item title={t('student_menu.items.studentProfile.title')} onClick={Student_menuItemsStudentProfileOnClick} href="stu_step_1" />
								</Nav>
								<Nav
									id="accountMenuSchools"
									if={{"what":"@cookie.type","is":"student_school"}}
									className="w-auto h-auto position-relative"
									direction="horizontal" >
									<Nav.Item title={t('student_schools_menu.items.studentSchools.title')} onClick={Student_schools_menuItemsStudentSchoolsOnClick} href="schools" />
									<Nav.Item title={t('student_schools_menu.items.schoolsApplications.title')} onClick={Student_schools_menuItemsSchoolsApplicationsOnClick} href="applications" />
									<Nav.Item title={t('student_schools_menu.items.studentFaq1658518549491.title')} onClick={Student_schools_menuItemsStudentFaq1658518549491OnClick} href="faq" />
									<Nav.Item title={t('student_schools_menu.items.studentProfile1658518549491.title')} onClick={Student_schools_menuItemsStudentProfile1658518549491OnClick} href="profile_a" />
								</Nav>
								<Dropdown
									id="accountAccountMenu"
									className="w-auto h-auto border-radius-32px valign-center position-relative"
									variant="primary"
									title={<>

									<Text
										id="accountAccountMenuTitle"
										className="w-auto h-auto position-relative ff-regular" >
										{t('account.accountAccountMenuTitle')}
									</Text>
								</>} >
									<Dropdown.Item title={t('session_menu.items.sessionSettings.title')} onClick={Session_menuItemsSessionSettingsOnClick} href="account" />
									<Dropdown.Item title={t('session_menu.items.sessionLogout.title')} onClick={Session_menuItemsSessionLogoutOnClick} href="logout" />
								</Dropdown>
							</Group>
						</Group>
					</Group>
				</Group>
				<Group
					id="accountContent"
					direction="vertical"
					className="w-100 h-fill align-center position-relative scrollable" >
					<Group
						id="accountListHeader"
						direction="vertical"
						className="w-100 min-h-250px h-250px position-relative" >
						<Image
							id="accountHeaderImage"
							src={faq_header}
							className="w-100 min-h-240px h-240px position-relative cover" />
						<Group
							id="accountTitleGroup"
							direction="auto"
							className="w-90 h-auto align-center valign-end position-absolute-bottom bg-lighten p-24px" >
							<Text
								id="accountTitle"
								tag="h2"
								className="min-w-300px w-fill h-auto position-relative text-primary ff-bold" >
								{t('account.accountTitle')}
							</Text>
						</Group>
					</Group>
					<Group
						id="accountTypeWrapper"
						direction="vertical"
						className="w-100 h-auto position-relative p-24px mt-32px" >
						<Group
							id="accountTypeWrapper1659658287097"
							direction="vertical"
							className="max-w-1200px w-fill h-auto border-top border-bottom border-start border-end border-radius-15px align-center position-relative p-24px mt-32px" >
							<Text
								id="component1659658520810"
								className="w-100 h-auto position-relative pb-24px text-primary ff-bold" >
								{t('account.component1659658520810')}
							</Text>
							<Group
								id="component1659691364892"
								direction="auto"
								className="w-100 h-auto position-relative" >
								<Group
									id="studentTypeWrapper"
									direction="vertical"
									className="w-fill h-auto position-relative p-4px" >
									<Group
										id="component1659691447489"
										onClick={component1659691447489OnClick}
										direction="vertical"
										className="w-100 h-auto border-top border-bottom border-start border-end border-radius-10px position-relative" >
										<Group
											id="component1659691549839"
											if={{"what":"@cookie.type","is":"student_school"}}
											direction="vertical"
											className="w-100 min-h-100 fg-100 border-radius-10px position-absolute bg-light" >
										</Group>
										<Group
											id="component1659691727986"
											direction="vertical"
											className="w-100 h-auto position-relative p-32px" >
											<Text
												id="component1659691820533"
												tag="h2"
												className="w-100 h-auto position-relative pb-24px text-primary-dark ff-bold text-center" >
												{t('account.component1659691820533')}
											</Text>
											<Text
												id="component1659691980645"
												className="w-100 h-auto position-relative text-primary-text ff-regular text-center" >
												{t('account.component1659691980645')}
											</Text>
										</Group>
									</Group>
								</Group>
								<Group
									id="universityTypeWrapper"
									direction="vertical"
									className="w-fill h-auto position-relative p-4px" >
									<Group
										id="universityTypeGroup"
										onClick={universityTypeGroupOnClick}
										direction="vertical"
										className="w-100 h-auto border-top border-bottom border-start border-end border-radius-10px position-relative" >
										<Group
											id="universityTypeBackground"
											if={{"what":"@cookie.type","is":"student"}}
											direction="vertical"
											className="w-100 min-h-100 fg-100 border-radius-10px position-absolute bg-light" >
										</Group>
										<Group
											id="universityTypeInfo"
											direction="vertical"
											className="w-100 h-auto position-relative p-32px" >
											<Text
												id="universityTypeTitle"
												tag="h2"
												className="w-100 h-auto position-relative pb-24px text-primary-dark ff-bold text-center" >
												{t('account.universityTypeTitle')}
											</Text>
											<Text
												id="universityTypeDescription"
												className="w-100 h-auto position-relative text-primary-text ff-regular text-center" >
												{t('account.universityTypeDescription')}
											</Text>
										</Group>
									</Group>
								</Group>
							</Group>
						</Group>
					</Group>
					<Group
						id="passwordEditWrapper"
						if={{"what":"@property.neverShowThis","is":true}}
						direction="vertical"
						className="w-100 h-auto position-relative px-24px pt-24px" >
						<Group
							id="accountTypeWrapper1659658711334"
							direction="vertical"
							className="max-w-1200px w-fill h-auto border-top border-bottom border-start border-end border-radius-15px align-center position-relative p-24px mt-32px" >
							<Text
								id="component1659658711334"
								className="w-100 h-auto position-relative pb-24px text-primary ff-bold" >
								{t('account.component1659658711334')}
							</Text>
							<Group
								id="component1661990898958"
								direction="horizontal"
								className="w-100 h-auto position-relative" >
								<Input.Text
									id="component1661990928154"
									label={t('account.component1661990928154.label')}
									name="text"
									src="@property.newPassword"
									className="w-150px h-auto position-relative me-8px" />
								<Input.Text
									id="component1661991034299"
									label={t('account.component1661991034299.label')}
									name="text"
									src="@property.newPasswordSec"
									className="w-150px h-auto position-relative me-8px" />
								<Button
									id="component1661991063262"
									onClick={component1661991063262OnClick}
									variant="primary"
									className="w-auto h-auto border-radius-30px valign-end position-relative" >
									{t('account.component1661991063262')}
								</Button>
							</Group>
						</Group>
					</Group>
					<Group
						id="dangerZoneWrapper"
						direction="vertical"
						className="w-100 h-auto position-relative px-24px" >
						<Group
							id="accountTypeWrapper1659658775305"
							direction="vertical"
							className="max-w-1200px w-fill h-auto border-top border-bottom border-start border-end border-radius-15px align-center position-relative p-24px mt-32px" >
							<Text
								id="dangerZoneTitle"
								className="w-100 h-auto position-relative pb-24px text-accent ff-bold" >
								{t('account.dangerZoneTitle')}
							</Text>
							<Text
								id="dangerZoneDescription"
								className="w-100 h-auto position-relative pb-24px text-primary-text" >
								{t('account.dangerZoneDescription')}
							</Text>
							<Text
								id="component1659658943274"
								onClick={component1659658943274OnClick}
								className="w-auto h-auto border-radius-40px align-end position-relative bg-accent px-12px py-6px text-text-on-primary" >
								{t('account.component1659658943274')}
							</Text>
						</Group>
					</Group>
					<Group
						id="footerSpacer1659653742999"
						direction="vertical"
						className="w-100 min-h-300px h-fill position-relative" >
					</Group>
					<Group
						id="faqFooter1659653742999"
						direction="vertical"
						className="w-100 h-auto position-relative mt-48px" >
						<Group
							id="homeFooterBar1659653742999"
							direction="vertical"
							className="w-100 h-auto valign-end position-relative bg-footer_bg py-48px" >
							<Group
								id="homeFooterBarContent1659653742999"
								direction="auto"
								className="max-w-1200px w-100 h-auto align-center position-relative p-32px" >
								<Text
									id="homeFooterAbout1659653742999"
									tag="p"
									className="max-w-500px min-w-300px w-fill h-auto position-relative text-text-on-primary ff-medium" >
									{t('account.homeFooterAbout1659653742999')}
								</Text>
								<Group
									id="homeFooterSpacer1659653742999"
									direction="vertical"
									className="w-fill h-auto position-relative" >
								</Group>
								<Group
									id="homeFooterContact1659653742999"
									direction="vertical"
									className="max-w-400px min-w-300px w-fill h-auto position-relative" >
									<Image
										id="homeFooterLogo1659653742999"
										src={logo_72_white}
										className="w-auto min-h-72px h-72px align-end position-relative" />
									<Text
										id="homeFooterCities1659653742999"
										tag="p"
										className="w-auto h-auto align-end position-relative text-text-on-primary ff-medium text-end" >
										{t('account.homeFooterCities1659653742999')}
									</Text>
									<Text
										id="homeFooterTel1659653742999"
										onClick={homeFooterTel1659653742999OnClick}
										tag="p"
										className="w-auto h-auto align-end position-relative text-text-on-primary ff-medium text-end" >
										{t('account.homeFooterTel1659653742999')}
									</Text>
									<Text
										id="homeFooterEmail1659653742999"
										onClick={homeFooterEmail1659653742999OnClick}
										tag="p"
										className="w-auto h-auto align-end position-relative text-text-on-primary ff-medium text-end" >
										{t('account.homeFooterEmail1659653742999')}
									</Text>
									<Text
										id="homeFooterWeb1659653742999"
										onClick={homeFooterWeb1659653742999OnClick}
										tag="p"
										className="w-auto h-auto align-end position-relative text-text-on-primary ff-medium text-end" >
										{t('account.homeFooterWeb1659653742999')}
									</Text>
									<Group
										id="homeBlueSSNN1659653742999"
										direction="horizontal"
										className="w-auto h-auto align-end position-relative" >
										<Icon
											id="homeBlueInstagramIcon1659653742999"
											onClick={homeBlueInstagramIcon1659653742999OnClick}
											src="instagram"
											color="var(--text-on-primary)"
											className="w-auto h-auto position-relative me-12px"
										/>
										<Icon
											id="homeBlueFacebookIcon1659653742999"
											onClick={homeBlueFacebookIcon1659653742999OnClick}
											src="facebook"
											color="var(--text-on-primary)"
											className="w-auto h-auto position-relative me-12px"
										/>
										<Icon
											id="homeBlueTwitterIcon1659653742999"
											onClick={homeBlueTwitterIcon1659653742999OnClick}
											src="twitter"
											color="var(--text-on-primary)"
											className="w-auto h-auto position-relative me-12px"
										/>
										<Icon
											id="homeBlueYoutubeIcon1659653742999"
											onClick={homeBlueYoutubeIcon1659653742999OnClick}
											src="youtube"
											color="var(--text-on-primary)"
											className="w-auto h-auto position-relative"
										/>
									</Group>
								</Group>
							</Group>
						</Group>
					</Group>
				</Group>
			</Group>
      </FullscreenWrapper>
    </>
  );
};
