import React, { useEffect, useState } from 'react';
import Component from './Component';
import { useSource } from '../hooks';

export const Video = ({
  className = '',
  itemDataSrc,
  fallback,
  src: propSrc,
  tag: Tag = 'video',
  ...props
}) => {
  const { itemData: data } = props;
  const [dataValue, setDataValue] = useState();
  if (itemDataSrc?.[0] === '@') {
    propSrc = itemDataSrc;
  }
  const { value } = useSource(propSrc, { data });
  const [hasError, setHasError] = useState(false);

  useEffect(() => {
    let mounted = true;
    (async () => {
      try {
        const { default: url } = await import(
          `../assets/${itemDataSrc || value}`
        );
        mounted && setDataValue(url);
      } catch (e) {}
    })();
    return () => {
      mounted = false;
    };
  }, [itemDataSrc, value]);

  useEffect(() => {
    setHasError(false);
  }, [value]);

  fallback =
    fallback &&
    encodeURI(
      fallback.replace(/\(@field\.([^)]*)\)/g, (str, field) => data[field])
    );
  let src =
    dataValue ||
    (itemDataSrc?.match?.(/^(@|data:video\/|(blob:)?http|\/static\/)/) &&
      itemDataSrc) ||
    (value?.match?.(/^(@|data:video\/|(blob:)?http|\/static\/)/) && value) ||
    (propSrc?.match?.(/^(data:video\/|(blob:)?http|\/static\/)/) && propSrc) ||
    fallback;

  if (hasError) {
    src = fallback;
  }

  if (src?.match(/^@/)) {
    return <Video {...props} className={className} src={src} />;
  }

  return (
    <Component
      {...props}
      src={src}
      className={`video ${className}`.trim()}
      component={Tag}
      onError={(e) => {
        if (fallback && e.currentTarget.src !== fallback) {
          setHasError(true);
        }
      }}
    />
  );
};

Video.displayName = 'Video';
export default Video;
