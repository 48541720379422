import React from 'react';
import { Container } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import { useFunctions, useRefs } from '../hooks';
import { FullscreenWrapper } from '../wrappers';

// Custom imports:
import { Group, Icon, Text, Image, Nav, Dropdown, Input, List, Card, Button } from '../components';
import { useEffect } from 'react';
import logo_72_white from '../assets/icon/logo_72_white.png';
import placeholder_logo from '../assets/image/placeholder_logo.svg';
import collage from '../assets/image/collage.jpg';
import logo_72 from '../assets/icon/logo_72.png';
import { useTranslation } from 'react-i18next';

export const SchoolsView = () => {
  const { run } = useFunctions();
  // Custom exports:
	const { t } = useTranslation();

  // Custom functions:
	const schoolsOnLoaded = (options) => {
		run([{"function":"set","what":"@property.search","value":"@firebase.firestore.data.values.space"},{"function":"set","what":"@cookie.lang","value":"es","if":{"what":"@app.language","starts_with":"es","else":[{"function":"set","what":"@cookie.lang","value":"en"}]}},{"function":"set","what":"@cookie.order","value":"name","if":{"what":"@cookie.order","is":null}},{"function":"set","what":"@cookie.orderDirection","value":"desc","if":{"what":"@cookie.order","is":"students","else":[{"function":"set","what":"@cookie.orderDirection","value":"asc"}]}},{"function":"set","what":"@cookie.favField","value":"fav","if":{"what":"@cookie.onlyFavs","is":true,"else":[{"function":"set","what":"@cookie.favField","value":"active"}]}},{"function":"request","url":"https:\/\/europe-west1-education-74543.cloudfunctions.net\/getStudentUniversities","params":{"userID":"@cookie.userID","entity":"school"},"into":"@database.schools","response":"json-api","method":"post","onerror":[{"function":"clone","into":"@database.universities","what":"@firebase.firestore.schools","filter":{"what":"active","is":true},"order":{"type":"asc","field":"name"}}],"onsuccess":[{"function":"set","what":"@property.unisLoaded","value":true}],"if":{"what":"@property.unisLoaded","is_not":true}}], options)
	}
	useEffect(schoolsOnLoaded, [])
	const homeBlueInstagramIcon1658518408743OnClick = (options) => {
		run([{"function":"browser","url":"https:\/\/www.instagram.com\/mae.sports\/","target":"_maeinstagram"}], options)
	}
	const homeFooterWeb1658518408743OnClick = (options) => {
		run([{"function":"browser","url":"https:\/\/www.maeducation.net","target":"_self"}], options)
	}
	const homeFooterEmail1658518408743OnClick = (options) => {
		run([{"function":"browser","url":"mailto:info@maeducation.net"}], options)
	}
	const homeFooterTel1658518408743OnClick = (options) => {
		run([{"function":"browser","url":"tel:+34646516514"}], options)
	}
	const homeFooterCookies1663113294545OnClick = (options) => {
		run([{"function":"set","what":"@property.policy","value":"cookies_policy"},{"function":"popup","dialog":"terms"}], options)
	}
	const homeFooterPrivacy1663113294545OnClick = (options) => {
		run([{"function":"set","what":"@property.policy","value":"privacy_policy"},{"function":"popup","dialog":"terms"}], options)
	}
	const schoolListButtonMoreOnClick = (options) => {
		run([{"function":"set","what":"@cookie.universityID","value":"@element.schoolList.selected"},{"function":"set","what":"@cookie.entityID","value":"@element.schoolList.selected"},{"function":"set","what":"@database.entity","value":"@element.schoolList.value"},{"function":"set","what":"@property.isFavorite","value":"@database.schools.(@cookie.entityID).fav"},{"function":"goto","view":"school"}], options)
	}
	const schoolsOrderSelectOnChanged = (options) => {
		run([{"function":"set","what":"@cookie.orderDirection","value":"desc","if":{"what":"@cookie.order","is":"students","else":[{"function":"set","what":"@cookie.orderDirection","value":"asc"}]}}], options)
	}
	const schoolsFavsSelectOnChanged = (options) => {
		run([{"function":"set","what":"@cookie.favField","value":"fav","if":{"what":"@cookie.onlyFavs","is":true,"else":[{"function":"set","what":"@cookie.favField","value":"active"}]}}], options)
	}
	const Session_menuItemsSessionLogoutOnClick = (options) => {
		run([{"function":"goto","view":"logout"}], options)
	}
	const Session_menuItemsSessionSettingsOnClick = (options) => {
		run([{"function":"goto","view":"account"}], options)
	}
	const Student_schools_menuItemsStudentProfile1658518549491OnClick = (options) => {
		run([{"function":"goto","view":"profile_a"}], options)
	}
	const Student_schools_menuItemsStudentFaq1658518549491OnClick = (options) => {
		run([{"function":"goto","view":"faq"}], options)
	}
	const Student_schools_menuItemsSchoolsApplicationsOnClick = (options) => {
		run([{"function":"goto","view":"applications"}], options)
	}
	const Student_schools_menuItemsStudentSchoolsOnClick = (options) => {
		run([{"function":"goto","view":"schools"}], options)
	}
	const homeBlueYoutubeIcon1658518408743OnClick = (options) => {
		run([{"function":"browser","url":"https:\/\/www.youtube.com\/channel\/UCmfIE4aab0Qjz5xuqD3Pbfg","target":"_maeyoutube"}], options)
	}
	const homeBlueTwitterIcon1658518408743OnClick = (options) => {
		run([{"function":"browser","url":"https:\/\/twitter.com\/mae_sports","target":"_maetwitter"}], options)
	}
	const homeBlueFacebookIcon1658518408743OnClick = (options) => {
		run([{"function":"browser","url":"https:\/\/www.facebook.com\/mae.sports\/","target":"_maefacebook"}], options)
	}
	const homeBlueInstagramIcon1658518408742OnClick = (options) => {
		run([{"function":"browser","url":"https:\/\/www.instagram.com\/mae.sports\/","target":"_maeinstagram"}], options)
	}
	const homeBlueEmail1658518408742OnClick = (options) => {
		run([{"function":"browser","url":"mailto:info@maeducationusa.com"}], options)
	}
	const homeBluePhone1658518408742OnClick = (options) => {
		run([{"function":"browser","url":"tel:+34646516514"}], options)
	}

  return (
    <>
      <Helmet>
				<title>{t('schools.title')}</title>
      </Helmet>
      <FullscreenWrapper>
          
			<Group
				id="schoolsBody"
				direction="vertical"
				className="w-100 min-h-100 fg-100 position-relative scrollable" >
				<Group
					id="schoolsMainHeader"
					direction="vertical"
					className="w-100 h-auto position-relative" >
					<Group
						id="schoolsBlueBar"
						direction="vertical"
						className="w-100 h-auto position-relative bg-primary" >
						<Group
							id="schoolsBlueContent"
							direction="auto"
							className="w-100 h-auto align-center position-relative" >
							<Group
								id="homeBluePhone1658518408742"
								onClick={homeBluePhone1658518408742OnClick}
								direction="horizontal"
								className="w-auto h-auto align-center position-relative p-12px" >
								<Icon
									id="homeBluePhoneIcon1658518408742"
									src="phone"
									color="var(--text-on-primary)"
									className="w-auto h-auto position-relative"
								/>
								<Text
									id="homeBluePhoneNumber1658518408742"
									className="w-auto h-auto valign-center position-relative text-text-on-primary f-16px" >
									{t('schools.homeBluePhoneNumber1658518408742')}
								</Text>
							</Group>
							<Group
								id="homeBlueEmail1658518408742"
								onClick={homeBlueEmail1658518408742OnClick}
								direction="horizontal"
								className="w-auto h-auto align-center position-relative p-12px" >
								<Icon
									id="homeBlueEmailIcon1658518408742"
									src="email"
									color="var(--text-on-primary)"
									className="w-auto h-auto position-relative"
								/>
								<Text
									id="homeBlueEmailAddress1658518408742"
									className="w-auto h-auto valign-center position-relative text-text-on-primary f-16px" >
									{t('schools.homeBlueEmailAddress1658518408742')}
								</Text>
							</Group>
							<Group
								id="homeBlueSpacer1658518408742"
								direction="vertical"
								className="w-fill h-auto position-relative" >
							</Group>
							<Group
								id="homeBlueRightSide1658518408743"
								direction="vertical"
								className="w-auto h-auto align-center position-relative p-12px" >
								<Group
									id="homeBlueSSNN1658518408743"
									direction="horizontal"
									className="w-auto h-auto align-end position-relative" >
									<Icon
										id="homeBlueInstagramIcon1658518408742"
										onClick={homeBlueInstagramIcon1658518408742OnClick}
										src="instagram"
										color="var(--text-on-primary)"
										className="w-auto h-auto position-relative me-12px"
									/>
									<Icon
										id="homeBlueFacebookIcon1658518408743"
										onClick={homeBlueFacebookIcon1658518408743OnClick}
										src="facebook"
										color="var(--text-on-primary)"
										className="w-auto h-auto position-relative me-12px"
									/>
									<Icon
										id="homeBlueTwitterIcon1658518408743"
										onClick={homeBlueTwitterIcon1658518408743OnClick}
										src="twitter"
										color="var(--text-on-primary)"
										className="w-auto h-auto position-relative me-12px"
									/>
									<Icon
										id="homeBlueYoutubeIcon1658518408743"
										onClick={homeBlueYoutubeIcon1658518408743OnClick}
										src="youtube"
										color="var(--text-on-primary)"
										className="w-auto h-auto position-relative"
									/>
								</Group>
							</Group>
						</Group>
					</Group>
					<Group
						id="schoolsWhiteHeader"
						direction="vertical"
						className="w-100 h-auto position-relative p-24px" >
						<Group
							id="schoolsHeaderContent"
							direction="auto"
							className="w-100 h-auto align-center valign-center position-relative" >
							<Group
								id="schoolsNavBar"
								direction="horizontal"
								className="min-w-320px w-fill h-auto align-start valign-center position-relative" >
								<Image
									id="schoolsLogo"
									src={logo_72}
									className="w-auto min-h-64px h-64px valign-center position-relative" />
							</Group>
							<Group
								id="schoolsNavBarContent"
								direction="auto"
								className="w-auto h-auto align-end valign-center position-relative" >
								<Nav
									id="schoolsMenu"
									className="w-auto h-auto position-relative"
									direction="horizontal" >
									<Nav.Item title={t('student_schools_menu.items.studentSchools.title')} onClick={Student_schools_menuItemsStudentSchoolsOnClick} href="schools" />
									<Nav.Item title={t('student_schools_menu.items.schoolsApplications.title')} onClick={Student_schools_menuItemsSchoolsApplicationsOnClick} href="applications" />
									<Nav.Item title={t('student_schools_menu.items.studentFaq1658518549491.title')} onClick={Student_schools_menuItemsStudentFaq1658518549491OnClick} href="faq" />
									<Nav.Item title={t('student_schools_menu.items.studentProfile1658518549491.title')} onClick={Student_schools_menuItemsStudentProfile1658518549491OnClick} href="profile_a" />
								</Nav>
								<Dropdown
									id="schoolsAccountMenu"
									className="w-auto h-auto border-radius-32px position-relative"
									variant="primary"
									title={<>

									<Text
										id="schoolsAccountMenuTitle"
										className="w-auto h-auto position-relative ff-regular" >
										{t('schools.schoolsAccountMenuTitle')}
									</Text>
								</>} >
									<Dropdown.Item title={t('session_menu.items.sessionSettings.title')} onClick={Session_menuItemsSessionSettingsOnClick} href="account" />
									<Dropdown.Item title={t('session_menu.items.sessionLogout.title')} onClick={Session_menuItemsSessionLogoutOnClick} href="logout" />
								</Dropdown>
							</Group>
						</Group>
					</Group>
				</Group>
				<Group
					id="schoolsContent"
					direction="vertical"
					className="w-100 h-fill align-center position-relative scrollable" >
					<Group
						id="schoolListHeader"
						direction="vertical"
						className="w-100 min-h-250px h-250px position-relative" >
						<Image
							id="schoolListHeaderImage"
							src={collage}
							className="w-100 min-h-240px h-240px position-relative cover" />
						<Group
							id="schoolListSearchTitleGroup"
							direction="auto"
							className="w-90 h-auto align-center valign-end position-absolute-bottom bg-lighten p-24px" >
							<Text
								id="schoolListTitle"
								tag="h2"
								className="min-w-300px w-fill h-auto position-relative text-primary ff-bold" >
								{t('schools.schoolListTitle')}
							</Text>
							<Group
								id="schoolsFavsGroup"
								direction="vertical"
								className="min-w-100px w-auto h-auto valign-center position-relative pb-4px me-16px" >
								<Input.Checkbox
									id="schoolsFavsSelect"
									label={t('schools.schoolsFavsSelect.label')}
									name="select"
									src="@cookie.onlyFavs"
								onChange={schoolsFavsSelectOnChanged}
									className="w-auto h-auto position-relative" />
							</Group>
							<Group
								id="schoolsOrderGroup"
								direction="vertical"
								className="w-auto h-auto valign-center position-relative py-4px me-16px" >
								<Input.Select
									id="schoolsOrderSelect"
									name="select"
									src="@cookie.order"
									onChange={schoolsOrderSelectOnChanged}
									className="w-auto h-auto position-relative" >
									<option value={"name"}>{t('schools.schoolsOrderSelect.opt0')}</option>
									<option value={"tuition"}>{t('schools.schoolsOrderSelect.opt1')}</option>
									<option value={"students"}>{t('schools.schoolsOrderSelect.opt2')}</option>
								</Input.Select>
							</Group>
							<Group
								id="schoolsSearchGroup"
								direction="horizontal"
								className="max-w-250px min-w-150px w-fill h-auto valign-center position-relative py-4px" >
								<Input.Text
									id="schoolsSearchInput"
									name="text"
									src="@property.search"
									className="w-fill h-auto position-relative" />
								<Icon
									id="schoolsSearchIcon"
									src="magnify"
									color="var(--gray)"
									className="w-auto h-auto align-end valign-center position-absolute me-8px"
								/>
								<Icon
									id="schoolsSearchIcon"
									src="magnify"
									color="var(--gray)"
									className="w-auto h-auto align-end valign-center position-absolute me-8px"
								/>
							</Group>
						</Group>
					</Group>
					<List
						id="schoolList"
						src="@database.schools"
						filter={{"what":"@cookie.favField","is":true,"and":{"what":"search_words","includes":"@property.search.lower"}}}
						order={{"type":"@cookie.orderDirection","field":"@cookie.order"}}
						columns={"auto"}
						className="w-90 h-auto align-center position-relative mt-24px" >
						<Card
							id="schoolCard"
							className="min-w-300px w-fill h-auto position-relative m-8px" >
							<Card.Body>
									<Group
										id="schoolLogoGroup"
										direction="horizontal"
										className="w-100 h-auto border-gray border-bottom position-relative" >
										<Text
											id="schoolListName"
											tag="b"
											src="name"
											className="w-fill h-auto valign-center position-relative ff-bold" >
											{t('schools.schoolListName')}
										</Text>
										<Image
											id="schoolListLogo"
											fallback={placeholder_logo}
											src="logo"
											className="w-auto min-h-52px h-52px align-center valign-center position-relative fill" />
									</Group>
									<Text
										id="schoolCity"
										tag="b"
										src="city"
										className="w-100 h-auto position-relative mt-8px text-primary ff-regular" >
										{t('schools.schoolCity')}
									</Text>
									<Group
										id="schoolStudentsGroup"
										direction="horizontal"
										className="w-100 h-auto position-relative" >
										<Icon
											id="schoolStudentsIcon"
											src="account-group"
											className="w-auto h-auto position-relative"
										/>
										<Text
											id="schoolStudentsScore"
											format={{"type":"amount","thousands":true}}
											src="students"
											className="w-auto h-auto valign-center position-relative ms-12px me-4px ff-regular" >
											{t('schools.schoolStudentsScore')}
										</Text>
										<Text
											id="schoolStudentsScore"
											className="w-fill h-auto valign-center position-relative ff-regular" >
											{t('schools.schoolStudentsScore')}
										</Text>
									</Group>
									<Group
										id="schoolTuitionGroup"
										direction="horizontal"
										className="w-100 h-auto position-relative" >
										<Icon
											id="uniTuitionIcon1658518408743"
											src="piggy-bank-outline"
											className="w-auto h-auto position-relative"
										/>
										<Text
											id="uniTuitionScore1658518408743"
											format={{"type":"amount","thousands":true}}
											src="tuition"
											className="w-auto h-auto valign-center position-relative ms-12px ff-regular" >
											{t('schools.uniTuitionScore1658518408743')}
										</Text>
										<Text
											id="uniTuitionCurrency1658518408743"
											className="w-auto h-auto valign-center position-relative ms-4px ff-regular" >
											{t('schools.uniTuitionCurrency1658518408743')}
										</Text>
									</Group>
									<Group
										id="schoolListButtonsGroup"
										direction="horizontal"
										className="w-auto h-auto border-top align-end position-relative pt-8px mt-24px" >
										<Button
											id="schoolListButtonMore"
											onClick={schoolListButtonMoreOnClick}
											variant="primary"
											className="w-auto h-auto border-radius-50px align-end position-relative" >
											{t('schools.schoolListButtonMore')}
										</Button>
									</Group>
							</Card.Body>
						</Card>
					</List>
					<Group
						id="footerSpacer1658518408743"
						direction="vertical"
						className="w-100 min-h-300px h-fill position-relative" >
					</Group>
					<Group
						id="stuMainFooter1658518408743"
						direction="vertical"
						className="w-100 h-auto position-relative mt-48px" >
						<Group
							id="homeFooterBar1658518408743"
							direction="vertical"
							className="w-100 h-auto valign-end position-relative bg-footer_bg py-48px" >
							<Group
								id="homeFooterBarContent1658518408743"
								direction="auto"
								className="max-w-1200px w-100 h-auto align-center position-relative p-32px" >
								<Group
									id="homeFooterInfo1663113294545"
									direction="vertical"
									className="w-fill h-auto position-relative" >
									<Text
										id="homeFooterAbout1663113294545"
										tag="p"
										className="max-w-500px min-w-300px w-fill h-auto position-relative text-text-on-primary ff-medium" >
										{t('schools.homeFooterAbout1663113294545')}
									</Text>
									<Group
										id="homeFooterPolicy1663113294545"
										direction="auto"
										className="w-fill h-auto position-relative" >
										<Text
											id="homeFooterPrivacy1663113294545"
											onClick={homeFooterPrivacy1663113294545OnClick}
											tag="small"
											className="max-w-100 w-auto h-auto position-relative pe-8px text-gray_b ff-medium" >
											{t('schools.homeFooterPrivacy1663113294545')}
										</Text>
										<Text
											id="homeFooterPrivacySeparator1663113294545"
											tag="small"
											className="w-auto h-auto position-relative pe-8px text-gray_b ff-medium" >
											{t('schools.homeFooterPrivacySeparator1663113294545')}
										</Text>
										<Text
											id="homeFooterCookies1663113294545"
											onClick={homeFooterCookies1663113294545OnClick}
											tag="small"
											className="max-w-100 w-auto h-auto position-relative pe-8px text-gray_b ff-medium" >
											{t('schools.homeFooterCookies1663113294545')}
										</Text>
									</Group>
								</Group>
								<Group
									id="homeFooterSpacer1658518408743"
									direction="vertical"
									className="w-fill h-auto position-relative" >
								</Group>
								<Group
									id="homeFooterContact1658518408743"
									direction="vertical"
									className="max-w-400px min-w-300px w-fill h-auto position-relative" >
									<Image
										id="homeFooterLogo1658518408743"
										src={logo_72_white}
										className="w-auto min-h-72px h-72px align-end position-relative" />
									<Text
										id="homeFooterCities1658518408743"
										tag="p"
										className="w-auto h-auto align-end position-relative text-text-on-primary ff-medium text-end" >
										{t('schools.homeFooterCities1658518408743')}
									</Text>
									<Text
										id="homeFooterTel1658518408743"
										onClick={homeFooterTel1658518408743OnClick}
										tag="p"
										className="w-auto h-auto align-end position-relative text-text-on-primary ff-medium text-end" >
										{t('schools.homeFooterTel1658518408743')}
									</Text>
									<Text
										id="homeFooterEmail1658518408743"
										onClick={homeFooterEmail1658518408743OnClick}
										tag="p"
										className="w-auto h-auto align-end position-relative text-text-on-primary ff-medium text-end" >
										{t('schools.homeFooterEmail1658518408743')}
									</Text>
									<Text
										id="homeFooterWeb1658518408743"
										onClick={homeFooterWeb1658518408743OnClick}
										tag="p"
										className="w-auto h-auto align-end position-relative text-text-on-primary ff-medium text-end" >
										{t('schools.homeFooterWeb1658518408743')}
									</Text>
									<Group
										id="homeBlueSSNN1658518408743"
										direction="horizontal"
										className="w-auto h-auto align-end position-relative" >
										<Icon
											id="homeBlueInstagramIcon1658518408743"
											onClick={homeBlueInstagramIcon1658518408743OnClick}
											src="instagram"
											color="var(--text-on-primary)"
											className="w-auto h-auto position-relative me-12px"
										/>
										<Icon
											id="homeBlueFacebookIcon1658518408743"
											onClick={homeBlueFacebookIcon1658518408743OnClick}
											src="facebook"
											color="var(--text-on-primary)"
											className="w-auto h-auto position-relative me-12px"
										/>
										<Icon
											id="homeBlueTwitterIcon1658518408743"
											onClick={homeBlueTwitterIcon1658518408743OnClick}
											src="twitter"
											color="var(--text-on-primary)"
											className="w-auto h-auto position-relative me-12px"
										/>
										<Icon
											id="homeBlueYoutubeIcon1658518408743"
											onClick={homeBlueYoutubeIcon1658518408743OnClick}
											src="youtube"
											color="var(--text-on-primary)"
											className="w-auto h-auto position-relative"
										/>
									</Group>
								</Group>
							</Group>
						</Group>
					</Group>
				</Group>
			</Group>
      </FullscreenWrapper>
    </>
  );
};
