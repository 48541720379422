import React from 'react';
import BootstrapDropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import Component from './Component';

const DropdownItem = ({
  children,
  onClick: handleClick = () => {},
  href = '',
  icon = null,
  image = null,
  title,
  type,
}) => {
  if (children) {
    children = Array.isArray(children) ? children : [children];
    return <Dropdown title={title}>{children}</Dropdown>;
  }

  const regexp = new RegExp(`^/(${href})$`);
  const isActive = href && window.location.pathname.match(regexp);
  const itemProps = {};
  if (isActive) {
    itemProps.active = true;
  }

  return (
    <BootstrapDropdown.Item onClick={handleClick} {...itemProps}>
      <>
        {icon}
        {title}
      </>
    </BootstrapDropdown.Item>
  );
};

export const Dropdown = ({ arrow, children, variant, title, ...props }) => {
  const toggleChildren = title?.props?.children;
  if (arrow === false) {
    props['data-arrow'] = false;
  }
  if (
    !Array.isArray(toggleChildren) &&
    (toggleChildren?.type?.displayName === 'Icon' ||
      toggleChildren?.type?.displayName === 'Image' ||
      toggleChildren?.type?.prototype?.constructor?.displayName === 'Icon' ||
      toggleChildren?.type?.prototype?.constructor?.displayName === 'Image')
  ) {
    props['data-image'] = true;
  }

  return (
    <Component
      {...props}
      variant={variant}
      component={DropdownButton}
      title={title}
    >
      {children}
    </Component>
  );
};

Dropdown.Item = DropdownItem;

Dropdown.displayName = 'Dropdown';
export default Dropdown;
