import React from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { ContextProviders } from './context';


// Custom imports:
import Dialogs from './dialogs';
import { AccountView, Admin_faqView, Admin_mainView, Admin_schoolsView, Admin_studentView, Admin_studentsView, ApplicationsView, FaqView, ForbiddenView, HomeView, HomenewView, LogoutView, Not_foundView, Profile_aView, Profile_bView, Profile_cView, SchoolView, School_editView, SchoolsView, Signup_universityView, Stu_loginView, Stu_mainView, Stu_signupView, Stu_signup_1View, Stu_step_1View, Stu_step_2View, Stu_step_3View, Uni_editView, Uni_infoView, Uni_unverifiedView } from './pages';
import PrivateRoute from './PrivateRoute';


import './styles/main.scss';

function App() {
  const { t } = useTranslation();
  return (
    <>
      <Helmet>
          <title>{t('title')}</title>
          <meta name="description" content={t('description')} data-react-helmet="true" />
      </Helmet>
      <Router>
        <ContextProviders>
          <Switch>
            <Route exact path="/" component={HomeView} />
						<Route exact path="/account" component={AccountView} />
<Route exact path="/account/:params(.+)" component={AccountView} />
						<Route exact path="/admin_faq" component={Admin_faqView} />
						<Route exact path="/admin_faq/:params(.+)" component={Admin_faqView} />
						<Route exact path="/admin_main" component={Admin_mainView} />
						<Route exact path="/admin_main/:params(.+)" component={Admin_mainView} />
						<Route exact path="/admin_schools" component={Admin_schoolsView} />
						<Route exact path="/admin_schools/:params(.+)" component={Admin_schoolsView} />
						<Route exact path="/admin_student" component={Admin_studentView} />
						<Route exact path="/admin_student/:params(.+)" component={Admin_studentView} />
						<PrivateRoute exact path="/admin_students" component={Admin_studentsView} />
						<PrivateRoute exact path="/admin_students/:params(.+)" component={Admin_studentsView} />
						<Route exact path="/applications" component={ApplicationsView} />
						<Route exact path="/applications/:params(.+)" component={ApplicationsView} />
						<Route exact path="/faq" component={FaqView} />
						<Route exact path="/faq/:params(.+)" component={FaqView} />
						<Route exact path="/forbidden" component={ForbiddenView} />
						<Route exact path="/forbidden/:params(.+)" component={ForbiddenView} />
						<Route exact path="/home" component={HomeView} />
						<Route exact path="/home/:params(.+)" component={HomeView} />
						<Route exact path="/homenew" component={HomenewView} />
						<Route exact path="/homenew/:params(.+)" component={HomenewView} />
						<Route exact path="/logout" component={LogoutView} />
						<Route exact path="/logout/:params(.+)" component={LogoutView} />
						<Route exact path="/not_found" component={Not_foundView} />
						<Route exact path="/not_found/:params(.+)" component={Not_foundView} />
						<Route exact path="/profile_a" component={Profile_aView} />
						<Route exact path="/profile_a/:params(.+)" component={Profile_aView} />
						<Route exact path="/profile_b" component={Profile_bView} />
						<Route exact path="/profile_b/:params(.+)" component={Profile_bView} />
						<Route exact path="/profile_c" component={Profile_cView} />
						<Route exact path="/profile_c/:params(.+)" component={Profile_cView} />
						<Route exact path="/school" component={SchoolView} />
						<Route exact path="/school/:params(.+)" component={SchoolView} />
						<Route exact path="/school_edit" component={School_editView} />
						<Route exact path="/school_edit/:params(.+)" component={School_editView} />
						<Route exact path="/schools" component={SchoolsView} />
						<Route exact path="/schools/:params(.+)" component={SchoolsView} />
						<Route exact path="/signup_university" component={Signup_universityView} />
						<Route exact path="/signup_university/:params(.+)" component={Signup_universityView} />
						<Route exact path="/stu_login" component={Stu_loginView} />
						<Route exact path="/stu_login/:params(.+)" component={Stu_loginView} />
						<Route exact path="/stu_main" component={Stu_mainView} />
						<Route exact path="/stu_main/:params(.+)" component={Stu_mainView} />
						<Route exact path="/stu_signup" component={Stu_signupView} />
						<Route exact path="/stu_signup/:params(.+)" component={Stu_signupView} />
						<Route exact path="/stu_signup_1" component={Stu_signup_1View} />
						<Route exact path="/stu_signup_1/:params(.+)" component={Stu_signup_1View} />
						<Route exact path="/stu_step_1" component={Stu_step_1View} />
						<Route exact path="/stu_step_1/:params(.+)" component={Stu_step_1View} />
						<Route exact path="/stu_step_2" component={Stu_step_2View} />
						<Route exact path="/stu_step_2/:params(.+)" component={Stu_step_2View} />
						<Route exact path="/stu_step_3" component={Stu_step_3View} />
						<Route exact path="/stu_step_3/:params(.+)" component={Stu_step_3View} />
						<Route exact path="/uni_edit" component={Uni_editView} />
						<Route exact path="/uni_edit/:params(.+)" component={Uni_editView} />
						<Route exact path="/uni_info" component={Uni_infoView} />
						<Route exact path="/uni_info/:params(.+)" component={Uni_infoView} />
						<PrivateRoute exact path="/uni_unverified" component={Uni_unverifiedView} />
						<PrivateRoute exact path="/uni_unverified/:params(.+)" component={Uni_unverifiedView} />
						<Route exact path="*" component={HomeView} />
						
          </Switch>

					<Dialogs />
        </ContextProviders>
      </Router>
    </>
  );
}

export default App;
