import React from 'react';
import { useFunctions } from '../hooks';

// Custom imports:
import { Toast, Icon } from '../components';
import { useTranslation } from 'react-i18next';

export const Uni_savedDialog = () => {
    const { run } = useFunctions();
  // Custom exports:
	const { t } = useTranslation();

  // Custom functions:


  return (
    
    
			<Toast id="uni_saved" autohide delay={3}>
					{t('uni_saved.text')}
			</Toast>
    
  );
};

export default Uni_savedDialog;
