import React from 'react';
import { useDialogs } from '../hooks';
import { useFunctions } from '../hooks';

// Custom imports:
import { Modal, Text, Button } from '../components';
import { useTranslation } from 'react-i18next';

const Invalid_loginDialog = () => {
    const { run } = useFunctions();
    // Custom exports:
	const { t } = useTranslation();

    // Custom functions:
	const invalid_login_buttons_positiveOnClick = (options) => {
		run([{"function":"hide","dialog":"invalid_login"}], options)
	}

  return (
    
    
			<Modal id="invalid_login">
					<Modal.Header>
						<Modal.Title>{t('invalid_login.title')}</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<Text
							id="wrongLoginText"
							className="w-100 h-auto position-relative" >
							{t('invalid_login.wrongLoginText')}
						</Text>
					</Modal.Body>
					<Modal.Footer>
						<Button
							id="invalid_login_buttons_positive"
							onClick={invalid_login_buttons_positiveOnClick}
							variant="primary"
							className="w-auto h-auto position-relative" >
							{t('invalid_login.invalid_login_buttons_positive')}
						</Button>
					</Modal.Footer>
			</Modal>
    
  );
};

export default Invalid_loginDialog;
