import React from 'react';
import { Container } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import { useFunctions, useRefs } from '../hooks';
import { FullscreenWrapper } from '../wrappers';

// Custom imports:
import { Group, Input } from '../components';
import { useTranslation } from 'react-i18next';

export const Not_foundView = () => {
  const { run } = useFunctions();
  // Custom exports:
	const { t } = useTranslation();

  // Custom functions:

  return (
    <>
      <Helmet>
				<title>{t('not_found.title')}</title>
      </Helmet>
      <FullscreenWrapper>
          
			<Group
				id="notFoundBody"
				direction="vertical"
				className="w-100 min-h-100 fg-100 position-relative" >
				<Input.Checkbox
					id="component1646042557109"
					name="checkbox"
					className="w-auto h-auto position-relative" />
			</Group>
      </FullscreenWrapper>
    </>
  );
};
