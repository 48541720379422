import React from 'react';
import { Container } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import { useFunctions, useRefs } from '../hooks';
import { FullscreenWrapper } from '../wrappers';

// Custom imports:
import { Group, Icon, Text, Image, Nav, Dropdown, Accordion, List } from '../components';
import { useEffect } from 'react';
import logo_72_white from '../assets/icon/logo_72_white.png';
import flag_es from '../assets/assets/flag_es.png';
import faq_header from '../assets/image/faq_header.jpg';
import logo_72 from '../assets/icon/logo_72.png';
import { useTranslation } from 'react-i18next';

export const FaqView = () => {
  const { run } = useFunctions();
  // Custom exports:
	const { t } = useTranslation();

  // Custom functions:
	const faqOnLoaded = (options) => {
		run([{"function":"clone","what":"@firebase.firestore.data.faq.categories","into":"@database.faqcat"},{"function":"do","what":[{"function":"set","what":"@cookie.lang","value":"@app.language"},{"function":"set","what":"@app.language","value":"es","if":{"what":"@database.i18n.langs.(@app.language)","is_not":true}}],"comments":"Set default language to ES"}], options)
	}
	useEffect(faqOnLoaded, [])
	const homeBlueYoutubeIcon1658182653757OnClick = (options) => {
		run([{"function":"browser","url":"https:\/\/www.youtube.com\/channel\/UCmfIE4aab0Qjz5xuqD3Pbfg","target":"_maeyoutube"}], options)
	}
	const homeBlueTwitterIcon1658182653757OnClick = (options) => {
		run([{"function":"browser","url":"https:\/\/twitter.com\/mae_sports","target":"_maetwitter"}], options)
	}
	const homeBlueFacebookIcon1658182653757OnClick = (options) => {
		run([{"function":"browser","url":"https:\/\/www.facebook.com\/mae.sports\/","target":"_maefacebook"}], options)
	}
	const homeBlueInstagramIcon1658182653757OnClick = (options) => {
		run([{"function":"browser","url":"https:\/\/www.instagram.com\/mae.sports\/","target":"_maeinstagram"}], options)
	}
	const homeFooterWeb1658182653757OnClick = (options) => {
		run([{"function":"browser","url":"https:\/\/www.maeducation.net","target":"_self"}], options)
	}
	const homeFooterEmail1658182653757OnClick = (options) => {
		run([{"function":"browser","url":"mailto:info@maeducation.net"}], options)
	}
	const homeFooterTel1658182653757OnClick = (options) => {
		run([{"function":"browser","url":"tel:+34646516514"}], options)
	}
	const Lang_menuItemsOptENOnClick = (options) => {
		run([{"function":"set","what":"@app.language","value":"en"},{"function":"set","what":"@cookie.flag","value":"flag_en.png"},{"function":"set","what":"@cookie.langName","value":"English"}], options)
	}
	const Lang_menuItemsOptESOnClick = (options) => {
		run([{"function":"set","what":"@app.language","value":"es"},{"function":"set","what":"@cookie.flag","value":"flag_es.png"},{"function":"set","what":"@cookie.langName","value":"Espa\u00f1ol"}], options)
	}
	const homeFooterCookies1663113409244OnClick = (options) => {
		run([{"function":"set","what":"@property.policy","value":"cookies_policy"},{"function":"popup","dialog":"terms"}], options)
	}
	const homeFooterPrivacy1663113409244OnClick = (options) => {
		run([{"function":"set","what":"@property.policy","value":"privacy_policy"},{"function":"popup","dialog":"terms"}], options)
	}
	const Session_menuItemsSessionLogoutOnClick = (options) => {
		run([{"function":"goto","view":"logout"}], options)
	}
	const Session_menuItemsSessionSettingsOnClick = (options) => {
		run([{"function":"goto","view":"account"}], options)
	}
	const Student_schools_menuItemsStudentProfile1658518549491OnClick = (options) => {
		run([{"function":"goto","view":"profile_a"}], options)
	}
	const Student_schools_menuItemsStudentFaq1658518549491OnClick = (options) => {
		run([{"function":"goto","view":"faq"}], options)
	}
	const Student_schools_menuItemsSchoolsApplicationsOnClick = (options) => {
		run([{"function":"goto","view":"applications"}], options)
	}
	const Student_schools_menuItemsStudentSchoolsOnClick = (options) => {
		run([{"function":"goto","view":"schools"}], options)
	}
	const Student_menuItemsStudentProfileOnClick = (options) => {
		run([{"function":"goto","view":"stu_step_1"}], options)
	}
	const Student_menuItemsStudentFaqOnClick = (options) => {
		run([{"function":"goto","view":"faq"}], options)
	}
	const Student_menuItemsStudentApplicationsOnClick = (options) => {
		run([{"function":"goto","view":"applications"}], options)
	}
	const Student_menuItemsStudentUniversitiesOnClick = (options) => {
		run([{"function":"goto","view":"stu_main"}], options)
	}
	const faqBlueYoutubeIconOnClick = (options) => {
		run([{"function":"browser","url":"https:\/\/www.youtube.com\/channel\/UCmfIE4aab0Qjz5xuqD3Pbfg","target":"_maeyoutube"}], options)
	}
	const faqBlueTwitterIconOnClick = (options) => {
		run([{"function":"browser","url":"https:\/\/twitter.com\/mae_sports","target":"_maetwitter"}], options)
	}
	const faqBlueFacebookIconOnClick = (options) => {
		run([{"function":"browser","url":"https:\/\/www.facebook.com\/mae.sports\/","target":"_maefacebook"}], options)
	}
	const faqBlueInstagramIconOnClick = (options) => {
		run([{"function":"browser","url":"https:\/\/www.instagram.com\/mae.sports\/","target":"_maeinstagram"}], options)
	}
	const faqBlueEmailOnClick = (options) => {
		run([{"function":"browser","url":"mailto:info@maeducationusa.com"}], options)
	}
	const faqBluePhoneOnClick = (options) => {
		run([{"function":"browser","url":"tel:+34646516514"}], options)
	}

  return (
    <>
      <Helmet>
				<title>{t('faq.title')}</title>
      </Helmet>
      <FullscreenWrapper>
          
			<Group
				id="faqBody"
				direction="vertical"
				className="w-100 min-h-100 fg-100 position-relative scrollable" >
				<Group
					id="faqHeader"
					direction="vertical"
					className="w-100 h-auto position-relative" >
					<Group
						id="faqBlueBar"
						direction="vertical"
						className="w-100 h-auto position-relative bg-primary" >
						<Group
							id="faqBlueContent"
							direction="auto"
							className="w-100 h-auto align-center position-relative" >
							<Group
								id="faqBluePhone"
								onClick={faqBluePhoneOnClick}
								direction="horizontal"
								className="w-auto h-auto align-center position-relative p-12px" >
								<Icon
									id="faqBluePhoneIcon"
									src="phone"
									color="var(--text-on-primary)"
									className="w-auto h-auto position-relative"
								/>
								<Text
									id="faqBluePhoneNumber"
									className="w-auto h-auto valign-center position-relative text-text-on-primary f-16px" >
									{t('faq.faqBluePhoneNumber')}
								</Text>
							</Group>
							<Group
								id="faqBlueEmail"
								onClick={faqBlueEmailOnClick}
								direction="horizontal"
								className="w-auto h-auto align-center position-relative p-12px" >
								<Icon
									id="faqBlueEmailIcon"
									src="email"
									color="var(--text-on-primary)"
									className="w-auto h-auto position-relative"
								/>
								<Text
									id="faqBlueEmailAddress"
									className="w-auto h-auto valign-center position-relative text-text-on-primary f-16px" >
									{t('faq.faqBlueEmailAddress')}
								</Text>
							</Group>
							<Group
								id="faqBlueSpacer"
								direction="vertical"
								className="w-fill h-auto position-relative" >
							</Group>
							<Group
								id="faqBlueRightSide"
								direction="vertical"
								className="w-auto h-auto align-center position-relative p-12px" >
								<Group
									id="faqBlueSSNN"
									direction="horizontal"
									className="w-auto h-auto align-end position-relative" >
									<Icon
										id="faqBlueInstagramIcon"
										onClick={faqBlueInstagramIconOnClick}
										src="instagram"
										color="var(--text-on-primary)"
										className="w-auto h-auto position-relative me-12px"
									/>
									<Icon
										id="faqBlueFacebookIcon"
										onClick={faqBlueFacebookIconOnClick}
										src="facebook"
										color="var(--text-on-primary)"
										className="w-auto h-auto position-relative me-12px"
									/>
									<Icon
										id="faqBlueTwitterIcon"
										onClick={faqBlueTwitterIconOnClick}
										src="twitter"
										color="var(--text-on-primary)"
										className="w-auto h-auto position-relative me-12px"
									/>
									<Icon
										id="faqBlueYoutubeIcon"
										onClick={faqBlueYoutubeIconOnClick}
										src="youtube"
										color="var(--text-on-primary)"
										className="w-auto h-auto position-relative"
									/>
								</Group>
							</Group>
						</Group>
					</Group>
					<Group
						id="faqWhiteHeader"
						direction="vertical"
						className="w-100 h-auto position-relative p-24px" >
						<Group
							id="faqHeaderContent"
							direction="auto"
							className="w-100 h-auto align-center valign-center position-relative" >
							<Group
								id="faqNavBar"
								direction="horizontal"
								className="min-w-320px w-fill h-auto align-start valign-center position-relative" >
								<Image
									id="faqLogo"
									src={logo_72}
									className="w-auto min-h-64px h-64px valign-center position-relative" />
							</Group>
							<Group
								id="faqNavBarContent"
								direction="auto"
								className="w-auto h-auto align-end valign-center position-relative" >
								<Nav
									id="faqMenu"
									if={{"what":"@cookie.type","is":"student"}}
									className="w-auto h-auto position-relative"
									direction="horizontal" >
									<Nav.Item title={t('student_menu.items.studentUniversities.title')} onClick={Student_menuItemsStudentUniversitiesOnClick} href="stu_main" />
									<Nav.Item title={t('student_menu.items.studentApplications.title')} onClick={Student_menuItemsStudentApplicationsOnClick} href="applications" />
									<Nav.Item title={t('student_menu.items.studentFaq.title')} onClick={Student_menuItemsStudentFaqOnClick} href="faq" />
									<Nav.Item title={t('student_menu.items.studentProfile.title')} onClick={Student_menuItemsStudentProfileOnClick} href="stu_step_1" />
								</Nav>
								<Nav
									id="faqMenu1659626287839"
									if={{"what":"@cookie.type","is":"student_school"}}
									className="w-auto h-auto position-relative"
									direction="horizontal" >
									<Nav.Item title={t('student_schools_menu.items.studentSchools.title')} onClick={Student_schools_menuItemsStudentSchoolsOnClick} href="schools" />
									<Nav.Item title={t('student_schools_menu.items.schoolsApplications.title')} onClick={Student_schools_menuItemsSchoolsApplicationsOnClick} href="applications" />
									<Nav.Item title={t('student_schools_menu.items.studentFaq1658518549491.title')} onClick={Student_schools_menuItemsStudentFaq1658518549491OnClick} href="faq" />
									<Nav.Item title={t('student_schools_menu.items.studentProfile1658518549491.title')} onClick={Student_schools_menuItemsStudentProfile1658518549491OnClick} href="profile_a" />
								</Nav>
								<Dropdown
									id="applicationsAccountMenu1659654476916"
									className="w-auto h-auto border-radius-32px valign-center position-relative"
									variant="primary"
									title={<>

									<Text
										id="applicationsAccountMenu1659654476916Title"
										className="w-auto h-auto position-relative ff-regular" >
										{t('faq.applicationsAccountMenu1659654476916Title')}
									</Text>
								</>} >
									<Dropdown.Item title={t('session_menu.items.sessionSettings.title')} onClick={Session_menuItemsSessionSettingsOnClick} href="account" />
									<Dropdown.Item title={t('session_menu.items.sessionLogout.title')} onClick={Session_menuItemsSessionLogoutOnClick} href="logout" />
								</Dropdown>
							</Group>
						</Group>
					</Group>
				</Group>
				<Group
					id="faqContent"
					direction="vertical"
					className="w-100 h-fill align-center position-relative scrollable" >
					<Group
						id="faqListHeader"
						direction="vertical"
						className="w-100 min-h-250px h-250px position-relative" >
						<Image
							id="faqHeaderImage"
							src={faq_header}
							className="w-100 min-h-240px h-240px position-relative cover" />
						<Group
							id="faqTitleGroup"
							direction="auto"
							className="w-90 h-auto align-center valign-end position-absolute-bottom bg-lighten p-24px" >
							<Text
								id="faqTitle"
								tag="h2"
								className="min-w-300px w-fill h-auto position-relative text-primary ff-bold" >
								{t('faq.faqTitle')}
							</Text>
						</Group>
					</Group>
					<Accordion defaultActiveKey="0"
						id="faqCategories"
						src="@database.faqcat"
						header="@field.name"
						filter={{"what":"lang","is":"@cookie.lang"}}
						order={{"type":"asc"}}
						className="max-w-800px w-80 h-auto align-center position-relative my-52px" >
						<List
							id="faqList"
							src="@firebase.firestore.faq"
							filter={{"what":"active","is":true,"and":{"what":"lang","is":"@cookie.lang","and":{"what":"category","is":"@field.value"}}}}
							order={{"type":"asc","field":"position"}}
							className="w-88 h-auto align-center position-relative mt-48px" >
							<Group
								id="faqRow1658308907148"
								direction="vertical"
								className="w-100 h-auto position-relative mb-48px" >
								<Text
									id="faqQuestion1658308907148"
									src="question"
									className="w-100 h-auto position-relative text-primary ff-bold" >
									{t('faq.faqQuestion1658308907148')}
								</Text>
								<Text
									id="faqAnswer1658308907148"
									src="answer"
									className="w-100 h-auto position-relative text-primary-text ff-regular" >
									{t('faq.faqAnswer1658308907148')}
								</Text>
							</Group>
						</List>
					</Accordion>
					<Group
						id="footerSpacer1658182653757"
						direction="vertical"
						className="w-100 min-h-300px h-fill position-relative" >
					</Group>
					<Group
						id="faqFooter"
						direction="vertical"
						className="w-100 h-auto position-relative mt-48px" >
						<Group
							id="homeFooterBar1658182653757"
							direction="vertical"
							className="w-100 h-auto valign-end position-relative bg-footer_bg py-48px" >
							<Group
								id="homeFooterBarContent1658182653757"
								direction="auto"
								className="max-w-1200px w-100 h-auto align-center position-relative p-32px" >
								<Group
									id="homeFooterInfo1663113409244"
									direction="vertical"
									className="w-fill h-auto position-relative" >
									<Text
										id="homeFooterAbout1663113409244"
										tag="p"
										className="max-w-500px min-w-300px w-fill h-auto position-relative text-text-on-primary ff-medium" >
										{t('faq.homeFooterAbout1663113409244')}
									</Text>
									<Group
										id="homeFooterPolicy1663113409244"
										direction="auto"
										className="w-fill h-auto position-relative" >
										<Text
											id="homeFooterPrivacy1663113409244"
											onClick={homeFooterPrivacy1663113409244OnClick}
											tag="small"
											className="max-w-100 w-auto h-auto position-relative pe-8px text-gray_b ff-medium" >
											{t('faq.homeFooterPrivacy1663113409244')}
										</Text>
										<Text
											id="homeFooterPrivacySeparator1663113409244"
											tag="small"
											className="w-auto h-auto position-relative pe-8px text-gray_b ff-medium" >
											{t('faq.homeFooterPrivacySeparator1663113409244')}
										</Text>
										<Text
											id="homeFooterCookies1663113409244"
											onClick={homeFooterCookies1663113409244OnClick}
											tag="small"
											className="max-w-100 w-auto h-auto position-relative pe-8px text-gray_b ff-medium" >
											{t('faq.homeFooterCookies1663113409244')}
										</Text>
									</Group>
									<Dropdown
										id="faqLangDropdown"
										className="w-auto h-auto border-radius-40px valign-center position-relative bg-primary-dark mt-16px"
										variant="primary-dark"
										drop="down"
										align="end"
										title={<>

										<Image
											id="faqLangDropdownIcon"
											fallback={flag_es}
											src="@cookie.flag"
											className="w-24px h-auto position-relative" />
										<Text
											id="faqLangDropdownTitle"
											src="@cookie.langName"
											className="w-auto h-auto position-relative ff-regular" >
											{t('faq.faqLangDropdownTitle')}
										</Text>
									</>} >
										<Dropdown.Item title={t('lang_menu.items.optES.title')} onClick={Lang_menuItemsOptESOnClick} />
										<Dropdown.Item title={t('lang_menu.items.optEN.title')} onClick={Lang_menuItemsOptENOnClick} />
									</Dropdown>
								</Group>
								<Group
									id="homeFooterSpacer1658182653757"
									direction="vertical"
									className="w-fill h-auto position-relative" >
								</Group>
								<Group
									id="homeFooterContact1658182653757"
									direction="vertical"
									className="max-w-400px min-w-300px w-fill h-auto position-relative" >
									<Image
										id="homeFooterLogo1658182653757"
										src={logo_72_white}
										className="w-auto min-h-72px h-72px align-end position-relative" />
									<Text
										id="homeFooterCities1658182653757"
										tag="p"
										className="w-auto h-auto align-end position-relative text-text-on-primary ff-medium text-end" >
										{t('faq.homeFooterCities1658182653757')}
									</Text>
									<Text
										id="homeFooterTel1658182653757"
										onClick={homeFooterTel1658182653757OnClick}
										tag="p"
										className="w-auto h-auto align-end position-relative text-text-on-primary ff-medium text-end" >
										{t('faq.homeFooterTel1658182653757')}
									</Text>
									<Text
										id="homeFooterEmail1658182653757"
										onClick={homeFooterEmail1658182653757OnClick}
										tag="p"
										className="w-auto h-auto align-end position-relative text-text-on-primary ff-medium text-end" >
										{t('faq.homeFooterEmail1658182653757')}
									</Text>
									<Text
										id="homeFooterWeb1658182653757"
										onClick={homeFooterWeb1658182653757OnClick}
										tag="p"
										className="w-auto h-auto align-end position-relative text-text-on-primary ff-medium text-end" >
										{t('faq.homeFooterWeb1658182653757')}
									</Text>
									<Group
										id="homeBlueSSNN1658182653757"
										direction="horizontal"
										className="w-auto h-auto align-end position-relative" >
										<Icon
											id="homeBlueInstagramIcon1658182653757"
											onClick={homeBlueInstagramIcon1658182653757OnClick}
											src="instagram"
											color="var(--text-on-primary)"
											className="w-auto h-auto position-relative me-12px"
										/>
										<Icon
											id="homeBlueFacebookIcon1658182653757"
											onClick={homeBlueFacebookIcon1658182653757OnClick}
											src="facebook"
											color="var(--text-on-primary)"
											className="w-auto h-auto position-relative me-12px"
										/>
										<Icon
											id="homeBlueTwitterIcon1658182653757"
											onClick={homeBlueTwitterIcon1658182653757OnClick}
											src="twitter"
											color="var(--text-on-primary)"
											className="w-auto h-auto position-relative me-12px"
										/>
										<Icon
											id="homeBlueYoutubeIcon1658182653757"
											onClick={homeBlueYoutubeIcon1658182653757OnClick}
											src="youtube"
											color="var(--text-on-primary)"
											className="w-auto h-auto position-relative"
										/>
									</Group>
								</Group>
							</Group>
						</Group>
					</Group>
				</Group>
			</Group>
      </FullscreenWrapper>
    </>
  );
};
