import React from 'react';
import { useFunctions } from '../hooks';

// Custom imports:
import { Offcanvas, Group, Text, List, Input } from '../components';
import { useTranslation } from 'react-i18next';

export const Select_degree_1Dialog = () => {
    const { run } = useFunctions();
  // Custom exports:
	const { t } = useTranslation();

  // Custom functions:
	const buttonSave1647530115554OnClick = (options) => {
		run([{"function":"add","what":{"name":"@property.selectedDegree"},"to":"@firebase.firestore.(@property.route)","if":{"what":"@property.currentSchool","is":null}},{"function":"set","what":"@firebase.firestore.(@property.route)","value":"@property.selectedDegree","if":{"what":"@property.currentSchool","is_not":null}},{"function":"hide","dialog":"select_degree"}], options)
	}
	const degreeListName1647530115554OnClick = (options) => {
		run([{"function":"set","what":"@property.selectedDegree","value":"@element.selectDegreeList.value.name"}], options)
	}

  return (
    
    
			<Offcanvas id="select_degree_1" placement="end">
					<Offcanvas.Body>
						<Group
							id="component1647530115554"
							direction="vertical"
							className="w-100 min-h-100 fg-100 position-relative" >
							<Text
								id="selectDegreeTitle1647530115554"
								className="w-100 h-auto position-relative text-primary ff-bold" >
								{t('select_degree_1.selectDegreeTitle1647530115554')}
							</Text>
							<Group
								id="degreeListGroup1647530115554"
								direction="vertical"
								className="w-100 h-fill position-relative p-24px scrollable" >
								<List
									id="selectDegreeList1647530115554"
									src="@firebase.firestore.data.values.degrees"
									className="w-100 h-auto position-relative" >
									<Text
										id="degreeListName1647530115554"
										onClick={degreeListName1647530115554OnClick}
										tag="small"
										src="name"
										className="w-100 h-auto position-relative p-8px ff-regular" >
										{t('select_degree_1.degreeListName1647530115554')}
									</Text>
								</List>
							</Group>
							<Input.Text
								id="customDegreeName1647530115554"
								name="text"
								src="@property.selectedDegree"
								className="w-auto h-auto position-relative mt-32px" />
							<Text
								id="buttonSave1647530115554"
								onClick={buttonSave1647530115554OnClick}
								className="w-100 h-auto border-radius-50px position-relative bg-accent p-12px mt-32px text-text-on-accent ff-bold text-center" >
								{t('select_degree_1.buttonSave1647530115554')}
							</Text>
						</Group>
					</Offcanvas.Body>
			</Offcanvas>
    
  );
};

export default Select_degree_1Dialog;
