import React from 'react';
import { Container } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import { useFunctions, useRefs } from '../hooks';
import { FullscreenWrapper } from '../wrappers';

// Custom imports:
import { Group, Icon, Image, Button, Text, List, Accordion, Video } from '../components';
import { useEffect } from 'react';
import placeholder_banner from '../assets/image/placeholder_banner.jpg';
import logo_placeholder from '../assets/image/logo_placeholder.png';
import placeholder_uni from '../assets/image/placeholder_uni.jpg';
import heart_on from '../assets/assets/heart_on.svg';
import heart_off from '../assets/assets/heart_off.svg';
import logo_72_white from '../assets/icon/logo_72_white.png';
import { useTranslation } from 'react-i18next';

export const Uni_infoView = () => {
  const { run } = useFunctions();
  // Custom exports:
	const { t } = useTranslation();

  // Custom functions:
	const uni_infoOnLoaded = (options) => {
		run([{"function":"set","what":"@cookie.lang","value":"es","if":{"what":"@app.language","starts_with":"es","else":[{"function":"set","what":"@cookie.lang","value":"en"}]}},{"function":"set","what":"@cookie.uniSat","value":"@database.university.sat"}], options)
	}
	useEffect(uni_infoOnLoaded, [])
	const homeBlueYoutubeIcon1647886872330OnClick = (options) => {
		run([{"function":"browser","url":"https:\/\/www.youtube.com\/channel\/UCmfIE4aab0Qjz5xuqD3Pbfg","target":"_maeyoutube"}], options)
	}
	const homeBlueTwitterIcon1647886872330OnClick = (options) => {
		run([{"function":"browser","url":"https:\/\/twitter.com\/mae_sports","target":"_maetwitter"}], options)
	}
	const homeBlueFacebookIcon1647886872330OnClick = (options) => {
		run([{"function":"browser","url":"https:\/\/www.facebook.com\/mae.sports\/","target":"_maefacebook"}], options)
	}
	const homeBlueInstagramIcon1647886872330OnClick = (options) => {
		run([{"function":"browser","url":"https:\/\/www.instagram.com\/mae.sports\/","target":"_maeinstagram"}], options)
	}
	const homeFooterWeb1647537545384OnClick = (options) => {
		run([{"function":"browser","url":"https:\/\/www.maeducation.net","target":"_self"}], options)
	}
	const homeFooterEmail1647537545384OnClick = (options) => {
		run([{"function":"browser","url":"mailto:info@maeducation.net"}], options)
	}
	const homeFooterTel1647537545384OnClick = (options) => {
		run([{"function":"browser","url":"tel:+34646516514"}], options)
	}
	const homeFooterCookies1663113925193OnClick = (options) => {
		run([{"function":"set","what":"@property.policy","value":"cookies_policy"},{"function":"popup","dialog":"terms"}], options)
	}
	const homeFooterPrivacy1663113925193OnClick = (options) => {
		run([{"function":"set","what":"@property.policy","value":"privacy_policy"},{"function":"popup","dialog":"terms"}], options)
	}
	const buttonFooterReviewApplicationOnClick = (options) => {
		run([{"function":"goto","view":"applications"}], options)
	}
	const buttonFooterApplyOnClick = (options) => {
		run([{"function":"set","what":"@cookie.schoolID","value":"none"},{"function":"set","what":"@cookie.degreeID","value":"none"},{"function":"popup","dialog":"stu_send_application"}], options)
	}
	const buttonFooterRemoveFavOnClick = (options) => {
		run([{"function":"delete","what":"@firebase.firestore.students.(@firebase.user.id).favs.(@cookie.universityID)"},{"function":"set","what":"@database.universities.(@cookie.universityID).fav","value":false},{"function":"set","what":"@property.isFavorite","value":false}], options)
	}
	const buttonFooterAddFavOnClick = (options) => {
		run([{"function":"set","what":"@firebase.firestore.students.(@firebase.user.id).favs.(@cookie.universityID).isFav","value":true},{"function":"set","what":"@property.isFavorite","value":true},{"function":"set","what":"@database.universities.(@cookie.universityID).fav","value":true}], options)
	}
	const rowDescriptionFeat7_valueOnClick = (options) => {
		run([{"function":"browser","url":"@database.university.website","target":"_outmae"}], options)
	}
	const buttonApplyOnClick = (options) => {
		run([{"function":"set","what":"@cookie.schoolID","value":"none"},{"function":"set","what":"@cookie.degreeID","value":"none"},{"function":"popup","dialog":"stu_send_application"}], options)
	}
	const actionBarHeartOn1647555353940OnClick = (options) => {
		run([{"function":"delete","what":"@firebase.firestore.students.(@firebase.user.id).favs.(@cookie.universityID)"},{"function":"set","what":"@database.universities.(@cookie.universityID).fav","value":false},{"function":"set","what":"@property.isFavorite","value":false}], options)
	}
	const actionBarHeartOff1647555353940OnClick = (options) => {
		run([{"function":"set","what":"@firebase.firestore.students.(@firebase.user.id).favs.(@cookie.universityID).isFav","value":true},{"function":"set","what":"@property.isFavorite","value":true},{"function":"set","what":"@database.universities.(@cookie.universityID).fav","value":true}], options)
	}
	const uniWrapperLogo1647555353940OnClick = (options) => {
		run([{"function":"back"}], options)
	}
	const uniWrapperBackIcon1647555353940OnClick = (options) => {
		run([{"function":"back"}], options)
	}

  return (
    <>
      <Helmet>
				<title>{t('uni_info.title')}</title>
      </Helmet>
      <FullscreenWrapper>
          
			<Group
				id="uniWrapperBody1647555353940"
				direction="horizontal"
				className="w-100 h-auto position-relative bg-primary p-20px" >
				<Icon
					id="uniWrapperBackIcon1647555353940"
					onClick={uniWrapperBackIcon1647555353940OnClick}
					src="arrow-left"
					color="var(--text-on-primary)"
					className="w-auto h-auto valign-center position-relative"
				/>
				<Image
					id="uniWrapperLogo1647555353940"
					onClick={uniWrapperLogo1647555353940OnClick}
					src={logo_72_white}
					className="w-72px h-auto position-relative" />
				<Group
					id="uniWrapperSpacer1647555353940"
					direction="vertical"
					className="w-fill h-auto position-relative" >
				</Group>
				<Image
					id="actionBarHeartOff1647555353940"
					if={{"what":"@property.isFavorite","is_not":true}}
					onClick={actionBarHeartOff1647555353940OnClick}
					src={heart_off}
					className="w-32px h-auto valign-center position-relative" />
				<Image
					id="actionBarHeartOn1647555353940"
					if={{"what":"@property.isFavorite","is":true}}
					onClick={actionBarHeartOn1647555353940OnClick}
					src={heart_on}
					className="w-32px h-auto position-relative" />
				<Button
					id="buttonApply"
					if={{"what":"@firebase.firestore.students.(@cookie.userID).applications.(@cookie.universityID).active","is_not":true}}
					onClick={buttonApplyOnClick}
					variant="primary"
					className="w-auto h-auto border-radius-50px valign-center position-relative bg-accent ms-16px" >
					{t('uni_info.buttonApply')}
				</Button>
			</Group>
			<Group
				id="bodyUniInfo"
				direction="vertical"
				className="w-100 h-fill position-relative scrollable" >
				<Group
					id="uniInfoBanner"
					direction="vertical"
					className="w-100 min-h-800px h-800px position-relative" >
					<Group
						id="bannerGroup"
						direction="vertical"
						className="w-100 min-h-500px h-500px position-relative" >
						<Image
							id="bannerImageUniversity"
							fallback={placeholder_uni}
							src="@firebase.firestore.schools.(@cookie.universityID).photo_school"
							className="w-100 min-h-500px h-500px position-relative cover" />
					</Group>
					<Group
						id="logoPositioning"
						direction="horizontal"
						className="max-w-90 w-100 h-auto align-center position-relative" >
						<Group
							id="uniInfoHeadLogo1646175495386"
							direction="vertical"
							className="min-w-300px w-fill min-h-300px h-300px position-relative bg-white p-25px" >
							<Image
								id="uniInfoHeadLogoImg1646175495386"
								fallback={logo_placeholder}
								src="@firebase.storage.schools/(@cookie.universityID)/logo.png"
								className="w-auto min-h-250px h-250px align-center position-relative" />
						</Group>
						<Group
							id="uniInfoHead"
							direction="vertical"
							className="max-w-1200px w-fill min-h-300px h-300px align-center position-relative p-32px" >
							<Group
								id="uniInfoHeadHighlightsContainer1647908910952"
								direction="vertical"
								className="w-100 min-h-100 fg-100 align-center position-relative" >
								<Group
									id="uniInfoHeadHighlightsText1647908910952"
									direction="vertical"
									className="w-auto h-auto align-center valign-center position-absolute" >
									<Text
										id="uniInfoHeadhlightCity1647908910952"
										tag="h3"
										src="@firebase.firestore.schools.(@cookie.universityID).city"
										className="w-auto h-auto position-relative text-primary ff-black" >
										{t('uni_info.uniInfoHeadhlightCity1647908910952')}
									</Text>
									<Group
										id="uniInfoHeadlightStudentsGroup1647908910952"
										direction="horizontal"
										className="w-auto h-auto position-relative mt-16px" >
										<Text
											id="uniInfoHeadlightStudentsAmount1647908910952"
											format={{"type":"amount","thousands":true}}
											tag="b"
											src="@firebase.firestore.schools.(@cookie.universityID).students"
											className="w-auto h-auto position-relative ff-regular" >
											{t('uni_info.uniInfoHeadlightStudentsAmount1647908910952')}
										</Text>
										<Text
											id="uniInfoHeadlightStudentsDesc1647908910952"
											tag="b"
											className="w-auto h-auto position-relative ps-8px ff-regular" >
											{t('uni_info.uniInfoHeadlightStudentsDesc1647908910952')}
										</Text>
									</Group>
									<Group
										id="uniInfoHeadlightRankingGroup1647908910952"
										direction="horizontal"
										className="w-auto h-auto position-relative mt-16px" >
										<Text
											id="uniInfoHeadlightRankingAmount1647908910952"
											tag="b"
											src="@firebase.firestore.schools.(@cookie.universityID).ranking"
											className="w-auto h-auto position-relative ff-regular" >
											{t('uni_info.uniInfoHeadlightRankingAmount1647908910952')}
										</Text>
									</Group>
									<Group
										id="uniInfoHeadlightTuitionGroup1647908910952"
										direction="horizontal"
										className="w-auto h-auto position-relative mt-16px" >
										<Text
											id="uniInfoHeadlightTuitionFrom1647908910952"
											tag="b"
											className="w-auto h-auto position-relative ff-regular" >
											{t('uni_info.uniInfoHeadlightTuitionFrom1647908910952')}
										</Text>
										<Text
											id="uniInfoHeadlightTuitionAmount1647908910952"
											tag="b"
											src="@firebase.firestore.schools.(@cookie.universityID).tuition"
											className="w-auto h-auto position-relative ps-8px ff-regular" >
											{t('uni_info.uniInfoHeadlightTuitionAmount1647908910952')}
										</Text>
										<Text
											id="uniInfoHeadlightTuitionDesc1647908910952"
											tag="b"
											className="w-auto h-auto position-relative ps-8px ff-regular" >
											{t('uni_info.uniInfoHeadlightTuitionDesc1647908910952')}
										</Text>
									</Group>
								</Group>
							</Group>
						</Group>
					</Group>
				</Group>
				<Group
					id="uniInfoPromo"
					direction="vertical"
					className="w-100 h-auto position-relative bg-light" >
					<Text
						id="uniInfoPromoText"
						tag="h3"
						src="@firebase.firestore.schools.(@cookie.universityID).slogan_(@cookie.lang)"
						className="w-100 h-auto position-relative p-64px text-primary ff-slogan text-center" >
						{t('uni_info.uniInfoPromoText')}
					</Text>
				</Group>
				<Group
					id="uniInfoDescription"
					direction="horizontal"
					className="w-100 h-auto position-relative py-64px" >
					<Group
						id="uniInfoDescriptionFeatures"
						direction="vertical"
						className="max-w-800px min-w-340px w-fill h-auto position-relative p-48px" >
						<Group
							id="uniInfoBestOfTitleGroup1649240051887"
							direction="vertical"
							className="w-auto h-auto align-center position-relative" >
							<Text
								id="uniInfoDescriptionFeaturesTitle"
								tag="h3"
								className="w-auto h-auto position-relative text-primary ff-bold" >
								{t('uni_info.uniInfoDescriptionFeaturesTitle')}
							</Text>
							<Group
								id="rowDescriptionFeatures1"
								if={{"what":"@database.university.type","is_not":null}}
								direction="horizontal"
								className="w-100 h-auto position-relative mt-24px" >
								<Text
									id="rowAdmissionLabel_1649240051887"
									className="w-auto h-auto position-relative text-primary-text ff-regular" >
									{t('uni_info.rowAdmissionLabel_1649240051887')}
								</Text>
								<Text
									id="rowAdmissionValue_1649240051887"
									src="@database.config.en.(@database.university.type)"
									className="w-auto h-auto position-relative ms-12px text-primary-text ff-bold" >
									{t('uni_info.rowAdmissionValue_1649240051887')}
								</Text>
							</Group>
							<Group
								id="rowDescriptionFeatures2"
								if={{"what":"@database.university.religion","is_not":null}}
								direction="horizontal"
								className="w-100 h-auto position-relative mt-24px" >
								<Text
									id="rowDescriptionFeat2_label"
									className="w-auto h-auto position-relative text-primary-text ff-regular" >
									{t('uni_info.rowDescriptionFeat2_label')}
								</Text>
								<Text
									id="rowDescriptionFeat2_value"
									src="@database.university.religion"
									className="w-auto h-auto position-relative ms-12px text-primary-text ff-bold" >
									{t('uni_info.rowDescriptionFeat2_value')}
								</Text>
							</Group>
							<Group
								id="rowDescriptionFeatures3"
								if={{"what":"@database.university.airports","is_not":null}}
								direction="horizontal"
								className="w-100 h-auto position-relative mt-24px" >
								<Text
									id="rowDescriptionFeat3_label"
									className="w-auto h-auto position-relative text-primary-text ff-regular" >
									{t('uni_info.rowDescriptionFeat3_label')}
								</Text>
								<Text
									id="rowDescriptionFeat3_value"
									src="@database.university.airports"
									className="w-auto h-auto position-relative ms-12px text-primary-text ff-bold" >
									{t('uni_info.rowDescriptionFeat3_value')}
								</Text>
							</Group>
							<Group
								id="rowDescriptionFeatures4"
								if={{"what":"@database.university.city","is_not":null}}
								direction="horizontal"
								className="w-100 h-auto position-relative mt-24px" >
								<Text
									id="rowDescriptionFeat4_label"
									className="w-auto h-auto position-relative text-primary-text ff-regular" >
									{t('uni_info.rowDescriptionFeat4_label')}
								</Text>
								<Text
									id="rowDescriptionFeat4_value"
									src="@database.university.city"
									className="w-auto h-auto position-relative ms-12px text-primary-text ff-bold" >
									{t('uni_info.rowDescriptionFeat4_value')}
								</Text>
							</Group>
							<Group
								id="rowDescriptionFeatures5"
								if={{"what":"@database.university.facultyRatio","is_not":null}}
								direction="horizontal"
								className="w-100 h-auto position-relative mt-24px" >
								<Text
									id="rowDescriptionFeat5_label"
									className="w-auto h-auto position-relative text-primary-text ff-regular" >
									{t('uni_info.rowDescriptionFeat5_label')}
								</Text>
								<Text
									id="rowDescriptionFeat5_value"
									src="@database.university.facultyRatio"
									className="w-auto h-auto position-relative ms-12px text-primary-text ff-bold" >
									{t('uni_info.rowDescriptionFeat5_value')}
								</Text>
							</Group>
							<Group
								id="rowDescriptionFeatures6"
								if={{"what":"@database.university.students","is_not":null}}
								direction="horizontal"
								className="w-100 h-auto position-relative mt-24px" >
								<Text
									id="rowDescriptionFeat6_label"
									className="w-auto h-auto position-relative text-primary-text ff-regular" >
									{t('uni_info.rowDescriptionFeat6_label')}
								</Text>
								<Text
									id="rowDescriptionFeat6_value"
									src="@database.university.students"
									className="w-auto h-auto position-relative ms-12px text-primary-text ff-bold" >
									{t('uni_info.rowDescriptionFeat6_value')}
								</Text>
							</Group>
							<Group
								id="rowDescriptionFeatures7"
								if={{"what":"@database.university.website","is_not":null}}
								direction="horizontal"
								className="w-100 h-auto position-relative mt-24px" >
								<Text
									id="rowDescriptionFeat7_label"
									className="w-auto h-auto position-relative text-primary-text ff-regular" >
									{t('uni_info.rowDescriptionFeat7_label')}
								</Text>
								<Text
									id="rowDescriptionFeat7_value"
									onClick={rowDescriptionFeat7_valueOnClick}
									src="@database.university.website"
									className="w-fill h-auto position-relative ms-12px text-primary-text ff-bold" >
									{t('uni_info.rowDescriptionFeat7_value')}
								</Text>
							</Group>
						</Group>
					</Group>
					<Text
						id="uniInfoDescriptionText"
						tag="p"
						src="@firebase.firestore.schools.(@cookie.universityID).overview_(@cookie.lang)"
						className="min-w-300px w-fill h-auto valign-center position-relative p-64px text-primary ff-medium text-center" >
						{t('uni_info.uniInfoDescriptionText')}
					</Text>
				</Group>
				<Group
					id="uniInfoBestOf"
					direction="horizontal"
					className="w-100 h-auto align-center position-relative" >
					<Image
						id="uniInfoBestOfImage"
						fallback={placeholder_banner}
						src="@firebase.firestore.schools.(@cookie.universityID).photo_campus"
						className="w-100 h-fill position-absolute cover" />
					<Group
						id="component1649263731358"
						direction="vertical"
						className="w-fill h-auto position-relative" >
					</Group>
					<Group
						id="uniInfoBestOfContent"
						direction="vertical"
						className="max-w-800px w-auto h-auto align-end position-relative bg-darken p-48px" >
						<Group
							id="uniInfoBestOfTitleGroup"
							direction="vertical"
							className="w-auto h-auto position-relative" >
							<Text
								id="uniInfoBestOfName"
								tag="h3"
								className="w-auto h-auto position-relative text-text-on-primary ff-bold" >
								{t('uni_info.uniInfoBestOfName')}
							</Text>
							<Group
								id="rowAdmission_1"
								direction="horizontal"
								className="w-100 h-auto position-relative mt-24px" >
								<Text
									id="rowAdmissionLabel_1"
									className="w-auto h-auto position-relative text-text-on-primary ff-regular" >
									{t('uni_info.rowAdmissionLabel_1')}
								</Text>
								<Text
									id="rowAdmissionValue_1"
									format={{"type":"date","year":"numeric","month":"long","day":"numeric"}}
									src="@database.university.deadline"
									className="w-auto h-auto position-relative ms-12px text-text-on-primary ff-bold" >
									{t('')}
								</Text>
							</Group>
							<Group
								id="rowAdmission_2"
								direction="horizontal"
								className="w-100 h-auto position-relative mt-24px" >
								<Text
									id="rowAdmissionLabel_2"
									className="w-auto h-auto position-relative text-text-on-primary ff-regular" >
									{t('uni_info.rowAdmissionLabel_2')}
								</Text>
								<Text
									id="rowAdmissionValue_2b"
									if={{"what":"@cookie.uniSat","is_not":"yes"}}
									className="w-auto h-auto position-relative ms-12px text-text-on-primary ff-bold" >
									{t('uni_info.rowAdmissionValue_2b')}
								</Text>
								<Text
									id="rowAdmissionValue_2"
									if={{"what":"@cookie.uniSat","is":"yes"}}
									className="w-auto h-auto position-relative ms-12px text-text-on-primary ff-bold" >
									{t('uni_info.rowAdmissionValue_2')}
								</Text>
							</Group>
							<Group
								id="rowAdmission_3"
								if={{"what":"@cookie.uniSat","is":"yes","and":{"what":"@database.university.minSat","is_not":null}}}
								direction="horizontal"
								className="w-100 h-auto position-relative mt-24px" >
								<Text
									id="rowAdmissionLabel_3"
									className="w-auto h-auto position-relative text-text-on-primary ff-regular" >
									{t('uni_info.rowAdmissionLabel_3')}
								</Text>
								<Text
									id="rowAdmissionValue_3"
									src="@database.university.minSat"
									className="w-auto h-auto position-relative ms-12px text-text-on-primary ff-bold" >
									{t('uni_info.rowAdmissionValue_3')}
								</Text>
							</Group>
							<Group
								id="rowAdmission_4"
								direction="horizontal"
								className="w-100 h-auto position-relative mt-24px" >
								<Text
									id="rowAdmissionLabel_4"
									className="w-auto h-auto position-relative text-text-on-primary ff-regular" >
									{t('uni_info.rowAdmissionLabel_4')}
								</Text>
								<Group
									id="component1646328074233"
									direction="vertical"
									className="w-auto h-auto position-relative" >
									<Text
										id="rowAdmissionValue_1646328135359"
										if={{"what":"@database.university.minToefl","more_than":0}}
										className="w-auto h-auto position-relative ms-12px text-text-on-primary ff-bold" >
										{t('uni_info.rowAdmissionValue_1646328135359')}
									</Text>
									<Text
										id="rowAdmissionValue_1646328364985"
										if={{"what":"@database.university.minIelts","more_than":0}}
										className="w-auto h-auto position-relative ms-12px text-text-on-primary ff-bold" >
										{t('uni_info.rowAdmissionValue_1646328364985')}
									</Text>
									<Text
										id="rowAdmissionValue_1646328372228"
										if={{"what":"@database.university.minDuolingo","more_than":0}}
										className="w-auto h-auto position-relative ms-12px text-text-on-primary ff-bold" >
										{t('uni_info.rowAdmissionValue_1646328372228')}
									</Text>
									<Text
										id="rowAdmissionValue_EnglishComments"
										src="@database.university.englishComments"
										className="w-auto h-auto position-relative ms-12px text-text-on-primary ff-bold" >
										{t('uni_info.rowAdmissionValue_EnglishComments')}
									</Text>
								</Group>
							</Group>
							<Group
								id="rowAdmission_6"
								direction="horizontal"
								className="w-100 h-auto position-relative mt-24px" >
								<Text
									id="rowAdmissionLabel_6"
									className="w-auto h-auto position-relative text-text-on-primary ff-regular" >
									{t('uni_info.rowAdmissionLabel_6')}
								</Text>
								<Text
									id="rowAdmissionValue_6"
									src="@database.university.specialReq_(@cookie.lang)"
									className="w-fill h-auto position-relative ms-12px text-text-on-primary ff-bold" >
									{t('uni_info.rowAdmissionValue_6')}
								</Text>
							</Group>
						</Group>
						<List
							id="uniInfoBestOfList"
							src="@firebase.firestore.schools.(@cookie.universityID).best"
							className="w-100 h-auto position-relative" >
							<Group
								id="uniInfoBestList"
								direction="horizontal"
								className="w-100 h-auto position-relative mt-24px" >
								<Icon
									id="uniInfoBestListDot"
									src="circle-medium"
									color="var(--primary-text)"
									className="w-auto h-auto position-relative"
								/>
								<Text
									id="uniInfoBestListText"
									className="w-fill h-auto position-relative text-primary-text ff-regular" >
									{t('uni_info.uniInfoBestListText')}
								</Text>
							</Group>
						</List>
					</Group>
				</Group>
				<Group
					id="uniInfoDegrees"
					direction="vertical"
					className="w-100 h-auto align-center position-relative py-24px" >
					<Text
						id="uniInfoDegreesTitle"
						tag="h2"
						className="w-100 h-auto position-relative p-64px text-primary ff-bold text-center" >
						{t('uni_info.uniInfoDegreesTitle')}
					</Text>
					<Accordion defaultActiveKey="0"
						id="uniInfoDegreeAccordion"
						src="@firebase.firestore.schools.(@cookie.universityID).schools"
						header="@field.name"
						className="w-80 h-auto border-radius-15px align-center position-relative bg-gray" >
						<List
							id="uniInfoDegreeSchoolDegreeList1651259103123"
							direction="auto"
							src="@firebase.firestore.schools.(@cookie.universityID).schools.(@field.id).degrees"
							order={{"type":"asc","field":"name"}}
							columns={"auto"}
							className="w-100 h-auto position-relative p-16px mt-16px" >
							<Group
								id="uniInfoDegreeRow1651259103123"
								direction="horizontal"
								className="w-100 h-auto position-relative" >
								<Text
									id="component1651259103123"
									tag="small"
									src="name"
									className="max-w-100 w-auto h-auto border-radius-50px position-relative bg-gray_b px-8px py-4px me-4px mb-8px text-primary-text ff-regular" >
									{t('uni_info.component1651259103123')}
								</Text>
							</Group>
						</List>
					</Accordion>
					<Text
						id="uniDegreeComments"
						tag="small"
						src="@firebase.firestore.schools.(@cookie.universityID).degreeComments"
						className="w-auto h-auto align-center position-relative p-24px text-primary ff-black" >
						{t('uni_info.uniDegreeComments')}
					</Text>
				</Group>
				<Group
					id="uniInfoSports"
					direction="vertical"
					className="w-100 h-auto align-center position-relative mt-48px" >
					<Group
						id="uniInfoSportsHeader"
						direction="vertical"
						className="w-100 h-auto position-relative" >
						<Image
							id="uniInfoSportsImage"
							fallback={placeholder_banner}
							src="@firebase.firestore.schools.(@cookie.universityID).photo_sports"
							className="min-w-400px w-fill min-h-500px h-500px position-absolute cover" />
						<Group
							id="uniInfoSportsHeaderContentMargin"
							direction="horizontal"
							className="min-w-400px w-auto min-h-500px h-500px align-end position-relative bg-darken p-48px" >
							<Group
								id="uniInfoSportsHeaderContent"
								direction="vertical"
								className="w-auto h-auto align-center valign-center position-relative" >
								<Text
									id="uniInfoSportsTitle"
									tag="h2"
									className="w-auto h-auto align-start position-relative text-text-on-primary ff-bold" >
									{t('uni_info.uniInfoSportsTitle')}
								</Text>
								<Group
									id="rowSports_1"
									direction="horizontal"
									className="w-auto h-auto position-relative mt-24px" >
									<Text
										id="rowSportsLabel_1"
										className="w-auto h-auto position-relative text-text-on-primary ff-bold" >
										{t('uni_info.rowSportsLabel_1')}
									</Text>
								</Group>
								<Group
									id="rowSports_2"
									direction="horizontal"
									className="w-auto h-auto position-relative mt-24px" >
									<Text
										id="rowSportsLabel_2"
										className="w-auto h-auto position-relative text-text-on-primary ff-regular" >
										{t('uni_info.rowSportsLabel_2')}
									</Text>
									<List
										id="rowSportsValue_2"
										src="@firebase.firestore.schools.(@cookie.universityID).sports_aso"
										className="w-auto h-auto position-relative ms-12px" >
										<Text
											id="rowSportsList_2"
											src="name"
											className="w-auto h-auto position-relative text-text-on-primary ff-bold" >
											{t('uni_info.rowSportsList_2')}
										</Text>
									</List>
								</Group>
							</Group>
						</Group>
					</Group>
					<Group
						id="uniInfoSportsContent"
						direction="vertical"
						className="min-w-400px w-fill min-h-100 fg-100 position-relative p-48px" >
						<Group
							id="rowSportsList"
							direction="vertical"
							className="w-fill h-auto position-relative" >
							<Group
								id="rowSports_3"
								direction="auto"
								className="min-w-380px w-fill h-auto position-relative mt-24px" >
								<Text
									id="rowSportsLabel_3"
									className="max-w-300px min-w-250px w-fill h-auto position-relative ff-regular" >
									{t('uni_info.rowSportsLabel_3')}
								</Text>
								<List
									id="rowSportsValue_3"
									direction="auto"
									src="@firebase.firestore.schools.(@cookie.universityID).sports_var"
									order={{"type":"asc","field":"name"}}
									columns={"auto"}
									className="min-w-300px w-fill h-auto position-relative" >
									<Text
										id="rowSportsList_3"
										src="name"
										className="w-auto h-auto border-radius-50px position-relative bg-light px-8px py-4px me-8px ff-bold" >
										{t('uni_info.rowSportsList_3')}
									</Text>
								</List>
							</Group>
							<Group
								id="rowSports_1647552540038"
								direction="auto"
								className="min-w-380px w-fill h-auto position-relative mt-24px" >
								<Text
									id="rowSportsLabel_1647552540038"
									className="max-w-300px min-w-250px w-fill h-auto position-relative ff-regular" >
									{t('uni_info.rowSportsLabel_1647552540038')}
								</Text>
								<List
									id="rowSportsValue_1647552540038"
									direction="auto"
									src="@firebase.firestore.schools.(@cookie.universityID).sports_var_female"
									order={{"type":"asc","field":"name"}}
									columns={"auto"}
									className="min-w-300px w-fill h-auto position-relative" >
									<Text
										id="rowSportsList_1647552540038"
										src="name"
										className="w-auto h-auto border-radius-50px position-relative bg-light px-8px py-4px me-8px ff-bold" >
										{t('uni_info.rowSportsList_1647552540038')}
									</Text>
								</List>
							</Group>
							<Group
								id="rowSports_1647552549535"
								direction="auto"
								className="min-w-380px w-fill h-auto position-relative mt-24px" >
								<Text
									id="rowSportsLabel_1647552549535"
									className="max-w-300px min-w-250px w-fill h-auto position-relative ff-regular" >
									{t('uni_info.rowSportsLabel_1647552549535')}
								</Text>
								<List
									id="rowSportsValue_1647552549535"
									direction="auto"
									src="@firebase.firestore.schools.(@cookie.universityID).sports"
									order={{"type":"asc","field":"name"}}
									columns={"auto"}
									className="min-w-300px w-fill h-auto position-relative" >
									<Text
										id="rowSportsList_1647552549535"
										src="name"
										className="w-auto h-auto border-radius-50px position-relative bg-light px-8px py-4px me-8px ff-bold" >
										{t('uni_info.rowSportsList_1647552549535')}
									</Text>
								</List>
							</Group>
						</Group>
					</Group>
				</Group>
				<Group
					id="uniInfoHousing"
					direction="horizontal"
					className="w-100 h-auto align-center position-relative" >
					<Image
						id="uniInfoHousingImage"
						fallback={placeholder_banner}
						src="@firebase.firestore.schools.(@cookie.universityID).photo_housing"
						className="w-100 h-fill position-absolute cover" />
					<Group
						id="uniInfoHousingContent"
						direction="vertical"
						className="max-w-90 w-auto h-auto position-relative bg-darken p-48px" >
						<Group
							id="uniInfoHousingTitleGroup"
							direction="vertical"
							className="w-auto h-auto position-relative" >
							<Text
								id="uniInfoHousingTitle"
								tag="h3"
								className="w-auto h-auto position-relative text-text-on-primary ff-bold" >
								{t('uni_info.uniInfoHousingTitle')}
							</Text>
						</Group>
						<List
							id="uniInfoHousingList"
							src="@firebase.firestore.schools.(@cookie.universityID).housing"
							order={{"type":"asc","field":"position"}}
							className="w-auto h-auto position-relative" >
							<Group
								id="uniInfoHousingListRow"
								direction="horizontal"
								className="w-auto h-auto position-relative mt-24px" >
								<Icon
									id="uniInfoHousingRowDot"
									src="circle-medium"
									color="var(--text-on-primary)"
									className="w-auto h-auto position-relative"
								/>
								<Text
									id="uniInfoHousingRowDescription"
									if={{"what":"@cookie.lang","is":"es"}}
									src="description_es"
									className="w-auto h-auto position-relative text-text-on-primary ff-regular" >
									{t('uni_info.uniInfoHousingRowDescription')}
								</Text>
								<Text
									id="uniInfoHousingRowDescriptionEN"
									if={{"what":"@cookie.lang","is_not":"es"}}
									src="description_en"
									className="w-auto h-auto position-relative text-text-on-primary ff-regular" >
									{t('uni_info.uniInfoHousingRowDescriptionEN')}
								</Text>
							</Group>
						</List>
					</Group>
				</Group>
				<Group
					id="uniInfoVideo"
					if={{"what":"@firebase.firestore.schools.(@cookie.universityID).video","is_not":null}}
					direction="vertical"
					className="w-100 h-auto align-center position-relative bg-gray_b" >
					<Group
						id="uniVideoContent"
						direction="vertical"
						className="max-w-800px w-auto h-auto align-center position-relative p-24px" >
						<Text
							id="uniVideoTitle"
							tag="h2"
							className="w-auto h-auto align-center position-relative text-primary ff-bold text-center" >
							{t('uni_info.uniVideoTitle')}
						</Text>
						<Video
							id="uniVideoPreview"
							src="@firebase.firestore.schools.(@cookie.universityID).video"
						autoPlay={true}
						controls
						loop
						muted
							className="max-w-1000px w-90 h-auto align-center position-relative bg-gray mt-24px" />
					</Group>
				</Group>
				<Group
					id="uniInfoCost"
					direction="vertical"
					className="w-100 h-auto align-center position-relative" >
					<Group
						id="uniCostContent"
						direction="vertical"
						className="max-w-800px w-auto h-auto align-center position-relative p-24px" >
						<Text
							id="uniCostTitle"
							tag="h2"
							className="w-auto h-auto align-center position-relative text-primary ff-bold text-center" >
							{t('uni_info.uniCostTitle')}
						</Text>
						<List
							id="uniInfoCostList"
							src="@firebase.firestore.schools.(@cookie.universityID).costs"
							className="w-100 h-auto position-relative mt-24px" >
							<Group
								id="uniInfoCostRow"
								direction="vertical"
								className="w-100 h-auto position-relative" >
								<Text
									id="uniInfoCostRowTitle"
									src="title"
									className="w-100 h-auto position-relative text-primary-text ff-bold text-center" >
									{t('uni_info.uniInfoCostRowTitle')}
								</Text>
								<Text
									id="uniInfoCostRowSubtitle"
									src="subtitle"
									className="w-100 h-auto position-relative mb-24px text-secondary-text ff-regular text-center" >
									{t('uni_info.uniInfoCostRowSubtitle')}
								</Text>
							</Group>
						</List>
					</Group>
				</Group>
				<Group
					id="uniInfoButtons"
					direction="vertical"
					className="w-320px h-auto align-center position-relative mt-48px" >
					<Text
						id="buttonFooterAddFav"
						if={{"what":"@property.isFavorite","is_not":true}}
						onClick={buttonFooterAddFavOnClick}
						className="w-100 h-auto border-radius-50px valign-center position-relative bg-primary p-8px mt-16px text-text-on-primary ff-medium text-center" >
						{t('uni_info.buttonFooterAddFav')}
					</Text>
					<Text
						id="buttonFooterRemoveFav"
						if={{"what":"@property.isFavorite","is":true}}
						onClick={buttonFooterRemoveFavOnClick}
						className="w-100 h-auto border-radius-50px valign-center position-relative bg-primary p-8px mt-16px text-text-on-primary ff-medium text-center" >
						{t('uni_info.buttonFooterRemoveFav')}
					</Text>
					<Text
						id="buttonFooterApply"
						if={{"what":"@firebase.firestore.students.(@cookie.userID).applications.(@cookie.universityID).active","is_not":true}}
						onClick={buttonFooterApplyOnClick}
						className="w-100 h-auto border-radius-50px valign-center position-relative bg-accent p-8px mt-16px text-text-on-primary ff-medium text-center" >
						{t('uni_info.buttonFooterApply')}
					</Text>
					<Text
						id="buttonFooterReviewApplication"
						if={{"what":"@firebase.firestore.students.(@cookie.userID).applications.(@cookie.universityID).active","is":true}}
						onClick={buttonFooterReviewApplicationOnClick}
						className="w-100 h-auto border-radius-50px valign-center position-relative bg-accent p-8px mt-16px text-text-on-primary text-center" >
						{t('uni_info.buttonFooterReviewApplication')}
					</Text>
				</Group>
				<Group
					id="uniInfoFooterSpacer"
					direction="vertical"
					className="w-100 min-h-50px h-50px position-relative" >
				</Group>
				<Group
					id="uniInfoFooter"
					direction="vertical"
					className="w-100 h-auto position-relative mt-48px" >
					<Group
						id="homeFooterBar1647537545384"
						direction="vertical"
						className="w-100 h-auto valign-end position-relative bg-footer_bg py-48px" >
						<Group
							id="homeFooterBarContent1647537545384"
							direction="auto"
							className="max-w-1200px w-100 h-auto align-center position-relative p-32px" >
							<Group
								id="homeFooterInfo1663113925193"
								direction="vertical"
								className="w-fill h-auto position-relative" >
								<Text
									id="homeFooterAbout1663113925193"
									tag="p"
									className="max-w-500px min-w-300px w-fill h-auto position-relative text-text-on-primary ff-medium" >
									{t('uni_info.homeFooterAbout1663113925193')}
								</Text>
								<Group
									id="homeFooterPolicy1663113925193"
									direction="auto"
									className="w-fill h-auto position-relative" >
									<Text
										id="homeFooterPrivacy1663113925193"
										onClick={homeFooterPrivacy1663113925193OnClick}
										tag="small"
										className="max-w-100 w-auto h-auto position-relative pe-8px text-gray_b ff-medium" >
										{t('uni_info.homeFooterPrivacy1663113925193')}
									</Text>
									<Text
										id="homeFooterPrivacySeparator1663113925193"
										tag="small"
										className="w-auto h-auto position-relative pe-8px text-gray_b ff-medium" >
										{t('uni_info.homeFooterPrivacySeparator1663113925193')}
									</Text>
									<Text
										id="homeFooterCookies1663113925193"
										onClick={homeFooterCookies1663113925193OnClick}
										tag="small"
										className="max-w-100 w-auto h-auto position-relative pe-8px text-gray_b ff-medium" >
										{t('uni_info.homeFooterCookies1663113925193')}
									</Text>
								</Group>
							</Group>
							<Group
								id="homeFooterSpacer1647537545384"
								direction="vertical"
								className="w-fill h-auto position-relative" >
							</Group>
							<Group
								id="homeFooterContact1647537545384"
								direction="vertical"
								className="max-w-400px min-w-300px w-fill h-auto position-relative" >
								<Image
									id="homeFooterLogo1647537545384"
									src={logo_72_white}
									className="w-auto min-h-72px h-72px align-end position-relative" />
								<Text
									id="homeFooterCities1647537545384"
									tag="p"
									className="w-auto h-auto align-end position-relative text-text-on-primary ff-medium text-end" >
									{t('uni_info.homeFooterCities1647537545384')}
								</Text>
								<Text
									id="homeFooterTel1647537545384"
									onClick={homeFooterTel1647537545384OnClick}
									tag="p"
									className="w-auto h-auto align-end position-relative text-text-on-primary ff-medium text-end" >
									{t('uni_info.homeFooterTel1647537545384')}
								</Text>
								<Text
									id="homeFooterEmail1647537545384"
									onClick={homeFooterEmail1647537545384OnClick}
									tag="p"
									className="w-auto h-auto align-end position-relative text-text-on-primary ff-medium text-end" >
									{t('uni_info.homeFooterEmail1647537545384')}
								</Text>
								<Text
									id="homeFooterWeb1647537545384"
									onClick={homeFooterWeb1647537545384OnClick}
									tag="p"
									className="w-auto h-auto align-end position-relative text-text-on-primary ff-medium text-end" >
									{t('uni_info.homeFooterWeb1647537545384')}
								</Text>
								<Group
									id="homeBlueSSNN1647886872330"
									direction="horizontal"
									className="w-auto h-auto align-end position-relative" >
									<Icon
										id="homeBlueInstagramIcon1647886872330"
										onClick={homeBlueInstagramIcon1647886872330OnClick}
										src="instagram"
										color="var(--text-on-primary)"
										className="w-auto h-auto position-relative me-12px"
									/>
									<Icon
										id="homeBlueFacebookIcon1647886872330"
										onClick={homeBlueFacebookIcon1647886872330OnClick}
										src="facebook"
										color="var(--text-on-primary)"
										className="w-auto h-auto position-relative me-12px"
									/>
									<Icon
										id="homeBlueTwitterIcon1647886872330"
										onClick={homeBlueTwitterIcon1647886872330OnClick}
										src="twitter"
										color="var(--text-on-primary)"
										className="w-auto h-auto position-relative me-12px"
									/>
									<Icon
										id="homeBlueYoutubeIcon1647886872330"
										onClick={homeBlueYoutubeIcon1647886872330OnClick}
										src="youtube"
										color="var(--text-on-primary)"
										className="w-auto h-auto position-relative"
									/>
								</Group>
							</Group>
						</Group>
					</Group>
				</Group>
			</Group>
      </FullscreenWrapper>
    </>
  );
};
