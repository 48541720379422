import React from 'react';
import { useDialogs } from '../hooks';
import { useFunctions } from '../hooks';

// Custom imports:
import { Modal, Text, Input, Button } from '../components';
import { useTranslation } from 'react-i18next';

const Admin_newschoolDialog = () => {
    const { run } = useFunctions();
    // Custom exports:
	const { t } = useTranslation();

    // Custom functions:
	const admin_newschool_buttons_positiveOnClick = (options) => {
		run([{"function":"add","what":{"name":"@property.universityName","city":"USA","active":false,"search_words":"@property.universityName","entity":"@property.entity"},"to":"@firebase.firestore.schools"},{"function":"clone","what":"@firebase.firestore.schools","into":"@database.(@property.database)","filter":{"what":"entity","is":"@property.entity"}},{"function":"hide","dialog":"admin_newschool"}], options)
	}

  return (
    
    
			<Modal id="admin_newschool">
					<Modal.Body>
						<Text
							id="component1645545728769"
							if={{"what":"@property.entity","is":"university"}}
							className="w-100 h-auto position-relative" >
							{t('admin_newschool.component1645545728769')}
						</Text>
						<Text
							id="component1658338010174"
							if={{"what":"@property.entity","is":"school"}}
							className="w-100 h-auto position-relative" >
							{t('admin_newschool.component1658338010174')}
						</Text>
						<Input.Text
							id="newSchoolName"
							name="text"
							src="@property.universityName"
							className="w-auto h-auto position-relative mt-24px" />
					</Modal.Body>
					<Modal.Footer>
						<Button
							id="admin_newschool_buttons_positive"
							onClick={admin_newschool_buttons_positiveOnClick}
							variant="primary"
							className="w-auto h-auto position-relative" >
							{t('admin_newschool.admin_newschool_buttons_positive')}
						</Button>
					</Modal.Footer>
			</Modal>
    
  );
};

export default Admin_newschoolDialog;
