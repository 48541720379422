import React from 'react';
import { useDialogs } from '../hooks';
import { useFunctions } from '../hooks';

// Custom imports:
import { Modal, Text, Input, Button } from '../components';
import { useTranslation } from 'react-i18next';

const Edit_dialogDialog = () => {
    const { run } = useFunctions();
    // Custom exports:
	const { t } = useTranslation();

    // Custom functions:
	const edit_dialog_buttons_positiveOnClick = (options) => {
		run([{"function":"set","what":"@firebase.firestore.(@property.route)","value":"@property.editValue"},{"function":"hide","dialog":"edit_dialog"}], options)
	}
	const edit_dialog_buttons_negativeOnClick = (options) => {
		run([{"function":"hide","dialog":"edit_dialog"}], options)
	}

  return (
    
    
			<Modal id="edit_dialog">
					<Modal.Body>
						<Text
							id="editDialogInstructions"
							className="w-100 h-auto position-relative" >
							{t('edit_dialog.editDialogInstructions')}
						</Text>
						<Input.Text
							id="editDialogInput"
							name="text"
							src="@property.editValue"
							className="w-auto h-auto position-relative" />
					</Modal.Body>
					<Modal.Footer>
						<Button
							id="edit_dialog_buttons_negative"
							onClick={edit_dialog_buttons_negativeOnClick}
							variant="transparent"
							className="w-auto h-auto position-relative" >
							{t('edit_dialog.edit_dialog_buttons_negative')}
						</Button>
						<Button
							id="edit_dialog_buttons_positive"
							onClick={edit_dialog_buttons_positiveOnClick}
							variant="primary"
							className="w-auto h-auto position-relative" >
							{t('edit_dialog.edit_dialog_buttons_positive')}
						</Button>
					</Modal.Footer>
			</Modal>
    
  );
};

export default Edit_dialogDialog;
