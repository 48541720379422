import React from 'react';
import { useDialogs } from '../hooks';
import { useFunctions } from '../hooks';

// Custom imports:
import { Modal, Text } from '../components';
import { useTranslation } from 'react-i18next';

const Msg_i18nDialog = () => {
    const { run } = useFunctions();
    // Custom exports:
	const { t } = useTranslation();

    // Custom functions:

  return (
    
    
			<Modal id="msg_i18n">
					<Modal.Body>
						<Text
							id="txtError1661678136305"
							src="@database.i18n.(@cookie.lang).(@property.msg)"
							className="w-100 h-auto position-relative" >
							{t('msg_i18n.txtError1661678136305')}
						</Text>
					</Modal.Body>
			</Modal>
    
  );
};

export default Msg_i18nDialog;
