import React from 'react';
import { useFunctions } from '../hooks';

// Custom imports:
import { Offcanvas, Group, Text, List } from '../components';
import { useTranslation } from 'react-i18next';

export const TermsDialog = () => {
    const { run } = useFunctions();
  // Custom exports:
	const { t } = useTranslation();

  // Custom functions:

  return (
    
    
			<Offcanvas id="terms" placement="end" scrollable>
					<Offcanvas.Body>
						<Group
							id="component1662827929484"
							direction="vertical"
							className="w-100 min-h-100 fg-100 position-relative scrollable" >
							<Text
								id="component1662828624513"
								tag="h3"
								className="w-100 h-auto position-relative pb-16px text-accent ff-bold" >
								{t('terms.component1662828624513')}
							</Text>
							<List
								id="component1662827948475"
								src="@firebase.firestore.data.documentation.(@property.policy)"
								className="w-100 h-auto position-relative" >
								<Text
									id="component1662828132605"
									src="title"
									className="w-100 h-auto position-relative text-primary ff-bold" >
									{t('terms.component1662828132605')}
								</Text>
								<Text
									id="component1662828203617"
									src="content"
									className="w-100 h-auto position-relative pb-32px text-primary-text ff-regular" >
									{t('terms.component1662828203617')}
								</Text>
							</List>
						</Group>
					</Offcanvas.Body>
			</Offcanvas>
    
  );
};

export default TermsDialog;
