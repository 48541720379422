import React from 'react';
import { Container } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import { useFunctions, useRefs } from '../hooks';
import { Private_adminWrapper } from '../wrappers';

// Custom imports:
import { Group, Image, Text, Input, Icon, List, Card } from '../components';
import { useEffect } from 'react';
import status_error from '../assets/image/status_error.svg';
import status_ok from '../assets/image/status_ok.svg';
import school from '../assets/image/school.jpg';
import { useTranslation } from 'react-i18next';

export const Admin_studentsView = () => {
  const { run } = useFunctions();
  // Custom exports:
	const { t } = useTranslation();

  // Custom functions:
	const admin_studentsOnLoaded = (options) => {
		run([{"function":"set","what":"@cookie.lang","value":"es","if":{"what":"@app.language","starts_with":"es","else":[{"function":"set","what":"@cookie.lang","value":"en"}]}},{"function":"set","what":"@cookie.filterType","value":"student","if":{"what":"@cookie.filterType","is":null}},{"function":"set","what":"@cookie.orderField","value":"lastname","if":{"what":"@cookie.orderField","is":null}},{"function":"set","what":"@property.orderDirection","value":"asc","if":{"what":"@cookie.orderField","is":"name","else":[{"function":"set","what":"@property.orderDirection","value":"desc"}]}},{"function":"goto","view":"home","if":{"what":"@cookie.type","is_not":"admin"}},{"function":"request","url":"https:\/\/europe-west1-education-74543.cloudfunctions.net\/getStudents","params":{"userID":"@cookie.userID"},"into":"@database.students","response":"json-api","method":"post","onerror":[{"function":"clone","into":"@database.students","what":"@firebase.firestore.students"}],"onsuccess":[{"function":"set","what":"@property.studentsLoaded","value":true}],"if":{"what":"@property.studentsLoaded","is_not":true}}], options)
	}
	useEffect(admin_studentsOnLoaded, [])
	const adminStudentRowOnClick = (options) => {
		run([{"function":"set","what":"@database.student","value":"@element.adminStudentList.value"},{"function":"set","what":"@cookie.student","value":"@element.adminStudentList.value"},{"function":"set","what":"@cookie.studentID","value":"@element.adminStudentList.selected"},{"function":"goto","view":"admin_student"}], options)
	}
	const filterOrderSelectOnChanged = (options) => {
		run([{"function":"set","what":"@property.orderDirection","value":"asc","if":{"what":"@cookie.orderField","is":"name","else":[{"function":"set","what":"@property.orderDirection","value":"desc"}]}}], options)
	}

  return (
    <>
      <Helmet>
				<title>{t('admin_students.title')}</title>
      </Helmet>
      <Private_adminWrapper>
          
			<Group
				id="adminStudentsHeader"
				direction="horizontal"
				className="w-100 min-h-100px h-100px border-top border-bottom border-start border-end position-relative" >
				<Image
					id="component1649634234427"
					src={school}
					className="w-100 min-h-100px h-100px position-absolute cover" />
				<Group
					id="adminStudentsToobar"
					direction="auto"
					className="w-100 h-auto align-center valign-center position-relative bg-lighten_med p-32px" >
					<Text
						id="adminStudentSearchBar"
						tag="h2"
						className="min-w-300px w-fill h-auto position-relative text-primary ff-bold" >
						{t('admin_students.adminStudentSearchBar')}
					</Text>
					<Group
						id="studentSearchGroup"
						direction="horizontal"
						className="max-w-250px min-w-150px w-fill h-auto valign-center position-relative py-4px" >
						<Input.Text
							id="studentSearchInput"
							name="text"
							src="@property.search"
							className="w-fill h-auto position-relative" />
						<Icon
							id="studentSearchIcon"
							src="magnify"
							color="var(--gray)"
							className="w-auto h-auto align-end valign-center position-absolute me-8px"
						/>
					</Group>
					<Group
						id="studentTypeFilterGroup"
						direction="horizontal"
						className="w-auto h-auto valign-center position-relative ps-16px py-4px" >
						<Input.Select
							id="filterTypeSelect"
							name="select"
							src="@cookie.filterType"
							className="w-auto h-auto position-relative" >
							<option value={"student"}>{t('admin_students.filterTypeSelect.opt0')}</option>
							<option value={"student_school"}>{t('admin_students.filterTypeSelect.opt1')}</option>
						</Input.Select>
					</Group>
					<Group
						id="studentOrderGroup"
						direction="horizontal"
						className="w-auto h-auto valign-center position-relative ps-16px py-4px" >
						<Input.Select
							id="filterOrderSelect"
							name="select"
							src="@cookie.orderField"
							onChange={filterOrderSelectOnChanged}
							className="w-auto h-auto position-relative" >
							<option value={"lastname"}>{t('admin_students.filterOrderSelect.opt0')}</option>
							<option value={"created_at"}>{t('admin_students.filterOrderSelect.opt1')}</option>
						</Input.Select>
					</Group>
				</Group>
			</Group>
			<Group
				id="adminStudentsMainBody"
				direction="vertical"
				className="w-100 h-fill position-relative p-32px scrollable" >
				<Group
					id="adminStudentsArea"
					direction="vertical"
					className="w-100 h-auto position-relative mt-24px" >
					<List
						id="adminStudentList"
						direction="auto"
						src="@database.students"
						filter={{"what":"type","and":{"what":"name","includes":"@property.search","or":{"what":"lastname","includes":"@property.search"}},"is":"@cookie.filterType"}}
						order={{"type":"@property.orderDirection","field":"@cookie.orderField"}}
						columns={"auto"}
						className="w-100 h-auto position-relative" >
						<Card
							id="adminStudentRow"
							onClick={adminStudentRowOnClick}
							className="min-w-350px w-fill h-auto border-radius-10px position-relative me-8px mb-8px" >
							<Card.Body>
									<Group
										id="adminStudentCardContent"
										direction="vertical"
										className="w-100 h-auto position-relative" >
										<Text
											id="adminStudentName"
											tag="small"
											className="w-100 h-auto position-relative ff-bold" >
											{t('admin_students.adminStudentName')}
										</Text>
										<Text
											id="adminStudentEmail"
											src="email"
											className="w-100 h-auto position-relative pt-8px f-12px ff-regular" >
											{t('admin_students.adminStudentEmail')}
										</Text>
										<Group
											id="adminStudentStatusGroup"
											direction="vertical"
											className="w-16px h-auto align-end position-absolute p-8px" >
											<Image
												id="adminStudentStatusOK"
												if={{"what":"@field.step1","is":true,"and":{"what":"@field.step2","is":true,"and":{"what":"@field.step3","is":true}}}}
												src={status_ok}
												className="w-16px h-auto position-relative" />
											<Image
												id="adminStudentStatusINCOMPLETE"
												if={{"what":"@field.step1","is_not":true,"or":{"what":"@field.step2","is_not":true,"or":{"what":"@field.step3","is_not":true}}}}
												src={status_error}
												className="w-16px h-auto position-relative" />
										</Group>
									</Group>
							</Card.Body>
						</Card>
					</List>
				</Group>
			</Group>
      </Private_adminWrapper>
    </>
  );
};
