import React from 'react';
import { useFunctions } from '../hooks';

// Custom imports:
import { Offcanvas, Group, Text, List, Input } from '../components';
import { useTranslation } from 'react-i18next';

export const Select_sportDialog = () => {
    const { run } = useFunctions();
  // Custom exports:
	const { t } = useTranslation();

  // Custom functions:
	const buttonSaveSportOnClick = (options) => {
		run([{"function":"add","what":{"name":"@property.selectedSport"},"to":"@firebase.firestore.(@property.route)"},{"function":"set","what":"@property.selectedSport","value":null},{"function":"hide","dialog":"select_sport"}], options)
	}
	const sportListNameOnClick = (options) => {
		run([{"function":"set","what":"@property.selectedSportTemp","value":"@element.selectSportList.value.name"},{"function":"add","what":{"name":"@property.selectedSportTemp"},"to":"@firebase.firestore.(@property.route)"},{"function":"hide","dialog":"select_sport","if":{"what":"@property.collection","is":"sport_associations_temp"}}], options)
	}

  return (
    
    
			<Offcanvas id="select_sport" placement="end">
					<Offcanvas.Body>
						<Group
							id="selectSportBody"
							direction="vertical"
							className="w-100 min-h-100 fg-100 position-relative" >
							<Text
								id="selectSportTitle"
								className="w-100 h-auto position-relative text-primary ff-bold" >
								{t('select_sport.selectSportTitle')}
							</Text>
							<Group
								id="sportListGroup"
								direction="vertical"
								className="w-100 h-fill position-relative p-24px scrollable" >
								<List
									id="selectSportList"
									src="@firebase.firestore.data.values.(@property.collection)"
									order={{"type":"asc","field":"name"}}
									className="w-100 h-auto position-relative" >
									<Text
										id="sportListName"
										onClick={sportListNameOnClick}
										tag="small"
										src="name"
										className="w-100 h-auto position-relative p-8px ff-regular" >
										{t('select_sport.sportListName')}
									</Text>
								</List>
							</Group>
							<Group
								id="customSportGroup"
								direction="horizontal"
								className="w-100 h-auto position-relative mt-32px" >
								<Input.Text
									id="customSportName"
									if={{"what":"@property.collection","is_not":"sport_associations"}}
									name="text"
									src="@property.selectedSport"
									className="w-fill h-auto position-relative" />
								<Text
									id="buttonSaveSport"
									if={{"what":"@property.collection","is_not":"sport_associations"}}
									onClick={buttonSaveSportOnClick}
									className="w-auto h-auto border-radius-50px valign-center position-relative bg-accent p-4px ms-8px text-text-on-accent ff-bold text-center" >
									{t('select_sport.buttonSaveSport')}
								</Text>
							</Group>
						</Group>
					</Offcanvas.Body>
			</Offcanvas>
    
  );
};

export default Select_sportDialog;
