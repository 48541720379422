import React from 'react';
import BootstrapAccordion from 'react-bootstrap/Accordion';
import Component from './Component';
import Text from './Text';
import { useSource } from '../hooks';

export const Accordion = ({
  children,
  filter,
  header,
  limit,
  order,
  src,
  ...props
}) => {
  const { id, itemData: data } = props;
  let { value: sourceData } = useSource(src, {
    data,
    filter,
    isCollection: true,
    order,
    limit,
  });
  if (sourceData) {
    children = Array.isArray(children) ? children : [children];
    sourceData = Object.entries(sourceData || []).map(([key, value]) => ({
      NO_ID_FIELD: key,
      ...value,
    }));
    const items = Array.isArray(sourceData) ? sourceData : [sourceData];
    children = items.map((item, i) => (
      <BootstrapAccordion.Item key={`AccordionItem-${id}-${i}`} eventKey={i}>
        <BootstrapAccordion.Header>
          <Text itemData={item} src={header}>
            {header}
          </Text>
        </BootstrapAccordion.Header>
        <BootstrapAccordion.Body>
          {children.map(({ props, ...child }) => {
            if (
              item.lastModified &&
              item.lastModifiedDate &&
              item.name &&
              item.size &&
              item.type
            ) {
              item = {
                lastModified: item.lastModified,
                lastModifiedDate: item.lastModifiedDate,
                name: item.name,
                size: item.size,
                type: item.type,
                url: URL.createObjectURL(item),
              };
            }
            const itemData = {
              ...(data || {}),
              ...item,
            };
            const itemDataSrc = props?.src ? item[props?.src] : item;
            return {
              ...child,
              props: { ...props, itemData, itemDataSrc, __listId__: id },
            };
          })}
        </BootstrapAccordion.Body>
      </BootstrapAccordion.Item>
    ));
  }

  return (
    <Component {...props} component={BootstrapAccordion}>
      {children}
    </Component>
  );
};

Accordion.Body = BootstrapAccordion.Body;
Accordion.Header = BootstrapAccordion.Header;
Accordion.Item = BootstrapAccordion.Item;

Accordion.displayName = 'Accordion';
export default Accordion;
