import React from 'react';
import { Container } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import { useFunctions, useRefs } from '../hooks';
import { FullscreenWrapper } from '../wrappers';

// Custom imports:
import { Text } from '../components';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

export const LogoutView = () => {
  const { run } = useFunctions();
  // Custom exports:
	const { t } = useTranslation();

  // Custom functions:
	const logoutOnLoaded = (options) => {
		run([{"function":"set","what":"@cookie.userID","value":null},{"function":"delete","what":"@cookie.stuAddress"},{"function":"delete","what":"@cookie.stuAs"},{"function":"delete","what":"@cookie.stuBirthdate"},{"function":"delete","what":"@cookie.stuDni"},{"function":"delete","what":"@cookie.stuDniPhoto"},{"function":"delete","what":"@cookie.stuAddress"},{"function":"logout","provider":"firebase"},{"function":"goto","view":"home"}], options)
	}
	useEffect(logoutOnLoaded, [])

  return (
    <>
      <Helmet>
				<title>{t('logout.title')}</title>
      </Helmet>
      <FullscreenWrapper>
          
			<Text
				id="component1646135539420"
				className="w-100 h-auto position-relative p-24px" >
				{t('logout.component1646135539420')}
			</Text>
      </FullscreenWrapper>
    </>
  );
};
