import React from 'react';
import { useDialogs } from '../hooks';
import { useFunctions } from '../hooks';

// Custom imports:
import { Modal, Group, Text, Input, Button } from '../components';
import { useTranslation } from 'react-i18next';

const Recover_passwordDialog = () => {
    const { run } = useFunctions();
    // Custom exports:
	const { t } = useTranslation();

    // Custom functions:
	const recover_password_buttons_positiveOnClick = (options) => {
		run([{"function":"login","provider":"firebase.email","forgot":true,"email":"@cookie.email","onvalid":[{"function":"popup","dialog":"recover_sent"}],"oninvalid":[{"function":"popup","dialog":"recover_error"}]},{"function":"hide","dialog":"recover_password"}], options)
	}

  return (
    
    
			<Modal id="recover_password">
					<Modal.Body>
						<Group
							id="forgotPassword"
							direction="vertical"
							className="w-100 h-auto align-center position-relative" >
							<Text
								id="recoverPasswordExplanation"
								className="w-100 h-auto position-relative text-primary ff-regular" >
								{t('recover_password.recoverPasswordExplanation')}
							</Text>
							<Input.Email
								id="recoverPasswordInput"
								name="text"
								src="@cookie.email"
								className="w-100 h-auto position-relative mt-16px" />
						</Group>
					</Modal.Body>
					<Modal.Footer>
						<Button
							id="recover_password_buttons_positive"
							onClick={recover_password_buttons_positiveOnClick}
							variant="primary"
							className="w-auto h-auto position-relative" >
							{t('recover_password.recover_password_buttons_positive')}
						</Button>
					</Modal.Footer>
			</Modal>
    
  );
};

export default Recover_passwordDialog;
