import React from 'react';
import { Container } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import { useFunctions, useRefs } from '../hooks';
import { FullscreenWrapper } from '../wrappers';

// Custom imports:
import { Group, Image, Text, Input, Button } from '../components';
import logo_72 from '../assets/icon/logo_72.png';
import { useTranslation } from 'react-i18next';

export const Stu_signup_1View = () => {
  const { run } = useFunctions();
  // Custom exports:
	const { t } = useTranslation();

  // Custom functions:
	const buttonSignupGoogle1647956751754OnClick = (options) => {
		run([{"function":"login","provider":"firebase.google","email":"@property.email","onvalid":[{"function":"set","what":"@cookie.type","value":"@firebase.firestore.users.(@firebase.user.id).type"},{"function":"set","what":"@cookie.email","value":"@firebase.user.email"},{"function":"set","what":"@cookie.userID","value":"@firebase.user.id"},{"function":"set","what":"@cookie.avatar","value":"https:\/\/s.gravatar.com\/avatar\/(@cookie.email.md5)?s=80"},{"function":"set","what":"@cookie.name","value":"@firebase.firestore.students.(@firebase.user.id).name"},{"function":"goto","view":"stu_main"}],"oninvalid":[{"function":"popup","dialog":"signup_wrong"}]}], options)
	}
	const buttonSignup1647956751754OnClick = (options) => {
		run([{"function":"signup","provider":"firebase","email":"@cookie.email","onvalid":[{"function":"add","what":{"type":"student"},"to":"@firebase.firestore.users","key":"@firebase.user.id"},{"function":"add","what":{"name":"@cookie.name","lastname":"@cookie.lastname","city":"@cookie.city","phone":"@cookie.phone","school":"@cookie.school","schoolyear":"@cookie.schoolyear","birthdate":"@cookie.birthdate","complete":false,"email":"@firebase.user.email"},"to":"@firebase.firestore.students","key":"@firebase.user.id"},{"function":"set","what":"@cookie.type","value":"student"},{"function":"set","what":"@cookie.email","value":"@firebase.user.email"},{"function":"set","what":"@cookie.userID","value":"@firebase.user.id"},{"function":"set","what":"@cookie.avatar","value":"https:\/\/s.gravatar.com\/avatar\/(@cookie.email.md5)?s=24"},{"function":"set","what":"@cookie.name","value":"@firebase.firestore.students.(@firebase.user.id).name"},{"function":"goto","view":"stu_step_1"}],"oninvalid":[{"function":"popup","dialog":"signup_wrong"}],"pass":"@property.password","if":{"what":"@cookie.terms","is":true,"else":[{"function":"popup","dialog":"terms_accept"}]}}], options)
	}
	const termsLabel1647956751754OnClick = (options) => {
		run([{"function":"popup","dialog":"terms"}], options)
	}

  return (
    <>
      <Helmet>
				<title>{t('stu_signup_1.title')}</title>
      </Helmet>
      <FullscreenWrapper>
          
			<Group
				id="signupBody1647956751754"
				direction="vertical"
				className="w-100 min-h-100 fg-100 position-relative p-24px" >
				<Group
					id="signupForm1647956751754"
					direction="vertical"
					className="w-auto h-auto align-center valign-center position-absolute p-16px" >
					<Image
						id="signupLogo1647956751753"
						src={logo_72}
						className="w-128px h-auto align-center position-relative" />
					<Text
						id="signupCatch1647956751753"
						tag="h3"
						className="w-600px h-auto position-relative p-24px text-primary ff-bold text-center" >
						{t('stu_signup_1.signupCatch1647956751753')}
					</Text>
					<Text
						id="signupInstructions1647956751753"
						tag="p"
						className="w-600px h-auto position-relative p-24px text-primary-text ff-regular text-center" >
						{t('stu_signup_1.signupInstructions1647956751753')}
					</Text>
					<Input.Text
						id="signupPassword1647956751754"
						name="text"
						src="@property.password"
					size="lg"
						className="max-w-600px w-80 h-auto position-relative p-8px" />
					<Group
						id="termsGroup1647956751754"
						direction="horizontal"
						className="w-auto h-auto align-center position-relative mt-24px" >
						<Input.Switch
							id="termsSwitch1647956751754"
							name="switch"
							src="@cookie.terms"
							className="w-auto h-auto position-relative" />
						<Text
							id="termsLabel1647956751754"
							onClick={termsLabel1647956751754OnClick}
							className="w-100 h-auto position-relative" >
							{t('stu_signup_1.termsLabel1647956751754')}
						</Text>
					</Group>
					<Button
						id="buttonSignup1647956751754"
						onClick={buttonSignup1647956751754OnClick}
						variant="primary"
						className="w-auto h-auto border-radius-100px align-center position-relative mt-24px" >
						{t('stu_signup_1.buttonSignup1647956751754')}
					</Button>
					<Text
						id="signupInstructionsGoogle1647956751754"
						tag="p"
						className="w-600px h-auto position-relative p-24px text-primary-text ff-regular text-center" >
						{t('stu_signup_1.signupInstructionsGoogle1647956751754')}
					</Text>
					<Button
						id="buttonSignupGoogle1647956751754"
						onClick={buttonSignupGoogle1647956751754OnClick}
						variant="black"
						className="w-auto h-auto border-radius-100px align-center position-relative" >
						{t('stu_signup_1.buttonSignupGoogle1647956751754')}
					</Button>
				</Group>
			</Group>
      </FullscreenWrapper>
    </>
  );
};
