import React from 'react';
import { Container } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import { useFunctions, useRefs } from '../hooks';
import { FullscreenWrapper } from '../wrappers';

// Custom imports:
import { Group, Icon, Text, Image, Nav, Dropdown, Input, List, Card, Button } from '../components';
import { useEffect } from 'react';
import logo_72_white from '../assets/icon/logo_72_white.png';
import placeholder_logo from '../assets/image/placeholder_logo.svg';
import collage from '../assets/image/collage.jpg';
import logo_72 from '../assets/icon/logo_72.png';
import { useTranslation } from 'react-i18next';

export const Stu_mainView = () => {
  const { run } = useFunctions();
  // Custom exports:
	const { t } = useTranslation();

  // Custom functions:
	const stu_mainOnLoaded = (options) => {
		run([{"function":"set","what":"@property.search","value":"@firebase.firestore.data.values.space"},{"function":"set","what":"@cookie.lang","value":"es","if":{"what":"@app.language","starts_with":"es","else":[{"function":"set","what":"@cookie.lang","value":"en"}]}},{"function":"set","what":"@cookie.order","value":"name","if":{"what":"@cookie.order","is":null}},{"function":"set","what":"@cookie.orderDirection","value":"desc","if":{"what":"@cookie.order","is":"students","else":[{"function":"set","what":"@cookie.orderDirection","value":"asc"}]}},{"function":"set","what":"@cookie.favField","value":"fav","if":{"what":"@cookie.onlyFavs","is":true,"else":[{"function":"set","what":"@cookie.favField","value":"active"}]}},{"function":"request","url":"https:\/\/europe-west1-education-74543.cloudfunctions.net\/getStudentUniversities","params":{"userID":"@cookie.userID","entity":"university"},"into":"@database.universities","response":"json-api","method":"post","onerror":[{"function":"clone","into":"@database.universities","what":"@firebase.firestore.schools","filter":{"what":"active","is":true},"order":{"type":"asc","field":"name"}}],"onsuccess":[{"function":"set","what":"@property.unisLoaded","value":true}],"if":{"what":"@property.unisLoaded","is_not":true}}], options)
	}
	useEffect(stu_mainOnLoaded, [])
	const homeBlueYoutubeIcon1647886827818OnClick = (options) => {
		run([{"function":"browser","url":"https:\/\/www.youtube.com\/channel\/UCmfIE4aab0Qjz5xuqD3Pbfg","target":"_maeyoutube"}], options)
	}
	const homeBlueTwitterIcon1647886827818OnClick = (options) => {
		run([{"function":"browser","url":"https:\/\/twitter.com\/mae_sports","target":"_maetwitter"}], options)
	}
	const homeBlueFacebookIcon1647886827818OnClick = (options) => {
		run([{"function":"browser","url":"https:\/\/www.facebook.com\/mae.sports\/","target":"_maefacebook"}], options)
	}
	const homeBlueInstagramIcon1647886827818OnClick = (options) => {
		run([{"function":"browser","url":"https:\/\/www.instagram.com\/mae.sports\/","target":"_maeinstagram"}], options)
	}
	const homeFooterWeb1647466015362OnClick = (options) => {
		run([{"function":"browser","url":"https:\/\/www.maeducation.net","target":"_self"}], options)
	}
	const homeFooterEmail1647466015362OnClick = (options) => {
		run([{"function":"browser","url":"mailto:info@maeducation.net"}], options)
	}
	const homeFooterTel1647466015362OnClick = (options) => {
		run([{"function":"browser","url":"tel:+34646516514"}], options)
	}
	const homeFooterCookies1663113214169OnClick = (options) => {
		run([{"function":"set","what":"@property.policy","value":"cookies_policy"},{"function":"popup","dialog":"terms"}], options)
	}
	const homeFooterPrivacy1663113214169OnClick = (options) => {
		run([{"function":"set","what":"@property.policy","value":"privacy_policy"},{"function":"popup","dialog":"terms"}], options)
	}
	const uniListButtonMoreOnClick = (options) => {
		run([{"function":"set","what":"@cookie.universityID","value":"@element.uniList.selected"},{"function":"set","what":"@database.university","value":"@element.uniList.value"},{"function":"set","what":"@property.isFavorite","value":"@database.universities.(@cookie.universityID).fav"},{"function":"goto","view":"uni_info"}], options)
	}
	const uniOrderSelectOnChanged = (options) => {
		run([{"function":"set","what":"@cookie.orderDirection","value":"desc","if":{"what":"@cookie.order","is":"students","else":[{"function":"set","what":"@cookie.orderDirection","value":"asc"}]}}], options)
	}
	const uniFavsSelectOnChanged = (options) => {
		run([{"function":"set","what":"@cookie.favField","value":"fav","if":{"what":"@cookie.onlyFavs","is":true,"else":[{"function":"set","what":"@cookie.favField","value":"active"}]}}], options)
	}
	const Session_menuItemsSessionLogoutOnClick = (options) => {
		run([{"function":"goto","view":"logout"}], options)
	}
	const Session_menuItemsSessionSettingsOnClick = (options) => {
		run([{"function":"goto","view":"account"}], options)
	}
	const Student_menuItemsStudentProfileOnClick = (options) => {
		run([{"function":"goto","view":"stu_step_1"}], options)
	}
	const Student_menuItemsStudentFaqOnClick = (options) => {
		run([{"function":"goto","view":"faq"}], options)
	}
	const Student_menuItemsStudentApplicationsOnClick = (options) => {
		run([{"function":"goto","view":"applications"}], options)
	}
	const Student_menuItemsStudentUniversitiesOnClick = (options) => {
		run([{"function":"goto","view":"stu_main"}], options)
	}
	const homeBlueYoutubeIcon1648653501181OnClick = (options) => {
		run([{"function":"browser","url":"https:\/\/www.youtube.com\/channel\/UCmfIE4aab0Qjz5xuqD3Pbfg","target":"_maeyoutube"}], options)
	}
	const homeBlueTwitterIcon1648653501181OnClick = (options) => {
		run([{"function":"browser","url":"https:\/\/twitter.com\/mae_sports","target":"_maetwitter"}], options)
	}
	const homeBlueFacebookIcon1648653501181OnClick = (options) => {
		run([{"function":"browser","url":"https:\/\/www.facebook.com\/mae.sports\/","target":"_maefacebook"}], options)
	}
	const homeBlueInstagramIcon1648653501181OnClick = (options) => {
		run([{"function":"browser","url":"https:\/\/www.instagram.com\/mae.sports\/","target":"_maeinstagram"}], options)
	}
	const homeBlueEmail1648653501181OnClick = (options) => {
		run([{"function":"browser","url":"mailto:info@maeducationusa.com"}], options)
	}
	const homeBluePhone1648653501181OnClick = (options) => {
		run([{"function":"browser","url":"tel:+34646516514"}], options)
	}

  return (
    <>
      <Helmet>
				<title>{t('stu_main.title')}</title>
      </Helmet>
      <FullscreenWrapper>
          
			<Group
				id="stuMainBody"
				direction="vertical"
				className="w-100 min-h-100 fg-100 position-relative scrollable" >
				<Group
					id="stuMainHeader"
					direction="vertical"
					className="w-100 h-auto position-relative" >
					<Group
						id="homeBlueBar1648653501181"
						direction="vertical"
						className="w-100 h-auto position-relative bg-primary" >
						<Group
							id="homeBlueContent1648653501181"
							direction="auto"
							className="w-100 h-auto align-center position-relative" >
							<Group
								id="homeBluePhone1648653501181"
								onClick={homeBluePhone1648653501181OnClick}
								direction="horizontal"
								className="w-auto h-auto align-center position-relative p-12px" >
								<Icon
									id="homeBluePhoneIcon1648653501181"
									src="phone"
									color="var(--text-on-primary)"
									className="w-auto h-auto position-relative"
								/>
								<Text
									id="homeBluePhoneNumber1648653501181"
									className="w-auto h-auto valign-center position-relative text-text-on-primary f-16px" >
									{t('stu_main.homeBluePhoneNumber1648653501181')}
								</Text>
							</Group>
							<Group
								id="homeBlueEmail1648653501181"
								onClick={homeBlueEmail1648653501181OnClick}
								direction="horizontal"
								className="w-auto h-auto align-center position-relative p-12px" >
								<Icon
									id="homeBlueEmailIcon1648653501181"
									src="email"
									color="var(--text-on-primary)"
									className="w-auto h-auto position-relative"
								/>
								<Text
									id="homeBlueEmailAddress1648653501181"
									className="w-auto h-auto valign-center position-relative text-text-on-primary f-16px" >
									{t('stu_main.homeBlueEmailAddress1648653501181')}
								</Text>
							</Group>
							<Group
								id="homeBlueSpacer1648653501181"
								direction="vertical"
								className="w-fill h-auto position-relative" >
							</Group>
							<Group
								id="homeBlueRightSide1648653501181"
								direction="vertical"
								className="w-auto h-auto align-center position-relative p-12px" >
								<Group
									id="homeBlueSSNN1648653501181"
									direction="horizontal"
									className="w-auto h-auto align-end position-relative" >
									<Icon
										id="homeBlueInstagramIcon1648653501181"
										onClick={homeBlueInstagramIcon1648653501181OnClick}
										src="instagram"
										color="var(--text-on-primary)"
										className="w-auto h-auto position-relative me-12px"
									/>
									<Icon
										id="homeBlueFacebookIcon1648653501181"
										onClick={homeBlueFacebookIcon1648653501181OnClick}
										src="facebook"
										color="var(--text-on-primary)"
										className="w-auto h-auto position-relative me-12px"
									/>
									<Icon
										id="homeBlueTwitterIcon1648653501181"
										onClick={homeBlueTwitterIcon1648653501181OnClick}
										src="twitter"
										color="var(--text-on-primary)"
										className="w-auto h-auto position-relative me-12px"
									/>
									<Icon
										id="homeBlueYoutubeIcon1648653501181"
										onClick={homeBlueYoutubeIcon1648653501181OnClick}
										src="youtube"
										color="var(--text-on-primary)"
										className="w-auto h-auto position-relative"
									/>
								</Group>
							</Group>
						</Group>
					</Group>
					<Group
						id="stuMainWhiteHeader"
						direction="vertical"
						className="w-100 h-auto position-relative p-24px" >
						<Group
							id="homeHeaderContent1648653501181"
							direction="auto"
							className="w-100 h-auto align-center valign-center position-relative" >
							<Group
								id="homeNavBar1648653501181"
								direction="horizontal"
								className="min-w-320px w-fill h-auto align-start valign-center position-relative" >
								<Image
									id="homeLogo1648653501181"
									src={logo_72}
									className="w-auto min-h-64px h-64px valign-center position-relative" />
							</Group>
							<Group
								id="homeNavBarContent1648653501181"
								direction="auto"
								className="w-auto h-auto align-end valign-center position-relative" >
								<Nav
									id="homeMenu1648653501181"
									className="w-auto h-auto position-relative"
									direction="horizontal" >
									<Nav.Item title={t('student_menu.items.studentUniversities.title')} onClick={Student_menuItemsStudentUniversitiesOnClick} href="stu_main" />
									<Nav.Item title={t('student_menu.items.studentApplications.title')} onClick={Student_menuItemsStudentApplicationsOnClick} href="applications" />
									<Nav.Item title={t('student_menu.items.studentFaq.title')} onClick={Student_menuItemsStudentFaqOnClick} href="faq" />
									<Nav.Item title={t('student_menu.items.studentProfile.title')} onClick={Student_menuItemsStudentProfileOnClick} href="stu_step_1" />
								</Nav>
								<Dropdown
									id="unisAccountMenu"
									className="w-auto h-auto border-radius-32px position-relative"
									variant="primary"
									title={<>

									<Text
										id="unisAccountMenuTitle"
										className="w-auto h-auto position-relative ff-regular" >
										{t('stu_main.unisAccountMenuTitle')}
									</Text>
								</>} >
									<Dropdown.Item title={t('session_menu.items.sessionSettings.title')} onClick={Session_menuItemsSessionSettingsOnClick} href="account" />
									<Dropdown.Item title={t('session_menu.items.sessionLogout.title')} onClick={Session_menuItemsSessionLogoutOnClick} href="logout" />
								</Dropdown>
							</Group>
						</Group>
					</Group>
				</Group>
				<Group
					id="stuMainContent"
					direction="vertical"
					className="w-100 h-fill align-center position-relative scrollable" >
					<Group
						id="uniListHeader"
						direction="vertical"
						className="w-100 min-h-250px h-250px position-relative" >
						<Image
							id="uniListHeaderImage"
							src={collage}
							className="w-100 min-h-240px h-240px position-relative cover" />
						<Group
							id="uniListSearchTitleGroup"
							direction="auto"
							className="w-90 h-auto align-center valign-end position-absolute-bottom bg-lighten p-24px" >
							<Text
								id="uniListTitle"
								tag="h2"
								className="min-w-300px w-fill h-auto position-relative text-primary ff-bold" >
								{t('stu_main.uniListTitle')}
							</Text>
							<Group
								id="uniFavsGroup"
								direction="vertical"
								className="min-w-100px w-auto h-auto valign-center position-relative pb-4px me-16px" >
								<Input.Checkbox
									id="uniFavsSelect"
									label={t('stu_main.uniFavsSelect.label')}
									name="select"
									src="@cookie.onlyFavs"
								onChange={uniFavsSelectOnChanged}
									className="w-auto h-auto position-relative" />
							</Group>
							<Group
								id="uniOrderGroup"
								direction="vertical"
								className="w-auto h-auto valign-center position-relative py-4px me-16px" >
								<Input.Select
									id="uniOrderSelect"
									name="select"
									src="@cookie.order"
									onChange={uniOrderSelectOnChanged}
									className="w-auto h-auto position-relative" >
									<option value={"name"}>{t('stu_main.uniOrderSelect.opt0')}</option>
									<option value={"tuition"}>{t('stu_main.uniOrderSelect.opt1')}</option>
									<option value={"rankingValue"}>{t('stu_main.uniOrderSelect.opt2')}</option>
									<option value={"students"}>{t('stu_main.uniOrderSelect.opt3')}</option>
								</Input.Select>
							</Group>
							<Group
								id="uniSearchGroup"
								direction="horizontal"
								className="max-w-250px min-w-150px w-fill h-auto valign-center position-relative py-4px" >
								<Input.Text
									id="uniSearchInput"
									name="text"
									src="@property.search"
									className="w-fill h-auto position-relative" />
								<Icon
									id="uniSearchIcon"
									src="magnify"
									color="var(--gray)"
									className="w-auto h-auto align-end valign-center position-absolute me-8px"
								/>
							</Group>
						</Group>
					</Group>
					<List
						id="uniList"
						src="@database.universities"
						filter={{"what":"@cookie.favField","is":true,"and":{"what":"search_words","includes":"@property.search.lower"}}}
						order={{"type":"@cookie.orderDirection","field":"@cookie.order"}}
						columns={"auto"}
						className="w-90 h-auto align-center position-relative mt-24px" >
						<Card
							id="uniCard"
							className="min-w-300px w-fill h-auto position-relative m-8px" >
							<Card.Body>
									<Group
										id="uniLogoGroup"
										direction="horizontal"
										className="w-100 h-auto border-gray border-bottom position-relative" >
										<Text
											id="uniListName"
											tag="b"
											src="name"
											className="w-fill h-auto valign-center position-relative ff-bold" >
											{t('stu_main.uniListName')}
										</Text>
										<Image
											id="uniListLogo"
											fallback={placeholder_logo}
											src="logo"
											className="w-auto min-h-52px h-52px align-center valign-center position-relative fill" />
									</Group>
									<Text
										id="uniCity"
										tag="b"
										src="city"
										className="w-100 h-auto position-relative mt-8px text-primary ff-regular" >
										{t('stu_main.uniCity')}
									</Text>
									<Group
										id="uniRankingGroup"
										direction="horizontal"
										className="w-100 h-auto position-relative mt-16px" >
										<Icon
											id="uniRankingIcon"
											src="podium"
											className="w-auto h-auto position-relative"
										/>
										<Text
											id="uniRankingScore"
											src="ranking"
											className="w-fill h-auto valign-center position-relative ms-12px ff-regular" >
											{t('stu_main.uniRankingScore')}
										</Text>
									</Group>
									<Group
										id="uniStudentsGroup"
										direction="horizontal"
										className="w-100 h-auto position-relative" >
										<Icon
											id="uniStudentsIcon"
											src="account-group"
											className="w-auto h-auto position-relative"
										/>
										<Text
											id="uniStudentsScore"
											format={{"type":"amount","thousands":true}}
											src="students"
											className="w-auto h-auto valign-center position-relative ms-12px me-4px ff-regular" >
											{t('stu_main.uniStudentsScore')}
										</Text>
										<Text
											id="uniStudentsScore1651227631366"
											className="w-fill h-auto valign-center position-relative ff-regular" >
											{t('stu_main.uniStudentsScore1651227631366')}
										</Text>
									</Group>
									<Group
										id="uniTuitionGroup"
										direction="horizontal"
										className="w-100 h-auto position-relative" >
										<Icon
											id="uniTuitionIcon"
											src="piggy-bank-outline"
											className="w-auto h-auto position-relative"
										/>
										<Text
											id="uniTuitionScore"
											format={{"type":"amount","thousands":true}}
											src="tuition"
											className="w-auto h-auto valign-center position-relative ms-12px ff-regular" >
											{t('stu_main.uniTuitionScore')}
										</Text>
										<Text
											id="uniTuitionCurrency"
											className="w-auto h-auto valign-center position-relative ms-4px ff-regular" >
											{t('stu_main.uniTuitionCurrency')}
										</Text>
									</Group>
									<Group
										id="uniListButtonsGroup"
										direction="horizontal"
										className="w-auto h-auto border-top align-end position-relative pt-8px mt-24px" >
										<Button
											id="uniListButtonMore"
											onClick={uniListButtonMoreOnClick}
											variant="primary"
											className="w-auto h-auto border-radius-50px align-end position-relative" >
											{t('stu_main.uniListButtonMore')}
										</Button>
									</Group>
							</Card.Body>
						</Card>
					</List>
					<Group
						id="footerSpacer"
						direction="vertical"
						className="w-100 min-h-300px h-fill position-relative" >
					</Group>
					<Group
						id="stuMainFooter"
						direction="vertical"
						className="w-100 h-auto position-relative mt-48px" >
						<Group
							id="homeFooterBar1647466015362"
							direction="vertical"
							className="w-100 h-auto valign-end position-relative bg-footer_bg py-48px" >
							<Group
								id="homeFooterBarContent1647466015362"
								direction="auto"
								className="max-w-1200px w-100 h-auto align-center position-relative p-32px" >
								<Group
									id="homeFooterInfo1663113214169"
									direction="vertical"
									className="w-fill h-auto position-relative" >
									<Text
										id="homeFooterAbout1663113214169"
										tag="p"
										className="max-w-500px min-w-300px w-fill h-auto position-relative text-text-on-primary ff-medium" >
										{t('stu_main.homeFooterAbout1663113214169')}
									</Text>
									<Group
										id="homeFooterPolicy1663113214169"
										direction="auto"
										className="w-fill h-auto position-relative" >
										<Text
											id="homeFooterPrivacy1663113214169"
											onClick={homeFooterPrivacy1663113214169OnClick}
											tag="small"
											className="max-w-100 w-auto h-auto position-relative pe-8px text-gray_b ff-medium" >
											{t('stu_main.homeFooterPrivacy1663113214169')}
										</Text>
										<Text
											id="homeFooterPrivacySeparator1663113214169"
											tag="small"
											className="w-auto h-auto position-relative pe-8px text-gray_b ff-medium" >
											{t('stu_main.homeFooterPrivacySeparator1663113214169')}
										</Text>
										<Text
											id="homeFooterCookies1663113214169"
											onClick={homeFooterCookies1663113214169OnClick}
											tag="small"
											className="max-w-100 w-auto h-auto position-relative pe-8px text-gray_b ff-medium" >
											{t('stu_main.homeFooterCookies1663113214169')}
										</Text>
									</Group>
								</Group>
								<Group
									id="homeFooterSpacer1647466015362"
									direction="vertical"
									className="w-fill h-auto position-relative" >
								</Group>
								<Group
									id="homeFooterContact1647466015362"
									direction="vertical"
									className="max-w-400px min-w-300px w-fill h-auto position-relative" >
									<Image
										id="homeFooterLogo1647466015362"
										src={logo_72_white}
										className="w-auto min-h-72px h-72px align-end position-relative" />
									<Text
										id="homeFooterCities1647466015362"
										tag="p"
										className="w-auto h-auto align-end position-relative text-text-on-primary ff-medium text-end" >
										{t('stu_main.homeFooterCities1647466015362')}
									</Text>
									<Text
										id="homeFooterTel1647466015362"
										onClick={homeFooterTel1647466015362OnClick}
										tag="p"
										className="w-auto h-auto align-end position-relative text-text-on-primary ff-medium text-end" >
										{t('stu_main.homeFooterTel1647466015362')}
									</Text>
									<Text
										id="homeFooterEmail1647466015362"
										onClick={homeFooterEmail1647466015362OnClick}
										tag="p"
										className="w-auto h-auto align-end position-relative text-text-on-primary ff-medium text-end" >
										{t('stu_main.homeFooterEmail1647466015362')}
									</Text>
									<Text
										id="homeFooterWeb1647466015362"
										onClick={homeFooterWeb1647466015362OnClick}
										tag="p"
										className="w-auto h-auto align-end position-relative text-text-on-primary ff-medium text-end" >
										{t('stu_main.homeFooterWeb1647466015362')}
									</Text>
									<Group
										id="homeBlueSSNN1647886827818"
										direction="horizontal"
										className="w-auto h-auto align-end position-relative" >
										<Icon
											id="homeBlueInstagramIcon1647886827818"
											onClick={homeBlueInstagramIcon1647886827818OnClick}
											src="instagram"
											color="var(--text-on-primary)"
											className="w-auto h-auto position-relative me-12px"
										/>
										<Icon
											id="homeBlueFacebookIcon1647886827818"
											onClick={homeBlueFacebookIcon1647886827818OnClick}
											src="facebook"
											color="var(--text-on-primary)"
											className="w-auto h-auto position-relative me-12px"
										/>
										<Icon
											id="homeBlueTwitterIcon1647886827818"
											onClick={homeBlueTwitterIcon1647886827818OnClick}
											src="twitter"
											color="var(--text-on-primary)"
											className="w-auto h-auto position-relative me-12px"
										/>
										<Icon
											id="homeBlueYoutubeIcon1647886827818"
											onClick={homeBlueYoutubeIcon1647886827818OnClick}
											src="youtube"
											color="var(--text-on-primary)"
											className="w-auto h-auto position-relative"
										/>
									</Group>
								</Group>
							</Group>
						</Group>
					</Group>
				</Group>
			</Group>
      </FullscreenWrapper>
    </>
  );
};
