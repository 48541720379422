import React from 'react';
import { Container } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import { useFunctions, useRefs } from '../hooks';
import { FullscreenWrapper } from '../wrappers';

// Custom imports:
import { Group, Icon, Text, Image, Nav, Dropdown, Input } from '../components';
import { useEffect } from 'react';
import logo_72_white from '../assets/icon/logo_72_white.png';
import passport from '../assets/image/passport.png';
import dni_back from '../assets/image/dni_back.png';
import dni_front from '../assets/image/dni_front.png';
import logo_72 from '../assets/icon/logo_72.png';
import { useTranslation } from 'react-i18next';

export const Stu_step_1View = () => {
  const { run } = useFunctions();
  // Custom exports:
	const { t } = useTranslation();

  // Custom functions:
	const stu_step_1OnLoaded = (options) => {
		run([{"function":"set","what":"@cookie.stuName","value":"@firebase.firestore.students.(@cookie.userID).name"},{"function":"set","what":"@cookie.stuLastname","value":"@firebase.firestore.students.(@cookie.userID).lastname"},{"function":"set","what":"@cookie.stuDni","value":"@firebase.firestore.students.(@cookie.userID).dni"},{"function":"set","what":"@cookie.stuDniFrontPhotoUrl","value":"@firebase.firestore.students.(@cookie.userID).dniFrontPhoto"},{"function":"set","what":"@cookie.stuDniBackPhotoUrl","value":"@firebase.firestore.students.(@cookie.userID).dniBackPhoto"},{"function":"set","what":"@cookie.stuPassport","value":"@firebase.firestore.students.(@cookie.userID).passport"},{"function":"set","what":"@cookie.stuPassportPhotoUrl","value":"@firebase.firestore.students.(@cookie.userID).passportPhoto"},{"function":"set","what":"@cookie.stuAddress","value":"@firebase.firestore.students.(@cookie.userID).address"},{"function":"set","what":"@cookie.stuBirthdate","value":"@firebase.firestore.students.(@cookie.userID).birthdate"},{"function":"set","what":"@cookie.stuNationality","value":"@firebase.firestore.students.(@cookie.userID).nationality"},{"function":"set","what":"@cookie.step1","value":"@firebase.firestore.students.(@cookie.userID).step1"}], options)
	}
	useEffect(stu_step_1OnLoaded, [])
	const homeBlueYoutubeIcon1647956632262OnClick = (options) => {
		run([{"function":"browser","url":"https:\/\/www.youtube.com\/channel\/UCmfIE4aab0Qjz5xuqD3Pbfg","target":"_maeyoutube"}], options)
	}
	const homeBlueTwitterIcon1647956632262OnClick = (options) => {
		run([{"function":"browser","url":"https:\/\/twitter.com\/mae_sports","target":"_maetwitter"}], options)
	}
	const homeBlueFacebookIcon1647956632262OnClick = (options) => {
		run([{"function":"browser","url":"https:\/\/www.facebook.com\/mae.sports\/","target":"_maefacebook"}], options)
	}
	const homeBlueInstagramIcon1647956632262OnClick = (options) => {
		run([{"function":"browser","url":"https:\/\/www.instagram.com\/mae.sports\/","target":"_maeinstagram"}], options)
	}
	const homeFooterWeb1647956632262OnClick = (options) => {
		run([{"function":"browser","url":"https:\/\/www.maeducation.net","target":"_self"}], options)
	}
	const homeFooterEmail1647956632262OnClick = (options) => {
		run([{"function":"browser","url":"mailto:info@maeducation.net"}], options)
	}
	const homeFooterTel1647956632262OnClick = (options) => {
		run([{"function":"browser","url":"tel:+34646516514"}], options)
	}
	const homeFooterCookies1663113563638OnClick = (options) => {
		run([{"function":"set","what":"@property.policy","value":"cookies_policy"},{"function":"popup","dialog":"terms"}], options)
	}
	const homeFooterPrivacy1663113563638OnClick = (options) => {
		run([{"function":"set","what":"@property.policy","value":"privacy_policy"},{"function":"popup","dialog":"terms"}], options)
	}
	const component1650458146679OnClick = (options) => {
		run([{"function":"set","what":"@cookie.stuPassportPhotoUrl","value":null},{"function":"delete","what":"@firebase.storage.students\/(@firebase.user.id)\/passport.jpg"},{"function":"set","what":"@property.step1","value":true,"if":{"what":"@cookie.stuName","is_not":null,"and":{"what":"@cookie.stuLastname","is_not":null,"and":{"what":"@cookie.stuBirthdate","is_not":null,"and":{"what":"@cookie.stuNationality","is_not":null,"and":{"what":"@cookie.stuAddress","is_not":null,"and":{"what":"@cookie.stuDni","is_not":null,"and":{"what":"@cookie.stuPassport","is_not":null,"and":{"what":"@cookie.stuDniFrontPhotoUrl","is_not":null,"and":{"what":"@cookie.stuDniBackPhotoUrl","is_not":null,"and":{"what":"@cookie.stuPassportPhotoUrl","is_not":null}}}}}}}}},"else":[{"function":"set","what":"@property.step1","value":false}]}},{"function":"set","what":"@firebase.firestore.students.(@cookie.userID).step1","value":"@property.step1","if":{"what":"@property.step1","is_not":"@cookie.step1"}},{"function":"set","what":"@cookie.step1","value":"@property.step1","if":{"what":"@property.step1","is_not":"@cookie.step1"}},{"function":"set","what":"@firebase.firestore.students.(@cookie.userID).passportPhoto","value":null}], options)
	}
	const passportUploadButtonOnClick = (options) => {
		run([{"function":"file","into":"@property.tempFile","multiple":false,"onsuccess":[{"function":"set","what":"@cookie.stuPassportPhoto","value":"@property.tempFile"},{"function":"set","what":"@firebase.storage.students\/(@firebase.user.id)\/passport.jpg","value":"@cookie.stuPassportPhoto"},{"function":"set","what":"@firebase.firestore.students.(@cookie.userID).passportPhoto","value":"@firebase.storage.students\/(@firebase.user.id)\/passport.jpg"},{"function":"set","what":"@cookie.stuPassportPhotoUrl","value":"@firebase.storage.students\/(@firebase.user.id)\/passport.jpg"},{"function":"set","what":"@property.step1","value":true,"if":{"what":"@cookie.stuName","is_not":null,"and":{"what":"@cookie.stuLastname","is_not":null,"and":{"what":"@cookie.stuBirthdate","is_not":null,"and":{"what":"@cookie.stuNationality","is_not":null,"and":{"what":"@cookie.stuAddress","is_not":null,"and":{"what":"@cookie.stuDni","is_not":null,"and":{"what":"@cookie.stuPassport","is_not":null,"and":{"what":"@cookie.stuDniFrontPhotoUrl","is_not":null,"and":{"what":"@cookie.stuDniBackPhotoUrl","is_not":null,"and":{"what":"@cookie.stuPassportPhotoUrl","is_not":null}}}}}}}}},"else":[{"function":"set","what":"@property.step1","value":false}]}},{"function":"set","what":"@firebase.firestore.students.(@cookie.userID).step1","value":"@property.step1","if":{"what":"@property.step1","is_not":"@cookie.step1"}},{"function":"set","what":"@cookie.step1","value":"@property.step1","if":{"what":"@property.step1","is_not":"@cookie.step1"}}]}], options)
	}
	const formPassportOnLostFocus = (options) => {
		run([{"function":"set","what":"@firebase.firestore.students.(@cookie.userID).passport","value":"@cookie.stuPassport"},{"function":"set","what":"@property.step1","value":true,"if":{"what":"@cookie.stuName","is_not":null,"and":{"what":"@cookie.stuLastname","is_not":null,"and":{"what":"@cookie.stuBirthdate","is_not":null,"and":{"what":"@cookie.stuNationality","is_not":null,"and":{"what":"@cookie.stuAddress","is_not":null,"and":{"what":"@cookie.stuDni","is_not":null,"and":{"what":"@cookie.stuPassport","is_not":null,"and":{"what":"@cookie.stuDniFrontPhotoUrl","is_not":null,"and":{"what":"@cookie.stuDniBackPhotoUrl","is_not":null,"and":{"what":"@cookie.stuPassportPhotoUrl","is_not":null}}}}}}}}},"else":[{"function":"set","what":"@property.step1","value":false}]}},{"function":"set","what":"@firebase.firestore.students.(@cookie.userID).step1","value":"@property.step1","if":{"what":"@property.step1","is_not":"@cookie.step1"}},{"function":"set","what":"@cookie.step1","value":"@property.step1","if":{"what":"@property.step1","is_not":"@cookie.step1"}}], options)
	}
	const component1650456982086OnClick = (options) => {
		run([{"function":"set","what":"@cookie.stuDniBackPhotoUrl","value":null},{"function":"delete","what":"@firebase.storage.students\/(@firebase.user.id)\/dni_back.jpg"},{"function":"set","what":"@property.step1","value":true,"if":{"what":"@cookie.stuName","is_not":null,"and":{"what":"@cookie.stuLastname","is_not":null,"and":{"what":"@cookie.stuBirthdate","is_not":null,"and":{"what":"@cookie.stuNationality","is_not":null,"and":{"what":"@cookie.stuAddress","is_not":null,"and":{"what":"@cookie.stuDni","is_not":null,"and":{"what":"@cookie.stuPassport","is_not":null,"and":{"what":"@cookie.stuDniFrontPhotoUrl","is_not":null,"and":{"what":"@cookie.stuDniBackPhotoUrl","is_not":null,"and":{"what":"@cookie.stuPassportPhotoUrl","is_not":null}}}}}}}}},"else":[{"function":"set","what":"@property.step1","value":false}]}},{"function":"set","what":"@firebase.firestore.students.(@cookie.userID).step1","value":"@property.step1","if":{"what":"@property.step1","is_not":"@cookie.step1"}},{"function":"set","what":"@cookie.step1","value":"@property.step1","if":{"what":"@property.step1","is_not":"@cookie.step1"}},{"function":"set","what":"@firebase.firestore.students.(@cookie.userID).dniBackPhoto","value":null}], options)
	}
	const dniBackUploadButtonOnClick = (options) => {
		run([{"function":"file","into":"@property.tempFile","multiple":false,"onsuccess":[{"function":"set","what":"@cookie.stuDniBackPhoto","value":"@property.tempFile"},{"function":"set","what":"@firebase.storage.students\/(@firebase.user.id)\/dni_back.jpg","value":"@cookie.stuDniBackPhoto"},{"function":"set","what":"@cookie.stuDniBackPhotoUrl","value":"@firebase.storage.students\/(@firebase.user.id)\/dni_back.jpg"},{"function":"set","what":"@firebase.firestore.students.(@cookie.userID).dniBackPhoto","value":"@firebase.storage.students\/(@firebase.user.id)\/dni_back.jpg"},{"function":"set","what":"@property.step1","value":true,"if":{"what":"@cookie.stuName","is_not":null,"and":{"what":"@cookie.stuLastname","is_not":null,"and":{"what":"@cookie.stuBirthdate","is_not":null,"and":{"what":"@cookie.stuNationality","is_not":null,"and":{"what":"@cookie.stuAddress","is_not":null,"and":{"what":"@cookie.stuDni","is_not":null,"and":{"what":"@cookie.stuPassport","is_not":null,"and":{"what":"@cookie.stuDniFrontPhotoUrl","is_not":null,"and":{"what":"@cookie.stuDniBackPhotoUrl","is_not":null,"and":{"what":"@cookie.stuPassportPhotoUrl","is_not":null}}}}}}}}},"else":[{"function":"set","what":"@property.step1","value":false}]}},{"function":"set","what":"@firebase.firestore.students.(@cookie.userID).step1","value":"@property.step1","if":{"what":"@property.step1","is_not":"@cookie.step1"}},{"function":"set","what":"@cookie.step1","value":"@property.step1","if":{"what":"@property.step1","is_not":"@cookie.step1"}}]}], options)
	}
	const component1650456796366OnClick = (options) => {
		run([{"function":"set","what":"@cookie.stuDniFrontPhotoUrl","value":null},{"function":"delete","what":"@firebase.storage.students\/(@firebase.user.id)\/dni_front.jpg"},{"function":"set","what":"@property.step1","value":true,"if":{"what":"@cookie.stuName","is_not":null,"and":{"what":"@cookie.stuLastname","is_not":null,"and":{"what":"@cookie.stuBirthdate","is_not":null,"and":{"what":"@cookie.stuNationality","is_not":null,"and":{"what":"@cookie.stuAddress","is_not":null,"and":{"what":"@cookie.stuDni","is_not":null,"and":{"what":"@cookie.stuPassport","is_not":null,"and":{"what":"@cookie.stuDniFrontPhotoUrl","is_not":null,"and":{"what":"@cookie.stuDniBackPhotoUrl","is_not":null,"and":{"what":"@cookie.stuPassportPhotoUrl","is_not":null}}}}}}}}},"else":[{"function":"set","what":"@property.step1","value":false}]}},{"function":"set","what":"@firebase.firestore.students.(@cookie.userID).step1","value":"@property.step1","if":{"what":"@property.step1","is_not":"@cookie.step1"}},{"function":"set","what":"@firebase.firestore.students.(@cookie.userID).dniFrontPhoto","value":null},{"function":"set","what":"@cookie.step1","value":"@property.step1","if":{"what":"@property.step1","is_not":"@cookie.step1"}}], options)
	}
	const component1649281824190OnClick = (options) => {
		run([{"function":"file","into":"@property.tempFile","multiple":false,"onsuccess":[{"function":"set","what":"@cookie.stuDniFrontPhotoUrl","value":"@property.tempFile"},{"function":"set","what":"@firebase.storage.students\/(@firebase.user.id)\/dni_front.jpg","value":"@cookie.stuDniFrontPhotoUrl"},{"function":"set","what":"@cookie.stuDniFrontPhotoUrl","value":"@firebase.storage.students\/(@firebase.user.id)\/dni_front.jpg"},{"function":"set","what":"@firebase.firestore.students.(@cookie.userID).dniFrontPhoto","value":"@firebase.storage.students\/(@firebase.user.id)\/dni_front.jpg"},{"function":"set","what":"@property.step1","value":true,"if":{"what":"@cookie.stuName","is_not":null,"and":{"what":"@cookie.stuLastname","is_not":null,"and":{"what":"@cookie.stuBirthdate","is_not":null,"and":{"what":"@cookie.stuNationality","is_not":null,"and":{"what":"@cookie.stuAddress","is_not":null,"and":{"what":"@cookie.stuDni","is_not":null,"and":{"what":"@cookie.stuPassport","is_not":null,"and":{"what":"@cookie.stuDniFrontPhotoUrl","is_not":null,"and":{"what":"@cookie.stuDniBackPhotoUrl","is_not":null,"and":{"what":"@cookie.stuPassportPhotoUrl","is_not":null}}}}}}}}},"else":[{"function":"set","what":"@property.step1","value":false}]}},{"function":"set","what":"@firebase.firestore.students.(@cookie.userID).step1","value":"@property.step1","if":{"what":"@property.step1","is_not":"@cookie.step1"}},{"function":"set","what":"@cookie.step1","value":"@property.step1","if":{"what":"@property.step1","is_not":"@cookie.step1"}}]}], options)
	}
	const formDniOnLostFocus = (options) => {
		run([{"function":"set","what":"@firebase.firestore.students.(@cookie.userID).dni","value":"@cookie.stuDni"},{"function":"set","what":"@property.step1","value":true,"if":{"what":"@cookie.stuName","is_not":null,"and":{"what":"@cookie.stuLastname","is_not":null,"and":{"what":"@cookie.stuBirthdate","is_not":null,"and":{"what":"@cookie.stuNationality","is_not":null,"and":{"what":"@cookie.stuAddress","is_not":null,"and":{"what":"@cookie.stuDni","is_not":null,"and":{"what":"@cookie.stuPassport","is_not":null,"and":{"what":"@cookie.stuDniFrontPhotoUrl","is_not":null,"and":{"what":"@cookie.stuDniBackPhotoUrl","is_not":null,"and":{"what":"@cookie.stuPassportPhotoUrl","is_not":null}}}}}}}}},"else":[{"function":"set","what":"@property.step1","value":false}]}},{"function":"set","what":"@firebase.firestore.students.(@cookie.userID).step1","value":"@property.step1","if":{"what":"@property.step1","is_not":"@cookie.step1"}},{"function":"set","what":"@cookie.step1","value":"@property.step1","if":{"what":"@property.step1","is_not":"@cookie.step1"}}], options)
	}
	const formAddressOnLostFocus = (options) => {
		run([{"function":"set","what":"@firebase.firestore.students.(@cookie.userID).address","value":"@cookie.stuAddress"},{"function":"set","what":"@property.step1","value":true,"if":{"what":"@cookie.stuName","is_not":null,"and":{"what":"@cookie.stuLastname","is_not":null,"and":{"what":"@cookie.stuBirthdate","is_not":null,"and":{"what":"@cookie.stuNationality","is_not":null,"and":{"what":"@cookie.stuAddress","is_not":null,"and":{"what":"@cookie.stuDni","is_not":null,"and":{"what":"@cookie.stuPassport","is_not":null,"and":{"what":"@cookie.stuDniFrontPhotoUrl","is_not":null,"and":{"what":"@cookie.stuDniBackPhotoUrl","is_not":null,"and":{"what":"@cookie.stuPassportPhotoUrl","is_not":null}}}}}}}}},"else":[{"function":"set","what":"@property.step1","value":false}]}},{"function":"set","what":"@firebase.firestore.students.(@cookie.userID).step1","value":"@property.step1","if":{"what":"@property.step1","is_not":"@cookie.step1"}},{"function":"set","what":"@cookie.step1","value":"@property.step1","if":{"what":"@property.step1","is_not":"@cookie.step1"}}], options)
	}
	const formNationalityOnLostFocus = (options) => {
		run([{"function":"set","what":"@firebase.firestore.students.(@cookie.userID).nationality","value":"@cookie.stuNationality"},{"function":"set","what":"@property.step1","value":true,"if":{"what":"@cookie.stuName","is_not":null,"and":{"what":"@cookie.stuLastname","is_not":null,"and":{"what":"@cookie.stuBirthdate","is_not":null,"and":{"what":"@cookie.stuNationality","is_not":null,"and":{"what":"@cookie.stuAddress","is_not":null,"and":{"what":"@cookie.stuDni","is_not":null,"and":{"what":"@cookie.stuPassport","is_not":null,"and":{"what":"@cookie.stuDniFrontPhotoUrl","is_not":null,"and":{"what":"@cookie.stuDniBackPhotoUrl","is_not":null,"and":{"what":"@cookie.stuPassportPhotoUrl","is_not":null}}}}}}}}},"else":[{"function":"set","what":"@property.step1","value":false}]}},{"function":"set","what":"@firebase.firestore.students.(@cookie.userID).step1","value":"@property.step1","if":{"what":"@property.step1","is_not":"@cookie.step1"}},{"function":"set","what":"@cookie.step1","value":"@property.step1","if":{"what":"@property.step1","is_not":"@cookie.step1"}}], options)
	}
	const formBirthdateOnChanged = (options) => {
		run([{"function":"set","what":"@firebase.firestore.students.(@cookie.userID).birthdate","value":"@cookie.stuBirthdate"},{"function":"set","what":"@property.step1","value":true,"if":{"what":"@cookie.stuName","is_not":null,"and":{"what":"@cookie.stuLastname","is_not":null,"and":{"what":"@cookie.stuBirthdate","is_not":null,"and":{"what":"@cookie.stuNationality","is_not":null,"and":{"what":"@cookie.stuAddress","is_not":null,"and":{"what":"@cookie.stuDni","is_not":null,"and":{"what":"@cookie.stuPassport","is_not":null,"and":{"what":"@cookie.stuDniFrontPhotoUrl","is_not":null,"and":{"what":"@cookie.stuDniBackPhotoUrl","is_not":null,"and":{"what":"@cookie.stuPassportPhotoUrl","is_not":null}}}}}}}}},"else":[{"function":"set","what":"@property.step1","value":false}]}},{"function":"set","what":"@firebase.firestore.students.(@cookie.userID).step1","value":"@property.step1","if":{"what":"@property.step1","is_not":"@cookie.step1"}},{"function":"set","what":"@cookie.step1","value":"@property.step1","if":{"what":"@property.step1","is_not":"@cookie.step1"}}], options)
	}
	const formSurnameOnLostFocus = (options) => {
		run([{"function":"set","what":"@firebase.firestore.students.(@cookie.userID).lastname","value":"@cookie.stuLastname"},{"function":"set","what":"@property.step1","value":true,"if":{"what":"@cookie.stuName","is_not":null,"and":{"what":"@cookie.stuLastname","is_not":null,"and":{"what":"@cookie.stuBirthdate","is_not":null,"and":{"what":"@cookie.stuNationality","is_not":null,"and":{"what":"@cookie.stuAddress","is_not":null,"and":{"what":"@cookie.stuDni","is_not":null,"and":{"what":"@cookie.stuPassport","is_not":null,"and":{"what":"@cookie.stuDniFrontPhotoUrl","is_not":null,"and":{"what":"@cookie.stuDniBackPhotoUrl","is_not":null,"and":{"what":"@cookie.stuPassportPhotoUrl","is_not":null}}}}}}}}},"else":[{"function":"set","what":"@property.step1","value":false}]}},{"function":"set","what":"@firebase.firestore.students.(@cookie.userID).step1","value":"@property.step1","if":{"what":"@property.step1","is_not":"@cookie.step1"}},{"function":"set","what":"@cookie.step1","value":"@property.step1","if":{"what":"@property.step1","is_not":"@cookie.step1"}}], options)
	}
	const formNameOnLostFocus = (options) => {
		run([{"function":"set","what":"@firebase.firestore.students.(@cookie.userID).name","value":"@cookie.stuName"},{"function":"set","what":"@property.step1","value":true,"if":{"what":"@cookie.stuName","is_not":null,"and":{"what":"@cookie.stuLastname","is_not":null,"and":{"what":"@cookie.stuBirthdate","is_not":null,"and":{"what":"@cookie.stuNationality","is_not":null,"and":{"what":"@cookie.stuAddress","is_not":null,"and":{"what":"@cookie.stuDni","is_not":null,"and":{"what":"@cookie.stuPassport","is_not":null,"and":{"what":"@cookie.stuDniFrontPhotoUrl","is_not":null,"and":{"what":"@cookie.stuDniBackPhotoUrl","is_not":null,"and":{"what":"@cookie.stuPassportPhotoUrl","is_not":null}}}}}}}}},"else":[{"function":"set","what":"@property.step1","value":false}]}},{"function":"set","what":"@firebase.firestore.students.(@cookie.userID).step1","value":"@property.step1","if":{"what":"@property.step1","is_not":"@cookie.step1"}},{"function":"set","what":"@cookie.step1","value":"@property.step1","if":{"what":"@property.step1","is_not":"@cookie.step1"}}], options)
	}
	const step1_but3GroupOnClick = (options) => {
		run([{"function":"goto","view":"stu_step_3"}], options)
	}
	const step1_but2GroupOnClick = (options) => {
		run([{"function":"goto","view":"stu_step_2"}], options)
	}
	const Session_menuItemsSessionLogoutOnClick = (options) => {
		run([{"function":"goto","view":"logout"}], options)
	}
	const Session_menuItemsSessionSettingsOnClick = (options) => {
		run([{"function":"goto","view":"account"}], options)
	}
	const Student_menuItemsStudentProfileOnClick = (options) => {
		run([{"function":"goto","view":"stu_step_1"}], options)
	}
	const Student_menuItemsStudentFaqOnClick = (options) => {
		run([{"function":"goto","view":"faq"}], options)
	}
	const Student_menuItemsStudentApplicationsOnClick = (options) => {
		run([{"function":"goto","view":"applications"}], options)
	}
	const Student_menuItemsStudentUniversitiesOnClick = (options) => {
		run([{"function":"goto","view":"stu_main"}], options)
	}
	const homeBlueYoutubeIcon1650454936791OnClick = (options) => {
		run([{"function":"browser","url":"https:\/\/www.youtube.com\/channel\/UCmfIE4aab0Qjz5xuqD3Pbfg","target":"_maeyoutube"}], options)
	}
	const homeBlueTwitterIcon1650454936791OnClick = (options) => {
		run([{"function":"browser","url":"https:\/\/twitter.com\/mae_sports","target":"_maetwitter"}], options)
	}
	const homeBlueFacebookIcon1650454936791OnClick = (options) => {
		run([{"function":"browser","url":"https:\/\/www.facebook.com\/mae.sports\/","target":"_maefacebook"}], options)
	}
	const homeBlueInstagramIcon1650454936791OnClick = (options) => {
		run([{"function":"browser","url":"https:\/\/www.instagram.com\/mae.sports\/","target":"_maeinstagram"}], options)
	}
	const homeBlueEmail1650454936791OnClick = (options) => {
		run([{"function":"browser","url":"mailto:info@maeducationusa.com"}], options)
	}
	const homeBluePhone1650454936791OnClick = (options) => {
		run([{"function":"browser","url":"tel:+34646516514"}], options)
	}

  return (
    <>
      <Helmet>
				<title>{t('stu_step_1.title')}</title>
      </Helmet>
      <FullscreenWrapper>
          
			<Group
				id="stuStep1Body"
				direction="vertical"
				className="w-100 min-h-100 fg-100 position-relative scrollable" >
				<Group
					id="stuMainHeader1650454936791"
					direction="vertical"
					className="w-100 h-auto position-relative" >
					<Group
						id="homeBlueBar1650454936791"
						direction="vertical"
						className="w-100 h-auto position-relative bg-primary" >
						<Group
							id="homeBlueContent1650454936791"
							direction="auto"
							className="w-100 h-auto align-center position-relative" >
							<Group
								id="homeBluePhone1650454936791"
								onClick={homeBluePhone1650454936791OnClick}
								direction="horizontal"
								className="w-auto h-auto align-center position-relative p-12px" >
								<Icon
									id="homeBluePhoneIcon1650454936791"
									src="phone"
									color="var(--text-on-primary)"
									className="w-auto h-auto position-relative"
								/>
								<Text
									id="homeBluePhoneNumber1650454936791"
									className="w-auto h-auto valign-center position-relative text-text-on-primary f-16px" >
									{t('stu_step_1.homeBluePhoneNumber1650454936791')}
								</Text>
							</Group>
							<Group
								id="homeBlueEmail1650454936791"
								onClick={homeBlueEmail1650454936791OnClick}
								direction="horizontal"
								className="w-auto h-auto align-center position-relative p-12px" >
								<Icon
									id="homeBlueEmailIcon1650454936791"
									src="email"
									color="var(--text-on-primary)"
									className="w-auto h-auto position-relative"
								/>
								<Text
									id="homeBlueEmailAddress1650454936791"
									className="w-auto h-auto valign-center position-relative text-text-on-primary f-16px" >
									{t('stu_step_1.homeBlueEmailAddress1650454936791')}
								</Text>
							</Group>
							<Group
								id="homeBlueSpacer1650454936791"
								direction="vertical"
								className="w-fill h-auto position-relative" >
							</Group>
							<Group
								id="homeBlueRightSide1650454936791"
								direction="vertical"
								className="w-auto h-auto align-center position-relative p-12px" >
								<Group
									id="homeBlueSSNN1650454936791"
									direction="horizontal"
									className="w-auto h-auto align-end position-relative" >
									<Icon
										id="homeBlueInstagramIcon1650454936791"
										onClick={homeBlueInstagramIcon1650454936791OnClick}
										src="instagram"
										color="var(--text-on-primary)"
										className="w-auto h-auto position-relative me-12px"
									/>
									<Icon
										id="homeBlueFacebookIcon1650454936791"
										onClick={homeBlueFacebookIcon1650454936791OnClick}
										src="facebook"
										color="var(--text-on-primary)"
										className="w-auto h-auto position-relative me-12px"
									/>
									<Icon
										id="homeBlueTwitterIcon1650454936791"
										onClick={homeBlueTwitterIcon1650454936791OnClick}
										src="twitter"
										color="var(--text-on-primary)"
										className="w-auto h-auto position-relative me-12px"
									/>
									<Icon
										id="homeBlueYoutubeIcon1650454936791"
										onClick={homeBlueYoutubeIcon1650454936791OnClick}
										src="youtube"
										color="var(--text-on-primary)"
										className="w-auto h-auto position-relative"
									/>
								</Group>
							</Group>
						</Group>
					</Group>
					<Group
						id="stuMainWhiteHeader1650454936791"
						direction="vertical"
						className="w-100 h-auto position-relative p-24px" >
						<Group
							id="homeHeaderContent1650454936791"
							direction="auto"
							className="w-100 h-auto align-center valign-center position-relative" >
							<Group
								id="homeNavBar1650454936791"
								direction="horizontal"
								className="min-w-320px w-fill h-auto align-start valign-center position-relative" >
								<Image
									id="homeLogo1650454936791"
									src={logo_72}
									className="w-auto min-h-64px h-64px valign-center position-relative" />
							</Group>
							<Group
								id="homeNavBarContent1650454936791"
								direction="auto"
								className="w-auto h-auto align-end valign-center position-relative" >
								<Nav
									id="homeMenu1650454936791"
									className="w-auto h-auto position-relative"
									direction="horizontal" >
									<Nav.Item title={t('student_menu.items.studentUniversities.title')} onClick={Student_menuItemsStudentUniversitiesOnClick} href="stu_main" />
									<Nav.Item title={t('student_menu.items.studentApplications.title')} onClick={Student_menuItemsStudentApplicationsOnClick} href="applications" />
									<Nav.Item title={t('student_menu.items.studentFaq.title')} onClick={Student_menuItemsStudentFaqOnClick} href="faq" />
									<Nav.Item title={t('student_menu.items.studentProfile.title')} onClick={Student_menuItemsStudentProfileOnClick} href="stu_step_1" />
								</Nav>
								<Dropdown
									id="stepAccountMenu1"
									className="w-auto h-auto border-radius-32px valign-center position-relative"
									variant="primary"
									title={<>

									<Text
										id="stepAccountMenu1Title"
										className="w-auto h-auto position-relative ff-regular" >
										{t('stu_step_1.stepAccountMenu1Title')}
									</Text>
								</>} >
									<Dropdown.Item title={t('session_menu.items.sessionSettings.title')} onClick={Session_menuItemsSessionSettingsOnClick} href="account" />
									<Dropdown.Item title={t('session_menu.items.sessionLogout.title')} onClick={Session_menuItemsSessionLogoutOnClick} href="logout" />
								</Dropdown>
							</Group>
						</Group>
					</Group>
				</Group>
				<Group
					id="tabsStep1"
					direction="horizontal"
					className="w-100 h-auto position-relative" >
					<Group
						id="tabsLeftStep1"
						direction="vertical"
						className="w-24px min-h-100 h-100 border-gray_b border-bottom position-relative" >
					</Group>
					<Group
						id="step1_but1Group"
						direction="horizontal"
						className="max-w-30 w-auto min-h-64px h-64px border-top border-start border-end border-radius-tl10px-tr10px align-center position-relative p-8px" >
						<Icon
							id="step1_but1_ko1651335143994"
							if={{"what":"@firebase.firestore.students.(@cookie.userID).step1","is_not":true}}
							src="alert"
							size={1}
							color="var(--accent)"
							className="w-auto h-auto align-end valign-start position-absolute p-4px ms-8px"
						/>
						<Text
							id="step1_but1_ok1651335143994"
							tag="b"
							className="max-w-100 w-auto h-auto border-radius-50px valign-center position-relative py-6px text-primary ff-bold text-center" >
							{t('stu_step_1.step1_but1_ok1651335143994')}
						</Text>
					</Group>
					<Group
						id="tabsMid1Step1"
						direction="vertical"
						className="w-16px min-h-100 h-100 border-gray_b border-bottom position-relative" >
					</Group>
					<Group
						id="step1_but2Group"
						onClick={step1_but2GroupOnClick}
						direction="horizontal"
						className="max-w-30 w-auto min-h-64px h-64px border-top border-bottom border-start border-end border-radius-tl10px-tr10px align-center position-relative bg-light p-8px" >
						<Icon
							id="step1_but2_ko1651335143994"
							if={{"what":"@firebase.firestore.students.(@cookie.userID).step2","is_not":true}}
							src="alert"
							size={1}
							color="var(--accent)"
							className="w-auto h-auto align-end valign-start position-absolute p-4px ms-8px"
						/>
						<Text
							id="step1_but2_ok1651335143994"
							tag="b"
							className="max-w-100 w-auto h-auto valign-center position-relative px-16px py-6px text-dark ff-bold text-center" >
							{t('stu_step_1.step1_but2_ok1651335143994')}
						</Text>
					</Group>
					<Group
						id="tabsMid2Step1"
						direction="vertical"
						className="w-16px min-h-100 h-100 border-gray_b border-bottom position-relative" >
					</Group>
					<Group
						id="step1_but3Group"
						onClick={step1_but3GroupOnClick}
						direction="horizontal"
						className="max-w-30 w-auto min-h-64px h-64px border-top border-bottom border-start border-end border-radius-tl10px-tr10px align-center position-relative bg-light p-8px" >
						<Icon
							id="step1_but3_ko1651335143994"
							if={{"what":"@firebase.firestore.students.(@cookie.userID).step3","is_not":true}}
							src="alert"
							size={1}
							color="var(--accent)"
							className="w-auto h-auto align-end valign-start position-absolute p-4px"
						/>
						<Text
							id="step1_but3_ok1651335143994"
							tag="b"
							className="max-w-100 w-auto h-auto valign-center position-relative px-16px py-6px text-dark ff-bold text-center" >
							{t('stu_step_1.step1_but3_ok1651335143994')}
						</Text>
					</Group>
					<Group
						id="tabsRightStep1"
						direction="vertical"
						className="w-fill min-h-100 h-100 border-gray_b border-bottom position-relative" >
					</Group>
				</Group>
				<Group
					id="stuStep1Content"
					direction="vertical"
					className="w-100 h-auto align-center position-relative p-24px" >
					<Text
						id="step1Title"
						tag="h1"
						className="w-100 h-auto position-relative pt-40px text-primary f-32px ff-bold" >
						{t('stu_step_1.step1Title')}
					</Text>
					<Group
						id="step1form"
						direction="vertical"
						className="w-100 h-auto position-relative" >
						<Group
							id="formStep1Data"
							direction="auto"
							className="max-w-1200px min-w-300px w-fill h-auto position-relative pb-64px" >
							<Group
								id="formNameMargin"
								direction="vertical"
								className="min-w-200px w-fill h-auto position-relative pe-32px mt-32px" >
								<Input.Text
									id="formName"
									label={t('stu_step_1.formName.label')}
									name="text"
									src="@cookie.stuName"
								onBlur={formNameOnLostFocus}
									className="w-100 h-auto position-relative" />
								<Icon
									id="iconMandatory_1"
									if={{"what":"@cookie.stuName","is":null,"or":{"what":"@cookie.stuName.length","is":0}}}
									src="asterisk"
									color="var(--danger)"
									className="w-auto h-auto align-end valign-end position-absolute-bottom me-6px mb-6px"
								/>
							</Group>
							<Group
								id="formSurnameMargin"
								direction="vertical"
								className="min-w-200px w-fill h-auto position-relative pe-32px mt-32px" >
								<Input.Text
									id="formSurname"
									label={t('stu_step_1.formSurname.label')}
									name="text"
									src="@cookie.stuLastname"
								onBlur={formSurnameOnLostFocus}
									className="w-100 h-auto position-relative" />
								<Icon
									id="iconMandatory_1649431301038"
									if={{"what":"@cookie.stuLastname","is":null,"or":{"what":"@cookie.stuLastname.length","is":0}}}
									src="asterisk"
									color="var(--danger)"
									className="w-auto h-auto align-end valign-end position-absolute-bottom me-6px mb-6px"
								/>
							</Group>
							<Group
								id="formBirthdateMargin"
								direction="vertical"
								className="max-w-300px min-w-200px w-fill h-auto position-relative pe-32px mt-32px" >
								<Input.Date
									id="formBirthdate"
									label={t('stu_step_1.formBirthdate.label')}
									name="text"
									src="@cookie.stuBirthdate"
								onChange={formBirthdateOnChanged}
									className="w-100 h-auto position-relative" />
								<Icon
									id="iconMandatory_1649431799843"
									if={{"what":"@cookie.stuBirthdate","is":null,"or":{"what":"@cookie.stuBirthdate.length","is":0}}}
									src="asterisk"
									color="var(--danger)"
									className="w-auto h-auto align-end valign-end position-absolute-bottom me-6px mb-26px"
								/>
							</Group>
							<Group
								id="formNationalityMargin"
								direction="vertical"
								className="min-w-200px w-fill h-auto position-relative pe-32px mt-32px" >
								<Input.Text
									id="formNationality"
									label={t('stu_step_1.formNationality.label')}
									name="text"
									src="@cookie.stuNationality"
								onBlur={formNationalityOnLostFocus}
									className="w-100 h-auto position-relative" />
								<Icon
									id="iconMandatory_1649431874230"
									if={{"what":"@cookie.stuNationality","is":null,"or":{"what":"@cookie.stuNationality.length","is":0}}}
									src="asterisk"
									color="var(--danger)"
									className="w-auto h-auto align-end valign-end position-absolute-bottom me-6px mb-26px"
								/>
							</Group>
							<Group
								id="formAddressLine"
								direction="vertical"
								className="w-100 h-auto position-relative" >
								<Group
									id="formAddressMargin"
									direction="vertical"
									className="max-w-500px w-fill h-auto position-relative pe-32px mt-32px" >
									<Input.TextArea
										id="formAddress"
										label={t('stu_step_1.formAddress.label')}
										name="text"
										src="@cookie.stuAddress"
									onBlur={formAddressOnLostFocus}
										className="w-100 h-auto position-relative" />
									<Icon
										id="iconMandatory_1649431714153"
										if={{"what":"@cookie.stuAddress","is":null,"or":{"what":"@cookie.stuAddress.length","is":0}}}
										src="asterisk"
										color="var(--danger)"
										className="w-auto h-auto align-end valign-end position-absolute-bottom me-6px mb-6px"
									/>
								</Group>
							</Group>
						</Group>
						<Group
							id="step1Documents"
							direction="auto"
							className="min-w-300px w-fill h-auto border-radius-10px position-relative bg-light px-16px pb-16px" >
							<Group
								id="formDniMargin"
								direction="vertical"
								className="max-w-500px w-100 h-auto position-relative pe-32px mt-32px" >
								<Group
									id="formDniInputGroup"
									direction="vertical"
									className="w-100 h-auto position-relative" >
									<Input.Text
										id="formDni"
										label={t('stu_step_1.formDni.label')}
										name="text"
										src="@cookie.stuDni"
									onBlur={formDniOnLostFocus}
										className="w-100 h-auto position-relative" />
									<Icon
										id="iconMandatory_1649431368026"
										if={{"what":"@cookie.stuDni","is":null,"or":{"what":"@cookie.stuDni.length","is":0}}}
										src="asterisk"
										color="var(--danger)"
										className="w-auto h-auto align-end valign-end position-absolute-bottom me-6px mb-6px"
									/>
								</Group>
								<Text
									id="formDniImg"
									className="w-100 h-auto position-relative mt-16px" >
									{t('stu_step_1.formDniImg')}
								</Text>
								<Group
									id="dniPickerGroup"
									direction="horizontal"
									className="w-100 h-auto position-relative mt-12px" >
									<Group
										id="dniFrontPickerGroup"
										direction="vertical"
										className="w-auto h-auto position-relative" >
										<Image
											id="dniFrontImg"
											fallback={dni_front}
											src="@cookie.stuDniFrontPhotoUrl"
											className="w-128px h-auto position-relative" />
										<Group
											id="component1650456356936"
											direction="horizontal"
											className="w-auto h-auto align-center position-relative" >
											<Icon
												id="component1649281824190"
												onClick={component1649281824190OnClick}
												src="upload"
												className="w-auto h-auto border-radius-50px align-center position-relative bg-light mt-12px"
											/>
											<Icon
												id="component1650456796366"
												onClick={component1650456796366OnClick}
												src="trash-can-outline"
												className="w-auto h-auto border-radius-50px align-center position-relative bg-light ms-12px mt-12px"
											/>
										</Group>
									</Group>
									<Group
										id="dniBackPickerGroup"
										direction="vertical"
										className="w-auto h-auto position-relative ms-16px" >
										<Image
											id="dniBackImg"
											fallback={dni_back}
											src="@cookie.stuDniBackPhotoUrl"
											className="w-128px h-auto position-relative" />
										<Group
											id="component1650456982086"
											direction="horizontal"
											className="w-auto h-auto align-center position-relative" >
											<Icon
												id="dniBackUploadButton"
												onClick={dniBackUploadButtonOnClick}
												src="upload"
												className="w-auto h-auto border-radius-50px align-center position-relative bg-light mt-12px"
											/>
											<Icon
												id="component1650456982086"
												onClick={component1650456982086OnClick}
												src="trash-can-outline"
												className="w-auto h-auto border-radius-50px align-center position-relative bg-light ms-12px mt-12px"
											/>
										</Group>
									</Group>
								</Group>
							</Group>
							<Group
								id="formPassportMargin"
								direction="vertical"
								className="max-w-500px w-100 h-auto position-relative pe-32px mt-32px" >
								<Group
									id="component1649431619929"
									direction="vertical"
									className="w-100 h-auto position-relative" >
									<Input.Text
										id="formPassport"
										label={t('stu_step_1.formPassport.label')}
										name="text"
										src="@cookie.stuPassport"
									onBlur={formPassportOnLostFocus}
										className="w-100 h-auto position-relative" />
									<Icon
										id="iconMandatory_1649431590678"
										if={{"what":"@cookie.stuPassport","is":null,"or":{"what":"@cookie.stuPassport.length","is":0}}}
										src="asterisk"
										color="var(--danger)"
										className="w-auto h-auto align-end valign-end position-absolute-bottom me-6px mb-6px"
									/>
								</Group>
								<Text
									id="formPassportImg"
									className="w-100 h-auto position-relative mt-16px" >
									{t('stu_step_1.formPassportImg')}
								</Text>
								<Group
									id="passportPickerGroup"
									direction="vertical"
									className="w-auto h-auto position-relative mt-12px" >
									<Image
										id="passportImg"
										fallback={passport}
										src="@cookie.stuPassportPhotoUrl"
										className="w-128px h-auto position-relative" />
									<Group
										id="component1650458146679"
										direction="horizontal"
										className="w-auto h-auto align-center position-relative" >
										<Icon
											id="passportUploadButton"
											onClick={passportUploadButtonOnClick}
											src="upload"
											className="w-auto h-auto border-radius-50px align-center position-relative bg-light mt-12px"
										/>
										<Icon
											id="component1650458146679"
											onClick={component1650458146679OnClick}
											src="trash-can-outline"
											className="w-auto h-auto border-radius-50px align-center position-relative bg-light ms-12px mt-12px"
										/>
									</Group>
								</Group>
							</Group>
						</Group>
					</Group>
				</Group>
				<Group
					id="uniInfoFooter1647956632262"
					direction="vertical"
					className="w-100 h-auto position-relative mt-48px" >
					<Group
						id="footerSpacer1647956728167"
						direction="vertical"
						className="w-100 min-h-300px h-300px position-relative" >
					</Group>
					<Group
						id="homeFooterBar1647956632262"
						direction="vertical"
						className="w-100 h-auto valign-end position-relative bg-footer_bg py-48px" >
						<Group
							id="homeFooterBarContent1647956632262"
							direction="auto"
							className="max-w-1200px w-100 h-auto align-center position-relative p-32px" >
							<Group
								id="homeFooterInfo1663113563638"
								direction="vertical"
								className="w-fill h-auto position-relative" >
								<Text
									id="homeFooterAbout1663113563638"
									tag="p"
									className="max-w-500px min-w-300px w-fill h-auto position-relative text-text-on-primary ff-medium" >
									{t('stu_step_1.homeFooterAbout1663113563638')}
								</Text>
								<Group
									id="homeFooterPolicy1663113563638"
									direction="auto"
									className="w-fill h-auto position-relative" >
									<Text
										id="homeFooterPrivacy1663113563638"
										onClick={homeFooterPrivacy1663113563638OnClick}
										tag="small"
										className="max-w-100 w-auto h-auto position-relative pe-8px text-gray_b ff-medium" >
										{t('stu_step_1.homeFooterPrivacy1663113563638')}
									</Text>
									<Text
										id="homeFooterPrivacySeparator1663113563638"
										tag="small"
										className="w-auto h-auto position-relative pe-8px text-gray_b ff-medium" >
										{t('stu_step_1.homeFooterPrivacySeparator1663113563638')}
									</Text>
									<Text
										id="homeFooterCookies1663113563638"
										onClick={homeFooterCookies1663113563638OnClick}
										tag="small"
										className="max-w-100 w-auto h-auto position-relative pe-8px text-gray_b ff-medium" >
										{t('stu_step_1.homeFooterCookies1663113563638')}
									</Text>
								</Group>
							</Group>
							<Group
								id="homeFooterSpacer1647956632262"
								direction="vertical"
								className="w-fill h-auto position-relative" >
							</Group>
							<Group
								id="homeFooterContact1647956632262"
								direction="vertical"
								className="max-w-400px min-w-300px w-fill h-auto position-relative" >
								<Image
									id="homeFooterLogo1647956632262"
									src={logo_72_white}
									className="w-auto min-h-72px h-72px align-end position-relative" />
								<Text
									id="homeFooterCities1647956632262"
									tag="p"
									className="w-auto h-auto align-end position-relative text-text-on-primary ff-medium text-end" >
									{t('stu_step_1.homeFooterCities1647956632262')}
								</Text>
								<Text
									id="homeFooterTel1647956632262"
									onClick={homeFooterTel1647956632262OnClick}
									tag="p"
									className="w-auto h-auto align-end position-relative text-text-on-primary ff-medium text-end" >
									{t('stu_step_1.homeFooterTel1647956632262')}
								</Text>
								<Text
									id="homeFooterEmail1647956632262"
									onClick={homeFooterEmail1647956632262OnClick}
									tag="p"
									className="w-auto h-auto align-end position-relative text-text-on-primary ff-medium text-end" >
									{t('stu_step_1.homeFooterEmail1647956632262')}
								</Text>
								<Text
									id="homeFooterWeb1647956632262"
									onClick={homeFooterWeb1647956632262OnClick}
									tag="p"
									className="w-auto h-auto align-end position-relative text-text-on-primary ff-medium text-end" >
									{t('stu_step_1.homeFooterWeb1647956632262')}
								</Text>
								<Group
									id="homeBlueSSNN1647956632262"
									direction="horizontal"
									className="w-auto h-auto align-end position-relative" >
									<Icon
										id="homeBlueInstagramIcon1647956632262"
										onClick={homeBlueInstagramIcon1647956632262OnClick}
										src="instagram"
										color="var(--text-on-primary)"
										className="w-auto h-auto position-relative me-12px"
									/>
									<Icon
										id="homeBlueFacebookIcon1647956632262"
										onClick={homeBlueFacebookIcon1647956632262OnClick}
										src="facebook"
										color="var(--text-on-primary)"
										className="w-auto h-auto position-relative me-12px"
									/>
									<Icon
										id="homeBlueTwitterIcon1647956632262"
										onClick={homeBlueTwitterIcon1647956632262OnClick}
										src="twitter"
										color="var(--text-on-primary)"
										className="w-auto h-auto position-relative me-12px"
									/>
									<Icon
										id="homeBlueYoutubeIcon1647956632262"
										onClick={homeBlueYoutubeIcon1647956632262OnClick}
										src="youtube"
										color="var(--text-on-primary)"
										className="w-auto h-auto position-relative"
									/>
								</Group>
							</Group>
						</Group>
					</Group>
				</Group>
			</Group>
      </FullscreenWrapper>
    </>
  );
};
