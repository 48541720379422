import React from 'react';
import { useDialogs } from '../hooks';
import { useFunctions } from '../hooks';

// Custom imports:
import { Modal, Input, Button } from '../components';
import { useTranslation } from 'react-i18next';

const Admin_newhousingDialog = () => {
    const { run } = useFunctions();
    // Custom exports:
	const { t } = useTranslation();

    // Custom functions:
	const admin_newhousing_buttons_positiveOnClick = (options) => {
		run([{"function":"add","what":{"position":0,"description_es":"@property.housingDescription_es","description_en":"@property.housingDescription_en"},"to":"@firebase.firestore.schools.(@cookie.universityID).housing","if":{"what":"@property.housingKey","is":false,"else":[{"function":"add","what":{"description_es":"@property.housingDescription_es","description_en":"@property.housingDescription_en"},"to":"@firebase.firestore.schools.(@cookie.universityID).housing","key":"@property.housingKey"}]}},{"function":"hide","dialog":"admin_newhousing"}], options)
	}
	const admin_newhousing_buttons_negativeOnClick = (options) => {
		run([{"function":"hide","dialog":"admin_newhousing"}], options)
	}

  return (
    
    
			<Modal id="admin_newhousing">
					<Modal.Body>
						<Input.Text
							id="newHousingDescription"
							label={t('admin_newhousing.newHousingDescription.label')}
							name="text"
							src="@property.housingDescription_es"
							className="w-auto h-auto position-relative mt-24px" />
						<Input.Text
							id="newHousingDescription1665393262989"
							label={t('admin_newhousing.newHousingDescription1665393262989.label')}
							name="text"
							src="@property.housingDescription_en"
							className="w-auto h-auto position-relative mt-24px" />
					</Modal.Body>
					<Modal.Footer>
						<Button
							id="admin_newhousing_buttons_negative"
							onClick={admin_newhousing_buttons_negativeOnClick}
							variant="transparent"
							className="w-auto h-auto position-relative" >
							{t('admin_newhousing.admin_newhousing_buttons_negative')}
						</Button>
						<Button
							id="admin_newhousing_buttons_positive"
							onClick={admin_newhousing_buttons_positiveOnClick}
							variant="primary"
							className="w-auto h-auto position-relative" >
							{t('admin_newhousing.admin_newhousing_buttons_positive')}
						</Button>
					</Modal.Footer>
			</Modal>
    
  );
};

export default Admin_newhousingDialog;
