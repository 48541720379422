import React from 'react';
import { useDialogs } from '../hooks';
import { useFunctions } from '../hooks';

// Custom imports:
import { Modal, Text, Button } from '../components';
import { useTranslation } from 'react-i18next';

const Stu_must_premiumDialog = () => {
    const { run } = useFunctions();
    // Custom exports:
	const { t } = useTranslation();

    // Custom functions:
	const stu_must_premium_buttons_positiveOnClick = (options) => {
		run([{"function":"set","what":"@property.premiumError","value":null},{"function":"hide","dialog":"stu_must_premium"}], options)
	}

  return (
    
    
			<Modal id="stu_must_premium">
					<Modal.Body>
						<Text
							id="mustPremiumLabel"
							className="w-100 h-auto position-relative" >
							{t('stu_must_premium.mustPremiumLabel')}
						</Text>
						<Text
							id="mustPremiumLabel1652172183138"
							className="w-100 h-auto position-relative mt-24px" >
							{t('stu_must_premium.mustPremiumLabel1652172183138')}
						</Text>
					</Modal.Body>
					<Modal.Footer>
						<Button
							id="stu_must_premium_buttons_positive"
							onClick={stu_must_premium_buttons_positiveOnClick}
							variant="primary"
							className="w-auto h-auto position-relative" >
							{t('stu_must_premium.stu_must_premium_buttons_positive')}
						</Button>
					</Modal.Footer>
			</Modal>
    
  );
};

export default Stu_must_premiumDialog;
